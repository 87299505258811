/*--------------------------------------------------------------------------------------------------- */
/*----------------------------------------- ScrollBar Style ---------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

/*--------------------------------------------------------------------------------------------------- */
/*----------------------------------------- ScrollBar Style ---------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.tfm-body.c-scrollbar_thumb {
      background-color: var(--bs-tfm-red);
}
 
/* Handle */
.tfm-body::-webkit-scrollbar-thumb {
      background: var(--bs-tfm-red) !important ; 
}
    
/* Handle on hover */
.tfm-body::-webkit-scrollbar-thumb:hover {
      background: var(--bs-tfm-red) !important; 
}
.tfm-body .c-scrollbar_thumb {
      background-color: var(--bs-tfm-red);
}

/*--------------------------------------------------------------------------------------------------- */
/*---------------------------------------------- Header --------------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.tfm-wrapper .main-header__navbar  .nav-link,
.tfm-wrapper .main-header__navbar  .dropdown-item{
      color: #000;
      
}

.tfm-wrapper .main-header__navbar .navbar-brand::before {
      background-image: url("./assets/images/TFM-logo-colour-mobile.svg");
      height: 27px;
      width: 238px;
}

.tfm-wrapper .navbar-brand__logo {
      width: 268px;
}

.tfm-wrapper .main-header__navbar #btnLogin,
.tfm-wrapper .main-header__navbar .nav-link.btn-primary {
      background-color: var(--bs-tfm-red);
      color: #fff;
      border-color: var(--bs-tfm-red);
}

.tfm-wrapper .main-header__navbar #btnLogin:active,
.tfm-wrapper .main-header__navbar #btnLogin:hover,
.tfm-wrapper .main-header__navbar #btnLogin:focus,
.tfm-wrapper .main-header__navbar .nav-link.btn-primary:active,
.tfm-wrapper .main-header__navbar .nav-link.btn-primary:hover,
.tfm-wrapper .main-header__navbar .nav-link.btn-primary:focus {
      background-color: #fff;
      color: var(--bs-tfm-red);
      border-color: var(--bs-tfm-red);
}

.tfm-wrapper .main-header__navbar .nav-link.btn-primary:hover::before,
.tfm-wrapper .main-header__navbar .nav-link.btn-primary:active::before, 
.tfm-wrapper .main-header__navbar .nav-link.btn-primary:focus::before {
      background-color: var(--bs-tfm-red);
}

.tfm-wrapper .has-icon:hover .icon-bg.icon-wallet,
.tfm-wrapper .has-icon:active .icon-bg.icon-wallet, 
.tfm-wrapper .has-icon:focus .icon-bg.icon-wallet {
      background-image: url(./assets/images/icon-wallet-red-tfm.svg);
}

.tfm-wrapper .main-header__navbar .nav-link:active, 
.tfm-wrapper .main-header__navbar .nav-link:hover,
.tfm-wrapper .main-header__navbar .nav-link:focus {
      color: var(--bs-tfm-red);
}

.tfm-wrapper .btn-primary + .dropdown-menu > li .dropdown-item:hover::before {
      background-image: url(./assets/images/icon-arrow-right-22x21-red-tfm.svg);
}



@media (max-width: 1199px) {

      .tfm-wrapper .main-header__navbar .dropdown-item:focus, .tfm-wrapper  .main-header__navbar .dropdown-item:hover {
            color: var(--bs-tfm-red);
      }

      .tfm-wrapper .main-header__navbar .navbar-brand::before {
            background-size: 238px auto;
            background-position: left bottom;
      }

      
}

@media (min-width: 1200px) {

      
      .tfm-wrapper .navbar-brand__logo {
            width: 334px;
      }

      .tfm-wrapper .btn-primary + .dropdown-menu > li .dropdown-item {
            background-color: var(--bs-tfm-red);
            border-color: var(--bs-tfm-red);
            border-bottom-color: #fff;
      }

      .tfm-wrapper .main-header__main-menu .btn-primary + .dropdown-menu > li .dropdown-item {
            color: #fff;
      }     


      .tfm-wrapper .btn-primary + .dropdown-menu > li .dropdown-item:hover,
      .tfm-wrapper .btn-primary + .dropdown-menu > li .dropdown-item:active,
      .tfm-wrapper .btn-primary + .dropdown-menu > li .dropdown-item:focus {
            color: var(--bs-tfm-red);
            background-color: #fff;
            border-bottom-color: var(--bs-tfm-red);
      } 

      .tfm-wrapper .dropdown-toggle.btn-primary:hover::after, 
      .tfm-wrapper .dropdown-toggle.btn-primary:active::after,
      .tfm-wrapper .dropdown-toggle.btn-primary:focus::after {
            background-image: url(./assets/images/icon-caret-down-red-tfm.svg);
      }

}
/*--------------------------------------------------------------------------------------------------- */
/*---------------------------------------------- Footer --------------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.tfm-wrapper .main-footer{
      background-color: var(--bs-tfm-red);
}

.tfm-wrapper .main-footer .logo__c {
      height: 40px;
      width: auto;
      top: -2px;
}
.tfm-wrapper .container__footer-menu .logo__ecologi__custom-text {
      /* top: -2px; */
}
.tfm-wrapper .container__footer-menu .logo__ecologi__custom-text {
      margin-top: 0px;
}



@media (min-width: 768px) {
      .tfm-wrapper .main-footer .logo__c {
            height: 52px;
            width: auto;
            top: -2px;
      }
}
/*--------------------------------------------------------------------------------------------------- */
/*--------------------------------------------- Common --------------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.tfm-wrapper .form-label-error {
      color: var(--bs-tfm-red) !important;
}

.tfm-wrapper .red {
      color: var(--bs-tfm-red) !important;
}

.tfm-wrapper a {
      color: var(--bs-tfm-red);
}

.tfm-wrapper .btn-primary {
      background-color: var(--bs-tfm-red);
      border-color: var(--bs-tfm-red);
      color: #fff;
}

.tfm-wrapper .btn-primary:hover,
.tfm-wrapper .btn-primary:active,
.tfm-wrapper .btn-primary:focus {
      background-color: #fff;
      color: var(--bs-tfm-red);
      border-color: var(--bs-tfm-red);
}


.tfm-wrapper .btn-heart.active {
      background-image: url(./assets/images/icon-heart-red-tfm.svg);
}

.tfm-wrapper .card__event-image .badge {
      background-color: var(--bs-tfm-red) !important;
}

.tfm-wrapper .card__event-image .badge__ticket-sold-out {
      background-color: transparent !important;
}

.tfm-wrapper .container__notification--alert {
      background-color: var(--bs-tfm-red);
}


.tfm-wrapper .bg-red {
      background-color: var(--bs-tfm-red) !important;
}

/*--------------------------------------------------------------------------------------------------- */
/*--------------------------------------------- Home Page ------------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.tfm-wrapper .section__cta--huge .btn-huge {
      color: #fff;
}
.tfm-wrapper .section__cta--huge .btn-huge:hover {
      background-color: #fff;
      color: var(--bs-tfm-red);
}
.tfm-wrapper .btn-primary:hover .icon-apple-as-bg {
      background-image: url(./assets/images/icon-apple-red-tfm.png);
      /* background-image: url(../images/icon-apple-red.png); */
}

.tfm-wrapper .btn-primary:hover .icon-googleplay-as-bg {
      background-image: url(./assets/images/icon-google-play-store-red-tfm.png);
}

.tfm-wrapper .owl-carousel__home-hero .item2 .hero__content {
      min-height: 375px;
}    


/*
* Item 2 Color Tickets placements for TFM 
*/
.tfm-wrapper .owl-carousel__home-hero .item2 .ticket-green {
      width: 37px;
      left: 16.75%;
      top: 29.6%;
      z-index: 2;
}

.tfm-wrapper  .owl-carousel__home-hero .item2 .ticket-aquamarine {
      width: 25px;
      bottom: 14.5%;
      left: 16.2%;
      z-index: 2;
}

.tfm-wrapper  .owl-carousel__home-hero .item2 .ticket-blue {
      width: 30px;
      bottom: 15.1%;
      right: 10.1%;
      z-index: 2;
}

.tfm-wrapper .owl-carousel__home-hero .item2 .ticket-yellow {
      width: 30px;
      right: 12.5%;
      top: 32.2%;
      z-index: 2;
}

.tfm-wrapper .owl-carousel__home-hero .hero__image::before {
      content: none;
}

@media (min-width:391px){
      .tfm-wrapper .owl-carousel__home-hero .item2 .hero__content {
            min-height: calc(414px -50px);
      }  
      
      .tfm-wrapper .owl-carousel__home-hero .item2 .ticket-green {
            width: 37px;
            left: 15.75%;
            top: 30.6%;
            z-index: 2;
      }
      
      .tfm-wrapper  .owl-carousel__home-hero .item2 .ticket-aquamarine {
            width: 25px;
            bottom: 14.5%;
            left: 14.2%;
            z-index: 2;
      }

      .tfm-wrapper  .owl-carousel__home-hero .item2 .ticket-blue {
            width: 30px;
            bottom: 15.1%;
            right: 19.1%;
            z-index: 2;
      }
      
      .tfm-wrapper .owl-carousel__home-hero .item2 .ticket-yellow {
            width: 30px;
            right: 21.5%;
            top: 32.2%;
            z-index: 2;
      }
}


@media (min-width:992px) {
      .tfm-wrapper .owl-carousel__home-hero .item2 .ticket-green {
            width: 83px;
            left: 71.75%;
            top: 81.6%;
            z-index: 1;
      }

      .tfm-wrapper  .owl-carousel__home-hero .item2 .ticket-aquamarine {
            width: 51px;
            top: 26.5%;
            left: 7.2%;
            z-index: 1;
      }

      .tfm-wrapper  .owl-carousel__home-hero .item2 .ticket-blue {
            width: 62px;
            bottom: 25.1%;
            left: 31.1%;
            z-index: 1;
      }
      
      .tfm-wrapper .owl-carousel__home-hero .item2 .ticket-yellow {
            width: 68px;
            right: 41.5%;
            top: 25.2%;
            z-index: 2;
      }

}

@media (min-width: 1400px) {
      .tfm-wrapper .content__register .row__form-fields > div:nth-child(2) {
            padding-left: calc(var(--bs-gutter-x) * .5);
      }
}

/*--------------------------------------------------------------------------------------------------- */
/*--------------------------------------- Register & Login Page ------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.tfm-wrapper .section__login .content__body .col__right .row__cta .forgot-password-link {
      color: var(--bs-tfm-red);
}

.tfm-wrapper .modal__registration-verification .content__body .form-group__button .invalid-mobile-number,
.tfm-wrapper #modalVerificationCode .invalid-otp {
      color: var(--bs-tfm-red);

}

.tfm-wrapper .ts-dropdown .active,
.tfm-wrapper .ts-dropdown .active:hover,
.tfm-wrapper .ts-dropdown .option:hover {
      color: var(--bs-tfm-red);
}

.tfm-wrapper .content__register-part2 .form-group .list__tags .checkedLabel {
      background-color: var(--bs-tfm-red);
      color: #fff;
      border-color: var(--bs-tfm-red);
}

.tfm-wrapper .content__register-part2 .icon-tooltip-info {
      color: var(--bs-tfm-red);
      border-color: var(--bs-tfm-red);
}

.tfm-wrapper .content__register-part2 .error-exists {
      color: var(--bs-tfm-red);
}

.tfm-wrapper .section__register .tomselect  .ts-control{
      border-radius: 10px
}


.tfm-wrapper .section__join-alt .ts-dropdown {
      border: 1px solid !important;
}


.tfm-wrapper .section__join-alt .form-group__file {
      margin-bottom: 24px;
}

.tfm-wrapper .section__join-alt .form-group__file .form-label {
      margin-bottom: 10px;
      line-height: 14px;
}

.tfm-wrapper .section__join-alt .form-file-wrapper {
      padding-right: 29px;
}

.tfm-wrapper .section__join-alt .custom-form-file {
      position: relative;
}

.tfm-wrapper .section__join-alt .btn__custom-file-upload, 
.tfm-wrapper .section__join-alt .btn__custom-file-upload + [type="file"] {
      background-color: #d5d5d5;
      font-size: 15px;
      letter-spacing: 0.3px;
      line-height: 18px;
      min-height: 39px;
      padding: 3px 10px;
}

.tfm-wrapper .section__join-alt .btn__custom-file-upload {
      min-height: 54px;
      min-width: 141px;
      border-width: 0;
      border-radius: 113px;
}

.tfm-wrapper .section__join-alt .btn__custom-file-upload + [type="file"] {
      border: none;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      min-height: 54px;
}

.tfm-wrapper .section__join-alt .btn__custom-file-upload + [type="file"] {
      cursor: pointer;
      width: 141px;
}

.tfm-wrapper .section__join-alt .p-asterisk {
      font-size: 12px;
      letter-spacing: 0.24px;
      line-height: 14px;

}

.tfm-wrapper .section__join-alt .dummy-image-preview,
.tfm-wrapper .section__join-alt .image-preview {
      width: 141px;
      height: 80px;
      border-radius: 10px;
      margin-top: 10px;
      background: rgb(213, 213, 213);
}

.tfm-wrapper .section__join-alt .image-preview {
      display: flex;
}



.tfm-wrapper .section__join-alt .form-group__cta .btn.btn__back {
      border-width: 0;
      border-radius: 113px;
      line-height: 30px;
      padding: 3px 29px 4px 29px;
      margin-top: 15px;
}

.tfm-wrapper .section__join-alt .form-group__cta {
      display: flex;
      justify-content: space-between;
      align-items: center;
}



@media (max-width : 575px) {
      .tfm-wrapper .section__join-alt .p-asterisk {
            position: relative;
            top: 2px;
      }

      .tfm-wrapper .section__join-alt .form-group__cta {
            display: block;
      }

      .tfm-wrapper .section__join-alt .form-group__cta .btn.btn__back {
            justify-content: center;
      }
}

@media (max-width : 1200px) {

      .tfm-wrapper .section__join-alt .form-group__file .form-label {
            margin-bottom: 5x;
      }
}

@media (min-width: 1400px) {
      .tfm-wrapper .section__join-alt textarea.form-control {
            min-height: 174px;
            padding-top: 15px;
            padding-bottom: 15px;
      }

      .tfm-wrapper .section__join-alt .form-group__file .form-file-wrapper {
            padding-right: 29px;
            margin-bottom: 14px;
      }
}
/*--------------------------------------------------------------------------------------------------- */
/*----------------------------------------- Contact Us Page  ---------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */


.tfm-wrapper .main__contact-us .content__footer .p-accordion-btn a {
      color: var(--bs-tfm-red) !important;
}

.tfm-wrapper  .section__contact-us .btn-outline-primary.hover-primary:hover,
.tfm-wrapper  .section__contact-us .btn-outline-primary.hover-primary:focus,
.tfm-wrapper  .section__contact-us .btn-outline-primary.hover-primary:active {
      background-color: var(--bs-tfm-red);
      border-color: var(--bs-tfm-red);
      color: #fff;
}


.tfm-wrapper  .section__contact-us .custom_contact_button {
      border-color: var(--bs-tfm-red) !important;
      color: var(--bs-tfm-red) !important;
}

.tfm-wrapper  .section__contact-us .custom_contact_button:hover,
.tfm-wrapper  .section__contact-us .custom_contact_button:active,
.tfm-wrapper  .section__contact-us .custom_contact_button:focus {
      color: #fff !important;
      background-color: var(--bs-tfm-red) !important;
}

.tfm-wrapper .main__contact-us .section__no-my-bookings {
      color: var(--bs-tfm-red);
}

.tfm-wrapper .main__contact-us .btn.btn-primary:hover, 
.tfm-wrapper .main__contact-us .btn.btn-primary:active,
.tfm-wrapper .main__contact-us .btn.btn-primary:focus {
      background-color: #fff;
      color: var(--bs-tfm-red);
      border-color: var(--bs-tfm-red);
}



/*--------------------------------------------------------------------------------------------------- */
/*------------------------------------------- Privacy Page  ----------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

/*--------------------------------------------------------------------------------------------------- */
/*-------------------------------------------- Terms Page  ------------------------------------------ */
/*--------------------------------------------------------------------------------------------------- */

/*--------------------------------------------------------------------------------------------------- */
/*-------------------------------------------- Faqs Page  ------------------------------------------ */
/*--------------------------------------------------------------------------------------------------- */

/*--------------------------------------------------------------------------------------------------- */
/*----------------------------------------- Access needs Page  --------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

/*--------------------------------------------------------------------------------------------------- */
/*----------------------------------------- New Look Page  --------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */
.tfm-wrapper .bg-red {
      background-color: var(--bs-tfm-red) !important;
}

.tfm-wrapper section.bg-red .btn-primary {
      background-color: var(--bs-tfm-red);
}

.tfm-wrapper section.bg-red .btn-primary:hover,
.tfm-wrapper section.bg-red .btn-primary:focus
 {
      background-color: #fff;
      border-color: #fff;
      color: var(--bs-tfm-red);
  }


/*--------------------------------------------------------------------------------------------------- */
/*----------------------------------------- About Us Page  --------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */
.tfm-wrapper .main__about-us .section__NHSCT.bg-blue .content__NHSCT .btn-primary {
      color: var(--bs-white);
}

.tfm-wrapper #sectionThanks .btn-primary,
.tfm-wrapper #sectionThanks .btn-primary:active,
.tfm-wrapper #sectionThanks .btn-primary :hover {
      color: var(--bs-tfm-red);
}


/*--------------------------------------------------------------------------------------------------- */
/*-----------------------------------------  Current Listing  --------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */


.tfm-wrapper .section__current-listings .ts-dropdown .active, .ajtix-current-listing .ts-dropdown .option:hover {
      color: var(--bs-tfm-red);
}

.tfm-wrapper .section__current-listings .owl-carousel__event-tags .badge-outline{
      border-color: var(--bs-tfm-red);
      color: var(--bs-tfm-red);
}

.tfm-wrapper .section__current-listings #collapseListingFilters .list__event-tags-wrapper .list__event-tags div .badge.active,
.tfm-wrapper .section__current-listings #collapseListingFilters .list__event-tags-wrapper .list__event-tags div:hover .badge.active {
      background-color: var(--bs-tfm-red);
      border-color: var(--bs-tfm-red);
      color: #fff;
}

.tfm-wrapper .section__current-listings #collapseListingFilters .list__event-tags-wrapper .list__event-tags div .badge.active:hover,
.tfm-wrapper .section__current-listings #collapseListingFilters .list__event-tags-wrapper .list__event-tags div .badge.active:focus {
      background-color: var(--bs-tfm-red);
      border-color: var(--bs-tfm-red);
      color: #fff;
}

.tfm-wrapper .section__current-listings #collapseListingFilters .list__event-tags-wrapper .list__event-tags div:hover .badge-outline {
      border-color: var(--bs-tfm-red);
      color: var(--bs-tfm-red);
}

.tfm-wrapper .section__current-listings #collapseListingFilters #selectLocation + .ts-wrapper .ts-dropdown.multi .selected {
      color: var(--bs-tfm-red);
}

.tfm-wrapper .section__current-listings #collapseListingFilters #selectLocation + .ts-wrapper .ts-control>div.item {
      background-color: var(--bs-tfm-red);
}

.tfm-wrapper .section__current-listings .collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected, 
.tfm-wrapper .section__current-listings .slidein__book-tickets .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected, 
.tfm-wrapper .section__current-listings .slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected {
      color: #fff;
}

.tfm-wrapper .section__current-listings .collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected:hover, 
.tfm-wrapper .section__current-listings .slidein__book-tickets .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected:hover, 
.tfm-wrapper .section__current-listings .slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected:hover {
      color: var(--bs-tfm-red);
}


.tfm-wrapper .section__current-listings .collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected::before, 
.tfm-wrapper .section__current-listings .slidein__book-tickets .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected::before, 
.tfm-wrapper .section__current-listings .slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected::before {
     background-color: var(--bs-tfm-red);
     border-color: var(--bs-tfm-red);
}


.tfm-wrapper .section__current-listings .collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.react-datepicker__day--selected:hover::before,
.tfm-wrapper .section__current-listings .slidein__book-tickets .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.react-datepicker__day--selected:hover::before, 
.tfm-wrapper .section__current-listings .slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.react-datepicker__day--selected:hover::before {
      background-color: #fff;
      border-color: var(--bs-tfm-red);
      color: var(--bs-tfm-red);
}     

.tfm-wrapper .section__current-listings .card__event .card-title a:hover {
      color: var(--bs-tfm-red);
}

.tfm-wrapper .section__current-listings  .btn-outline-primary.hover-primary:hover {
      background-color: var(--bs-tfm-red);
      border-color: var(--bs-tfm-red);
      color: #fff;
}

.tfm-wrapper .current-listing-spinner {
      color: var(--bs-tfm-red) !important;
}

.tfm-wrapper .slidein__book-tickets .ct_widget_spinner__alone, 
.tfm-wrapper .slidein__wallet-topup .ct_widget_spinner__alone {
      color: var(--bs-tfm-red);
}

/*--------------------------------------------------------------------------------------------------- */
/*------------------------------------------  Event Details  ---------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.tfm-wrapper .section__event-details .badge.bg-danger, 
.tfm-wrapper .section__event-details .badge.bg-red {
      background-color: var(--bs-tfm-red) !important;
      border-color: var(--bs-tfm-red) !important;
}


/*--------------------------------------------------------------------------------------------------- */
/*------------------------------------------  Booking Module  ---------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */


.tfm-wrapper .booking-module-slidein-wrapper .content__checkout .content__footer .btn, 
.tfm-wrapper .booking-module-slidein-wrappe .content__checkout .content__footer #btnContinueStep2.btn-primary:hover,
.tfm-wrapper .booking-module-slidein-wrapper .content__checkout .content__footer .btn, 
.tfm-wrapper .booking-module-slidein-wrappe .content__checkout .content__footer #btnContinueStep2.btn-primary:active,
.tfm-wrapper .booking-module-slidein-wrapper .content__checkout .content__footer .btn, 
.tfm-wrapper .booking-module-slidein-wrappe .content__checkout .content__footer #btnContinueStep2.btn-primary:focus {
      background-color: var(--bs-tfm-red);
      color: #fff;
}

.tfm-wrapper .booking-module-slidein-wrapper .content-scrollable.content-scrollable-in-steps .bookTicketsStep2__wrapper #btnBackToStep1,
.tfm-wrapper .booking-module-slidein-wrapper .content-scrollable.content-scrollable-in-steps .bookTicketsStep3__wrapper #btnBackToStep2,
.tfm-wrapper .booking-module-slidein-wrapper .content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaWallet__wrapper #btnBackToStep3FromPayViaWallet,
.content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaWalletPlusCard__wrapper #btnBackToStep3FromPayViaWalletPlusCard,
.content-scrollable.content-scrollable-in-steps .bookTicketsStep5OrderSummaryPayViaWalletPlusCard__wrapper .content__footer .btn__back.btn,
.content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaCard__wrapper #btnBackToStep3FromPayViaCard {
      color: #000;
}

.tfm-wrapper .booking-module-slidein-wrapper .content-scrollable.content-scrollable-in-steps .bookTicketsStep2__wrapper #btnBackToStep1:hover,
.tfm-wrapper .booking-module-slidein-wrapper .content-scrollable.content-scrollable-in-steps .bookTicketsStep3__wrapper #btnBackToStep2:hover,
.tfm-wrapper .booking-module-slidein-wrapper .content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaWallet__wrapper #btnBackToStep3FromPayViaWallet:hover,
.tfm-wrapper .content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaWalletPlusCard__wrapper #btnBackToStep3FromPayViaWalletPlusCard:hover,
.tfm-wrapper .content-scrollable.content-scrollable-in-steps .bookTicketsStep5OrderSummaryPayViaWalletPlusCard__wrapper .content__footer .btn__back.btn:hover,
.tfm-wrapper .content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaCard__wrapper #btnBackToStep3FromPayViaCard:hover {
      color: var(--bs-tfm-red);
}

.tfm-wrapper .booking-module-slidein-wrapper #btnPaymentMethodFullWalletCredit:hover,
.tfm-wrapper .booking-module-slidein-wrapper #btnPaymentMethodFullWalletCredit:focus,
.tfm-wrapper .booking-module-slidein-wrapper #btnPaymentMethodFullWalletCredit:active,
.tfm-wrapper .booking-module-slidein-wrapper #btnPaymentMethodPartWalletCredit:hover,
.tfm-wrapper .booking-module-slidein-wrapper #btnPaymentMethodPartWalletCredit:focus,
.tfm-wrapper .booking-module-slidein-wrapper #btnPaymentMethodPartWalletCredit:active,
.tfm-wrapper .booking-module-slidein-wrapper #btnRestartBooking:hover,
.tfm-wrapper .booking-module-slidein-wrapper #btnRestartBooking:focus,
.tfm-wrapper .booking-module-slidein-wrapper #btnRestartBooking:active  {
      background-color: #fff;
      color: var(--bs-tfm-red);
      border-color: var(--bs-tfm-red);
}


.tfm-wrapper .booking-module-slidein-wrapper .anchor-style {
      color: var(--bs-tfm-red) !important;
}

.tfm-wrapper .booking-module-slidein-wrapper .btn-circle-x:hover, 
.tfm-wrapper .booking-module-slidein-wrapper .btn__plus-to-x:hover {
      background-color: var(--bs-tfm-red);
      border-color: var(--bs-tfm-red);
}


.tfm-wrapper .booking-module-slidein-wrapper  .btn-outline-primary:hover, 
.tfm-wrapper .booking-module-slidein-wrapper  .btn-check:active + .btn-outline-primary, 
.tfm-wrapper .booking-module-slidein-wrapper  .btn-check:checked + .btn-outline-primary, 
.tfm-wrapper .booking-module-slidein-wrapper  .btn-outline-primary.active, 
.tfm-wrapper .booking-module-slidein-wrapper  .btn-outline-primary.dropdown-toggle.show, 
.tfm-wrapper .booking-module-slidein-wrapper  .btn-outline-primary:active {
      color: var(--bs-tfm-red);
      border-color: var(--bs-tfm-red);
}

.tfm-wrapper .booking-module-slidein-wrapper .btn__payment-method:hover {
      border-color: var(--bs-tfm-red);
      box-shadow: 0 0 0 1px var(--bs-tfm-red);
}

.tfm-wrapper .booking-module-slidein-wrapper .btn__payment-method:hover .sub-heading__upper, 
.tfm-wrapper .booking-module-slidein-wrapper .btn__payment-method:hover .heading, 
.tfm-wrapper .booking-module-slidein-wrapper .btn__payment-method:hover .wallet-balance {
      color: var(--bs-tfm-red);
}


.tfm-wrapper .booking-module-slidein-wrapper .btn__payment-method .button__icon {
      background-color: var(--bs-tfm-red);
}


.tfm-wrapper .booking-module-slidein-wrapper .btn__payment-method:hover .button__arrow {
      background-color: var(--bs-tfm-red);
      border-color: var(--bs-tfm-red);
}

.tfm-wrapper .booking-module-slidein-wrapper .btn-outline-primary.hover-primary:hover {
      background-color: var(--bs-tfm-red);
      color: #fff;
}

/*--------------------------------------------------------------------------------------------------- */
/*------------------------------------------ Refer Friend Page ---------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */


.tfm-wrapper .section__refer-friend .input-group__invite-link .btn:hover, 
.tfm-wrapper .section__refer-friend .input-group__invite-link .btn:focus {
      background-color: #fff;
      color: var(--bs-tfm-red);
      border-color: var(--bs-tfm-red);
}

/*--------------------------------------------------------------------------------------------------- */
/*----------------------------------------My Account Home Page -------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.tfm-wrapper .section__my-account .nav-link__card{
      background-color: var(--bs-tfm-red);
      border-color: var(--bs-tfm-red);
      color: #fff;
}

.tfm-wrapper .section__my-account .nav-link__card:hover,
.tfm-wrapper .section__my-account .nav-link__card:active,
.tfm-wrapper .section__my-account .nav-link__card:focus {
      background-color: #fff;
      color: var(--bs-tfm-red);
      border-color: var(--bs-tfm-red);
}


/*--------------------------------------------------------------------------------------------------- */
/*------------------------------------------ My Account Page ---------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.tfm-wrapper .section__my-account .emailPrefrenceForm .error-exists {
      color: var(--bs-tfm-red);
}

.tfm-wrapper .section__my-account .emailPrefrenceForm .form-group__your-interests .list__tags .checkedLabel {
      background-color: var(--bs-tfm-red);
      border-color: var(--bs-tfm-red);
}


.tfm-wrapper .section__my-account .icon-tooltip-info {
      color: var(--bs-tfm-red);
      border-color: var(--bs-tfm-red);
}

.tfm-wrapper .section__my-account .ts-dropdown .active, 
.tfm-wrapper .section__my-account .ts-dropdown .create:hover, 
.tfm-wrapper .section__my-account .ts-dropdown .option:hover {
      color: var(--bs-tfm-red);
}

.tfm-wrapper .emailPrefrenceForm .form-group__list-tags .list__tags .checkedLabel, 
.tfm-wrapper .emailPrefrenceForm .form-group__your-interests .list__tags .checkedLabel {
      background-color: var(--bs-tfm-red);
      border-color: var(--bs-tfm-red);
}

.tfm-wrapper .modal__my-account-delete-card .modal-content  {
      background-image: none;
}
/*--------------------------------------------------------------------------------------------------- */
/*--------------------------------- My Booking & Manage Booking Page --------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.tfm-wrapper .slidein__manage-booking .btn__payment-method:hover{
      border-color: var(--bs-tfm-red);
      box-shadow: 0 0 0 1px var(--bs-tfm-red);
}

.tfm-wrapper .slidein__manage-booking  .btn__payment-method:hover .sub-heading__upper, 
.tfm-wrapper .slidein__manage-booking  .btn__payment-method:hover .heading, 
.tfm-wrapper .slidein__manage-booking  .btn__payment-method:hover .wallet-balance {
      color: var(--bs-tfm-red);
}

.tfm-wrapper .slidein__manage-booking .btn__payment-method:hover .button__arrow {
      background-color: var(--bs-tfm-red);
      border-color: var(--bs-tfm-red);
}

.tfm-wrapper .slidein__manage-booking .ts-dropdown .active, 
.tfm-wrapper .slidein__manage-booking .ts-dropdown .create:hover, 
.tfm-wrapper .slidein__manage-booking .ts-dropdown .option:hover {
      color: var(--bs-tfm-red);
}

.tfm-wrapper .section__my-bookings .section__no-my-bookings {
      color: var(--bs-tfm-red);
}

/*--------------------------------------------------------------------------------------------------- */
/*------------------------------------- My Favourites Page ----------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */


.tfm-wrapper .section__my-favourites .section__no-my-favourites {
      color: var(--bs-tfm-red);
}

.tfm-wrapper .section__my-favourites .card__event .card-title a:hover {
      color: var(--bs-tfm-red);
}

.tfm-wrapper .section__my-favourites .btn-outline-primary.hover-primary:hover {
      background-color: var(--bs-tfm-red);
      border-color: var(--bs-tfm-red);
      color: #fff;
}


/*--------------------------------------------------------------------------------------------------- */
/*------------------------------------ My Wallet & Gift Voucher -------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.tfm-wrapper .section__my-wallet .card__credit-balance {
      border-color: var(--bs-tfm-red);
      background-color: var(--bs-tfm-red);
}

.tfm-wrapper .content__my-wallet .p-top-up .btn:hover,
.tfm-wrapper .content__my-wallet .p-top-up .btn:active,
.tfm-wrapper .content__my-wallet .p-top-up .btn:focus {
      background-color: var(--bs-tfm-red);
      color: #fff;
      border-color: var(--bs-tfm-red);
}


.tfm-wrapper .slidein__wallet-topup .bottom-text span:last-child {
      color: var(--bs-tfm-red) !important;
}

.tfm-wrapper .slidein__wallet-topup .btn__payment-method:hover {
      border-color: var(--bs-tfm-red);
      box-shadow: 0 0 0 1px var(--bs-tfm-red);
}

.tfm-wrapper .slidein__wallet-topup .btn__payment-method:hover .heading {
      color: var(--bs-tfm-red);
}

.tfm-wrapper .slidein__wallet-topup .btn__payment-method:hover .sub-heading__upper {
      color: var(--bs-tfm-red);
}

.tfm-wrapper .slidein__wallet-topup .btn__payment-method:hover .button__arrow {
      background-color: var(--bs-tfm-red);
      border-color: var(--bs-tfm-red);
}

.tfm-wrapper .slidein__wallet-topup .btn__payment-method .button__icon--debitcredit,
.tfm-wrapper .slidein__wallet-topup .btn__payment-method .button__icon--wallet
 {
      background-color: var(--bs-tfm-red);
}

.tfm-wrapper .slidein__wallet-topup #btnWalletTopUp:hover,
.tfm-wrapper .slidein__wallet-topup #btnWalletTopUp:active,
.tfm-wrapper .slidein__wallet-topup #btnWalletTopUp:focus {
      background-color: var(--bs-tfm-red);
      color: #fff;
}

.tfm-wrapper .slidein__wallet-topup #btnBackToStep1:hover {
      color: var(--bs-tfm-red);
}

.tfm-wrapper .col__transaction-history .col__no-transaction-history {
      background-color: var(--bs-tfm-red);
}
.tfm-wrapper .col__transaction-history .btn-outline-primary.hover-primary:hover,
.tfm-wrapper .col__transaction-history .btn-outline-primary.hover-primary:active,
.tfm-wrapper .col__transaction-history .btn-outline-primary.hover-primary:focus
 {
      background-color: var(--bs-tfm-red);
}

.tfm-wrapper .slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected {
      color: #fff;
}

.tfm-wrapper .slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected::before {
      background-color: var(--bs-tfm-red);
      border-color: var(--bs-tfm-red);
}   

.tfm-wrapper .slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected:hover::before {
      background-color: #fff;
      border-color: var(--bs-tfm-red) !important;
}  

.tfm-wrapper .slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected:hover {
      color: var(--bs-tfm-red);
}

/*--------------------------------------------------------------------------------------------------- */
/*-------------------------------------- Leave Feedback page ---------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.tfm-wrapper .section__leave-feedback .error-exists{
      color: var(--bs-tfm-red);
}

.tfm-wrapper .section__leave-feedback .btn-outline-primary.hover-primary:hover,
.tfm-wrapper .section__leave-feedback .btn-outline-primary.hover-primary:active,
.tfm-wrapper .section__leave-feedback  .form-check-input:checked + .btn-outline-primary.hover-primary {
      background-color: var(--bs-tfm-red);
      border-color: var(--bs-tfm-red);
      color: #fff;
}

.tfm-wrapper .section__leave-feedback .form-group__cta button:first-child {
      color: #000;
      border-color: #000;
      background-color: #fff;
}

.tfm-wrapper .section__leave-feedback .form-group__cta button:first-child:hover {
      color: var(--bs-tfm-red);
      border-color: var(--bs-tfm-red);
      background-color: #fff;
}


.tfm-wrapper .container__leave-feedback:after, .tfm-wrapper .container__leave-feedback:before {
      background-image: none;
}

/*--------------------------------------------------------------------------------------------------- */
/*--------------------------------- Uptake & Uptake by Venue page ----------------------------------- */
/*--------------------------------------------------------------------------------------------------- */


.tfm-wrapper .main__uptake .content__uptake .list__uptake-details .list-item__allocated .heading, 
.tfm-wrapper .main__uptake .content__uptake .list__uptake-details .list-item__booked .heading {
      color: var(--bs-tfm-red);
}

.tfm-wrapper .main__uptake .list__uptake-details .heading {
      color: var(--bs-tfm-red);
}

.tfm-wrapper .main__uptake .content__uptake-event .p-important-wrapper {
      border-top-color: var(--bs-tfm-red);
}

.tfm-wrapper .main__uptake .progress-bar-overlay .progress-bar {
      background-color: var(--bs-tfm-red);
}

.tfm-wrapper .main__uptake .no-uptake-event {
      color: var(--bs-tfm-red);
}


/*--------------------------------------------------------------------------------------------------- */
/*------------------------------------ Reactive Account & Modal ------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.tfm-wrapper .reactive-account #btnBackToStep3FromPayViaCard:hover{
      color: var(--bs-tfm-red);
}

.tfm-wrapper .reactive-account #btnContinueStep5 {
      background-color: var(--bs-tfm-red);
      color: #fff;
}


/*--------------------------------------------------------------------------------------------------- */
/*------------------------------------------- OTP Modal -------------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.tfm-wrapper .form-group__cta.d-flex .btn {
      background-color: var(--bs-tfm-red);
      color: #fff;
      border-color: var(--bs-tfm-red);
}

.tfm-wrapper#modalVerificationCode .invalid-otp {
      color: var(--bs-tfm-red);
}

/*--------------------------------------------------------------------------------------------------- */
/*---------------------------------- Underrepresented Communities ----------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.tfm-wrapper .main__underrepresented_communities .owl-carousel__event-tags .badge-outline{
      color: var(--bs-tfm-red);
      border-color: var(--bs-tfm-red);
}

.tfm-wrapper .main__underrepresented_communities .list-underrepresented-detail .heading {
      color: var(--bs-tfm-red);
}

/*--------------------------------------------------------------------------------------------------- */
/*-------------------------------------- Member Feedback Report ------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.tfm-wrapper .section__feedback-report .ct_bar_section {
      background-color: var(--bs-tfm-red);
}

.tfm-wrapper .section__feedback-report .ct_progress_sub {
      background-color: var(--bs-tfm-red);
}

.tfm-wrapper .section__feedback-report .ct_report_question_sub,
.tfm-wrapper .section__feedback-report .ct_percentage {
      color: var(--bs-tfm-red);
}

.tfm-wrapper .ct_slider,
.tfm-wrapper  .slider_div_right,
.tfm-wrapper .slider_div_left{
      background-color: var(--bs-tfm-red);
}


/*--------------------------------------------------------------------------------------------------- */
/*------------------------------------------ ETicket Page ------------------------------------------ */
/*--------------------------------------------------------------------------------------------------- */
.tfm-wrapper .section__etickets .qr_container .download_btn_container .btn-group .download-ticket-btn {
      background-color: var(--bs-tfm-red);
}

.tfm-wrapper .section__etickets .help-link-block a.help-link {
      color: var(--bs-tfm-red);
}

.tfm-wrapper .section__etickets .button-card-wrapper .sharebtn {
      color:  var(--bs-tfm-red);
}

/*--------------------------------------------------------------------------------------------------- */
/*------------------------------------------ Cookies Page ------------------------------------------ */
/*--------------------------------------------------------------------------------------------------- */
.tfm-wrapper.modal-cookies .form-group__cta .btn.reject {
      background-color: transparent;
      color: var(--bs-tfm-red);
}