.section__feedback-report .content {
      margin-bottom: 13px;

}

.section__feedback-report .content__feedback-report {
      max-width: 960px;
      position: relative;
      margin: 0px auto;
      padding: 4vw;
}

.section__feedback-report .big-heading h1 {
      font-size: 47px;
      line-height: 50px;
      padding-bottom: 30px;
}

.section__feedback-report .big-heading h2 {
      font-size: 25px;
      text-align: center;
}

.section__feedback-report .header_subtitle {
      font-size: 21px;
      line-height: 1.45;
      margin-bottom: 48px;
      margin-top: 0px;
      font-weight: bold;
      color: black;
      text-align: center;
}


.section__feedback-report .ct_report_title {
      /* margin-bottom: 40px; */
      display: flex;
      justify-content: left;
}

.section__feedback-report  .ct_report_sub_title {
      display: flex;
      flex-direction: column;
      -webkit-box-pack: center;
      justify-content: center;
}
  
.section__feedback-report .ct_rt_text {
      margin-top: 0px;
      font-weight: 400;
      font-size: 24px;
      line-height: 1.3;
      margin-bottom: 8px;
      color:black;
      white-space: pre-line;
}

.section__feedback-report .ct_rt_sub_text {
      margin-top: 0px;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.45;
      color:black;
}

.section__feedback-report .ct-chart {
      display: grid;
      row-gap: 32px;	
}

.section__feedback-report .ct-chart-section {
      display: grid;
      row-gap: 32px;
}

.section__feedback-report .ct_bar {
      display: grid;
      grid-template-rows: auto 20px;
      grid-template-columns: 50px auto auto;
      grid-template-areas:
      "header information responses"
      "header bar bar";
      row-gap: 8px;
      column-gap: 16px;
      font-size: 16px;
      line-height: 1.5;
}

.section__feedback-report  .ct_bar_section {
      width: 100%;
      height: 100%;
      color: black;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      font-size: 20px;
      line-height: 1.4;
      color: rgb(255, 255, 255);
      background-color: #ff0a41;
      grid-area: header / header / header / header;
      border-radius: 8px;
}

.section__feedback-report .ct_report_question {
      color: black;
      background-color: inherit;
      text-overflow: ellipsis;
      grid-area: information / information / information / information;
      overflow: hidden;
}

.section__feedback-report .ct_percentage {
      display: flex;
      align-items: flex-end;
      -webkit-box-pack: end;
      color: #ff0a41;
      justify-content: flex-end;
      grid-area: responses / responses / responses / responses;
}

.section__feedback-report .ct_report_question_sub {	 
      display: inline;
      opacity: 0.6;
      font-size: 12px;
      line-height: 1.5;
      background-color:transparent;;
      color: #ff0a41;
}

.section__feedback-report .ct_progress {
      display: flex;
      background-color: white;
      border:1px solid black;
      grid-area: bar / bar / bar / bar;
      border-radius: 4px;	
}

.section__feedback-report .ct_progress_sub {
      background-color: #ff0a41;
      border-radius: inherit;
      transition: all 2s;
}
               .ct_custom_slider
               {
               color: rgb(255, 255, 255);
               font-size: 20px;
               line-height: 1.4;
               border-radius: 12px;
               padding: 16px 0px;
               }
               .ct_slider
               {
               hite-space: pre-line;
               height: 160px;
               background-color: #f40b42;
               display: flex;
               -webkit-box-align: center;
               align-items: center;
               border-radius: 12px;
               padding: 40px 0px;
               }
               .message_content
               {
               color: #ffff;
               }
               .ct_spend_review
               {
               height: auto;
               max-height: 60px;
               opacity: 1;
               word-break: break-word;
               letter-spacing: 0.015em;
               overflow: auto;
               transition: opacity 250ms ease 0s;
               padding: 0px 40px;
               }
               .ct_slider_review
               {
               height: auto;
               max-height: 160px;
               opacity: 1;
               word-break: break-word;
               letter-spacing: 0.015em;
               overflow: auto;
               transition: opacity 250ms ease 0s;
               padding: 0px 40px;
               }
               .slider_custom_btn
               {
               margin-top: 24px;
               align-self: center;
               justify-self: start;
               display: flex;	
               }
               .slider_div_left
               {
               width: 32px;
               height: 32px;
               box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px 0px;
               margin-right: 16px;
               background-color: rgb(244 11 66);
               cursor: pointer;
               border-radius: 32px;
               border-width: initial;
               border-style: none;
               border-color: initial;
               border-image: initial;
               transition: opacity 0.3s ease 0s, transform 0.3s ease 0s, box-shadow 0.3s ease 0s;
               outline: none;
               }
               .slider_div_right
               {
               width: 32px;
               height: 32px;
               box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px 0px;
               margin-right: 16px;
               background-color: rgb(244 11 66);
               cursor: pointer;
               border-radius: 32px;
               border-width: initial;
               border-style: none;
               border-color: initial;
               border-image: initial;
               transition: opacity 0.3s ease 0s, transform 0.3s ease 0s, box-shadow 0.3s ease 0s;
               outline: none;
               }
               button {
               -webkit-appearance: button;
               -webkit-writing-mode: horizontal-tb !important;
               text-rendering: auto;
               color: buttontext;
               letter-spacing: normal;
               word-spacing: normal;
               text-transform: none;
               text-indent: 0px;
               text-shadow: none;
               display: inline-block;
               text-align: center;
               align-items: flex-start;
               cursor: default;
               background-color: buttonface;
               box-sizing: border-box;
               margin: 0em;
               padding: 1px 6px;
               border-width: 2px;
               border-style: outset;
               border-color: buttonface;
               border-image: initial;
               }
               .slider_div_left button
               {	
               border-width: initial;
               border-style: none;
               border-color: initial;
               border-image: initial;
               }
               svg {
               width: 20px;
               height: 31px;
               }
               :not(svg) {
               transform-origin: 0px 0px;
               }
               .slider_custom_btn svg {
               fill: rgb(255 255 255);
               }
               .ct_slider_counter
               {
               color: black;
               font-size: 14px;
               line-height: 1.45;
               align-self: center;
               justify-self: start;
               margin-right: 24px;
               }


@media (min-width: 768px) {
      .section__feedback-report .big-heading h1 br {
            display: none;
      }

      .section__feedback-report .big-heading h1 {
            font-size: 6.3vw;
            line-height: 1em;
            padding-bottom: 10px;
      }

      .section__feedback-report .big-heading + h2 {
            font-size: 25px;
            margin-bottom: 30px;
      }
}     
/* ------------------------------------------------------------------------------ */
/* ---------------------------Header Flyer Tickets------------------------------- */
/* ------------------------------------------------------------------------------ */

.section__feedback-report .big-heading .ticket-red {
      top: 15%;
      left: 39%;
      transform: rotate(242deg);
      width: 18px;
}

.section__feedback-report .big-heading .ticket-aquamarine {
      width: 20px;
      top: -3%;
      left: 63%;
}

.section__feedback-report .big-heading .ticket-green {
      top: 27%;
      width: 18px;
      left: 19%;
}

.section__feedback-report .big-heading .ticket-yellow {
      width: 16px;
      top: 68%;
      left: 39%;
      transform: rotate(90deg);
}

.section__feedback-report .big-heading .ticket-blue {
      width: 16px;
      top: 54%;
      left: 77%;
      transform: rotate(-114deg);
}

.section__feedback-report .big-heading .ticket-green1 {
      width: 16px;
      top: 41%;
      left: 63%;
}

.section__feedback-report  .main_body {
      display: block;
}

.section__feedback-report  .main_body .report_section {
      margin-bottom: 64px;
      border-top: 1px solid rgb(235, 235, 235);
}

.section__feedback-report  .main_body .report_article {
      padding-top: 64px;
      max-width: 100%;
      flex: 1 1 0%;
      -webkit-box-align: baseline;
      align-items: baseline;
      break-inside: avoid;
      -webkit-box-pack: justify;
      justify-content: space-between;
      margin-bottom: 64px;
      border-top: 1px solid rgb(235, 235, 235);
}

.section__feedback-report  .ct_report_sub_title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      -webkit-box-pack: center;
}

.section__feedback-report  .ct_report_sub_title .ct_rt_text {
      margin-top: 0px;
      font-weight: 400;
      font-size: 24px;
      line-height: 1.3;
      margin-bottom: 8px;
      color: #000;
      white-space: pre-line;
}

.section__feedback-report  .ct_report_sub_title .ct_rt_sub_text {
      margin-top: 0px;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.45;
      color: #000;
}

.section__feedback-report .ct-chart {
      display: grid;
      row-gap: 32px;
}

.section__feedback-report .ct-chart-section  {
      display: grid;
      row-gap: 32px;
}

.section__feedback-report .ct_bar {
      display: grid;
      grid-template-rows: auto 20px;
      grid-template-columns: 50px auto auto;
      grid-template-areas: "header information responses"
                              "header bar bar";
      row-gap: 8px;
      column-gap: 16px;
      font-size: 16px;
      line-height: 1.5;
}

.section__feedback-report .ct_bar_section {
      width: 100%;
      height: 100%;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
}


@media (min-width: 768px) {
      .section__feedback-report .big-heading .ticket-red {
            top: 39%;
            left: 27%;
            width: 40px;
      }
      
      .section__feedback-report .big-heading .ticket-aquamarine {
            width: 40px;
            top: -29%;
            left: 38%;
      }
      
      .section__feedback-report .big-heading .ticket-green {
            top: 43%;
            width: 40px;
            left: 15%;
      }
      
      .section__feedback-report .big-heading .ticket-yellow {
            width: 36px;
            top: 39%;
            left: 55%;
            transform: rotate(90deg);
      }
      
      .section__feedback-report .big-heading .ticket-blue {
            width: 30px;
            top: 27%;
            left: 78%;
            transform: rotate(-114deg);
      }
      
      .section__feedback-report .big-heading .ticket-green1 {
            width: 30px;
            top: -15%;
            left: 71%;
      }

}


@media (min-width: 991px) {
      .section__feedback-report .big-heading .ticket-red {
            top: 34%;
            left: 26%;
            width: 40px;
      }
      
      .section__feedback-report .big-heading .ticket-aquamarine {
            width: 40px;
            top: -11%;
            left: 40%;
      }
      
      .section__feedback-report .big-heading .ticket-green {
            top: 50%;
            width: 40px;
            left: 14%;
      }
      
      .section__feedback-report .big-heading .ticket-yellow {
            width: 36px;
            top: 52%;
            left: 62%;
            transform: rotate(90deg);
      }
      
      .section__feedback-report .big-heading .ticket-blue {
            width: 30px;
            top: 43%;
            left: 79%;
            transform: rotate(-41deg);
      }
      
      .section__feedback-report .big-heading .ticket-green1 {
            width: 30px;
            top: -8%;
            left: 73%;
      }
}

@media (min-width: 1200px) {

      .section__feedback-report .big-heading .ticket-red {
            top: 58%;
            left: 17%;
            width: 82px;
      }
      
      .section__feedback-report .big-heading .ticket-aquamarine {
            width: 82px;
            top: -36%;
            left: 33%;
      }
      
      .section__feedback-report .big-heading .ticket-green {
            top: 51%;
            width: 82px;
            left: 1%;
      }
      
      .section__feedback-report .big-heading .ticket-yellow {
            width: 59px;
            top: 38%;
            left: 65%;
            transform: rotate(90deg);
      }
      
      .section__feedback-report .big-heading .ticket-blue {
            width: 59px;
            top: 43%;
            left: 86%;
            transform: rotate(-41deg);
      }
      
      .section__feedback-report .big-heading .ticket-green1 {
            width: 59px;
            top: -24%;
            left: 78%;
        }

      
}

@media (min-width: 1400px) {

      .section__feedback-report .content__feedback-report {
            max-width: 990px;
      }

      .section__feedback-report .big-heading .ticket-red {
            top: 54%;
            left: 16%;
            width: 82px;
      }
      
      .section__feedback-report .big-heading .ticket-aquamarine {
            width: 82px;
            top: -35%;
            left: 32%;
      }
      
      .section__feedback-report .big-heading .ticket-green {
            top: 51%;
            width: 82px;
            left: 0%;
      }
      
      .section__feedback-report .big-heading .ticket-yellow {
            width: 59px;
            top: 51%;
            left: 67%;
            transform: rotate(90deg);
      }
      
      .section__feedback-report .big-heading .ticket-blue {
            width: 59px;
            top: 47%;
            left: 90%;
            transform: rotate(-41deg);
      }
      
      .section__feedback-report .big-heading .ticket-green1 {
            width: 59px;
            top: -17%;
            left: 81%;
        }
}

@media (min-width: 1600px) {
      .section__feedback-report .content__feedback-report {
            max-width: 1020px;
      }

      .section__feedback-report .big-heading .ticket-red {
            top: 48%;
            left: 11%;
            width: 82px;
      }
      
      .section__feedback-report .big-heading .ticket-aquamarine {
            width: 82px;
            top: -24%;
            left: 30%;
      }
      
      .section__feedback-report .big-heading .ticket-green {
            top: 51%;
            width: 82px;
            left: 0%;
      }
      
      .section__feedback-report .big-heading .ticket-yellow {
            width: 59px;
            top: 51%;
            left: 67%;
            transform: rotate(90deg);
      }
      
      .section__feedback-report .big-heading .ticket-blue {
            width: 59px;
            top: 47%;
            left: 92%;
            transform: rotate(-41deg);
      }
      
      .section__feedback-report .big-heading .ticket-green1 {
            width: 59px;
            top: -17%;
            left: 81%;
        }
}


@media (min-width: 1800px) {
      .section__feedback-report .content__feedback-report {
            max-width: 1220px;
      }
}


/* --------------------------------------------------------------------------------------------- */
/* ---------------------------GetFeedback Report Dummy Components------------------------------- */
/* --------------------------------------------------------------------------------------------- */
.section__feedback-report .header_subtitle.dummy,
.section__feedback-report  h2.dummy,
.section__feedback-report .ct_report_sub_title .ct_rt_text.dummy,
.section__feedback-report .report_article.dummy .ct_report_sub_title .ct_rt_text,
.section__feedback-report .report_article.dummy .ct_report_sub_title .ct_rt_sub_text,
.section__feedback-report .report_article.dummy .ct_bar_section,
.section__feedback-report .report_article.dummy .ct_report_question,
.section__feedback-report .report_article.dummy  .ct_percentage,
.section__feedback-report .report_article.dummy  .ct_progress
{
      background: #eee;
      background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
      -webkit-animation: shine 1.5s linear infinite;

}

.section__feedback-report .header_subtitle.dummy {
      width: 100%;
      height: 30px;
}

.section__feedback-report  h2.dummy {
      width: 100%;
      height: 60px;
}

.section__feedback-report .ct_report_sub_title .ct_rt_text.dummy {
      width: 100%;
      height: 62px;
}

.section__feedback-report .report_article.dummy .ct_report_sub_title  {
      width: 100%;
}

.section__feedback-report .report_article.dummy .ct_report_sub_title .ct_rt_text {
      width: 100%;
      height: 60px;
}

.section__feedback-report .report_article.dummy .ct_report_sub_title .ct_rt_sub_text {
      width: 100%;
      height: 20px;
}

.section__feedback-report .report_article.dummy  .ct_percentage {
      height: 24px;
}
.section__feedback-report .report_article.dummy  .ct_progress
{
      border: none;
}