.section__etickets .content__feedback-report {
    padding-top: 0px;
}

.section__etickets .content__header--template {
    padding-top: 12px;
    padding-bottom: 12px;
}

.section__etickets .content__header h4 {
    margin: 0;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.3;
    color: var(--bs-body-color);
} 

.section__etickets .main_body { 
    background-color: var(--bs-body-bg);
    /* box-shadow: 0 0 2px 0 rgba(0,0,0,.2); */
    border: 1pt solid var(--bs-body-color);
    border-radius: 20pt;
    margin: auto;
    width: 100%;
}

.section__etickets .main_body .report_section {
    padding: 0px;
    border: none;
    margin-bottom: 0px;
    border-radius: 20pt;
}

.section__etickets .main_body  .ticket_container {
    display: flex;
    flex-direction: column;
    background-color: var(--bs-body-bg);
    border-radius: 20pt;
}

.section__etickets .image_container img {
    width: 100% ;
    border-radius: 10pt;
}

.section__etickets .title_container {
    padding: 16px;
}

.section__etickets .title_container h4 {
    font-size: 30px;
    line-height: 1.3;
    font-weight: 700;
    margin-bottom: 8px;
}


.section__etickets .title_container h4  + p {
    color: var(--bs-body-color);
}

.section__etickets .title_container p {
    font-size: 21px;
    line-height: 30px;
    /* color: darkgray; */
    margin-bottom: 8px;
}

.section__etickets .banner_container {
    display: flex;
    flex-direction: column;
    padding: 16px;
}

.section__etickets .banner_container .special_notes {
    border-radius: 10pt;
    padding: 1rem;
    font-size: 0.9rem;
    /* background-color: rgb(255, 252, 209); */
    background-color: transparent;
    color: var(--bs-body-color);
    text-align: left;
    display: flex;
    flex-direction: row;
    width: 100%;
    border: 1pt solid var(--bs-body-color);
}

.section__etickets .special_notes #icon {
    margin-right: 16px;
}


.section__etickets .special_notes #icon img {
    width: 20px;
    height: 20px;
}


.section__etickets .special_notes .text_container {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
}

.section__etickets .special_notes .text_container h6 {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 21px;
    color: var(--bs-body-color);
}

.section__etickets .special_notes .text_container p {
    font-size: 14px;
    margin: 0;
    padding: 0;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: var(--bs-body-color);
}

.section__etickets .special_notes .text_container p strong {
    color:  var(--bs-body-color);
}

.section__etickets .age_restrictions {
    margin-top: 16px;
    /* background-color: rgb(207, 254, 212) !important; */
}

.section__etickets .qr_container {
    display: flex;
    flex-direction: column;
    margin:0px;
    padding: 16px;
    padding-left: 0px;
    background-color: var(--bs-body-bg);
    border-radius: 20pt;
}

.section__etickets .qr_container .row,
.section__etickets .qr_container .dummy-single_ticket_container{
    margin-top: 0px;
}

.section__etickets .qr_container .download_btn_container {
    display: flex;
    justify-content: center;
    position: relative;
    margin-left: 16px;
    margin-right: 16px;
    width: -moz-fit-content;
    width: fit-content;
    align-self: flex-start;
    margin-bottom: 16px;
}

.section__etickets .qr_container .download_btn_container .btn-group {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    border-radius: 10pt;
}

.section__etickets .qr_container .download_btn_container .btn-group .download-ticket-btn {
    /* background-color:  var(--bs-red);
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 10pt;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    position: relative;
    z-index: 1;
    cursor: pointer;
    white-space: nowrap; */
}

.section__etickets .qr_container .download_btn_container .btn-group .download-ticket-btn::after {
    content: "";
    display: none;
}

.section__etickets .qr_container .download_btn_container button img {
    width: 12px;
    height: auto;
    margin-left: 12px;
}

.section__etickets .qr_container .button-popup-container {
    display: block;
    position: absolute;
    background-color: var(--bs-body-bg);
    min-width: 160px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 16px 0px;
    z-index: 1;
    border-radius: 10pt;
    border: 1px solid var(--bs-body-color);
    inset: 0px 0px auto auto;
    top: 46px;
}

.section__etickets .qr_container .button-popup-container .pdf {
    width: 380px;
    border-bottom: 1px solid var(--bs-body-color);
    padding: 12px 20px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

.section__etickets .qr_container .button-popup-container .pdf > span {
    margin-right: 10px;
}

.section__etickets .qr_container .button-popup-container .pdf button {
    padding: 4px 16px;
    border: 1px solid var(--bs-body-color);
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    cursor: pointer;
    border-radius: 10pt;
    font-weight: 500;
}

.section__etickets .qr_container .button-popup-container .pdf button:hover {
    color: white;
    background-color: var(--bs-red);
}


.section__etickets .button-card-wrapper {
    margin-bottom: 0px;
}

.section__etickets .button-card-wrapper .action-cards {
    padding: 16px;
    margin: 16px;
    background: var(--bs-body-bg);
    border-radius: 10pt;
    border: 1px solid var(--bs-body-color);
    position: relative;
}

.section__etickets .button-card-wrapper .action-cards::before {  
    /* position: absolute;
    content: "";
    left: 50%;
    bottom: calc(100% - 0.4rem);
    transform: rotate(-45deg) translateX(-50%);
    width: 3rem;
    height: 3rem;  
    border-width: 1px;
    border-style: solid;
    border-color: rgb(245, 245, 249) rgb(245, 245, 249) rgb(218, 218, 224) rgb(218, 218, 224);
    border-radius: 50%;  
    background-color: rgb(245, 245, 249);
    box-sizing: content-box; */

    position: absolute;
    content: "";
    left: 50%;
    bottom: calc(100% - 0.4rem);
    transform: rotate(-45deg) translateX(-50%);
    width: 3rem;
    height: 3rem; /* Height is half of the full circle */
    border-width: 1px;
    border-style: solid;
    border-color: rgb(245, 245, 249) rgb(245, 245, 249) rgb(218, 218, 224) rgb(218, 218, 224);
    border-radius: 50%; /* Creates a half-circle */
    background-color: var(--bs-body-bg);
    border-top-width: 0;
    border-right-width: 0;
    border-top-color: var(--bs-body-bg);
    border-right-color: var(--bs-body-bg);
    box-sizing: content-box;
    overflow: hidden; /* Ensures anything outside the bounds is not visible */
    display: none;
}

.section__etickets .button-card-wrapper .action-cards h2 {
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: var(--bs-body-color);
    margin: 35px 0px 10px;
}

.section__etickets .button-card-wrapper .action-cards .action-cards-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    /* width: 100%;
    gap: 8px;
    padding: 10px;
    margin: 20px 0px 30px; */
}

.section__etickets .button-card-wrapper .action-cards .actionbtn {
    width: auto;
    /* border: 0px;
    height: 38px;
    background-color: var(--white-clr);
    border-radius: 10pt; */
}

.section__etickets .button-card-wrapper .action-cards .actionbtn img{
    width: 100%;
    height: 100%;
    border-radius: 10pt;
    object-fit: cover;
}

.section__etickets .button-card-wrapper .sharebtn {
    width: 50px;
    height: 50px;
    border: 1pt solid var(--bs-body-color);
    background: var(--bs-body-bg);
    border-radius: 50%;
    color:  var(--bs-red);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    content: "";
    top: 66px;
    right: -25px;
}

.section__etickets .button-card-wrapper .sharebtn svg {
    font-size: 1.5rem;
}


.section__etickets .button-card-wrapper .qr-blk {
    width: 208px;
    height: 208px;
    margin: 10px auto 10px;
    padding: 0px 0px 0px 0px;
}


.section__etickets .button-card-wrapper .qr-blk img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.section__etickets .help-link-block {
    padding: 32px;
    padding-bottom: 0px;
    display: flex;
    justify-content: center;
}


.section__etickets .help-link-block a.help-link {
    font-size: 16px;
    font-weight: 400;
    color: var(--bs-red);
    text-decoration: underline;
}

.section__etickets .image_container {
    padding: 16px;
}


@media (min-width: 991px) {
    .section__etickets .main_body .ticket_container {
        padding: 32px;
        border-radius: 20pt;
    }

    .section__etickets .main_body .header_container {
        flex-direction: row;
        display: flex;
    }

    .section__etickets .image_container {
        width: 50%;
        padding: 0;
    }

    .section__etickets .image_container  img {
        border-radius: 10pt;
        width: 90%;
    }
    
    .section__etickets .title_container {
        width: 50%;
        margin-left: 32px;
        padding: 0;
    }

    .section__etickets .title_container h4 {
        margin-bottom: 24px;
    }

    .section__etickets .banner_container {
        padding: 0px;
        margin-top: 16px;
    }

    .section__etickets .qr_container {
        padding: 16px;
        padding-top: 0px;
    }

}

/* ------------------------------------------------------------------------------- */
/* ---------------------------- QR Modal CSS ------------------------------------- */
/* ------------------------------------------------------------------------------- */

.modal__QRModal .modal-dialog {
    max-width: 500px;
}
.modal__QRModal .modal-content {
    background-image: none;
    border-radius: 10pt;
}

.modal__QRModal .modal-body {
    position: relative;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 16px;
    width: 100%;
    height: 100%;
    min-height: auto;
}

.modal__QRModal .modal-body  .content {
    width: 100%;
    height: 100%;
}

.modal__QRModal  .info-card-wrapper {
    padding: 16px;
    border-radius: 10pt;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.modal__QRModal  .info-card-wrapper + .info-card-wrapper {
    margin-top: 16px;
}

.modal__QRModal .bg-light-blue {
    background: var(--bs-body-bg);
}

.modal__QRModal .info-card-content p {
    font-size: 14px;
    color: var(--bs-body-color);
    letter-spacing: 0.48px;
    margin-bottom: 8px;
}

.modal__QRModal .qr-blk {
    width: 208px;
    height: 208px;
    margin: 10px auto 10px;
    padding: 0px 0px 0px 0px;
}

.modal__QRModal .qr-blk  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.modal__QRModal .actionbtn {
    width: 208px;
    border: 0px;
    height: 38px;
    background-color: var(--bs-white);
    border-radius: 10pt;
    cursor: pointer;
}

.modal__QRModal .actionbtn img {
    width: 100%;
    height: 100%;
    border-radius: 10pt;
    object-fit: cover;
}
/* ------------------------------------------------------------------------------- */
/* -------------------------Dummy Component CSS----------------------------------- */
/* ------------------------------------------------------------------------------- */

.section__etickets .content__header h4.dummy-order-title {
    width: 100%;
    height: 29px;
    border-radius: 10pt;
}

.section__etickets .image_container .dummy-event-image {
    width: 100%;
    height: 217px;
    border-radius: 10pt;
}

.section__etickets .title_container .dummy-event-title {
    width: 100%;
    height: 39px;
    border-radius: 10pt;
}

.section__etickets .title_container .dummy-event-content {
    width: 100%;
    height: 30px;
    border-radius: 10pt;
}

.section__etickets .banner_container .dummy-special_notes {
    width: 100%;
    height: 155px;
    border: none;
}

.section__etickets .banner_container .dummy-special_notes.dummy-age_restrictions {
    width: 100%;
    height: 155px;
}

.section__etickets .qr_container .dummy-btn {
    border-color: #eee !important;
    height: 45px;
    width: 176px;

}

.section__etickets .dummy-action-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.section__etickets .dummy-action-cards h2:first-child {
    width: 240px;
    height: 25px;
}

.section__etickets .dummy-action-cards .actionbtn {
    width: 208px;
    height: 48px;
    border: none;
}

.section__etickets .dummy-action-cards .qr-blk {
    width: 208px;
    height: 208px;
}

.section__etickets .content__header h4.dummy-order-title,
.section__etickets .image_container .dummy-event-image,
.section__etickets .title_container .dummy-event-title,
.section__etickets .title_container .dummy-event-content,
.section__etickets .banner_container .dummy-special_notes,
.section__etickets .qr_container .dummy-btn,
.section__etickets .dummy-action-cards h2:first-child,
.section__etickets .dummy-action-cards .actionbtn,
.section__etickets .dummy-action-cards .qr-blk
{
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    -webkit-animation: shine 1.5s linear infinite;

}