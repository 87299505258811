/* ==========================================================================
   Variables/Utilities
   ========================================================================== */
:root {
      --bs-avocado: #b0fe40;
      --bs-blue: #0054f4;
      --bs-body-color: #000;
      --bs-cobalt: #0084ff;
      --bs-dark: #1e1e1e;
      --bs-dark-green: #256020;
      --bs-green: #37ab2f;
      --bs-lime: #47fe40;
      --bs-mint: #89ffbc;
      --bs-mustard: #bfa440;
      --bs-red: #ff0a41;
      --bs-ajtix-red: #FFC400;
      --bs-tfm-red: #005bff;
      --bs-aqua: #06e3b3;
      --bs-turquoise: #04eac6;
      --bs-yellow: #ebff00;
      --bs-yellow2: #ebfd01;
      --bs-primary: #005bff;
      --bs-body-font-family: "PP Neue Montreal", -apple-system, BlinkMacSystemFont,
        "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      --bs-body-font-family-book: "PP Neue Montreal Book", "PP Neue Montreal",
        -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue",
        Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol", "Noto Color Emoji";
      --bs-body-font-size: 20px;
      --bs-body-font-weight: 400;
      --bs-body-letter-spacing: 0;
      --bs-body-line-height: 26px;
    
       /* CT Classes added by Rushit */
       --bs-ct_available: #5cb85c;
       --bs-ct_very_limited: #d9534f;
       --bs-ct_limited: #f0ad4e;
       --bs-ct_alert: #d9534f;
}

.bg-white {
  background: #fff !important;
}

.bg-danger,
.bg-red {
  background-color: var(--bs-red) !important;
}

.bg-blue {
  background: var(--bs-blue) !important;
}

.bg-primary {
  background: var(--bs-primary) !important;
}

.bg-aqua {
  background-color: var(--bs-aqua) !important;
}

.bg-avocado {
  background-color: var(--bs-avocado) !important;
}

.bg-cobalt {
  background-color: var(--bs-cobalt) !important;
}

.bg-dark-green {
  background-color: var(--bs-dark-green) !important;
}

.bg-green {
  background-color: var(--bs-green) !important;
}

.bg-lime {
  background-color: var(--bs-lime) !important;
}

.bg-mint {
  background-color: var(--bs-mint) !important;
}

.bg-mustard {
  background-color: var(--bs-mustard) !important;
}

.bg-turquoise {
  background-color: var(--bs-turquoise) !important;
}

.bg-yellow {
  background-color: var(--bs-yellow) !important;
}

.blue {
  color: var(--bs-blue) !important;
}

.primary {
  color: var(--bs-primary) !important;
}

.red {
  color: var(--bs-red) !important;
}

.yellow {
  color: var(--bs-yellow) !important;
}

.yellow2 {
  color: var(--bs-yellow2) !important;
}

.object-fit {
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/*!
     * keyframes for custom navbar-toggle main-menu
     */
@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes animateTransform {
  from {
    border-radius: 0;
    opacity: 0;
    width: 0;
  }

  to {
    border-radius: 50rem;
    opacity: 1;
    width: 100%;
  }
}

@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInHalf {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.5;
  }
}

@keyframes displayNoneToBLockTranslateY {
  0% {
    transform: translateY(150px);
  }

  100% {
    transform: none;
  }
}

.animate {
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
}

.animate__slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

.border-top,
.border-right,
.border-bottom,
.border-left {
  border-color: var(--bs-dark) !important;
}

.col-extend-fw {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

#preloader {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  z-index: 1002;
  background-color: #fff;
}

#preloader ~ [data-scroll-container] {
  overflow: hidden;
}

.preloader__bar {
  display: block;
  background-color: red;
  width: 0%;
  height: 5px;
  position: fixed;
  top: 0;
  transition: width 3s linear;
}

.preloader__bar.fast {
  transition: width 2s linear;
}

.letter-row .letter {
  font-size: 36px;
  font-weight: bold;
  position: relative;
  top: 0;
  transition: 0.3s;
  margin-left: -1px;
}

.letter-row .letter:nth-child(7) {
  margin-right: 18px;
}

.letter-row .letter.active {
  top: -3px;
  transition: 0.3s;
}

/* ==========================================================================
       Boostrap Overrides
       ========================================================================== */
body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  letter-spacing: var(--bs-body-letter-spacing);
  color: var(--bs-body-color);
  background-color: #fff;
  /*overflow-y: hidden;*/
}

.nav-link {
  padding: 0.5rem 1rem;
  color: var(--bs-dark);
}

a {
  color: var(--bs-red);
}

a.black-to-red {
  color: #000;
}

a:hover,
a.black-to-red:hover,
.nav-link:hover {
  color: var(--bs-red);
}

.nav-link:focus,
.nav-link:hover {
  color: var(--bs-red);
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin-bottom: 20px;
}

.btn {
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 24px;
  padding: 3px 29px 4px 29px;
}

.btn-video {
  padding: 0;
}

.btn-primary,
.btn-outline-primary {
  min-width: 159px;
}

.btn-primary {
  background-color: var(--bs-red);
  border-color: var(--bs-red);
  border-radius: 50rem;
  font-weight: bold;
  padding: 9px 29px 10px;
}

.btn-check:focus + .btn,
.btn:focus,
.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus,
.btn-check:checked + .btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus,
.form-check-input:focus {
  box-shadow: none;
}

.btn-primary:hover,
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle,
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  background-color: #fff;
  border-color: var(--bs-red);
  color: var(--bs-red);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: var(--bs-red);
  border-color: var(--bs-red);
}

.btn-primary.bg-white {
  color: var(--bs-red);
}

.btn-outline-primary {
  color: #000;
  border-color: #000;
  border-radius: 50rem;
  border-width: 1px;
  font-weight: bold;
  letter-spacing: 0.3px;
  padding: 10px 29px;
}

.btn-outline-primary:hover,
.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  color: var(--bs-red);
  background-color: #fff;
  border-color: var(--bs-red);
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
}

.btn-outline-white:hover {
  background-color: #fff;
  border-color: #fff;
  color: var(--bs-red);
}

.btn-sm {
  font-size: 20px;
  font-weight: normal;
  line-height: 24px;
  padding: 7px 15px 6px 15px;
}

.btn.btn-xxs {
  font-size: 15px;
  letter-spacing: 0.3px;
  line-height: 18px;
  padding: 7px 15px;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  opacity: 0.6;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion-button {
  padding: 13px 20px 12px 20px;
  font-size: 15px;
  letter-spacing: 0.32px;
  line-height: 20px;
}

.accordion-button:not(.collapsed) {
  color: #000;
  background-color: #fff;
  box-shadow: none;
}

.accordion-button:focus {
  border-color: #000;
  box-shadow: none;
}

.accordion-button::after {
  background-image: url(../images/icon-arrow-down-17x18-black.svg);
  width: 17px;
  height: 18px;
  background-size: 17px;
}

.accordion-button:not(.collapsed)::after {
  background-image: url(../images/icon-arrow-down-black.svg);
  transform: rotate(0deg);
}

.accordion-button[aria-expanded="true"]::after {
  transform: rotate(-180deg);
}

.accordion-item {
  border: none;
  border-top: 1px solid #000;
}

.accordion-item:not(:first-of-type) {
  border-top: 1px solid #000;
}

.accordion-item:last-child {
  border-bottom: 1px solid #000;
}

.accordion-body {
  padding: 1px 25px 13px 20px;
}

.lead {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}

.p-notice {
  font-size: 10px;
  line-height: 12px;
}

.p-in-support,
.list__stats .item {
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 26px;
}

label {
  font-size: 15px;
  font-weight: normal;
  letter-spacing: 0.3px;
  line-height: 20px;
  margin-bottom: 5px;
}

.form-label {
  margin-bottom: 6px;
}

hr {
  opacity: 1;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  width: 13px;
  height: 8px;
  background-image: url(../images/icon-caret-down-black.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 13px auto;
  transition: 0.3s;
}

.dropdown-toggle.btn-primary::after {
  background-image: url(../images/icon-caret-down-white.svg);
}

.dropdown-toggle.btn-primary.show {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle.btn-primary.show + .dropdown-menu {
  top: calc(100% - 1px);
}

.dropdown-toggle:hover::after,
.dropdown-toggle.btn-primary:hover::after {
  background-image: url(../images/icon-caret-down-red.svg);
}

.dropdown-menu {
  font-size: 22px;
  line-height: 26px;
  color: #000;
  border-color: #000;
  border-radius: 0;
}

.btn-primary + .dropdown-menu {
  min-width: 10rem;
  padding: 0;
  margin: 0;
  color: #000;
  background-color: transparent;
  border: 0;
}

.main-header__navbar .dropdown-item {
  font-weight: bold;
  padding: 7px 15px 8px 15px;
}

.dropdown-item {
  color: #000;
}

.nav-tabs {
  border-color: #000;
}

.nav-tabs .nav-link {
  border-color: #000;
  border-left: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-tabs .nav-item:first-child .nav-link {
  border-left: 1px solid #000;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  color: #000;
  background-color: #fff;
  border-color: #000;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #000;
  background-color: #fff;
  border-color: #000 #000 #fff;
}

.nav-tabs.flex-column {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  padding: 6px 0;
}

.nav-tabs.flex-column .nav-item {
  margin-bottom: 5px;
}

.nav-tabs.flex-column .nav-link {
  border: 0;
  margin-bottom: 0;
  padding: 0;
  width: 100%;
  text-align: left;
}

.nav-tabs.flex-column .nav-item:first-child .nav-link {
  border-left: 0;
}

.nav-tabs.flex-column .nav-item .nav-link.active {
  font-weight: bold;
}

.tab-content {
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
  padding: 16px 20px;
}

.nav-tabs.flex-column + .tab-content {
  border: 1px solid #000;
  padding: 9px 12px;
}

.text-muted {
  color: #767676 !important;
}

/*
     * form
     */
.form-control[readonly] {
  color: #5a5959;
  background-color: #fff;
}

.form-control {
  padding: 11px 15px;
  font-size: 15px;
  letter-spacing: 0.3px;
  line-height: 20px;
  color: #000;
  border: 1px solid rgb(0 0 0 / 90%);
  border-radius: 10px;
}

.form-control:focus {
  color: #000;
  border-color: rgb(0 0 0);
  box-shadow: none;
}

.form-control-line {
  border-radius: 0;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.form-control__search {
  padding-right: 44px;
}

.form-control__search + .icon {
  position: absolute;
  top: 8px;
  right: 4px;
  z-index: 1;
  width: 19px;
  height: auto;
}

textarea.form-control {
  min-height: 80px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.form-control::placeholder {
  color: #aaa;
}

.form-control::-ms-input-placeholder {
  color: #aaa;
}

.form-control:focus::placeholder {
  color: #aaa;
}

.form-control:focus::-ms-input-placeholder {
  color: #aaa;
}

.form-control:disabled,
.custom-control-input:disabled + .custom-control-label::before {
  background-color: #f4f4f4;
  border-color: #bbb;
}

.custom-control-input:disabled + .custom-control-label {
  color: #aaa;
}

.form-group,
.form-group__cta.d-flex .btn {
  margin-bottom: 19px;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  padding-left: 34px;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-control-label {
  margin-bottom: 0;
}

.custom-control-label::before {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.custom-control-label::before {
  position: absolute;
  top: 1px;
  left: 0;
  display: block;
  width: 17px;
  height: 17px;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #000;
}

.custom-control-label::after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  opacity: 0;
  position: absolute;
  top: 8px;
  left: 5px;
  transform: rotate(-45deg);
  width: 12px;
}

.custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before {
  border-color: var(--bs-red);
  background-color: var(--bs-red);
}

.custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .custom-control-input:valid ~ .custom-control-label::before {
  border-color: #000;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--bs-blue);
  background-color: var(--bs-blue);
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  opacity: 1;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 4px;
}

.custom-checkbox__circle .custom-control-label::before {
  border-radius: 50%;
}

.custom-checkbox__check .custom-control-label::after {
  background-image: url(../images/icon-check-thin-black-sm.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 14px 15px;
  border: none;
  width: 14px;
  height: 15px;
  opacity: 0;
  top: -1px;
  left: 4px;
  transform: none;
}

.custom-checkbox-sm {
  line-height: 18px;
  padding-left: 22px !important;
}

.custom-checkbox-sm .custom-control-label {
  font-size: 15px;
  letter-spacing: 0;
  line-height: 18px;
}

.custom-checkbox-sm .custom-control-input {
  width: 10px;
  height: 11px;
}

.custom-checkbox-sm.custom-checkbox__check .custom-control-label::after {
  background-size: 10px 11px;
  top: 2px;
  left: 4px;
  width: 10px;
  height: 11px;
}

.custom-checkbox-sm.custom-checkbox__check .custom-control-label::before {
  top: 3px;
  left: 0;
  width: 14px;
  height: 14px;
}

.custom-checkbox__check
  .custom-control-input:checked
  ~ .custom-control-label::before {
  border-color: #000;
  background-color: #fff;
}

.custom-checkbox__radio {
  margin-bottom: 14px;
  padding-left: 34px;
}

.custom-checkbox__radio .custom-control-label::before {
  border-radius: 50px;
  width: 23px;
  height: 23px;
  top: 1px;
  transition: 0.3s;
}

.custom-checkbox__radio
  .custom-control-input:checked
  ~ .custom-control-label::before {
  border-color: #000;
  background-color: transparent;
}

.custom-checkbox__radio .custom-control-label::after {
  border: none;
  border-top: none;
  border-right: none;
  border-radius: 50px;
  background-color: #000;
  content: "";
  opacity: 0;
  position: absolute;
  top: 5px;
  left: 4px;
  width: 15px;
  height: 15px;
  transform: none;
  transition: 0.3s;
}

.btn-close:focus {
  box-shadow: none;
}

.btn-close:hover {
  opacity: 1;
}

.btn-close__absolute {
  position: absolute;
}

.card {
  border-color: #000;
  border-radius: 10px;
}

.card-footer {
  background-color: #fff;
}

.card-footer:last-child {
  border-radius: 10px;
}

.card.bg-yellow {
  border-color: var(--bs-yellow);
}

.card-footer.bg-yellow,
.card.bg-yellow .card-footer {
  background-color: var(--bs-yellow);
}

.card.bg-aqua {
  border-color: var(--bs-aqua);
}

.card-footer.bg-aqua,
.card.bg-aqua .card-footer {
  background-color: var(--bs-aqua);
}

.table {
  color: #000;
}

.small,
small {
  font-size: 15px;
  line-height: 20px;
}

.modal {
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 26px;
}

.modal .btn-close__absolute {
  top: 20px;
  right: 32px;
}

.modal-content {
  border: none;
  border-radius: 20px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}

.modal-backdrop.show {
  opacity: 0.55;
}

.modal-backdrop {
  background-color: #fff;
}

.modal__fw.fade .modal-dialog {
  transform: none;
}

input[type="number"].no-spin::-webkit-inner-spin-button,
input[type="number"].no-spin::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* ==========================================================================
       Custom
       ========================================================================== */
/*!
     * custom navbar-toggle
     */
.navbar-toggle__custom {
  background-color: transparent;
  border: 0;
  border-left: 1px solid #000;
  border-radius: 0;
  cursor: pointer;
  padding: 0 16px;
  position: relative;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 66px;
  height: 50px;
  transition: 400ms;
  transform: rotate(0deg);
  opacity: 1;
}

.navbar-toggle__hamburger,
.navbar-toggle__hamburger::before,
.navbar-toggle__hamburger::after {
  position: absolute;
  width: 33px;
  height: 5px;
  border-radius: 0;
  background-color: #000;
  display: block;
  margin: auto;
  transition: 400ms;
}

.navbar-toggle__hamburger::before,
.navbar-toggle__hamburger::after {
  display: block;
  content: "";
  backface-visibility: hidden;
}

.navbar-toggle__hamburger::before {
  transform: rotate(0deg) translateY(11px);
}

.navbar-toggle__hamburger::after {
  transform: rotate(0deg) translateY(-11px);
}

.navbar-toggle__custom.active .navbar-toggle__hamburger {
  background-color: transparent;
}

.navbar-toggle__custom.active .navbar-toggle__hamburger::before {
  transform: rotate(45deg) translateY(0px);
}

.navbar-toggle__custom.active .navbar-toggle__hamburger::after {
  transform: rotate(-45deg) translateY(0px);
}

.content {
  display: flex;
  flex-direction: column;
}

.content__body--template {
  padding: 20px;
}

.min-height-0 {
  min-height: auto !important;
}

.btn.has-icon {
  display: flex;
  align-items: center;
  position: relative;
}

.icon__plus {
  position: relative;
  display: block;
  margin-right: auto;
  margin-left: auto;
  width: 25px;
  height: 25px;
}

.icon__plus::before,
.icon__plus::after {
  background-color: #000;
  content: " ";
  display: block;
  height: 25px;
  width: 1px;
  position: absolute;
  left: 12.5px;
}

.icon__plus::before {
  transform: rotate(0deg);
}

.icon__plus::after {
  transform: rotate(90deg);
}

.btn-circle-x {
  width: 18px;
  height: 18px;
  border: 1px solid #000;
  border-radius: 50px;
  padding: 0;
}

.btn-circle-x .icon__plus {
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  transition: 0.3s;
  transform-origin: center;
}

.btn-circle-x .icon__plus::before,
.btn-circle-x .icon__plus::after {
  height: 12px;
  left: 6px;
}

.btn__plus-to-x {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 39px;
  height: 39px;
  padding: 0;
  min-width: auto;
}

.btn__plus-to-x .icon__plus {
  transition: transform 0.3s;
}

.btn-circle-x:hover {
  border-color: var(--bs-red);
}

.btn-circle-x:hover,
.btn__plus-to-x:hover {
  background-color: var(--bs-red);
}

.btn-circle-x:hover .icon__plus::before,
.btn-circle-x:hover .icon__plus::after,
.btn__plus-to-x:hover .icon__plus::before,
.btn__plus-to-x:hover .icon__plus::after {
  background-color: #fff;
}

.icon-bg {
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  flex-shrink: 0;
  transition: 0.3s;
}

.icon-bg.icon-arrow-right {
  background-image: url(../images/icon-arrow-right-33x21-white.svg);
  background-size: 33px auto;
  width: 33px;
  height: 22px;
}

.icon-bg.icon-arrow-right-sm {
  background-size: 17px auto;
  width: 17px;
  height: 11px;
}

.icon-bg.icon-wallet {
  background-image: url(../images/icon-wallet-white.svg);
  background-size: 32px auto;
  width: 32px;
  height: 21px;
}

.has-icon:hover .icon-bg.icon-wallet {
  background-image: url(../images/icon-wallet-red.svg);
}

.btn-citymapper {
  max-width: 269px;
}

.btn-citymapper:hover {
  color: #fff;
}

.btn-heart {
  padding: 0;
  display: block;
  width: 31px;
  height: 28px;
  background-image: url(../images/icon-heart-outline-black.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 29px 26px;
}

.btn-heart.active {
  padding: 0;
  display: block;
  width: 31px;
  height: 28px;
  background-image: url(../images/icon-heart-red.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 29px 26px;
}

.input-group__as-form-control {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid #000;
  padding: 9px 15px;
}

.input-group__as-form-control .input-group-text {
  flex-shrink: 0;
  margin-right: 15px;
  padding: 0;
  font-size: 20px;
  line-height: 24px;
  color: #000;
  background-color: transparent;
  border: none;
  border-radius: 0;
}

.input-group__as-form-control .form-control {
  border: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0;
  text-align: right;
}

.list-square-style {
  list-style-type: none;
  padding-left: 16px;
}

.list-square-style > li {
  position: relative;
}

.list-square-style > li::before {
  background-color: #000;
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  position: absolute;
  top: 12px;
  left: -11px;
}

.table-black-theme > :not(:first-child) {
  border-top: 1px solid #000;
}

.table-black-theme tbody,
.table-black-theme td,
.table-black-theme tfoot,
.table-black-theme th,
.table-black-theme thead,
.table-black-theme tr {
  border-color: #000;
}

.owl-nav [class*="owl-"] {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: none;
}

.owl-nav [class*="owl-"]:hover {
  background: transparent;
}

.owl-nav [class*="owl-"] span {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 32px 21px;
  display: block;
  width: 32px;
  height: 21px;
  margin-right: auto;
  margin-left: auto;
  text-indent: -9999px;
}

.owl-nav button.owl-prev span {
  background-image: url(../images/icon-arrow-left-32x21-black.svg);
}

.owl-nav button.owl-prev:hover span {
  background-image: url(../images/icon-arrow-left-32x21-red.svg);
}

.owl-nav button.owl-next span {
  width: 33px;
  background-image: url(../images/icon-arrow-right-33x21-black.svg);
  background-size: 33px 21px;
}

.owl-nav button.owl-next:hover span {
  background-image: url(../images/icon-arrow-right-33x21-red.svg);
}

/*!
      * tom-select.bootstrap5 - overrides
      */
.ts-control {
  border: 1px solid rgb(0 0 0 / 90%);
  border-radius: 0;
  padding: 11px 16px;
}

.ts-wrapper.form-control {
}

.focus .ts-control {
  border-color: #000;
  box-shadow: none;
}

.ts-wrapper:not(.form-select).single .ts-control {
  background-image: url(../images/icon-arrow-down-black-sm.svg);
  background-position: right 13px center;
  background-size: 16px 14px;
  padding-right: 36px;
}

.ts-dropdown-content {
  padding: 2px 20px 20px;
  max-height: 201px;
}

.ts-control,
.ts-control input,
.ts-dropdown {
  color: #000;
  line-height: 20px;
}

.ts-dropdown,
.ts-dropdown.form-control,
.ts-dropdown.form-select {
  border: 1px solid rgba(0 0 0);
  border-radius: 0;
  box-shadow: none;
}

.ts-dropdown {
  margin: -1px 0 0;
}

.ts-dropdown,
.ts-dropdown.form-control,
.ts-dropdown.form-select {
  background: #fff;
  border: 1px solid #000;
  border-radius: 0;
}

.ts-dropdown .create,
.ts-dropdown .no-results,
.ts-dropdown .optgroup-header,
.ts-dropdown .option {
  border-bottom: 1px solid #000;
  padding: 10px 0;
}

.ts-dropdown .active,
.ts-dropdown .create:hover,
.ts-dropdown .option:hover {
  background-color: #fff;
  color: var(--bs-red);
}

/* remove jump after select */
.ts-control > .item,
.ts-control > input {
  display: inline-flex !important;
}

.ts-control > .item + input {
  max-width: 1px !important;
  min-width: 1px !important;
}

.ts-wrapper.focus .ts-control > .item + input {
  display: none !important;
}

.ts-wrapper.dropdown-active .ts-control > .item + input {
  display: inline-flex !important;
}

.has-items .ts-control > input {
  margin: 0 !important;
}

.ts-dropdown-content {
  scroll-behavior: smooth;
}

.content-scrollable::-webkit-scrollbar-track,
.ts-dropdown-content::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: #fff;
  border-radius: 10px;
}

.content-scrollable::-webkit-scrollbar,
.ts-dropdown-content::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #fff;
}

.content-scrollable::-webkit-scrollbar-thumb,
.ts-dropdown-content::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 10px;
  transition: 0.3s;
}

.content-scrollable::-webkit-scrollbar-thumb {
  background-color: #999;
}

.content-scrollable:hover::-webkit-scrollbar-thumb {
  background-color: #999;
}

.ts-dropdown-content:hover::-webkit-scrollbar-thumb {
  background-color: #000;
}

@-moz-document url-prefix() {
  .content-scrollable {
    scrollbar-color: #999 #fff;
    scrollbar-width: thin;
  }

  .ts-dropdown-content {
    scrollbar-color: #000 #fff;
    scrollbar-width: thin;
  }
}

/*!
      * Bootstrap Datepicker overrides
      */
.form-datepicker {
  position: relative;
}

.form-datepicker::after {
  content: " ";
  display: block;
  position: absolute;
  top: 15px;
  right: 14px;
  width: 16px;
  height: 14px;
  background-image: url(../images/icon-arrow-down-black-sm.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 16px 14px;
}

.form-control__datepicker {
  border-radius: 0;
  border: 1px solid #000;
  font-family: var(--bs-body-font-family);
  background: #fff;
  color: #000;
  font-size: 15px;
  letter-spacing: 0.3px;
  line-height: 20px;
  padding: 11px 46px 11px 16px;
  display: flex;
  align-items: center;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}

.datepicker-dropdown {
  border-radius: 0;
  border: 1px solid #000;
  font-family: var(--bs-body-font-family);
  background: #fff;
  color: #000;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: -1px;
  padding: 0;
}

.form-datepicker-lg::after {
  top: 18px;
  right: 19px;
  width: 17px;
  height: 18px;
  background-size: 18px auto;
}

.datepicker-days,
.datepicker-days table,
.datepicker-months,
.datepicker-months table,
.datepicker-years,
.datepicker-years table,
.datepicker-decades,
.datepicker-decades table,
.datepicker-centuries,
.datepicker-centuries table {
  width: 100%;
}

.datepicker-days thead > tr:nth-child(2),
.datepicker-months thead > tr:nth-child(2),
.datepicker-years thead > tr:nth-child(2),
.datepicker-decades thead > tr:nth-child(2),
.datepicker-centuries thead > tr:nth-child(2) {
  border-bottom: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.datepicker-days thead > tr:nth-child(3),
.datepicker-months thead > tr:nth-child(3),
.datepicker-years thead > tr:nth-child(3),
.datepicker-decades thead > tr:nth-child(3),
.datepicker-centuries thead > tr:nth-child(3),
.datepicker tbody,
.datepicker tfoot {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 1px);
}

.datepicker tbody,
.datepicker tfoot {
  flex-direction: column;
}

.datepicker tbody > tr,
.datepicker tfoot > tr {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}

.datepicker-days thead > tr:nth-child(3) > th,
.datepicker-months thead > tr:nth-child(3) > th,
.datepicker-years thead > tr:nth-child(3) > th,
.datepicker-decades thead > tr:nth-child(3) > th,
.datepicker-centuries thead > tr:nth-child(3) > th,
.datepicker tbody > tr > td,
.datepicker tfoot > tr > td {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 14.28%;
  flex-wrap: wrap;
}

.datepicker-months tbody > tr > td,
.datepicker-years tbody > tr > td,
.datepicker-decades tbody > tr > td,
.datepicker-centuries tbody > tr > td {
  flex-direction: row;
  width: 100%;
}

.datepicker-months .month,
.datepicker-years .year,
.datepicker-decades .decade,
.datepicker-centuries .century {
  width: 14.66%;
}

.datepicker td,
.datepicker th {
  font-weight: 400;
  height: auto;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.datepicker .next,
.datepicker .prev {
  font-size: 0;
  text-indent: -9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 39px;
  height: 39px;
}

.datepicker .prev::before,
.datepicker .next::before {
  content: "";
  width: 23px;
  height: 23px;
  background-image: url(../images/icon-arrow-left-black.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 23px auto;
  margin-right: auto;
  margin-left: auto;
}

.datepicker .next::before {
  transform: rotate(-180deg);
}

.datepicker .datepicker-switch {
  border-right: 1px solid #000;
  border-left: 1px solid #000;
  font-size: 16px;
  line-height: 19px;
  height: 39px;
  width: calc(100% - 78px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.datepicker .datepicker-switch:hover,
.datepicker .next:hover,
.datepicker .prev:hover,
.datepicker tfoot tr th:hover {
  background: transparent;
}

.datepicker-days .dow {
  height: 42px;
  position: relative;
}

.datepicker-days .day {
  height: 36px;
  position: relative;
}

.datepicker-days .day::before {
  content: "";
  display: block;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 50px;
  width: 35px;
  height: 35px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transition: 0.3s;
  z-index: -1;
}

.datepicker-days .today::before {
  background-color: transparent;
  border-color: transparent;
}

.datepicker-months .month {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: calc(100% / 6);
  height: 39px;
  line-height: 18px;
  float: none;
  margin: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.datepicker .month.focused,
.datepicker .month:hover {
  background: transparent;
  color: var(--bs-red);
}

.datepicker table tr td.day.focused,
.datepicker table tr td.day:hover {
  background: transparent;
}

.datepicker table tr td.day.focused::before,
.datepicker table tr td.day:hover::before {
  border-color: #000;
}

.datepicker table tr td.old.day.focused::before,
.datepicker table tr td.old.day:hover::before,
.datepicker table tr td.new.day.focused::before,
.datepicker table tr td.new.day:hover::before,
.datepicker table tr td.disabled.day.focused::before,
.datepicker table tr td.disabled.day:hover::before {
  border-color: #999;
}

.datepicker table tr td.today,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today:hover {
  color: var(--bs-red);
  background-color: transparent;
  background-image: none;
  background-repeat: initial;
  border-color: transparent;
  filter: none;
}

.datepicker table tr td span.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active:hover {
  color: var(--bs-red);
  background-color: transparent;
  background-image: none;
  background-repeat: initial;
  border-color: transparent;
  filter: none;
  text-shadow: none;
}

.datepicker table tr td.today:hover::before {
  border-color: #fff;
}

.datepicker table tr td.today.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today.disabled:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today[disabled],
.datepicker table tr td span.active.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active.disabled:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active[disabled] {
  background-color: transparent;
}

.datepicker table tr td.today:hover:hover {
  color: var(--bs-red);
}

.datepicker table tr td.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active:hover {
  color: #fff;
  background-color: transparent;
  background-image: none;
  background-repeat: initial;
  border-color: transparent;
  filter: none;
  text-shadow: none;
}

.datepicker table tr td.today.active.day:hover {
  color: #fff;
}

.datepicker table tr td.active.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active.disabled:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active[disabled] {
  background-color: transparent;
}

.datepicker table tr td.active::before,
.datepicker table tr td.active.disabled::before,
.datepicker table tr td.active.disabled.active::before,
.datepicker table tr td.active.disabled.disabled::before,
.datepicker table tr td.active.disabled:active::before,
.datepicker table tr td.active.disabled:hover::before,
.datepicker table tr td.active.disabled:hover.active::before,
.datepicker table tr td.active.disabled:hover.disabled::before,
.datepicker table tr td.active.disabled:hover:active::before,
.datepicker table tr td.active.disabled:hover:hover::before,
.datepicker table tr td.active.disabled:hover[disabled]::before,
.datepicker table tr td.active.disabled[disabled]::before,
.datepicker table tr td.active:active::before,
.datepicker table tr td.active:hover::before,
.datepicker table tr td.active:hover.active::before,
.datepicker table tr td.active:hover.disabled::before,
.datepicker table tr td.active:hover:active::before,
.datepicker table tr td.active:hover:hover::before,
.datepicker table tr td.active:hover[disabled]::before,
.datepicker table tr td.active[disabled]::before {
  background-color: var(--bs-red);
  border-color: var(--bs-red);
}

.datepicker-dropdown {
  border-radius: 0;
  padding: 0 0 8px 0;
  width: calc(100% - 40px);
}

.datepicker-dropdown::before,
.datepicker-dropdown::after {
  display: none;
}

.datepicker-container {
  font-size: 15px;
  line-height: 18px;
}

.datepicker.datepicker-inline {
  background: transparent;
  position: relative;
  z-index: 1;
}

/*! END Bootstrap Datepicker overrides */
/*!
     * .main-header
     */
.navbar-brand__logo {
  width: 268px;
  height: auto;
}

.main-header__navbar .nav-link,
.main-header__navbar .form__heading {
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 30px;
}

.main-header__navbar .nav-link.btn {
  min-width: 118px;
  padding: 6px 20px 9px 20px;
}

.main-header__navbar .nav-link.btn-primary {
  color: #fff;
}

.main-header__navbar .nav-link.btn-primary:hover,
.main-header__navbar .nav-link.btn-primary:active,
.main-header__navbar .nav-link.btn-primary:focus {
  color: var(--bs-red);
}

.main-header__navbar .slidein-login .nav-link.btn {
  min-width: 169px;
  padding: 8px 20px;
}

.main-header__navbar .btn.nav-link__price,
.main-header__navbar .nav-link.btn + .dropdown-menu {
  min-width: 170px;
}

.main-header__navbar .nav-link__price {
  font-size: 20px;
  line-height: 24px;
}

.main-header__navbar .nav-link__price + .dropdown-menu {
  width: 100%;
}

.main-header__navbar .nav-link__price::before {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 60px;
  transition: 0.3s;
}

.main-header__navbar .nav-link__price:hover::before {
  background-color: var(--bs-red);
}

.main-header__navbar .btn.nav-link__price {
  padding: 8px 15px 7px 15px;
}

.main-header__navbar .nav-link__price .icon-bg {
  margin-right: 10px;
}

.main-header__navbar .nav-link__price .nav-link__text {
  margin-left: 15px;
}

.main-header__navbar .nav-link.btn-primary:active::before,
.main-header__navbar .nav-link.btn-primary:focus::before {
  background-color: var(--bs-red);
}

.main-header__navbar .has-icon:active .icon-bg.icon-wallet,
.main-header__navbar .has-icon:focus .icon-bg.icon-wallet {
  background-image: url(../images/icon-wallet-red.svg);
}

.dropdown-toggle.btn-primary:active::after,
.dropdown-toggle.btn-primary:focus::after {
  background-image: url(../images/icon-caret-down-red.svg);
}

.main-header__navbar .btn-check:focus + .btn-primary,
.main-header__navbar .btn-primary:focus,
.main-header__navbar .btn-check:active + .btn-primary,
.main-header__navbar .btn-check:checked + .btn-primary,
.main-header__navbar .btn-primary.active,
.main-header__navbar .btn-primary:active,
.main-header__navbar .show > .btn-primary.dropdown-toggle {
  color: #fff;
}

.main-header__navbar {
  position: relative;
  height: 50px;
}

.main-header__main-menu .form__heading,
.main-header__main-menu .nav-link__heading {
  margin-bottom: 10px;
}

.main-header {
  background-color: #fff;
  border-bottom: 1px solid var(--bs-dark);
  z-index: 4;
}

.main-header .container-fluid {
  padding-left: 26px;
  padding-right: 39px;
}

/* END .main-header */
.btn.has-icon,
a.has-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn.has-icon .icon-wrapper {
  flex-shrink: 0;
}

.btn.has-icon .icon-wrapper:first-child {
  margin-right: 10px;
}

.icon-apple-as-bg,
.icon-googleplay-as-bg {
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  transition: 0.3s;
}

.icon-apple-as-bg {
  background-size: 18px auto;
  width: 18px;
  height: 18px;
}

.icon-googleplay-as-bg {
  background-size: 17px auto;
  width: 17px;
  height: 18px;
}

.icon-googleplay-as-bg {
  background-image: url(../images/icon-google-play-store-white.png);
}

.btn-primary:hover .icon-googleplay-as-bg {
  background-image: url(../images/icon-google-play-store-red.png);
}

.icon-apple-as-bg {
  background-image: url(../images/icon-apple-white.png);
}

.btn-primary:hover .icon-apple-as-bg {
  background-image: url(../images/icon-apple-red.png);
}

.ticket-1-star {
  width: 199px;
  height: auto;
}

.btn-huge {
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  font-size: 30px;
  font-weight: bold;
  line-height: 36px;
  height: 70px;
  padding: 15px 25px 20px;
  width: 100%;
}

.buttons-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.container__block,
.container__block > .row > * {
  padding-right: 0;
  padding-left: 0;
}

.container__block > .row {
  margin-right: 0;
  margin-left: 0;
}

.content > :last-child,
.main__about-us .content > :last-child,
p:last-child {
  margin-bottom: 0;
}

.big-heading h1,
.big-heading .h1,
.big-heading h2,
.big-heading .h2 {
  font-size: 47px;
  line-height: 56px;
}

.big-heading,
.container__for-venues,
.content__for-venues,
.container__for-venues .content__image,
.content__for-members,
.container__for-members .content__image {
  position: relative;
}

.big-heading img.ticket,
.big-heading img.emoji {
  position: absolute;
  height: auto;
  z-index: -1;
}

/*!
     * .content__what-we-do .big-heading
     */
.content__what-we-do .big-heading h1,
.content__what-we-do .big-heading .h1 {
  font-size: 105px;
  line-height: 90px;
}

.content__what-we-do .big-heading .ticket-red {
  top: 39px;
  left: 5.1%;
  width: 76px;
  z-index: -1;
}

.content__what-we-do .big-heading .ticket-yellow {
  bottom: 9px;
  left: 7.5%;
  width: 29px;
}

/*!
     * .content__membership-is-free .big-heading
     */
.content__membership-is-free .big-heading .ticket-green {
  top: 41%;
  left: 59.85%;
  width: 35px;
}

.content__membership-is-free .big-heading .ticket-blue {
  bottom: -12%;
  left: 31.2%;
  width: 39px;
}

[data-scroll].ticket,
.container__for-venues [data-scroll],
.container__for-members [data-scroll] {
  opacity: 0;
  position: relative;
  transform: translateY(150px);
  transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1),
    transform 2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.container__for-members [data-scroll] {
  transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1),
    transform 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.container__for-venues .image2,
.container__for-venues .image3,
.container__for-members .image2,
.container__for-members .image3 {
  border-radius: 10px;
}

.container__for-members [data-scroll].image2 {
  transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1),
    transform 2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

[data-scroll].ticket.is-inview,
.container__for-venues [data-scroll].is-inview,
.container__for-members [data-scroll].is-inview {
  opacity: 1;
  transform: none;
  transition-delay: 0.2s;
}

.container__for-venues [data-scroll].image1.is-inview {
  transition-delay: 0s;
}

.container__for-members [data-scroll].image1.is-inview {
  transition-delay: 0.4s;
}

.container__for-members [data-scroll].image3.is-inview {
  transition-delay: 0.5s;
}

[data-scroll].feature__impact {
  opacity: 0;
  position: relative;
  transform: translateY(100px);
  transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1),
    transform 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.content__ticket-booking-steps .feature,
.content__ticket-booking-steps .feature + .buttons-wrapper {
  opacity: 0;
  position: relative;
  transform: translateY(30px);
  transition: opacity 0.6s cubic-bezier(0.215, 0.61, 0.355, 1),
    transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.content__list-values .list__values [data-scroll] {
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  transition: opacity 0.6s cubic-bezier(0.215, 0.61, 0.355, 1),
    transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.content__ticket-booking-steps .feature.is-inview,
.content__ticket-booking-steps .feature + .buttons-wrapper.is-inview,
.content__list-values .list__values [data-scroll].is-inview,
[data-scroll].feature__impact.is-inview {
  opacity: 1;
  transform: none;
}

.content__ticket-booking-steps .feature.is-inview:first-child,
.content__list-values .list__values [data-scroll]:first-child.is-inview {
  transition-delay: 0.1s;
}

.content__ticket-booking-steps .feature.is-inview:nth-child(2),
.content__list-values .list__values [data-scroll]:nth-child(2).is-inview {
  transition-delay: 0.2s;
}

.content__ticket-booking-steps .feature.is-inview:nth-child(3),
.content__list-values .list__values [data-scroll]:nth-child(3).is-inview {
  transition-delay: 0.3s;
}

.content__ticket-booking-steps .feature.is-inview:nth-child(4),
.content__list-values .list__values [data-scroll]:nth-child(4).is-inview {
  transition-delay: 0.4s;
}

.content__ticket-booking-steps .feature + .buttons-wrapper.is-inview,
.content__list-values .list__values [data-scroll]:nth-child(5).is-inview {
  transition-delay: 0.5s;
}

[data-scroll].feature__impact:first-child.is-inview {
  transition-delay: 0s;
}

[data-scroll].feature__impact:nth-child(2).is-inview {
  transition-delay: 0.4s;
}

[data-scroll].feature__impact:nth-child(3).is-inview {
  transition-delay: 0.8s;
}

/*!
     * .content__just-few-steps .big-heading
     */
.content__just-few-steps .content__header {
  margin-bottom: 52px;
}

.content__just-few-steps .content__footer {
  margin-top: 30px;
}

.content__just-few-steps .big-heading .ticket-yellow {
  bottom: -47%;
  left: 34.7%;
  width: 48px;
  z-index: 1;
}

.content__just-few-steps .big-heading .ticket-blue {
  top: -12%;
  left: 11.8%;
  width: 31px;
  z-index: -1;
}

.sub-heading {
  font-size: 30px;
  line-height: 34px;
}

.icon-arrow-left-red {
  width: 35px;
  height: auto;
}

.icon-block__arrow img {
  width: 42px;
  height: auto;
}

.feature__icon .icon {
  height: auto;
  position: relative;
}

.feature__icon .icon-c {
  width: 59px;
  top: 11px;
}

.feature__icon .icon-ticket {
  top: 8px;
  left: 4px;
  width: 61px;
}

.feature__icon .icon-search {
  top: 12px;
  right: -2px;
  width: 60px;
}

.feature__icon .icon-location-pin {
  top: 10px;
  left: 6px;
  width: 25px;
}

.feature__ticket-booking-step .feature__icon {
  width: 60px;
  margin-right: 31px;
}

.feature__ticket-booking-step {
  font-size: 15px;
  line-height: 19px;
  margin-bottom: 25px;
}

.feature__ticket-booking-step .step {
  letter-spacing: 1px;
  min-width: 44px;
}

.feature__ticket-booking-step .step,
.feature__ticket-booking-step .heading {
  font-size: 20px;
  line-height: 37px;
}

.feature__ticket-booking-step .feature-heading {
  margin-bottom: 5px;
}

.feature__ticket-booking-step + .buttons-wrapper {
  padding-left: 49px;
}

.content__ticket-booking-steps .buttons-wrapper {
  font-size: 20px;
}

.content__ticket-booking-steps .buttons-wrapper .icon-wrapper {
  margin-left: 23px;
}

.content__ticket-booking-steps .buttons-wrapper .icon-arrow-left-red {
  width: 23px;
}

.features__impact {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 29px;
  row-gap: 22px;
}

.feature__impact {
  border: 2px solid #000;
  border-radius: 20px;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  flex-direction: column;
  width: 151px;
  flex-shrink: 0;
}

.feature__impact .heading {
  font-size: 20px;
  font-weight: bold;
  line-height: 16px;
  margin-bottom: 9px;
}

.feature__impact .feature__icon {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 21px;
  padding-bottom: 10px;
}

.feature__impact .feature__icon .icon {
  width: 80px;
  height: auto;
}

.feature__impact .feature__body {
  border-top: 2px solid #000;
  padding: 21px 10px 18px;
}

p + .features__impact {
  margin-top: 64px;
}

.logo__c {
  width: 57px;
  height: auto;
}

.logo__fsb {
  width: 94px;
  height: auto;
}

.logo__NHSCT {
  width: 150px;
  height: auto;
}

.logo__ecologi {
  width: 114px;
  height: auto;
}

.logo__ecologi {
  width: 142px;
  height: auto;
}

.logo__cyber-essentials {
  width: 69px;
  height: auto;
}

.logo-nhs-charities-blue,
.content__NHSCT .logo-nhs-charities {
  width: 173px;
  height: auto;
}

.logo-nhs-charities-blue + .p-notice {
  margin-top: 15px;
}

/*!
     * .owl-carousel__home-hero
     */
.owl-carousel__home-hero .owl-item {
  height: 100%;
}

.owl-carousel__home-hero .hero {
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.owl-carousel__home-hero .hero-header {
  position: relative;
  padding-top: 26px;
  padding-bottom: 50px;
}

.owl-carousel__home-hero .hero-header h1 {
  font-size: 65px;
  line-height: 57px;
  margin-bottom: 0;
}

.owl-carousel__home-hero .hero__content {
  padding-left: 18px;
  padding-right: 18px;
}

.owl-carousel__home-hero .hero__image {
  position: relative;
}

.owl-carousel__home-hero .hero__image::before {
  display: block;
  content: "";
  width: 40px;
  height: 40px;
  background-image: url(../images/logo-c-white.svg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 40px 40px;
  position: absolute;
  right: 20px;
  bottom: 16px;
  z-index: 3;
}

.owl-carousel__home-hero .owl-item .ticket {
  width: auto;
  height: auto;
  position: absolute;
  z-index: 1;
}

.owl-carousel__home-hero .item1 .ticket-blue {
  width: 30px;
  top: 49.4%;
  left: 8.8%;
}

.owl-carousel__home-hero .item1 .ticket-aquamarine {
  width: 25px;
  top: -2.25%;
  left: 51.45%;
}

.owl-carousel__home-hero .item1 .ticket-green {
  width: 37px;
  left: 46.8%;
  bottom: -11.8%;
  z-index: 2;
}

.owl-carousel__home-hero .item1 .ticket-yellow {
  width: 30px;
  top: 41.1%;
  right: 16.8%;
}

.owl-carousel__home-hero .item2 .ticket-yellow {
  width: 30px;
  right: 16.5%;
  top: 37.2%;
  z-index: 2;
}

.owl-carousel__home-hero .item3 .ticket-yellow {
  width: 29px;
  top: 12.8%;
  right: 54%;
}

.owl-carousel__home-hero .item3 .ticket-aquamarine {
  width: 25px;
  bottom: 22%;
  left: 56.2%;
}

.owl-carousel__home-hero .item3 .ticket-blue {
  width: 30px;
  top: 36.9%;
  left: 15.3%;
}

.owl-carousel__home-hero .hero-body {
  margin-bottom: 50px;
}

.content__carousel-home-hero .owl-nav {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  bottom: 20px;
  z-index: 1;
  width: 100%;
}

.content__body > :last-child,
.owl-carousel__home-hero .hero-body > :last-child,
.feature__ticket-booking-step .feature__body > :last-child {
  margin-bottom: 0;
}

.owl-carousel__showsTicker .show {
  position: relative;
}

.owl-carousel__showsTicker .show__image {
  width: 338px;
  height: 215px;
}

.owl-carousel__showsTicker .show__cat {
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  line-height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
}

.ticker {
  display: flex;
  align-items: center;
  font-size: 27px;
  line-height: 33px;
  text-transform: uppercase;
  height: 42px;
  width: 100%;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
}

.ticker__text {
  display: flex;
  align-items: center;
}

.ticker .dot {
  display: inline-block;
  flex-shrink: 0;
  background-color: var(--bs-dark);
  border-radius: 50px;
  width: 7px;
  height: 7px;
  margin: 0 12px;
}

.content__what-we-do {
  padding-bottom: 50px;
}

.container__for-venues {
  padding-top: 6px;
  padding-bottom: 80px;
}

.container__for-venues .image1,
.container__for-venues .image2,
.container__for-members .image1,
.container__for-members .image2 {
  height: auto;
  position: absolute;
}

.container__for-venues .image1 {
  top: 82.2%;
  left: 8.4%;
  width: 42%;
  z-index: 2;
}

.container__for-venues .image2 {
  top: 66.4%;
  z-index: 1;
  right: 0.8%;
  width: 58%;
}

.container__for-venues .image3 {
  height: auto;
  width: 92.3%;
}

.content__for-venues .sub-heading,
.content__for-members .sub-heading,
.content__membership-is-free .sub-heading {
  font-size: 15px;
  line-height: 20px;
}

.content__for-venues .sub-heading-upper,
.content__for-members .sub-heading-upper {
  margin-bottom: 4px;
}

.content__for-venues .ticket {
  height: auto;
  position: absolute;
  z-index: -1;
}

.content__for-venues .content__header,
.content__for-members .content__header {
  margin-bottom: 49px;
}

.content__for-venues .content__body,
.content__for-members .content__body {
  margin-bottom: 31px;
}

/*!
     * .content__for-members
     */
.container__for-members {
  padding-top: 19px;
  padding-bottom: 61px;
}

.container__for-members .ticket {
  position: absolute;
  z-index: -1;
  height: auto;
}

.content__for-members .ticket-yellow {
  top: 41.2%;
  left: 68.6%;
  width: 39px;
}

.content__for-members .ticket-blue {
  bottom: 5.8%;
  right: 20.8%;
  width: 40px;
}

.content__membership-is-free [data-scroll].ticket-aquamarine.is-inview,
.content__for-members [data-scroll].ticket-yellow.is-inview {
  transition-delay: 0s;
}

.content__for-members [data-scroll].ticket-blue.is-inview {
  transition-delay: 0.1s;
}

.container__for-members .image1 {
  top: -19.6%;
  right: 3.4%;
  width: 123px;
  z-index: 2;
}

.container__for-members .image2 {
  top: -12.2%;
  left: 1px;
  width: 57.2%;
  z-index: 1;
}

.container__for-members .image3 {
  top: -1px;
  left: -2px;
  width: 87%;
  height: auto;
}

/*!
     * .content__membership-is-free
     */
.content__membership-is-free {
  padding-top: 27px;
}

.content__membership-is-free .big-heading .h1 {
  font-size: 61px;
  line-height: 60px;
}

.content__membership-is-free .content__header .sub-heading {
  margin-bottom: 15px;
}

.content__membership-is-free .content__header {
  margin-bottom: 5vh;
}

.content__membership-is-free .content__body {
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 4vh;
}

/*!
     * About us
     * .spy-scrollbar
     */
.spy-scrollbar {
  width: 120px;
  height: 300px;
  left: 20px;
  overflow: hidden;
  padding-left: 14px;
  position: fixed;
  top: 23vh;
  z-index: 999;
}

.spy-scrollbar__content {
  position: relative;
}

.spy-scrollbar__line {
  transition: background-color 0.5s ease, height 1s cubic-bezier(0.4, 0, 0.2, 1);
}

.spy-scrollbar__line {
  background-color: #fff;
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 2px;
}

.spy-scrollbar__content .line:last-child {
  bottom: 100%;
}

.spy-scrollbar__progress {
  background-color: #fff;
  width: 5px;
  min-height: 10%;
  transition: all linear 0.1s;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.spy-scrollbar__nav {
  font-size: 15px;
  letter-spacing: 0.3px;
  line-height: 18px;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.spy-scrollbar__nav > li:not(:last-child) {
  margin-bottom: 2px;
}

.spy-scrollbar__nav a {
  color: #fff;
  padding: 5px 0;
  text-decoration: none;
}

.spy-scrollbar__nav a.active {
  font-weight: bold;
}

/*!
     * About us
     * .content__NHSCT
     */
.content__NHSCT .big-heading .ticket-aquamarine {
  top: -9px;
  left: 21.6%;
  width: 41px;
}

.content__NHSCT .lead + .buttons-wrapper {
  margin-top: 63px;
}

.content__NHSCT .content__header {
  margin-bottom: 55px;
}

.section__NHSCT {
  padding-top: 45px;
}

/*!
     * About us
     * .content__about-us
     */
.content__about-us .big-heading .ticket-blue {
  bottom: 15.2%;
  left: 19%;
  width: 31px;
}

.content__about-us .big-heading .ticket-orange {
  top: 4.95%;
  left: -2.65%;
  width: 30px;
}

.content__about-us .big-heading .ticket-green {
  top: 31.5%;
  left: 34.1%;
  width: 33px;
}

.content__about-us .big-heading .ticket-yellow {
  top: 20.55%;
  right: 16.75%;
  width: 35px;
}

.content__about-us .big-heading .ticket-aquamarine {
  bottom: -15.5%;
  right: 3.9%;
  width: 54px;
}

.content__ecologi .lead {
  max-width: 1300px;
}

/*!
     * About us
     * .section__stats
     */
.main__about-us .section__about-us {
  padding-top: 40px;
  padding-bottom: 47px;
}

.main__about-us .section__ecologi {
  padding-top: 40px;
  padding-bottom: 59px;
}

.main__about-us .section__our-numbers {
  padding-top: 60px;
  padding-bottom: 13px;
}

.content__our-numbers .heading {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 15px;
}

.list__stats {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 0;
  list-style: none;
  row-gap: 24px;
  margin-bottom: 0;
}

.list__stats .item {
  font-size: 15px;
  letter-spacing: 0.3px;
}

.list__stats .item__heading {
  font-size: 100px;
  line-height: 111px;
  margin-bottom: 0;
}

/*!
     * About us
     * .section__our-values
     */
.section__our-values {
  padding-bottom: 29px;
}

.content__our-values .big-heading .ticket-blue {
  bottom: 8.1%;
  left: 31.4%;
  width: 24px;
}

.content__our-values .big-heading .ticket-orange {
  top: 7.45%;
  left: 15.3%;
  width: 26px;
}

.content__our-values .big-heading .ticket-green {
  bottom: -3.8%;
  left: 57.85%;
  width: 30px;
}

.content__our-values .big-heading .ticket-yellow {
  top: 3.65%;
  right: 16.2%;
  width: 33px;
}

.content__our-values .big-heading .ticket-aquamarine {
  bottom: 15.25%;
  right: 5.2%;
  width: 102px;
}

.list__values > li {
  margin-bottom: 14px;
}

.list__values h3 {
  font-size: 29px;
  line-height: 34px;
  margin-bottom: 7px;
}

.list__values > li:not(:last-child) .item {
  padding-bottom: 15px;
  border-bottom: 1px solid #000;
}

.list__values .number {
  font-size: 20px;
  font-weight: bold;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list__values .item__number {
  width: 55px;
}

.list__values .item__text {
  font-size: 10px;
  line-height: 14px;
  padding-top: 2px;
}

.content__list-values {
  padding-top: 26px;
}

/*!
     * .section__listing-show-steps
     */
.section__listing-show-steps {
  padding-top: 30px;
  padding-bottom: 86px;
}

.section__listing-show-steps .content__header {
  margin-bottom: 71px;
}

.section__listing-show-steps .big-heading br,
.section__listing-show-steps .lead br {
  display: none;
}

.section__listing-show-steps .lead + .lead {
  margin-top: 24px;
}

.content__listing-show-steps .icon-block__arrow {
  margin-top: 34px;
  margin-bottom: 34px;
}

.section__listing-show-steps .big-heading__bottom {
  margin-top: 70px;
}

.section__thanks-to-supporters {
  padding-top: 56px;
  padding-bottom: 69px;
}

.main__about-us .section__thanks-to-supporters + div,
.main__about-us .section__thanks-to-supporters + section {
  position: relative;
  z-index: 1000;
}

/*!
     * Guidelines page
     * .content__mg-section1
     */
.main__membership-guidelines .vr {
  margin-left: auto;
  margin-right: auto;
  min-height: 55px;
  background-color: #000;
  opacity: 1;
}

.main__membership-guidelines .mg-screen .content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  height: 100vh;
}

.main__membership-guidelines .mg-screen {
  padding-top: 0;
  padding-bottom: 0;
}

.mg-screen .content__cta .btn-icon img {
  width: 34px;
  height: auto;
}

.main__membership-guidelines .big-heading h1,
.main__membership-guidelines .big-heading .h1,
.main__membership-guidelines .big-heading h2,
.main__membership-guidelines .big-heading .h2 {
  font-size: 61px;
  line-height: 57px;
}

.main__membership-guidelines .lead {
  font-size: 25px;
  letter-spacing: 0.5px;
  line-height: 30px;
}

.main__membership-guidelines .mg-screen .vr {
  margin-top: 23px;
}

.main__membership-guidelines .mg-screen .content__body {
  margin: 23px auto 12vh;
}

.main__membership-guidelines .screen01 .content {
  padding-top: 0;
}

.main__membership-guidelines .screen07 .content__body h2 {
  margin-bottom: 7vh;
}

/*.main__membership-guidelines .screen07 {
      background-image: url(../images/tickets/bg-tickets-guidelines-mobile.svg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 442px auto;
    }*/
.main__membership-guidelines .screen07 .big-heading h2,
.main__membership-guidelines .screen07 .big-heading .h2 {
  font-size: 46px;
  line-height: 47px;
}

.content__screen01 .big-heading .ticket-orange {
  top: 28.55%;
  left: -1.2%;
  width: 30px;
}

.content__screen01 .big-heading .ticket-blue {
  bottom: -10%;
  left: 17.1%;
  width: 31px;
}

.content__screen01 .big-heading .ticket-green {
  top: -1%;
  left: 42.25%;
  width: 33px;
}

.content__screen01 .big-heading .ticket-aquamarine {
  bottom: -13.85%;
  right: 25.6%;
  width: 55px;
}

.content__screen01 .big-heading .ticket-yellow {
  top: 23.75%;
  right: 15%;
  width: 33px;
}

/* .content__screen07 */
.content__screen07 .big-heading .ticket-orange1 {
  top: -25.65%;
  left: -0.95%;
  width: 38px;
}

.content__screen07 .big-heading .ticket-green1 {
  top: -19.55%;
  right: 30.65%;
  width: 38px;
}

.content__screen07 .big-heading .ticket-yellow1 {
  top: -2.7%;
  width: 37px;
}

.content__screen07 .big-heading .ticket-green3 {
  top: 5.15%;
  width: 40px;
}

.content__screen07 .big-heading .ticket-green2 {
  top: 31.2%;
  left: 6.1%;
  width: 41px;
}

.content__screen07 .big-heading .ticket-blue1 {
  bottom: 39.15%;
  left: 33.5%;
  width: 54px;
}

.content__screen07 .big-heading .ticket-aquamarine1 {
  bottom: 48.75%;
  right: 8.5%;
  width: 51px;
}

.content__screen07 .big-heading .ticket-blue2 {
  bottom: 10.65%;
  width: 62px;
}

.content__screen07 .big-heading .ticket-yellow2 {
  bottom: 4.35%;
  width: 41px;
}

.content__screen07 .big-heading .ticket-orange2 {
  bottom: -19.05%;
  left: 23.15%;
  width: 51px;
}

.content__screen07 .big-heading .ticket-aquamarine2 {
  bottom: -19.15%;
  right: -13.4%;
  width: 68px;
}

/*!
     * .content__my-account
     */
.list-nav-link-card {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  list-style: none;
  gap: 20px;
}

.nav-link__card {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--bs-red);
  border: 2px solid var(--bs-red);
  border-radius: 20px;
  color: #fff;
  padding: 20px 25px;
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
}

.nav-link__heading {
  font-size: 25px;
  line-height: 30px;
}

.nav-link__card:hover {
  background-color: #fff;
  color: var(--bs-red);
}

.tab__my-account {
  border: 1px solid #000;
  border-radius: 10px;
  max-width: 1760px;
  overflow: hidden;
}

.tab__my-account .nav-tabs,
.tab__my-account .nav-tabs .nav-link,
.tab__my-account .nav-tabs.flex-column + .tab-content {
  border: 0;
}

.tab__my-account .nav-tabs.flex-column + .tab-content {
  border-left: 1px solid #000;
}

.tab__my-account .nav-tabs .nav-item:first-child .nav-link {
  border-left: 0;
}

.tab__my-account .nav-tabs .nav-item .nav-link.active {
  border-right: 0;
}

.content__my-account {
  position: relative;
}

.content__my-account .content__header {
  padding-top: 4px;
  padding-bottom: 10px;
}

.content__my-account .big-heading h1 {
  font-size: 44px;
  line-height: 53px;
}

.content__my-account .big-heading h1 + .text-muted {
  font-size: 15px;
  line-height: 20px;
  margin-top: -3px;
}

.content__my-account .nav-tabs.flex-column .nav-item {
  padding-left: 12px;
  padding-right: 12px;
}

.content__my-account .h5,
.content__my-account h5 {
  font-size: 20px;
  line-height: 24px;
}

.content__my-account .form-group {
  max-width: 440px;
}

.content__my-account .fc__CardExpiry {
  max-width: 194px;
}

.content__my-account .tab-pane__ChangePassword .form-group,
.content__my-account .tab-pane__ChangeEmail .form-group {
  max-width: 532px;
}

.content__my-account .tab-pane__EmailPreferences .tab-pane__body,
.content__my-account .tab-pane__SavedCards .tab-pane__body {
  max-width: 900px;
}

.form-group.mw-100,
.form-group.mw-100 {
  max-width: 100%;
}

.content__my-account-home .big-heading .h4 {
  font-size: 30px;
  line-height: 36px;
}

.content__my-account-home .big-heading .ticket-blue {
  top: 70.5%;
  left: -9.85%;
  width: 49px;
}

.content__my-account-home .big-heading .ticket-green {
  top: 5.2%;
  right: 7.05%;
  width: 84px;
}

.content__my-account-home .ticket-orange {
  position: absolute;
  top: 90.3%;
  left: -4.1%;
  width: 82px;
}

.content__my-account-home .nav-link__heading {
  font-size: 42px;
  line-height: 46px;
}

.content__my-account-home .content__body {
  position: relative;
  z-index: 1;
}

.accordion__my-account-settings .heading {
  margin-bottom: 9px;
}

.modal__form-modal {
  font-size: 20px;
  letter-spacing: 0;
  line-height: 25px;
}

.modal__form-modal h2 {
  font-size: 67px;
  line-height: 63px;
  margin-bottom: 24px;
}

.modal__almost-done .modal-content {
  background-image: url(../images/tickets/bg-tickets-almost-done-mobile.svg);
  background-position: 15px 11px;
  background-repeat: no-repeat;
  background-size: 309px auto;
}

.modal__message-received .big-heading .ticket-mustard {
  bottom: -23.15%;
  left: 1.5%;
  width: 40px;
}

.modal__message-received .big-heading .ticket-green {
  top: -13.4%;
  left: 11.2%;
  width: 34px;
}

.modal__message-received .big-heading .ticket-yellow {
  bottom: 26.95%;
  left: 26.85%;
  width: 49px;
}

.modal__message-received .big-heading .ticket-red {
  bottom: -14.75%;
  right: 29.65%;
  width: 37px;
}

.modal__message-received .big-heading .ticket-aquamarine {
  top: 50%;
  right: 23.15%;
  width: 34px;
}

.modal__message-received .big-heading .ticket-blue {
  top: 0.25%;
  right: 7.25%;
  width: 41px;
}

.modal__message-received .form-group__cta .btn {
  min-width: 220px;
}

.modal__my-account-almost-done .modal-content {
  background-image: url(../images/tickets/bg-tickets-almost-done-my-account-mobile.svg);
  background-position: 15px 11px;
  background-size: 313px auto;
}

.modal__my-account-almost-done .p-send-again {
  margin-top: 30px;
}

.modal__almost-done .modal-body {
  padding: 32px 25px 30px;
  min-height: 281px;
}

.modal__my-account-almost-done .modal-body {
  padding-top: 42px;
}

.modal__almost-done .modal-dialog,
.modal__my-account-delete-card .modal-dialog {
  max-width: 420px;
}

.modal__my-account-delete-card .btn-close__absolute {
  top: 30px;
  right: 26px;
}

.modal__my-account-delete-card h2 {
  font-size: 35px;
  letter-spacing: 0.7px;
  line-height: 35px;
}

.modal__my-account-delete-card .modal-content {
  background-image: url(../images/tickets/bg-tickets-delete-success-mobile.svg);
  background-position: 21px 10px;
  background-repeat: no-repeat;
  background-size: 315px auto;
}

.modal__my-account-delete-card .modal-body {
  padding: 30px 25px;
  min-height: 183px;
}

/*!
     * Current Listings page
     * .content__current-listings
     */
.btn__listings-filter {
  font-size: 23px;
  font-weight: bold;
  letter-spacing: 0;
  margin-bottom: 10px;
  padding: 3px 22px 4px 19px;
}

.btn__listings-filter .icon__plus {
  border-radius: 50rem;
  border: 1px solid #000;
  width: 34px;
  height: 34px;
  margin-right: unset;
  margin-left: unset;
  transition: 0.3s;
}

.btn__listings-filter .icon__plus::before,
.btn__listings-filter .icon__plus::after {
  height: 16px;
  width: 2px;
  top: 8px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

/* .btn__listings-filter[aria-expanded="true"] .icon__plus {
  transform: rotate(45deg);
} */

.btn__listings-filter[aria-expanded="true"] .icon__plus::after
 {
      transform: rotate(-45deg);
      transform-origin: center;
}

.btn__listings-filter[aria-expanded="true"] .icon__plus::before
{
      transform: rotate(45deg);
      transform-origin: center;
}

.listing-filters-wrapper {
  border-bottom: 1px solid #000;
  padding-top: 11px;
}

.listing-filters {
  padding: 0 20px 17px;
}

.listing-filters .form-control::placeholder {
  color: #000;
}

.listing-filters .form-control::-ms-input-placeholder {
  color: #000;
}

.listing-filters .ts-control input::placeholder,
.listing-filters .form-control__datepicker::placeholder {
  color: #000;
}

.listing-filters .ts-control input::-ms-input-placeholder,
.listing-filters .form-control__datepicker::-ms-input-placeholder {
  color: #000;
}

.listing-filters .ts-control input:focus::placeholder,
.listing-filters .form-control__datepicker:focus::placeholder {
  color: #000;
}

.listing-filters .ts-control input:focus::-ms-input-placeholder,
.listing-filters .form-control__datepicker:focus::-ms-input-placeholder {
  color: #000;
}

.listing-filters .form-control,
.listing-filters .form-datepicker {
  width: 100%;
}

.listing-filters .ts-control {
  padding: 6px 16px 5px 15px;
}

.listing-filters .ts-wrapper.form-control,
.listing-filters .ts-control,
.listing-filters .form-control__datepicker {
}

.listing-filters .ts-control,
.listing-filters .ts-control input,
.listing-filters .ts-dropdown,
.listing-filters .form-control__datepicker {
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.3px;
}

.listing-filters .ts-wrapper:not(.form-select).single .ts-control {
  background-position: right 13px center;
  background-size: 16px 14px;
  padding-right: 46px;
}

.listing-filters .form-control__datepicker {
  padding: 6px 48px 5px 15px;
}

.listing-filters .form-datepicker::after {
  top: 12px;
  right: 14px;
  width: 16px;
  height: 14px;
  background-size: 16px 14px;
}

.listing-filters .form-group {
  margin-bottom: 10px;
}

.listing-filters-wrapper .list__event-tags-wrapper {
  position: relative;
  padding-top: 13px;
  padding-bottom: 13px;
}

.owl-carousel__event-tags .badge-outline {
  border-color: var(--bs-red);
  color: var(--bs-red);
  font-weight: normal;
  padding: 7px 16px 6px 16px;
  font-size: 15px;
  letter-spacing: 0.3px;
}

#owlCarouselEventTagsNav {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

#owlCarouselEventTagsNav button.owl-prev,
#owlCarouselEventTagsNav button.owl-next {
  background: #fff;
  outline-color: transparent !important;
  outline-style: none !important;
  outline-width: 0 !important;
  width: 100%;
  width: 36px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 3;
}

#owlCarouselEventTagsNav button.owl-prev {
  border-right: 1px solid #000;
}

#owlCarouselEventTagsNav button.owl-next {
  border-left: 1px solid #000;
  right: 0;
}

#owlCarouselEventTagsNav.owl-nav button.owl-prev span {
  width: 19px;
  height: 20px;
  background-image: url(../images/icon-arrow-right-short-sm-black.svg);
  background-size: 19px 22px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

#owlCarouselEventTagsNav.owl-nav button.owl-next span {
  width: 19px;
  height: 20px;
  background-image: url(../images/icon-arrow-right-short-sm-black.svg);
  background-size: 19px 22px;
}

.search-results-wrapper {
  font-size: 15px;
  line-height: 20px;
}

.list__event-tags-wrapper .search-results-wrapper {
  margin-top: 10px;
}

.icon__clock {
  width: 16px;
  height: auto;
}

.icon__location-pin {
  width: 13px;
  height: auto;
  position: relative;
}

.icon__payment-card {
  width: 22px;
  height: auto;
}

.card__event-image {
  position: relative;
}

.card__event-image .event-image {
  border-radius: 10px;
}

.card__event-image .badge {
  background-color: var(--bs-blue);
  border-radius: 50px;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  line-height: 14px;
  height: 23px;
  padding: 5px 14px 4px 14px;
}

.card__event-image .badge-red {
  background-color: var(--bs-red);
}

.card__event-image .badge-upper,
.card__event-image .list__event-tags {
  position: absolute;
  z-index: 1;
}

.card__event-image .badge-upper {
  background-color: var(--bs-red);
  border-radius: 0;
  padding: 4px 9px 4px 9px;
  top: 17px;
  left: -4px;
}

.card__event-image .list__event-tags {
  bottom: 14px;
  right: 16px;
}

.card__event-image .list__event-tags > li:not(:first-child) {
  margin-left: 16px;
}

.card__event .card-title,
.list-group__event-details {
  font-size: 15px;
  line-height: 18px;
}

.card__event .card-title {
  margin-bottom: 15px;
}

.card__event .btn-outline-primary {
  font-size: 20px;
  letter-spacing: 0.4px;
  padding-top: 10px;
  padding-bottom: 9px;
  min-width: 170px;
}

.card__event {
  border-radius: 0;
  border: none;
  padding: 20px 20px;
  position: relative;
}

.current-listings .card__event .card-title {
  padding-left: 3px;
  padding-right: 3px;
  margin-bottom: 18px;
}

.card__event .card-title a {
  color: #000;
  text-decoration: none;
}

.card__event .card-title a:hover {
  color: var(--bs-red);
}

.card__event .event-image {
  width: 100%;
}

.card__event .card-body {
  border: none;
  padding: 17px 0 15px 0;
}

.card__event .card-footer {
  border-top: 0;
  padding: 0;
}

.card__event .card-footer:last-child {
  border-radius: 0 0 0 0;
}

.card__event-image .badge__ticket-sold-out {
  color: #000;
  font-size: 13px;
  line-height: 25px;
  border-radius: 0;
  background-color: transparent;
  background-image: url(../images/tickets/ticket-yellow-4-xy-sides.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 82px auto;
  width: 82px;
  height: 35px;
  position: absolute;
  z-index: 1;
  transform: rotate(-15deg);
  bottom: calc(50% - 17.5px);
  left: calc(50% - 41px);
}

.card__event .icon__location-pin,
.card__event .icon__clock {
  position: relative;
  top: -2px;
}

.card__event .icon__payment-card {
  position: relative;
  top: -1px;
}

.event,
.event__header {
  display: flex;
  flex-direction: column;
}

hr.event__name-hr {
  height: 1px;
  width: 88px;
  margin: 10px 0;
}

.event__name + .event__name-hr {
  margin: 15px 0;
}

.event__name-hr + h6 {
  margin-top: 10px;
}

.event__header-image {
  flex-shrink: 0;
}

.event__location {
  font-size: 15px;
  letter-spacing: 0.36px;
  line-height: 20px;
  margin-bottom: 10px;
}

.event__header-text .badge {
  font-size: 10px;
  letter-spacing: 0.2px;
  padding: 3px 16px 4px;
}

.nav-tabs__info-venue .icon__clock,
.nav-tabs__info-venue .icon__payment-card,
.nav-tabs__info-venue .icon__location-pin {
  position: relative;
  top: -2px;
}

.list-group__location {
  margin-bottom: 8px;
}

.list-group__time,
.list-group__price {
  margin-bottom: 10px;
}

.list-group__event-details {
  border-radius: 0;
}

.list-group__event-details .list-group-item {
  border: 0;
  display: flex;
  gap: 11px;
  padding: 0;
}

.list-group__event-details .icon-wrapper {
  flex-shrink: 0;
  text-align: center;
  width: 32px;
}

.list-group-flat .list-group-item {
  color: #000;
  background-color: transparent;
}

.current-listings {
  display: flex;
  flex-direction: column;
  position: relative;
}

.current-listings .item {
  border-bottom: 1px solid #000;
  border-top: 1px solid #000;
  margin-top: -1px;
}

.content__current-listings .owl-carousel__events {
  padding-top: 16px;
  padding-bottom: 16px;
}

.content__current-listings .owl-carousel__events .event-image {
  width: 280px;
  height: auto;
  border-radius: 30px;
}

.content__current-listings .big-heading .ticket-blue {
  top: 7.4%;
  left: 12.4%;
  width: 23px;
}

.content__current-listings .big-heading .ticket-red {
  bottom: -13.9%;
  left: 20.5%;
  width: 25px;
}

.content__current-listings .big-heading .ticket-aquamarine {
  bottom: -9.8%;
  right: 40.8%;
  width: 31px;
}

.content__current-listings .big-heading .ticket-yellow {
  top: -4.9%;
  right: 8.9%;
  width: 24px;
}

.content__current-listings .listing-filters .form-control__search {
  width: 100%;
}

/*!
     * .content__event-details
     */
.btn__payment-method {
  height: 56px;
}

.section__event-details {
  padding-top: 21px;
}

.section__event-details .container {
  max-width: 100%;
}

.badge.bg-danger,
.badge.bg-red {
  background-color: var(--bs-red) !important;
  border-color: var(--bs-red) !important;
  color: #fff;
}

.event .event__name {
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 25px;
  margin-top: 6px;
  margin-bottom: 12px;
}

.nav-tabs__info-venue-wrapper {
  background-color: #fff;
}

.nav-tabs__info-venue .nav-tabs .nav-link {
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.3px;
  padding: 8px 21px 7px;
}

.nav-tabs__info-venue + .list__event-tags {
  gap: 7px;
}

.nav-tabs__info-venue .list-group + hr {
  margin: 0 0 15px;
}

.nav-tabs__info-venue + .list__event-tags .badge {
  font-size: 12px;
  padding: 5px 18px;
}

.nav-tabs__info-venue .tab-content .heading {
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 14px;
}

.nav-tabs__info-venue .tab-content > .tab-pane {
  flex-direction: column;
}

.nav-tabs__info-venue .tab-content > .active {
  display: flex;
}

.venue-map {
  border: 1px solid #000;
  line-height: 0;
  margin-top: 20px;
}

.content__event-details .event__header-image .event-image {
  border-radius: 10px;
}

.content__event-details .event__header-image {
  margin-bottom: 10px;
}

.content__event-details .event__header {
  margin-bottom: 4px;
}

.content__event-details .event__body {
  padding-top: 15px;
}

.content__event-details p + .video-wrapper {
  margin-top: 25px;
}

.video-wrapper__iframe {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.aspect-ratio-4-3 {
  padding-top: 75% !important;
}

.aspect-ratio-3-2 {
  padding-top: 66.66% !important;
}

.aspect-ratio-8-5 {
  padding-top: 62.5% !important;
}

.video-wrapper__iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

/*!
     * .content__my-favourites
     */
.content__my-favourites .big-heading .ticket-aquamarine {
  top: 8%;
  left: 12.25%;
  width: 23px;
}

.content__my-favourites .big-heading .ticket-red {
  bottom: -15.05%;
  left: 20.5%;
  width: 25px;
}

.content__my-favourites .big-heading .ticket-green {
  top: -3.9%;
  left: 40.2%;
  width: 27px;
}

.content__my-favourites .big-heading .ticket-blue {
  bottom: -10.15%;
  right: 40.75%;
  width: 31px;
}

.content__my-favourites .big-heading .ticket-orange {
  top: -4.25%;
  right: 9.05%;
  width: 24px;
}

.btn-outline-primary.hover-primary:hover {
  background-color: var(--bs-red);
  border-color: var(--bs-red);
  color: #fff;
}

.btn-outline-primary.hover-primary:active,
.form-check-input:checked + .btn-outline-primary.hover-primary {
  background-color: var(--bs-red);
  border-color: var(--bs-red);
  color: #fff;
}

.btn-outline-primary.rounded-circle {
  width: 54px;
  padding: 11px 7px;
}

.btn-sm.rounded-circle {
  width: 38px;
  padding: 6px;
}

.btn:not(.btn-icon).rounded-circle {
  min-width: auto !important;
}

/*!
     * .content__leave-feedback
     */
.content__leave-feedback {
  max-width: 860px;
  padding-left: 20px;
  padding-right: 20px;
}

.content__leave-feedback .content__body {
  padding-top: 25px;
  padding-bottom: 41px;
}

.content__leave-feedback .card__event {
  margin-bottom: 19px;
}

.content__leave-feedback .event-image {
  max-width: 575px;
}

.content__leave-feedback .card__event .card__event-image {
  margin-bottom: 19px;
}

.content__leave-feedback .card__event .card-title {
  font-size: 30px;
  letter-spacing: 0;
  line-height: 35px;
}

.content__leave-feedback .form-group__heading,
.content__leave-feedback .btn-check-wrapper .btn-sm {
  font-size: 15px;
  letter-spacing: 0.3px;
  line-height: 18px;
}

.content__leave-feedback .form-group__heading {
  margin-bottom: 22px;
}

.content__leave-feedback .btn-check-wrapper {
  max-width: 648px;
  margin-bottom: 13px;
}

.content__leave-feedback .btn-check-wrapper .btn-sm {
  padding: 11px 15px 10px 15px;
}

.content__leave-feedback .list__btn-checks {
  margin-bottom: 21px;
}

.content__leave-feedback .list__btn-checks-circle .btn-sm {
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 18px;
  min-width: 38px !important;
  padding: 9px;
}

.content__leave-feedback .form-group__cta {
  gap: 15px 11px;
}

.content__leave-feedback .form-group__cta .btn {
  margin-bottom: 0;
}

.content__leave-feedback .form-control {
  max-width: 746px;
}

.content__leave-feedback textarea {
  min-height: 176px;
}

.content__leave-feedback .form-group + .form-group {
  margin-top: 47px;
}

.content__leave-feedback .form-group + .form-group__cta {
  margin-top: 50px;
}

.content__leave-feedback .input-group__as-form-control {
  padding: 7px 15px;
}

.content__leave-feedback .input-group__as-form-control .input-group-text,
.content__leave-feedback .input-group__as-form-control .form-control {
  font-size: 25px;
  letter-spacing: 0;
  line-height: 35px;
}

.form-group__venue-spend .fc__amount[contenteditable]:empty::before {
  content: "0.00";
  color: #b2b2b2;
}

.list__btn-checks {
  display: flex;
  flex-wrap: wrap;
  gap: 13px 20px;
}

.list__btn-checks-circle {
  gap: 25px;
}

.list__btn-checks .btn-outline-primary.btn:not(.btn-icon) {
  min-width: 197px;
}

/*!
     * .content__refer-friend
     */
.content__refer-friend .big-heading h1,
.content__refer-friend .big-heading .h1 {
  font-size: 106px;
  line-height: 92px;
}

.faux-read-only[readonly] {
  background-color: #fff;
}

.input-group-pill:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group-pill:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-left-radius: 50rem;
  border-bottom-left-radius: 50rem;
}

.content__refer-friend .big-heading .ticket-yellow {
  bottom: 32.15%;
  left: 11.5%;
  width: 56px;
}

.content__refer-friend .big-heading .ticket-red {
  top: -3.2%;
  left: 23.1%;
  width: 53px;
}

.content__refer-friend .big-heading .ticket-blue {
  bottom: -7.6%;
  left: 39.5%;
  width: 46px;
}

.content__refer-friend .big-heading .ticket-orange {
  top: 50.1%;
  right: 4.6%;
  width: 54px;
}

.content__refer-friend .big-heading .ticket-green {
  bottom: 29.6%;
  right: 7.25%;
  width: 48px;
}

.content__refer-friend .content__header {
  padding-top: 35px;
  padding-bottom: 38px;
}

.content__refer-friend .card__invite-link {
  max-width: 1098px;
  position: relative;
  z-index: 1;
}

.content__refer-friend .card__invite-link {
  width: 100%;
}

.card__invite-link {
  border-radius: 20px;
}

.card__invite-link .lead {
  font-size: 15px;
  line-height: 20px;
}

.card__invite-link .lead + .form-group {
  margin-top: 27px;
}

.card__invite-link .input-group__invite-link .btn {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 21px;
  min-width: 96px;
}

.card__invite-link .form-group + .form-group__cta {
  margin-top: 30px;
}

.card__invite-link .card-body {
  padding: 24px 30px 23px 30px;
}

.input-group__invite-link {
  max-width: 650px;
}

.input-group__invite-link .form-control {
  border-color: #000;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
}

.input-group__invite-link .btn {
  border-color: #000;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.input-group__invite-link .btn:hover,
.input-group__invite-link .btn:focus {
  border-color: var(--bs-red);
}

.btn__modal-terms-conditions {
  font-size: 20px;
  line-height: 25px;
}

.btn__modal-terms-conditions .btn__text {
  margin-left: 15px;
  margin-right: 15px;
}

.btn__modal-terms-conditions .btn__icon {
  width: 20px;
  height: auto;
}

.modal__fw .modal-content {
  border-bottom: 1px solid #000;
  border-radius: 0;
  box-shadow: none;
}

.modal__fw .modal-dialog {
  max-width: 100%;
  margin: 91px auto 1.75rem;
}

/*!
     * FAQ page
     * .content__faq
     */
.accordion-line-separated .accordion-body {
  font-size: 12px;
  letter-spacing: 0.24px;
  line-height: 16px;
}

.content__faq .big-heading .ticket-orange {
  bottom: 44.05%;
  left: 7.75%;
  width: 27px;
}

.content__faq .big-heading .ticket-blue {
  top: 2.05%;
  left: 43.65%;
  width: 23px;
}

.content__faq .big-heading .ticket-aquamarine {
  bottom: -10.95%;
  left: 25.45%;
  width: 28px;
}

.content__faq .big-heading .ticket-yellow {
  top: 35.5%;
  right: 14.15%;
  width: 31px;
}

.content__faq .big-heading .ticket-red {
  bottom: -10.25%;
  right: 18.25%;
  width: 25px;
}

.accordion-line-separated .accordion-button {
  font-weight: bold;
}

.content__faq .big-heading h1,
.content__faq .big-heading .h1,
.content__faq .big-heading h2,
.content__faq .big-heading .h2 {
  font-size: 44px;
  line-height: 44px;
}

.content__faq .content__header {
  padding-top: 12px;
  padding-bottom: 22px;
}

.section__faq {
  padding-bottom: 59px;
}

/*!
     * .content__register
     */
.badge {
  color: #000;
  white-space: normal;
}

.badge-outline {
  border: 1px solid #000;
  border-radius: 50px;
  color: #000;
  transition: 0.3s;
}

.list__event-tags {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.list__tags {
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 14px 10px;
}

.list__tags .btn-outline-primary {
  color: #000;
  letter-spacing: 0.4px;
}

.list__tags label.btn-outline-primary {
  border-color: var(--bs-red);
}

.list__event-tags a:hover .badge-outline,
.list__event-tags a .badge.active {
  border-color: var(--bs-red);
  color: #fff;
  background-color: var(--bs-red);
}

.list__tags .btn-outline-primary:hover {
  color: #fff;
}

label + .list__tags,
label + .form-group__custom-controls {
  margin-top: 10px;
}

.list__tags .btn-check + .btn {
  font-size: 15px;
  letter-spacing: 0.3px;
  line-height: 18px;
}

.content__register .col__left .feature {
  margin-top: 30px;
  padding-left: 23px;
}

.content__register .big-heading .ticket-orange {
  bottom: -12.9%;
  left: 23.7%;
  width: 33px;
}

.content__register .big-heading .ticket-aquamarine {
  top: -4.5%;
  left: 43.9%;
  width: 39px;
}

.content__register .big-heading .ticket-yellow {
  top: 57%;
  right: 18.3%;
  width: 32px;
}

.content__register .form-group__custom-controls {
  margin-bottom: 15px;
}

.content__register .form-group__cta .btn-primary {
  min-width: 269px;
}

.content__contact-us .col__left .content__body,
.content__contact-us .col__right .content__body,
.content__register .col__left .content__body,
.content__register .col__right .content__body,
.content__uptake .col__left .content__body,
.content__uptake .col__right .content__body {
  padding: 20px;
}

.content__register-last-part .big-heading .ticket {
  display: none;
}

.content__register-last-part .p-account-created {
  font-size: 20px;
  line-height: 21px;
  margin-top: 26px;
}

.content__register-last-part .buttons-wrapper {
  margin-top: 31px;
}

/*!
     * .content__contact-us
     */
.content__contact-us .big-heading .ticket-aquamarine {
  top: 59.65%;
  left: 9.4%;
  width: 40px;
}

.content__contact-us .big-heading .ticket-blue {
  top: -9.75%;
  right: 39.15%;
  width: 29px;
}

.content__contact-us .big-heading .ticket-yellow {
  bottom: -1.5%;
  right: 7.2%;
  width: 40px;
}

.user-cat .heading,
.btn-prefix-arrow,
.custom-checkbox__arrow label {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.4px;
  line-height: 25px;
}

.user-cat > :not(.custom-checkbox__arrow),
.custom-checkbox__arrow,
.btn-prefix-arrow {
  padding-left: 33px;
}

.btn-prefix-arrow {
  color: #000;
  display: block;
  position: relative;
  text-decoration: none;
}

.btn-prefix-arrow:hover,
.btn-prefix-arrow.active {
  color: var(--bs-red);
}

.custom-checkbox__arrow {
  margin-bottom: 10px;
}

.custom-checkbox__arrow .custom-control-input {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.custom-checkbox__arrow .custom-control-label {
  cursor: pointer;
  margin-bottom: 0;
}

.btn-prefix-arrow.active::before {
  content: "";
  display: block;
  background-image: url(../images/icon-arrow-right-23x22-red.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 23px auto;
  position: absolute;
  top: -4px;
  left: -1px;
  width: 23px;
  height: 22px;
}

.custom-checkbox__arrow .custom-control-label::before {
  position: absolute;
  top: -4px;
  left: -1px;
  border-radius: 0;
  border: none;
  background-color: transparent;
  width: 34px;
  height: 31px;
}

.custom-checkbox__arrow .custom-control-input:checked ~ .custom-control-label {
  color: var(--bs-red);
}

.custom-checkbox__arrow
  .custom-control-input:checked
  ~ .custom-control-label::before {
  border-color: transparent;
  background-color: transparent;
  background-image: url(../images/icon-arrow-right-34x31-red.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 34px auto;
}

.custom-checkbox__arrow .custom-control-label::after {
  display: none;
}

.user-cat .heading + .user-cat__buttons {
  margin-top: 19px;
}

.user-cat .heading + .custom-checkbox__arrow {
  margin-top: 18px;
}

.user-cat .custom-checkbox__arrow {
  margin-bottom: 7px;
}

.user-cat .btn-prefix-arrow {
  margin-bottom: -1px;
}

.content__contact-us .user-cat + div {
  margin-top: 35px;
}

.btn-check-wrapper > label {
  margin-bottom: 0;
}

.buttons-wrapper__nav .btn {
  font-size: 18px;
  letter-spacing: 0.36px;
  line-height: 21px;
}

.buttons-wrapper__nav .icon-arrow__left {
  margin-top: -6px;
  margin-right: 6px;
}

.contact-clues .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  letter-spacing: 0.3px;
  line-height: 18px;
  padding: 13px 25px 12px 25px;
}

.contact-clues .btn-check-wrapper {
  margin-bottom: 14px;
}

.contact-clues + .form-group__cta {
  margin-top: 30px;
}

.content__contact-clues .heading + .contact-clues {
  margin-top: 26px;
}

.content__contact-form .buttons-wrapper__nav {
  margin-bottom: 24px;
}

.content-single-col .content__contact-form .buttons-wrapper__nav {
  margin-bottom: 16px;
}

.content__contact-form textarea {
  min-height: 220px;
}

.content__contact-form--problem textarea {
  min-height: 150px;
}

.content__contact-us .col__right {
  min-height: 500px;
}

.content__contact-us .card__event .card-body {
  padding-top: 0;
  padding-bottom: 0;
}

.content__contact-us .form__contact-us-member-login {
  max-width: 513px;
}

.content__contact-form .form-group + .p-notice,
.content__contact-form form + .p-notice,
.content__contact-form .buttons-wrapper__nav + .heading {
  margin-top: -5px;
}

.content__contact-form .heading + form {
  margin-top: -6px;
}

.content__contact-us.content-single-col {
  padding-top: 17px;
}

.section__contact-us .form-group__cta .btn-primary {
  min-width: 171px;
}

.accordion-line-separated .accordion-item {
  border-radius: 0;
}

.accordion-line-separated .accordion-item:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion-line-separated .accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.content__help-with-show {
  padding-bottom: 60px;
}

.content__help-with-show .content__header {
  padding: 6px 20px;
}

.content__help-with-show .content__header .heading {
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 25px;
}

.content__help-with-show .content__header > :last-child,
.content__contact-us-faq .content__header > :last-child {
  margin-bottom: 0;
}

.content__contact-us-faq {
  max-width: 1138px;
}

.content__contact-us-faq .content__header {
  padding: 26px 20px 26px;
}

.content__contact-us-faq .content__header .heading {
  color: var(--bs-red);
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 24px;
}

.content__contact-us-faq {
  padding-bottom: 39px;
}

.content__contact-us-faq .content__footer {
  padding-top: 17px;
  padding-left: 20px;
  padding-right: 20px;
}

.content__contact-us-faq .buttons-wrapper {
  margin-top: 20px;
}

.btn__scrollTo-prev-screen,
.btn__scrollTo-next-screen,
.btn__scrollTo-first-screen {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 50%;
}

.btn__scrollTo-next-screen {
  cursor: url(../images/icon-hand-pointer-down.png), auto;
  top: 50%;
}

.btn__scrollTo-first-screen,
.btn__scrollTo-prev-screen {
  cursor: url(../images/icon-hand-pointer-up.png), auto;
}

.content__screen01 .btn__scrollTo-next-screen,
.btn__scrollTo-first-screen {
  height: 100%;
  top: 0;
}

.z-index-2 {
  position: relative;
  z-index: 2;
}

section {
  padding-top: 50px;
  padding-bottom: 50px;
}

section.bg-red {
  color: #fff;
}

.section__what-we-do {
  padding-top: 53px;
  padding-bottom: 60px;
}

.section__new-look {
  padding-top: 50px;
  padding-bottom: 60px;
}

.section__new-look .content__header {
  margin-bottom: 40px;
}

.section__ticket-booking-steps {
  padding-top: 53px;
}

.section__cta--huge {
  padding: 21px 10px 24px;
}

.section__cta--huge .btn-huge {
  height: 72px;
  width: 0;
  padding: 0;
}

.section__cta--huge .btn-huge.is-inview {
  animation: animateTransform 1.5s cubic-bezier(0.43, 0, 0.035, 1);
  transition-delay: 0s;
  width: 100%;
}

.container__notification {
  padding-top: 7px;
  padding-bottom: 7px;
}

.container__notification--alert {
  background-color: var(--bs-red);
  color: #fff;
  font-size: 17px;
  letter-spacing: 0.51px;
  line-height: 30px;
}

.container__notification--alert a,
.container__notification--alert .btn {
  color: #fff;
}

.container__notification--alert a:hover,
.container__notification--alert .btn:hover {
  color: var(--bs-dark);
}

main {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 487.36px);
  padding-top: 51px;
  position: relative;
}

.main__about-us,
.main__membership-guidelines {
  transition: background-color 0.65s cubic-bezier(0.5, 0.05, 0.4, 1);
  transform: translate3d(0, 0, 0) perspective(1000px);
}

/*.main__about-us {
      background-color: var(--bs-blue);
    }*/
.main__membership-guidelines {
  background-color: #fff;
  overflow: hidden;
}

.main__membership-guidelines.bg-red p,
.main__membership-guidelines.bg-red h2 {
  color: #fff;
  transition: color 0.65s cubic-bezier(0.5, 0.05, 0.4, 1);
}

.main__about-us.bg-blue .content__NHSCT,
.main__about-us .section__NHSCT.bg-blue .content__NHSCT {
  color: #fff;
  transition: color 0.65s cubic-bezier(0.5, 0.05, 0.4, 1);
}

.main__about-us.bg-blue .content__NHSCT .btn-primary,
.main__about-us .section__NHSCT.bg-blue .content__NHSCT .btn-primary {
  background-color: var(--bs-blue);
  border-color: #fff;
}

section.bg-red .btn-primary {
  background-color: var(--bs-red);
  border-color: #fff;
}

.main__about-us.bg-blue .content__NHSCT .btn-primary:hover,
.main__about-us.bg-blue .content__NHSCT .btn-primary:focus {
  background-color: #fff;
  border-color: #fff;
  color: var(--bs-blue);
}

section.bg-red .btn-primary:hover,
section.bg-red .btn-primary:focus {
  background-color: #fff;
  border-color: #fff;
  color: var(--bs-red);
}

.main__about-us .spy-scrollbar.theme-white .spy-scrollbar__nav a {
  color: #fff;
  transition: none;
}

.main__about-us.bg-white .spy-scrollbar__nav a,
.main__about-us.bg-mint .spy-scrollbar__nav a {
  color: #000;
  transition: color 0.65s cubic-bezier(0.5, 0.05, 0.4, 1);
}

.main__about-us .spy-scrollbar.theme-white .spy-scrollbar__progress,
.main__about-us .spy-scrollbar.theme-white .spy-scrollbar__line {
  background-color: #fff;
}

.main__about-us.bg-mint .spy-scrollbar__progress,
.main__about-us.bg-white .spy-scrollbar__progress,
.main__about-us.bg-mint .spy-scrollbar__line,
.main__about-us.bg-white .spy-scrollbar__line {
  background-color: #000;
}

.bg-red .vr {
  background-color: #fff;
}

/*!
     * .main-footer
     */
.main-footer {
  background-color: var(--bs-red);
  color: #fff;
  font-size: 15px;
  line-height: 18px;
  padding-top: 22px;
}

.main-footer .nav-link {
  color: #fff;
  margin-bottom: 1px;
}

.main-footer .nav-link:hover {
  color: var(--bs-dark);
}

.main-footer .nav-heading {
  color: #fff;
  font-size: 15px;
  letter-spacing: 0.3px;
  line-height: 18px;
  margin-bottom: 19px;
}

.main-footer .logo__c {
  position: relative;
  top: -1px;
}

.main-footer .logo__fsb {
  position: relative;
  top: -5px;
}

.main-footer .logo__NHSCT {
  position: relative;
  top: -1px;
}

.main-footer .logo__cyber-essentials {
  position: relative;
  top: -3px;
}

.container__footer-menu .col-office-address,
.container__footer-menu .col-footer-menu {
  padding-right: 20px;
}

.main-footer .container__footer-menu {
  padding: 0 19px 15px 20px;
}

.main-footer .container__copyright {
  border-top: 1px solid #fff;
  font-size: 12px;
  line-height: 14px;
  padding: 24px 19px;
}

/* END .main-footer */
.btn-close__custom {
  width: 30px;
  height: 30px;
  padding: 0;
  background: url(../images/icon-x-black.svg) center center no-repeat;
  border-radius: 0;
  opacity: 1;
}

.form-group__verification-code {
  column-gap: 10px;
  margin-bottom: 30px;
}

.form-group__verification-code .form-control {
  padding: 0;
  font-size: 28px;
  line-height: 1;
  border: 1px solid rgb(0 0 0 / 90%);
  border-radius: 0;
  height: 45px;
  width: 43px;
  text-align: center;
}

.modal__registration-verification .form-group__cta + p {
  margin-top: 10px;
}

.modal__registration-verification p + .form-group__verification-code {
  margin-top: 25px;
}

.modal__registration-verification .btn-primary,
.modal__registration-verification .btn:not(.btn-icon) {
  min-width: 199px;
}

.modal__registration-verification .content {
  margin-top: 27px;
}

.modal__registration-verification .modal-body {
  padding: 35px 18px;
}

.modal__registration-verification .modal-dialog {
  max-width: 470px;
}

.modal__registration-completed h2 {
  font-size: 67px;
  line-height: 63px;
  margin-bottom: 11px;
}

.modal__registration-completed .form-group__cta {
  margin-top: 65px;
}

.modal__registration-completed .modal-dialog {
  max-width: 394px;
  margin-left: auto;
  margin-right: auto;
}

.modal__registration-completed .modal-body {
  padding: 50px 27px 45px 27px;
}

.modal__registration-completed .modal-content {
  background-image: url(../images/tickets/bg-tickets-register-success.svg);
  background-position: 13px 20px;
  background-repeat: no-repeat;
  background-size: 345px auto;
}

.modal__registration-completed .content {
  padding-top: 34px;
}

.modal__registration-completed .content .btn-primary {
  min-width: 190px;
}

/* .content__slidein */
.slidein-wrapper {
  position: fixed;
  top: 51px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 51px);
  z-index: 2;
  visibility: hidden;
}

.slidein-wrapper::before {
  content: "";
  display: block;
  background-color: rgb(255 255 255 / 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.5s;
}

.slidein-wrapper .slidein {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: -100%;
  z-index: 2;
  transition: all 0.5s;
}

.slidein-wrapper.show {
  visibility: visible;
}

.slidein-wrapper.show::before {
  background-color: rgb(255 255 255 / 50%);
}

.slidein-wrapper.show .slidein {
  animation: slideInRight 1s;
  right: 0;
}

.slidein .content__slidein {
  height: calc(100vh - 51px);
  overflow: auto;
}

.slidein-wrapper .slidein {
  background-color: #fff;
  width: 100%;
  height: 100%;
}

.slidein .content__checkout {
  min-height: calc(100vh - 51px);
}

.slidein .content__checkout > .content {
  min-height: calc(100vh - 111px);
}

/* .content__checkout */
.icon__apple {
  display: inline-flex;
  background: url(../images/icon-apple-sm-black.png) center center no-repeat;
  background-size: 25px 31px;
  width: 25px;
  height: 31px;
}

.btn__payment-method {
  border-radius: 10px;
  border: 1px solid #000;
  box-shadow: 0 0 0 1px transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 13px 29px 13px 15px;
  transition: 0.3s;
}

.btn__payment-method .button__text {
  display: flex;
  flex-direction: column;
  padding-left: 18px;
  text-align: left;
}

.btn__payment-method .button__icon + .button__text {
  padding-left: 27px;
}

.btn__payment-method .sub-heading__upper,
.wallet-balance__text .sub-heading__upper {
  font-size: 15px;
  letter-spacing: -0.15px;
  line-height: 19px;
  transition: 0.3s;
}

.btn__payment-method .sub-heading__upper {
  margin-top: -5px;
}

.btn__payment-method .button__text .heading,
.btn__payment-method .button__text .wallet-balance,
.wallet-balance__text .wallet-balance {
  font-weight: bold;
  transition: 0.3s;
}

.btn__payment-method .button__text .heading {
  margin-bottom: 0;
}

.btn__payment-method .button__icon {
  display: flex;
  flex-shrink: 0;
  background-color: var(--bs-red);
  border-radius: 9px;
  width: 85px;
  height: 68px;
}

.btn__payment-method .button__icon--wallet {
  background-image: url(../images/icon-wallet-sm-white.svg);
  background-repeat: no-repeat;
  background-position: 15px center;
  background-size: 59px 38px;
}

.btn__payment-method .button__icon--debitcredit {
  background-image: url(../images/icon-payment-card-sm-white.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 64px 42px;
}

.btn__payment-method .button__icon--poundsterling {
  background-image: url(../images/icon-pound-sterling-sm-white.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 31px 65px;
}

.btn__payment-method .button__arrow {
  display: flex;
  background-color: #fff;
  background-image: url(../images/icon-arrow-right-short-sm-black.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 19px 22px;
  border: 1px solid #000;
  border-radius: 50px;
  width: 50px;
  height: 50px;
  margin-left: auto;
  transition: 0.3s;
}

.btn__payment-method .icon__apple {
  background-size: 25px 31px;
  width: 25px;
  height: 31px;
  position: relative;
  margin-right: 2px;
  transition: 0.3s;
}

.btn__payment-method:hover .icon__apple {
  background-image: url(../images/icon-apple-sm-red.png);
}

.btn__payment-method:hover {
  border-color: var(--bs-red);
  box-shadow: 0 0 0 1px var(--bs-red);
}

.btn__payment-method:hover .sub-heading__upper,
.btn__payment-method:hover .heading,
.btn__payment-method:hover .wallet-balance {
  color: var(--bs-red);
}

.btn__payment-method:hover .button__arrow {
  background-color: var(--bs-red);
  background-image: url(../images/icon-arrow-right-short-sm-white.svg);
  border-color: var(--bs-red);
}

.checkbox-wrapper,
.logos-wrapper,
.subtotal-block {
  display: flex;
  flex-wrap: wrap;
}

.logos-wrapper {
  align-items: start;
  gap: 20px;
}

.subtotal-block {
  align-items: center;
  justify-content: space-between;
}

.form-groups__expiry-cvc > .form-group:first-child {
  margin-right: 31px;
}

.content__slidein .input-group__as-form-control .input-group-text {
  font-size: 24px;
  line-height: 30px;
}

.content__slidein .btn-close__absolute {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
}

.content__slidein .btn-close__custom {
  width: 20px;
  height: 20px;
  background-image: url(../images/icon-x-37x37-black.svg);
}

.content__slidein .content__checkout > .content__header {
  padding: 20px 70px 19px 20px;
}

.content__slidein .content__body {
  padding: 20px;
}

.content__slidein .content__title {
  letter-spacing: 0.3px;
}

.input-group__as-checkbox .custom-control.custom-checkbox__check {
  padding-left: 30px;
}

.content__checkout .input-group__as-checkbox {
  max-width: 428px;
  margin-bottom: 30px;
}

.content__checkout .input-group__as-checkbox .custom-control {
  position: relative;
  top: 1px;
}

.content__checkout .input-group__as-checkbox .input-group-text {
  font-size: 20px;
  line-height: 24px;
}

.content__checkout .form-group__card-expiry .form-control {
  width: 170px;
}

.content__checkout .form-group__cvc .form-control {
  width: 90px;
}

.content__checkout .buttons-wrapper .btn {
  letter-spacing: 0.4px;
}

.content__checkout .btn__payment-method {
  margin-bottom: 19px;
}

.content__checkout .btn__payment-method + .heading {
  margin-top: 30px;
}

.content__checkout .btn__payment-method .icon__apple,
.content__checkout .btn__payment-method:hover .icon__apple {
  background-size: 20px 24px;
  width: 20px;
  height: 24px;
}

.content__checkout .btn-primary:not(.btn__plus-to-x):not(.btn-xxs),
.content__checkout .btn-outline-primary:not(.btn__plus-to-x):not(.btn-xxs) {
  letter-spacing: 0.43px;
  padding: 11px 29px;
}

.content__checkout .heading,
.content__checkout .content__title,
.content__checkout .subtotal-block .label,
.content__checkout .subtotal-block .total-heading,
.content__checkout .subtotal-block .total-amount,
.content__checkout .content__footer .btn {
  font-size: 15px;
  line-height: 20px;
}

.content__checkout .subtotal-block .label,
.content__checkout .subtotal-block .total-heading {
  font-weight: bold;
}

.content__checkout .event__name {
  font-size: 20px;
  line-height: 25px;
}

.content__checkout .subtotal-block .total-heading,
.content__checkout .subtotal-block .total-amount {
  font-weight: bold;
}

.content__checkout .content__body--total {
  margin-top: auto;
}

.content__checkout .content__footer .btn,
.content__checkout
  .content__footer
  .btn-primary:not(.btn__plus-to-x):not(.btn-xxs) {
  min-height: 77px;
}

.content__checkout .content__footer .btn {
  border: 0;
  border-radius: 0;
}

.content__checkout .content__footer .btn.w-50 + .btn.w-50 {
  border-left: 1px solid #000;
}

.content__checkout .table > :not(caption) > * > * {
  padding: 0.5rem 0;
  box-shadow: inset 0 0 0 0 var(--bs-table-accent-bg);
}

.content__checkout .btn-primary:hover,
.content__checkout .btn-primary.active,
.content__checkout .btn-primary:active,
.content__checkout .btn-primary:focus {
  background-color: var(--bs-red);
  border-color: var(--bs-red);
  color: #fff;
}

.promo-code__form-group {
  margin-bottom: 23px;
}

.promo-code__form-group .form-group + .btn__plus-to-x {
  margin-left: 10px;
}

.promo-code__item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #000;
  padding-bottom: 5px;
}

.promo-code__item .promo-code {
  padding-right: 25px;
}

.promo-code__item .promo-code-value-wrapper {
  margin-left: auto;
}

.content__checkout .checkbox-wrapper {
  gap: 20px;
  margin-bottom: 12px;
}

.subtotal-block + .custom-control {
  margin-top: 9px;
}

.content__checkout .form-group-wrapper + hr {
  margin-top: 26px;
}

.content__order-summary .event__name {
  margin-bottom: 8px;
}

.table__items tr:last-child > td {
  border-bottom-width: 0;
}

.table__items .td-amount {
  text-align: right;
}

.content__payment .wallet-balance-wrapper {
  margin-bottom: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.content__checkout .input-group__as-form-control {
  padding: 9px 15px 7px;
}

.content__payment .buttons-wrapper + .heading {
  margin-top: 30px;
}

.content__checkout .content__select-time {
  font-size: 20px;
  line-height: 24px;
}

.content__select-time .p-label {
  margin-bottom: 17px;
}

.content__select-time .p-label + .p-value {
  margin-bottom: 13px;
}

.content__select-time .btn-check-wrapper + .btn-check-wrapper {
  margin-top: 17px;
}

.tickets-drop-down.d-flex [data-scroll].ticket,
.tickets-drop-down.d-flex .emoji {
  transform: none;
}

.content__booking-success .big-heading h1,
.content__booking-success .big-heading .h1,
.content__booking-success .big-heading h2,
.content__booking-success .big-heading .h2 {
  font-size: 101px;
  line-height: 96px;
}

.content__booking-success .big-heading .ticket-blue {
  top: -10px;
  left: 30.5%;
  width: 47px;
}

.content__booking-success .big-heading .ticket-red {
  top: 26.25%;
  left: 35%;
  width: 62px;
}

.content__booking-success .big-heading .ticket-yellow {
  top: 29%;
  right: 2%;
  width: 66px;
  z-index: 1;
}

.content__booking-success .big-heading .ticket-green {
  bottom: 35.25%;
  right: 22.15%;
  width: 56px;
}

.content__booking-success .big-heading .ticket-orange {
  bottom: 27.25%;
  left: 12.75%;
  width: 55px;
}

.content__booking-success .big-heading .ticket-aquamarine {
  bottom: -17px;
  right: 32.75%;
  width: 46px;
  z-index: 1;
}

.content__booking-success .content__header {
  padding-top: 80px;
}

.content__booking-success .event__name {
  margin-bottom: 6px;
}

.content__booking-success .content__header {
  margin-bottom: 6vh;
}

.content__booking-timeout .content__header {
  margin-bottom: 7.1vh;
}

.content__booking-timeout .big-heading h1,
.content__booking-timeout .big-heading .h1,
.content__booking-timeout .big-heading h2,
.content__booking-timeout .big-heading .h2 {
  font-size: 152px;
  line-height: 133px;
}

.content__booking-timeout .big-heading .emoji-yellow {
  top: 3.5%;
  left: 50.5%;
  width: 38px;
}

.content__booking-timeout .big-heading .emoji-aquamarine {
  top: 15%;
  left: 4.75%;
  width: 51px;
}

.content__booking-timeout .big-heading .emoji-blue {
  top: 41.95%;
  right: 1.2%;
  width: 47px;
}

.content__booking-timeout .big-heading .emoji-green {
  top: 47.5%;
  left: 39.5%;
  width: 52px;
}

.content__booking-timeout .big-heading .emoji-orange {
  bottom: 5.1%;
  right: 11.5%;
  width: 51px;
}

.content__booking-timeout .big-heading .emoji-red {
  bottom: -7.75%;
  left: 14.25%;
  width: 52px;
}

.content__booking-timeout .content__header {
  padding-top: 18.4vh;
}

.content__booking-timeout .event__name {
  margin-bottom: 6px;
}

.content__booking-timeout .content__body .buttons-wrapper {
  margin-top: 9.5vh;
}

.content__booking-success .content__body .buttons-wrapper {
  margin-top: 6.35vh;
}

.content__booking-timeout .content__body .btn-primary {
  min-width: 234px;
}

.tickets-drop-down .ticket,
.tickets-drop-down .emoji {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1),
    transform 2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-delay: 0.5s;
}

.tickets-drop-down.d-flex .ticket,
.tickets-drop-down.d-flex .emoji,
.big-heading-emoji .emoji {
  display: block;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1),
    transform 2s cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: displayNoneToBLockTranslateY 1.2s;
}

/*!
     * .content__my-wallet
     */
.content__my-wallet .big-heading .ticket-aquamarine {
  z-index: 1;
  bottom: 16.2%;
  left: 16.4%;
  width: 27px;
}

.content__my-wallet .big-heading .ticket-blue {
  top: 1.5%;
  left: 42.5%;
  width: 23px;
}

.content__my-wallet .big-heading .ticket-yellow {
  bottom: -2.9%;
  right: 15%;
  width: 31px;
}

.card__credit-balance .card-body .h3 {
  font-size: 22px;
  line-height: 23px;
  margin-bottom: 10px;
}

.card__credit-balance .card-body .wallet-balance {
  font-size: 35px;
  line-height: 38px;
}

.p-top-up {
  margin-bottom: 20px;
}

.p-top-up .btn-xxs {
  margin-left: 30px;
}

.content__my-wallet .p-terms {
  font-size: 16px;
  line-height: 19px;
}

.card__credit-balance .card-body {
  background-image: url(../images/icon-wallet-md-white-mobile.svg);
  background-repeat: no-repeat;
  background-position: -12px 14px;
  background-size: 106px auto;
  padding: 11px 20px 12px 115px;
}

.card__credit-balance .heading {
  margin-top: 3px;
}

.card__credit-balance {
  background-color: var(--bs-red);
  border-color: var(--bs-red);
  color: #fff;
}

.card__transaction-summary .card-body {
  padding: 15px;
}

.card__transaction-summary .card-footer__hr {
  margin-top: 0;
  margin-bottom: 13px;
}

.card__transaction-summary .transaction-amount {
  font-size: 20px;
  font-weight: bold;
}

.card__transaction-summary .card-footer {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 15px;
}

.card__transaction-summary + .card__transaction-summary {
  margin-top: 14px;
}

.content__my-wallet .card__credit-balance {
  margin-bottom: 20px;
}

.content__my-wallet .col__top-up-info .content,
.content__my-wallet .col__transaction-history .content {
  max-width: 571px;
}

/*!
     * .content__wallet-topup
     */
.content__wallet-topup-success .content__header {
  margin-bottom: 71px;
  padding-top: 120px;
}

.content__wallet-topup-success .big-heading h1 {
  font-size: 102px;
  line-height: 102px;
}

.content__wallet-topup-success .big-heading .ticket-red {
  top: 5%;
  right: 35.95%;
  width: 35px;
  z-index: 1;
}

.content__wallet-topup-success .big-heading .ticket-blue {
  top: 24%;
  left: 26.25%;
  width: 47px;
  z-index: 1;
}

.content__wallet-topup-success .big-heading .ticket-green {
  top: 34.15%;
  right: 22.25%;
  width: 56px;
  z-index: 1;
}

.content__wallet-topup-success .big-heading .ticket-orange {
  bottom: 21%;
  left: 4.65%;
  width: 55px;
}

.content__wallet-topup-success .big-heading .ticket-yellow {
  bottom: -7.95%;
  left: 36.05%;
  width: 64px;
  z-index: 1;
}

.content__wallet-topup-success .big-heading .ticket-aquamarine {
  bottom: -3.5%;
  right: 22.45%;
  width: 45px;
}

.content__wallet-topup-success .btn-primary {
  min-width: 234px;
}

/*!
     * .content__my-bookings
     */
.content__my-bookings .big-heading .ticket-aquamarine {
  top: -5%;
  left: 14.45%;
  width: 23px;
}

.content__my-bookings .big-heading .ticket-yellow {
  bottom: -15%;
  left: 20.6%;
  width: 25px;
}

.content__my-bookings .big-heading .ticket-green {
  top: -3.5%;
  left: 35.5%;
  width: 27px;
}

.content__my-bookings .big-heading .ticket-blue {
  bottom: -10.15%;
  left: 51.25%;
  width: 31px;
}

.content__my-bookings .big-heading .ticket-orange {
  top: -2%;
  right: 8.25%;
  width: 28px;
}

.list__my-bookings .card__event .event-image {
  width: 100%;
  height: auto;
}

.list__my-bookings .card__event .card__event-image {
  width: 170px;
  flex-shrink: 0;
}

.list__my-bookings .buttons-wrapper {
  display: flex;
}

.list__my-bookings .card__event .card-body {
  letter-spacing: 0.4px;
}

.list__my-bookings .card__event .card-footer {
  margin-top: 22px;
  width: 100%;
  flex-shrink: 0;
}

.list__my-bookings .card__event .card-footer .btn,
.list__my-bookings .card__event .col-right .btn {
  font-size: 15px;
  letter-spacing: 0.3px;
  line-height: 18px;
  padding: 11px 20px 11px;
  width: 100%;
}

.list__my-bookings .card__event .card-footer .buttons-wrapper {
  gap: 13px 10px;
}

.content__my-bookings .list__my-bookings .card__event .card-body,
.content__contact-us .list__my-bookings .card__event .card-body {
  padding-right: 0;
  padding-left: 10px;
}

.content__my-bookings .list__my-bookings .card__event {
  padding: 19px 20px 20px;
}

.content__my-bookings .list__my-bookings > li,
.content__contact-us .list__my-bookings > li {
  border-bottom: 1px solid #000;
}

.content__manage-booking .btn-primary {
  min-width: 281px;
}

.content__manage-booking-other-requests-received .big-heading h1 {
  font-size: 116px;
  line-height: 105px;
}

.content__manage-booking-other-requests textarea {
  min-height: 351px;
}

.content__manage-booking-other-requests-received .big-heading .ticket-green {
  top: -8.5%;
  left: 24.5%;
  width: 34px;
  z-index: 1;
}

.content__manage-booking-other-requests-received .big-heading .ticket-red {
  bottom: -16.5%;
  left: 17%;
  width: 39px;
  z-index: 1;
}

.content__manage-booking-other-requests-received .big-heading .ticket-blue {
  top: 41.1%;
  left: 48%;
  width: 41px;
  z-index: 1;
}

.content__manage-booking-other-requests-received
  .big-heading
  .ticket-aquamarine {
  bottom: -17.25%;
  right: 25.75%;
  width: 34px;
  z-index: 1;
}

.content__manage-booking-other-requests-received .big-heading .ticket-orange {
  top: 32.25%;
  right: 4.95%;
  width: 39px;
  z-index: 1;
}

/*!
     * .content__no-working-page
     */
.content__no-working-page .big-heading h1,
.content__no-working-page .big-heading .h1,
.content__no-working-page .big-heading h2,
.content__no-working-page .big-heading .h2 {
  font-size: 42px;
  line-height: 48px;
}

.content__no-working-page .big-heading .emoji-red {
  top: -9.6%;
  left: 9.7%;
  width: 46px;
}

.content__no-working-page .big-heading .emoji-aquamarine {
  top: 32%;
  left: -4.85%;
  width: 45px;
}

.content__no-working-page .big-heading .emoji-yellow {
  top: 10.5%;
  left: 78.4%;
  width: 33px;
}

.content__no-working-page .big-heading .emoji-blue {
  top: 54.25%;
  right: -4.2%;
  width: 42px;
}

.content__no-working-page .big-heading .emoji-green {
  bottom: -10.75%;
  left: 4.35%;
  width: 46px;
}

.content__no-working-page .big-heading .emoji-orange {
  bottom: -14.5%;
  right: 9.35%;
  width: 45px;
}

.content__no-working-page .content__header {
  padding-top: 100px;
  padding-bottom: 100px;
  padding-right: 50px;
  padding-left: 50px;
}

.content__no-working-page .content__header .big-heading {
  margin-bottom: 50px;
}

.content__no-working-page .browser-alternatives-block p {
  letter-spacing: 0;
}

/*!
     * .content__uptake
     */
.border-1 {
  border: 1px solid #000 !important;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.card-body > :only-child {
  margin-bottom: 0;
}

.card__uptake .card-body {
  padding: 11px 15px 9px;
}

.card__uptake--thank-you .card-body {
  padding: 13px 20px 17px;
}

.card__uptake--thank-you .card-title {
  font-size: 25px;
  letter-spacing: 0;
  line-height: 30px;
}

.content__uptake-event .status-wrapper {
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 24px;
}

.content__uptake-event .status-wrapper.d-flex .heading {
  margin-right: 4px;
}

.content__uptake-event .card__event {
  margin-bottom: 15px;
  padding: 15px 16px 16px;
}

.content__uptake-event .card__event .card-title {
  margin-bottom: 12px;
}

.content__uptake-event .logos-container .logo-nhs-charities {
  margin-right: 5px;
  width: 153px;
  height: auto;
}

.content__uptake-event .logos-container .logo-ecologi {
  width: 112px;
  height: auto;
}

.content__uptake-event .p-registered {
  font-size: 7px;
  line-height: 8px;
  margin-top: 8px;
}

.content__uptake-event .card__event .card__event-image {
  margin-bottom: 12px;
}

.content__uptake-event .card__event-status {
  padding: 10px 15px 11px;
}

.content__uptake-event .status-wrapper .icon {
  position: relative;
  top: 1px;
}

.content__uptake-event .small {
  letter-spacing: 0.3px;
  line-height: 20px;
}

.content__uptake-details .card__uptake {
  margin-bottom: 15px;
}

.owl-carousel__uptake .item__image {
  width: 192px;
  height: 128px;
}

.card__uptake--stat .stat__heading,
.card__uptake--stat .stat__text,
.card__uptake--progress .card-title {
  font-size: 24px;
  letter-spacing: 0.48px;
  line-height: 28px;
}

.card__uptake--details {
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 24px;
}

.card__uptake--details .card-body {
  padding: 16px 14px;
}

.card__uptake--details .uptake-item .item__header + .item__body {
  margin-top: 23px;
}

.card__uptake--details .uptake-item .item__body.border-top {
  padding-top: 13px;
}

.card__uptake--details .list-group__uptake-list .list-group-item {
  gap: 10px 27px;
}

.card__uptake--details .list-group__uptake-list .buttons-wrapper {
  margin-top: 14px;
}

.list-group__uptake-list .list-group-item.flex-row div > .heading {
  margin-right: 4px;
}

.list-group__uptake-head .list-group-item > div + div {
  margin-top: 21px;
}

.card__uptake--progress .card-body {
  padding: 11px 15px 14px;
}

.card__uptake--progress .card-title {
  margin-bottom: 10px;
}

.p-important-wrapper {
  font-size: 10px;
  letter-spacing: 0.2px;
  line-height: 14px;
}

.card__uptake--progress .progress-bar-overlay + div {
  margin-top: 9px;
}

.progress-bar-overlay {
  height: 29px;
  overflow: hidden;
  font-size: 10px;
  font-weight: bold;
  background-color: transparent;
  border-radius: 50px;
  position: relative;
}

.progress-bar-overlay .progress-bar {
  background-color: var(--bs-red);
  position: relative;
  z-index: 1;
}

.progress-bar-overlay::before {
  content: "";
  display: flex;
  height: 29px;
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  border-radius: 50px;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #000;
}

.progress .progress-value {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-48%);
  z-index: 2;
  text-align: center;
}

.card__uptake--members-feedback .card-title {
  font-size: 19px;
  letter-spacing: 0.26px;
  line-height: 24px;
}

.card__uptake--members-feedback .card-body {
  padding: 12px 15px;
}

.card.bg-primary {
  color: #fff;
}

.list-group__uptake-list .buttons-wrapper .btn {
  padding-bottom: 9px;
}

.list-group__uptake-list .btn,
.card__uptake--carousel .btn,
.content__uptake .btn {
  font-size: 17px;
  letter-spacing: 0.34px;
  line-height: 21px;
  padding-bottom: 9px;
}

.list__uptake-details {
  font-size: 15px;
  letter-spacing: 0.3px;
  line-height: 20px;
  gap: 0 30px;
}

.list__uptake-details > li {
  flex-shrink: 0;
}

.list__uptake-details > .list-item__date {
  order: 1;
}

.list__uptake-details > .list-item__status {
  order: 2;
}

.list__uptake-details > .list-item__allocated {
  order: 3;
}

.list__uptake-details > .list-item__booked {
  order: 4;
}

.list__uptake-details .heading {
  color: var(--bs-red);
  font-weight: bold;
  display: inline-block;
  min-width: 98px;
}

.list__uptake-stat {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px 29px;
  margin-bottom: 18px;
}

.list__uptake-stat > li {
  letter-spacing: 0.3px;
  padding-left: 11px;
  position: relative;
}

.list__uptake-stat > li::before {
  content: "";
  background-color: #000;
  display: block;
  position: absolute;
  top: 6px;
  left: 0;
  width: 1px;
  height: 52px;
}

.list__uptake-stat .stat__number {
  font-size: 40px;
  letter-spacing: 0.8px;
  line-height: 40px;
  margin-bottom: 3px;
}

.uptake-progress-wrapper .heading {
  font-size: 15px;
  font-weight: normal;
  letter-spacing: 0.3px;
  line-height: 20px;
  margin-bottom: 6px;
}

.content__uptake {
  padding: 0 20px;
  margin-bottom: 15px;
}

.content__uptake-venues {
  position: relative;
  z-index: 1;
}

.content__uptake-venues .big-heading .ticket-blue {
  bottom: 62.5%;
  left: 4.7%;
  width: 18px;
}

.content__uptake-venues .big-heading .ticket-green1 {
  top: 64%;
  left: 38.4%;
  width: 16px;
}

.content__uptake-venues .big-heading .ticket-yellow1 {
  left: 63.2%;
  top: -15.15%;
  width: 20px;
}

.content__uptake-venues .big-heading .ticket-aquamarine {
  left: 82.6%;
  top: 63.85%;
  width: 13px;
}

.content__uptake-event .row-event-title-status,
.content__uptake-event .row-uptake-list-progress,
.content__uptake-event .row-notice-buttons {
  display: flex;
  flex-direction: column;
}

.card__uptake--details .list__uptake-details + .buttons-wrapper {
  margin-top: 33px;
}

.card__uptake--carousel {
  overflow: hidden;
  margin: 14px 0;
}

.card__uptake--carousel .card-body {
  padding: 23px 14px 25px;
}

.card__uptake--carousel .logos-container {
  margin-top: 35px;
}

.card__uptake--carousel .logos-wrapper {
  gap: 20px 29px;
}

.card__uptake--carousel .logos-container .logo-nhs-charities {
  width: 153px;
  height: auto;
}

.card__uptake--carousel .logos-container .logo-ecologi {
  width: 112px;
  height: auto;
}

.card__uptake--carousel .logos-container .p-registered {
  font-size: 6px;
  letter-spacing: 0;
  line-height: 10px;
  margin-top: 10px;
  padding-left: 13px;
}

.content__uptake .col__left .content__body,
.content__uptake .col__right .content__body {
  padding: 20px;
}

.content__uptake-event .card__event .card__event-image + .card-body {
  padding: 0;
}

.content__uptake-event .status-wrapper {
  font-size: 15px;
  letter-spacing: 0.3px;
  line-height: 19px;
}

.content__uptake-event .status-wrapper .status {
  background-color: var(--bs-aqua);
  border-radius: 50px;
  margin-left: 2px;
  padding: 0 15px;
}

.content__uptake-event .p-important-wrapper {
  border-top: 1px solid var(--bs-red);
  margin-bottom: 33px;
  padding-top: 4px;
}

.row-uptake-list-progress .list__uptake-stat {
  margin-bottom: 18px;
}

.content__uptake-event .row-uptake-list-progress {
  margin-top: 24px;
  margin-bottom: 20px;
}

.content__uptake .card__uptake--details + .card__uptake--details {
  margin-top: 14px;
}

.content__uptake .p-donation {
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
}

.content__uptake .p-donation + .buttons-wrapper {
  margin-top: 33px;
}

.row-event-title-status .status {
  text-align: center;
}

.row__big-heading + .row {
  position: relative;
  z-index: 1;
}

.main__uptake .section__uptake {
  padding-top: 21px;
  padding-bottom: 0;
}

.main__uptake .section__uptake-venues {
  padding-top: 21px;
}

.accordion__circle-arrow .accordion-button::after {
  background-image: url(../images/icon-circle-arrow-down-24x24-black.svg);
  width: 24px;
  height: 24px;
  background-size: 24px;
  position: relative;
  top: -1px;
}

.accordion__circle-arrow .accordion-button:not(.collapsed)::after {
  background-image: url(../images/icon-circle-arrow-down-24x24-black.svg);
  transform: rotate(0deg);
}

.accordion__circle-arrow .accordion-button[aria-expanded="true"]::after {
  transform: rotate(-180deg);
}

.accordion__circle-arrow .accordion-button {
  padding: 16px 20px 14px 20px;
  font-size: 30px;
  letter-spacing: 0;
  line-height: 35px;
}

.accordion__circle-arrow .accordion-body {
  position: relative;
  border-top: 1px solid #000;
  line-height: 25px;
  padding: 15px 20px 30px 20px;
}

.btn-close__custom--thin {
  background-image: url(../images/icon-x-30x30-black.svg);
}

.datepicker__trigger {
  border: 1px solid rgb(0 0 0 / 90%);
  border-radius: 0;
  padding: 11px 16px;
  background-image: url(../images/icon-arrow-down-black-sm.svg);
  background-position: right 13px center;
  background-repeat: no-repeat;
  background-size: 16px 14px;
  padding-right: 36px;
  text-align: left;
  color: #777;
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 26px;
  width: 100%;
}

.datepicker__trigger:hover {
  color: #777;
}

.content__choose-date .datepicker__trigger {
  font-size: 15px;
  letter-spacing: 0.3px;
  line-height: 20px;
}

.datepicker__trigger.changed {
  color: #000;
}

.content__select-time .datepicker-inline {
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 0;
  padding: 0;
}

.content__select-time.as-dropdown {
  background-color: #fff;
  border: 1px solid #000;
  border-top: none;
  padding: 20px;
  position: absolute;
  top: 44px;
  left: 0;
  z-index: 99;
  width: 100%;
}

.content__select-time .btn-close {
  position: absolute;
  top: 16px;
  right: 16px;
  background-size: 15px;
}

.content__select-time {
  font-size: 20px;
  line-height: 24px;
}

.content__select-time > .col__right {
  margin-top: 14px;
}

.btn-check__select-time + label {
  font-size: 15px;
  line-height: 1;
  font-weight: 400;
}

.btn-check__select-time + label.btn {
  line-height: 20px;
  min-width: 112px;
  padding: 3px 15px 2px 15px !important;
}

.content__select-time .btn-check__select-time:checked + .btn-outline-primary {
  color: #fff;
  background-color: var(--bs-red);
  border-color: var(--bs-red);
}

.modal__terms-and-conditions.slideInDown {
  animation-name: slideInDown;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.modal__show-preview .modal-content {
  background-color: #000;
  border-radius: 0;
}

.modal__show-preview .btn-close {
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
  position: absolute;
  top: -28px;
  right: 0;
}

body.modal-slide-down .main-header {
  z-index: 1100;
}

body.modal-slide-down .modal-backdrop,
body.modal-slide-down .modal-backdrop.show {
  opacity: 0;
}

body.modal-slide-down .modal-backdrop.show.fadein {
  transition: 0.5s;
  opacity: 0.5;
}

.btn:focus-visible,
button:focus-visible {
  outline: none;
}

.tooltip-inner {
  opacity: 1;
  padding-top: 8px;
  padding-bottom: 12px;
}

.tooltip {
  opacity: 1 !important;
}

.tooltip-inner {
  background-color: #000 !important;
  opacity: 1 !important;
}

.tooltip.bs-tooltip-right .tooltip-arrow::before {
  border-right-color: #000 !important;
}

.tooltip.bs-tooltip-left .tooltip-arrow::before {
  border-left-color: #000 !important;
}

.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #000 !important;
}

.tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #000 !important;
}

.icon-tooltip-info {
  display: inline-flex;
  justify-content: center;
  font-family: serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: var(--bs-red);
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background-color: transparent;
  border: 1px solid var(--bs-red);
  position: relative;
  top: -2px;
}

.logo__ecologi__custom-text {
  display: block;
  width: 83px;
  height: 37.86px;
  position: relative;
}

.logo__ecologi__custom-text {
  display: block;
  width: 100px;
  height: 46px;
  position: relative;
}

.logo__ecologi__custom-text .custom-text {
  color: #000;
  font-size: 7px;
  line-height: 1;
  text-align: center;
  position: absolute;
  bottom: -2px;
  right: 0;
  z-index: 1;
  width: 44px;
  height: 10px;
}

/*!
     * .main__new-look
     */
.main__new-look .content__what-we-do .big-heading .ticket-red {
  left: 7%;
  width: 9%;
}

.main__new-look .content__what-we-do .big-heading .ticket-yellow {
  bottom: -5%;
  left: 15.5%;
  width: 7%;
}

.main__new-look .content__what-we-do .big-heading .ticket-green {
  right: 37%;
  width: 7%;
}

.main__new-look .content__what-we-do .big-heading .ticket-aquamarine {
  width: 9%;
}

.main__new-look .content__what-we-do .big-heading .ticket-blue {
  left: 66%;
  width: 7%;
}

.main__new-look .content__new-look .big-heading h1,
.main__new-look .content__new-look .big-heading .h1 {
  font-size: 21vw;
  line-height: 1em;
}

.main__new-look .container__for-members .image1 {
  top: -56px;
  right: 22%;
}

.main__new-look .section__what-we-do .content__header {
  position: relative;
  z-index: 1;
}

.main__new-look .section__faq .content__header {
  margin-bottom: 3vh;
}

.main__new-look .section__what-we-do .container__for-venues {
  padding-top: 0;
  padding-bottom: 0;
}

.content__questions .big-heading h2,
.content__questions .big-heading .h1 {
  font-size: 15vw;
  line-height: 1.1;
}

.content__questions .big-heading .ticket-red {
  top: 39px;
  left: 5.1%;
  width: 76px;
  z-index: -1;
}

.content__questions .big-heading .ticket-yellow {
  bottom: 9px;
  left: 7.5%;
  width: 29px;
}

section.bg-red .content__body {
  position: relative;
  z-index: 1;
}

section.bg-red .content__for-venues .ticket {
  z-index: 0;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

/*!
     * .section__reactive-account
     */
.section__reactive-account {
  padding-top: 40px;
  padding-bottom: 50px;
}

.content__reactive-account.buttons-wrapper .btn.btn-primary {
  min-width: 280px;
}

.content__reactive-account .big-heading h1,
.content__reactive-account .big-heading .h1 {
  font-size: 16vw;
  line-height: 1em;
}

.content__reactive-account .big-heading .ticket-yellow {
  top: -2%;
  left: 8%;
  width: 33px;
}

.content__reactive-account .big-heading .ticket-green {
  top: 18%;
  left: 42%;
  width: 44px;
}

.content__reactive-account .big-heading .ticket-blue {
  top: 12.4%;
  right: 7.5%;
  width: 30px;
}

.content__reactive-account .big-heading .ticket-orange {
  bottom: 23%;
  left: 11.5%;
  width: 40px;
}

.content__reactive-account .big-heading .ticket-aquamarine {
  bottom: -10%;
  left: 50%;
  width: 40px;
}

.content__reactive-account .big-heading .ticket-red {
  bottom: 29.6%;
  right: 7%;
  width: 50px;
}

.content__reactive-account .content__header {
  padding-bottom: 40px;
}

/*  */
/*  */
/*  */
