
.CurrentListingItemDummy .card__event .card__event-image .event-image {
      width: 100%;
      height: 190px;
      border-radius: 10px;
}

.CurrentListingItemDummy .card__event .card__event-image .list__event-tags li {
      height: 23px;
      width: 82px;
      background: #fff !important;
      border-radius: 10px;
}

.CurrentListingItemDummy .card__event .card-body .card-title {
      height: 23px;
}

.CurrentListingItemDummy .card__event .card-body .list-group .list-group-item .icon-wrapper {
      height: 23px;
}
.CurrentListingItemDummy .card__event .card-body .list-group .list-group-item p  {
      height: 23px;
      width: 100%;
}

.CurrentListingItemDummy .card__event .card-footer .btn.btn-outline-primary{
      height: 45px;
      border: #eee;
}

.CurrentListingItemDummy .card__event .card-footer .btn-heart {
      background-image: none;
      border-radius: 5px;
      border: none;
      height: 28px;
}

.CurrentListingItemDummy .card__event .card__event-image .event-image,
.CurrentListingItemDummy .card__event .card__event-image .list__event-tags li,
.CurrentListingItemDummy .card__event .card-body .card-title,
.CurrentListingItemDummy .card__event .card-body .list-group .list-group-item .icon-wrapper,
.CurrentListingItemDummy .card__event .card-body .list-group .list-group-item p,
.CurrentListingItemDummy .card__event .card-footer .btn.btn-outline-primary,
.CurrentListingItemDummy .card__event .card-footer .btn-heart {
      background: #eee;
      background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
      -webkit-animation: shine 1.5s linear infinite;

}

@media (min-width:390px){
      .CurrentListingItemDummy .card__event .card__event-image .event-image {
            height: 200px;
      }
}


@media (min-width:412px){
      .CurrentListingItemDummy .card__event .card__event-image .event-image {
            height: 212px;
      }

}

@media (min-width:540px){
      
      .CurrentListingItemDummy .card__event .card__event-image .event-image {
            height: 248px;
      }

}

@media (min-width:720px){
      
      .CurrentListingItemDummy .card__event .card__event-image .event-image {
            height: 206px;
      }

}

@media (min-width:912px){
      
      .CurrentListingItemDummy .card__event .card__event-image .event-image {
            height: 231px;
      }

}

@media (min-width:1024px){
      .CurrentListingItemDummy .card__event .card__event-image .event-image {
            height: 263px;
      }
 
}


@media (min-width: 1200px) {
      .CurrentListingItemDummy .card__event .card__event-image .event-image {
            height: 215px;
      }

      .CurrentListingItemDummy .card__event .card__event-image .list__event-tags li {
            height: 27px;
            width: 95px;
      }

      .CurrentListingItemDummy .card__event .card-body .card-title {
            height: 32px;
      }

      .CurrentListingItemDummy .card__event .card-body .list-group .list-group-item .icon-wrapper,
      .CurrentListingItemDummy .card__event .card-body .list-group .list-group-item p  {
            height: 32px;
      }

      .CurrentListingItemDummy .card__event .card-footer .btn.btn-outline-primary{
            height: 54px;
            min-width: 194px;
      }

      .CurrentListingItemDummy .card__event .card-footer .btn-heart {
            height: 37px;
      }
      
}

@media (min-width: 1400px) {
      .CurrentListingItemDummy .card__event .card__event-image .event-image {
            height: 285px;
      }
}

@media (min-width:1700px){
      .CurrentListingItemDummy .card__event .card__event-image .event-image {
            min-height: 332px;
      }

}

@keyframes shine {
      to {
            background-position-x: -200%;
      }
}
      
@keyframes move {

      0%, 100% { 
            left: 0px;
      }
      20% , 60%{
            left: 15px;
      }
      40% , 80%{
            left: -15px;
      }
}

    