
.uptake-dummy__image {
      width: 100%;
      height: 172px;
      border-radius: 10px;
}

.uptake-title__dummy {
      height: 18px;
      border-radius: 4px;
}

.uptake-heading__dummy {
      height: 20px;
      border-radius: 4px; 
}

.uptake-progressbar_dummy {
      height: 29px;
      border-radius: 12px;
}

.uptake-memberfeedback__dummy a{
      border: none;
      height: 41px;
}

.uptake-liststat__dummy {
      height: 55px;
}

.uptake-statuwrapper__dummy {
      height: 19px;
      width: 100%;
}

.uptake-instruction__dummy {
      height: 75px;
      border: none !important;
}

.uptake-card__dummy {
      height: 120px;
      background-color: #eee !important;
}
.uptake-cardcarousel__dummy {
      height: 491px !important;
      border: none !important;
}

.uptake-dummy__image, .uptake-title__dummy, .uptake-heading__dummy, .uptake-progressbar_dummy, .uptake-memberfeedback__dummy a, .uptake-liststat__dummy, .uptake-statuwrapper__dummy,
.uptake-instruction__dummy, .uptake-card__dummy, .uptake-cardcarousel__dummy {
      background: #eee;
      background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
      -webkit-animation: shine 1.5s linear infinite;

}

@media (min-width:390px){
      .uptake-dummy__image {
            height: 180px;
      }
}


@media (min-width:412px){
      .uptake-dummy__image {
            height: 194px;
      }

      .uptake-liststat__dummy {
            height: 63px;
      }

      .uptake-instruction__dummy {
            height: 61px;
      }

      .uptake-cardcarousel__dummy {
            height: 420px !important;
      }

}

@media (min-width:540px){
      .uptake-dummy__image {
            height: 266px;
      }


}

@media (min-width:720px){
      
      .uptake-dummy__image {
            height: 397px;
      }

      .uptake-title__dummy {
            width: 245px;
      }

      .uptake-statuwrapper__dummy {
            width: 123px;
      }

      .uptake-instruction__dummy {
            height: 47px;
      }

      .uptake-card__dummy {
            height: 90px;
      }
      


}

@media (min-width:912px){
      
      .uptake-dummy__image {
            height: 479px;
      }

      .uptake-statuwrapper__dummy {
            width: 123px;
      }

}

@media (min-width:1024px){

      .uptake-dummy__image {
            height: 183px;
      }

      .uptake-instruction__dummy {
            height: 61px;
      }

      .uptake-card__dummy {
            height: 72px;
      }

      .uptake-cardcarousel__dummy {
            height: 493px !important;
      }
 
}


@media (min-width: 1200px) {
      .uptake-dummy__image {
            height: 252px;
      }

      .uptake-title__dummy {
            height: 30px;
            width: 400px;
      }

      .uptake-liststat__dummy {
            height: 76px;
      }

      
      .uptake-statuwrapper__dummy {
            height: 24px;
            width: 154px;
      }

      .uptake-instruction__dummy {
            height: 105px;
      }

      .uptake-card__dummy {
            height: 84px;
      }

      .uptake-cardcarousel__dummy {
            height: 503px !important;
      }
      
}

@media (min-width: 1200px) {
      .uptake-instruction__dummy {
            height: 85px;
      }
}

@media (min-width: 1400px) {
      
}

@media (min-width:1700px){
      
      .uptake-liststat__dummy {
            height: 86px;
      }

      .uptake-statuwrapper__dummy {
            height: 31px;
            width: 154px;
      }

      .uptake-instruction__dummy {
            height: 89px;
      }

      .uptake-cardcarousel__dummy {
            height: 553px !important;
      }
}

@keyframes shine {
      to {
            background-position-x: -200%;
      }
}
      
@keyframes move {

      0%, 100% { 
            left: 0px;
      }
      20% , 60%{
            left: 15px;
      }
      40% , 80%{
            left: -15px;
      }
}

    