
/* ------------------------------------------------------------------------------------------------- */
/* -------------------------------------React DatePicker-------------------------------------------- */
/* ------------------------------------------------- ------------------------------------------------*/


.react-datepicker__input-container input {

      border-radius: 0;
      border: 1px solid #000;
      font-family: var(--bs-body-font-family);
      background: #fff;
      color: #000;
      font-size: 15px;
      letter-spacing: 0.3px;
      line-height: 20px;
      padding: 11px 46px 11px 16px;
      display: flex;
      align-items: center;
      width: 100%;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: none;
}



.react-datepicker__input-container input::after {
      content: " ";
      display: block;
      position: absolute;
      top: 15px;
      right: 14px;
      width: 16px;
      height: 14px;
      background-image: url(../assets/images/icon-arrow-down-black-sm.svg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 16px 14px;
}


.react-datepicker__input-container input::after {
      top: 12px;
      right: 14px;
      width: 16px;
      height: 14px;
      background-size: 16px 14px;
}


.collapse__listing-filters .listing-filters .react-datepicker-popper,
.slidein__gift-voucher .react-datepicker-popper {
      border-radius: 0;
      border: 1px solid #000;
      font-family: var(--bs-body-font-family);
      background: #fff;
      color: #000;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 18px;
      margin-top: -1px;
      padding: 0;
}

.collapse__listing-filters .listing-filters .react-datepicker-wrapper,
.slidein__gift-voucher .react-datepicker-wrapper{
      width: 100%;
}

.collapse__listing-filters .listing-filters .react-datepicker-popper,
.slidein__gift-voucher .react-datepicker-popper {
      border-radius: 0;
      padding: 0 0 8px 0;
      width: 100%;
      z-index: 1000;
}

.collapse__listing-filters .listing-filters .form-datepicker .react-datepicker-popper[data-placement^=bottom],
.slidein__gift-voucher .form-datepicker .react-datepicker-popper[data-placement^=bottom] 
{
      padding-top: 0px;
}

.collapse__listing-filters .listing-filters .react-datepicker__triangle,
.slidein__book-tickets .react-datepicker__triangle,
.slidein__gift-voucher .react-datepicker__triangle 
{
      display: none;
}

.slidein__gift-voucher .form-datepicker .react-datepicker-popper[data-placement^=bottom]  {
      transform: translate(0px,40px) !important;
}

.collapse__listing-filters .listing-filters .form-datepicker .react-datepicker-popper[data-placement^=bottom]  {
      transform: translate(0px,38px) !important;
}


.collapse__listing-filters .listing-filters .react-datepicker-popper .react-datepicker,
.slidein__book-tickets .react-datepicker,
.slidein__gift-voucher .react-datepicker {
      font-family: inherit;
      font-size: inherit;
      background-color: inherit;
      color: inherit;
      border: none;
      border-radius: 0px;
      display: block;
}

.slidein__book-tickets .react-datepicker {
      border: 1px solid #000;
}


.collapse__listing-filters .listing-filters .form-datepicker  .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--previous,
.slidein__book-tickets .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--previous,
.slidein__gift-voucher .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--previous {
      background-color: transparent;
      cursor: pointer;
      float: none;
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: center;
      font-size: 0;
      text-indent: -9999px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 39px !important;
      height: 39px;
      border-right: 1px solid var(--bs-dark);
      top: 0;
      left: 0;
}


.collapse__listing-filters .listing-filters .form-datepicker  .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--previous::before,
.slidein__book-tickets .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--previous::before,
.slidein__gift-voucher .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--previous::before {
      content: '';
      width: 23px;
      height: 23px;
      background-image: url(./../assets/images/icon-arrow-left-black.svg);
      /* background-image: url(../src/assests/images/icon-arrow-left-black.svg); */
      /* background-image: url(../images/icon-arrow-left-black.svg); */
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 23px auto;
      margin-right: auto;
      margin-left: auto;
}


.collapse__listing-filters .listing-filters .form-datepicker  .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--previous span,
.collapse__listing-filters .listing-filters .form-datepicker  .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--next span,
.slidein__book-tickets .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--previous span,
.slidein__book-tickets .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--next span,
.slidein__gift-voucher .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--previous span,
.slidein__gift-voucher .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--next span
{
      display: none !important;
}


.collapse__listing-filters .listing-filters .form-datepicker  .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--next,
.slidein__book-tickets .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--next,
.slidein__gift-voucher .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--next {

      cursor: pointer;
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: center;
      float: none;
      font-size: 0;
      text-indent: -9999px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 39px !important;
      height: 39px;
      border-left: 1px solid var(--bs-dark);
      background-color: transparent;
      top: 0;
      right: 0;
}

.collapse__listing-filters .listing-filters .form-datepicker  .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--next::before,
.slidein__book-tickets .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--next::before,
.slidein__gift-voucher .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--next::before 
{

      content: '';
      width: 23px;
      height: 23px;
      background-image: url(./../assets/images/icon-arrow-left-black.svg);
      /* background-image: url(../src/assests/images/icon-arrow-left-black.svg); */
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 23px auto;
      margin-right: auto;
      margin-left: auto;
      transform: rotate(-180deg);
}

.collapse__listing-filters .listing-filters .form-datepicker  .react-datepicker .react-datepicker__month-container,
.slidein__book-tickets .react-datepicker .react-datepicker__month-container,
.slidein__gift-voucher .react-datepicker .react-datepicker__month-container
{
      float: none;
      border: none;
      padding-bottom:10px;
}


.collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__header,
.slidein__book-tickets .react-datepicker .react-datepicker__month-container .react-datepicker__header,
.slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__header
{

      margin: 0;
      padding: 0;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      border: none;
      background: inherit;
      /* border-bottom: 1px solid var(--bs-dark); */
}


.collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__current-month,
.slidein__book-tickets .react-datepicker .react-datepicker__month-container .react-datepicker__current-month,
.slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__current-month 
{

      font-size: 16px;
      line-height: 19px;
      width: 100%;
      height: 39px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      float: none;
      cursor: pointer;
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: center;
      font-weight: inherit;
      /* position: absolute; */
      border-bottom: 1px solid var(--bs-dark);
}


.collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__header  .react-datepicker__day-names,
.slidein__book-tickets .react-datepicker .react-datepicker__month-container .react-datepicker__header  .react-datepicker__day-names,
.slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__header  .react-datepicker__day-names
{

      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      width: 100%;
      margin: 0;
      padding: 0;
      width: 100%;
}

.collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__header  .react-datepicker__day-names .react-datepicker__day-name,
.slidein__book-tickets .react-datepicker .react-datepicker__month-container .react-datepicker__header  .react-datepicker__day-names .react-datepicker__day-name,
.slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__header  .react-datepicker__day-names .react-datepicker__day-name
{

      list-style: none;
      margin: 0;
      padding: 0;
      text-align: center;
      float: none;
      width: 14.28%;
      height: 42px;
      padding-top: 12px;
      padding-bottom: 12px;
      /* background-color: var(--bs-off-white); */
      cursor: default;
      line-height: 18px;
}

.collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__month,
.slidein__book-tickets .react-datepicker .react-datepicker__month-container .react-datepicker__month,
.slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month
{
      margin-bottom: 8px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      width: 100%;
      margin: 0;
      padding: 0;
}


.collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week,
.slidein__book-tickets .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week,
.slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week
{

      width: 100%;
}

.collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day,
.collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--today,
.slidein__book-tickets .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day,
.slidein__book-tickets .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--today,
.slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day,
.slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--today
{

      box-sizing: border-box;
      cursor: pointer;
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: center;
      float: none;
      width: 14.28%;
      background-color: transparent;
      /* color: #999;  */
      margin-top: 1px;
      margin-bottom: 1px;
      padding-top: 8px;
      padding-bottom: 8px;
      height: 36px;
      position: relative;
      font-weight: 400;
}

.collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day:focus,
.slidein__book-tickets .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day:focus,
.slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day:focus
{
      outline: none;
}

.collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--outside-month,
.react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--disabled 
.slidein__book-tickets .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--outside-month,
.slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--outside-month
{
      color: #999; 
}

.collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--outside-month:hover::before,
.collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--disabled:hover::before, 
.slidein__book-tickets .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--outside-month:hover::before,
.slidein__book-tickets .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--disabled:hover::before,
.slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--outside-month:hover::before,
.slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--disabled:hover::before
{
      border-color: #999 !important; 
}

.collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--keyboard-selected,
.slidein__book-tickets .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--keyboard-selected,
.slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--keyboard-selected
{
      color: #000;
      font-weight: inherit;
}
/* .collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--today, */
.collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected,
.slidein__book-tickets .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected,
.slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected
 {
      /* color: #000; */
      color:var(--bs-red); 
      font-weight: inherit;
}

/* .collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week 
.react-datepicker__day--today:hover::before{
      border-color:var(--bs-red) !important;  
} */

.collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day::before,
.slidein__book-tickets .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day::before,
.slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day::before
{
      content: '';
      display: block;
      background-color: transparent;
      border: 1px solid transparent;
      border-radius: 50px;
      width: 35px;
      height: 35px;
      margin-left: auto;
      margin-right: auto;
      position: absolute;
      top: 4px;
      left: 0;
      right: 0;
      transition: 0.3s;
      z-index: -1;
} 

.collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected,
.slidein__book-tickets .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected,
.slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected
{
      color: #fff;
}

.collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected::before,
.slidein__book-tickets .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected::before,
.slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected::before
{
      content: '';
      display: block;
      background-color: transparent;
      border: 1px solid transparent;
      border-radius: 50px;
      width: 35px;
      height: 35px;
      margin-left: auto;
      margin-right: auto;
      position: absolute;
      top: 4px;
      left: 0;
      right: 0;
      transition: 0.3s;
      z-index: -1;
}


.collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day:hover::before,
.slidein__book-tickets .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day:hover::before,
.slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day:hover::before
{
      border-color: var(--bs-dark);
} 


.collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.react-datepicker__day--selected:hover::before,
.slidein__book-tickets .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.react-datepicker__day--selected:hover::before,
.slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.react-datepicker__day--selected:hover::before
{
      border-color: var(--bs-red);
} 

.collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected::before,
.slidein__book-tickets .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected::before,
.slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected::before
{
      background-color: var(--bs-red);
      border-color: var(--bs-red);
}

@media (max-width: 768px) {

      .slidein__book-tickets .react-datepicker,
      .slidein__gift-voucher .react-datepicker
      {
            width: calc(100% - 30px);
      }
     
}

@media (min-width: 768px) {

      .collapse__listing-filters .listing-filters .form-datepicker .react-datepicker-popper,
      .slidein__gift-voucher .react-datepicker-popper
      {
            width: 282px;
      }

      .slidein__book-tickets .react-datepicker,
      .slidein__gift-voucher .react-datepicker
      {
            width: 282px;
      }
}

@media (min-width: 991px) {
      .slidein__gift-voucher .form-datepicker .react-datepicker-popper[data-placement^=bottom]  {
            transform: translate(0px,49px) !important;
      }
}

@media (min-width: 1200px) {

      .slidein__gift-voucher .form-datepicker .react-datepicker-popper[data-placement^=bottom]  {
            transform: translate(0px,51px) !important;
      }

      .collapse__listing-filters .listing-filters .form-datepicker .react-datepicker-popper[data-placement^=bottom]  {
            transform: translate(0px,54px) !important;
      }

      .collapse__listing-filters .listing-filters .react-datepicker__input-container input,
      .slidein__gift-voucher .react-datepicker__input-container input  {
            font-size: 20px;
            letter-spacing: 0.4px;
            line-height: 25px;
            min-height: 54px;
            padding: 5px 23px 5px 27px;
      }

      .collapse__listing-filters .listing-filters .react-datepicker__input-container input::after,
      .slidein__gift-voucher .react-datepicker__input-container input::after {
            top: 19px;
            right: 14px;
            width: 22px;
            height: 19px;
            background-size: 22px 19px;
      }


      .collapse__listing-filters .listing-filters .form-datepicker .react-datepicker-popper {
            transform: translate(0px, 54px) !important;
      }

      /* .collapse__listing-filters .listing-filters .form-datepicker .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--previous,
      .collapse__listing-filters .listing-filters .form-datepicker .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--next{
            width: 62px !important;
      } */

      /* .collapse__listing-filters .listing-filters .form-datepicker .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--previous::before{
            margin-right: 10px;
      } */

      /* .collapse__listing-filters .listing-filters .form-datepicker .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--next::before{
            margin-left: 10px;
      } */
}


/* ------------------------------------------------------------------------------------------------- */
/* ------------------------------- End React DatePicker-------------------------------------------- */
/* ------------------------------------------------- ------------------------------------------------*/



/* -------------------------------------------------------------------------------------------------------------------------------------------------- */
/* -------------------------------------------React Datepicker Choose Date Calender Styles----------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------------------------------------------------------- */


#bookTicketsStep1 .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--keyboard-selected{
      color: #ccc;
}

#bookTicketsStep1 .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--disabled::before,
#bookTicketsStep1 .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--disabled::before {
      border: none;
}     

#bookTicketsStep1 .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--disabled:hover::before,
#bookTicketsStep1 .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--disabled:focus::before {
      border: none;
}     



/* -------------------------------------------------------------------------------------------------------------------------------------------------- */
/* -------------------------------------------React Datepicker Custom Class Got From API------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------------------------------------------------------- */

#bookTicketsStep1 .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.ct_available {
      color: #5cb85c;
      border-color: #5cb85c !important;
      background-color: transparent;
      z-index: 1;
}

#bookTicketsStep1 .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.ct_available:hover {
      color: #ffffff !important;
}

#bookTicketsStep1 .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.ct_available::before {
      border: 1px solid #5cb85c;
      z-index: -1;
}

#bookTicketsStep1 .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.ct_available:hover:before {
      background-color: #5cb85c !important;
      border-color: #5cb85c !important;
}

#bookTicketsStep1 .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected.ct_available {
      color: #ffffff;
}

#bookTicketsStep1 .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected.ct_available::before {
      background-color: #5cb85c;
      color: #ffffff;
}




#bookTicketsStep1 .content__body .col__right .ct_available .btn-check+label.btn {
      color: #5cb85c;
      border-color: #5cb85c !important;
}

#bookTicketsStep1 .content__body .col__right .ct_available .btn-check:checked ~ label.btn {
      background-color: #5cb85c;
      color: #ffffff !important;
}

#bookTicketsStep1 .content__body .col__right .ct_available .btn-outline-primary:active {
      background-color: #5cb85c;
      color: #ffffff !important;
}


/* ------------------ ct_very_limited ------------------------*/
#bookTicketsStep1 .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.ct_very_limited {
      color: #d9534f;
      border-color: #d9534f !important;
      z-index: 1;
}

#bookTicketsStep1 .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.ct_very_limited:hover {
      color: #ffffff !important;
}

#bookTicketsStep1 .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.ct_very_limited::before {
      border: 1px solid #d9534f;
      z-index: -1;
}

#bookTicketsStep1 .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected.ct_very_limited {
      color: #ffffff;
}

#bookTicketsStep1 .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.ct_very_limited:hover:before {
      background-color: #d9534f !important;
      
}

#bookTicketsStep1 .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected.ct_very_limited::before {
      background-color: #d9534f;
      color: #ffffff;
}




#bookTicketsStep1 .content__body .col__right .ct_very_limited .btn-check+label.btn {
      color: #d9534f;
      border-color: #d9534f !important;
}

#bookTicketsStep1 .content__body .col__right .ct_very_limited .btn-check:checked ~ label.btn {
      background-color: #d9534f;
      color: #ffffff !important;
}

#bookTicketsStep1 .content__body .col__right .ct_very_limited .btn-outline-primary:active {
      background-color: #d9534f;
      color: #ffffff !important;
}


/* ------------------ ct_limited ------------------------*/
#bookTicketsStep1 .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.ct_limited {
      color: #f0ad4e;
      border-color: #f0ad4e !important;
      background: transparent;
      z-index: 1;
}

#bookTicketsStep1 .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.ct_limited:hover {
      color: #ffffff !important;
}

#bookTicketsStep1 .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.ct_limited::before {
      border: 1px solid #f0ad4e;
      z-index: -1;
}

#bookTicketsStep1 .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.ct_limited:hover:before {
      background-color: #f0ad4e !important;
      
}

#bookTicketsStep1 .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected.ct_limited {
      color: #ffffff;
}

#bookTicketsStep1 .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected.ct_limited::before {
      background-color: #f0ad4e;
      color: #ffffff;
}




#bookTicketsStep1 .content__body .col__right .ct_limited .btn-check+label.btn {
      color: #f0ad4e;
      border-color: #f0ad4e !important;
}

#bookTicketsStep1 .content__body .col__right .ct_limited .btn-check:checked ~ label.btn {
      background-color: #f0ad4e;
      color: #ffffff !important;
}

#bookTicketsStep1 .content__body .col__right .ct_limited .btn-outline-primary:active {
      background-color: #f0ad4e;
      color: #ffffff !important;
}




/* ------------------ ct_alert ------------------------*/
#bookTicketsStep1 .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.ct_alert {
      color: #d9534f !important;
      border-color: #d9534f !important;
      z-index: 1;
      cursor: not-allowed !important;
      pointer-events:none;
}

#bookTicketsStep1 .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.ct_alert:hover {
      color: #ffffff !important;
}

#bookTicketsStep1 .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.ct_alert::before {
      border: 1px solid #d9534f;
      z-index: -1;
}

#bookTicketsStep1 .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.ct_alert:hover:before {
      background-color: #d9534f !important;
      
}

#bookTicketsStep1 .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected.ct_alert::before {
      background-color: #d9534f;
      color: #ffffff;
}



#bookTicketsStep1 .content__body .col__right .ct_alert .btn-check+label.btn {
      color: #d9534f;
      border-color: #d9534f !important;
      position: relative;
}

#bookTicketsStep1 .content__body .col__right .ct_alert .btn-check+label.btn::after {
      height: 3px;
      width: 90%;
      position: relative;
      top: 10px;
      left: 6px;
      background: #d9534f;
      content: "";
      transform: rotate(11deg);
      display: table;
      position: absolute;
}

@media (min-width : 1800px){
      #bookTicketsStep1 .content__body .col__right .ct_alert .btn-check+label.btn::after {
            height: 3px;
            width: 90%;
            position: relative;
            top: 8px;
            left: 6px;
            background: #d9534f;
            content: "";
            transform: rotate(9deg);
            display: table;
            position: absolute;
      }
}

#bookTicketsStep1 .content__body .col__right .ct_alert .btn-check:checked ~ label.btn {
      background-color: #d9534f;
      color: #ffffff !important;
}

#bookTicketsStep1 .content__body .col__right .ct_alert .btn-outline-primary:active {
      background-color: #d9534f;
      color: #ffffff !important;
}

#bookTicketsStep1 .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.ct_alert:after {

      height: 3px;
      width: 80%;
      position: relative;
      top: 20px;
      left: 5px;
      background: #d9534f;
      content: "";
      transform: rotate(45deg);
      display: table;
      position: absolute;
}


@media (min-width : 412px) {
      #bookTicketsStep1 .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.ct_alert:after {
           top: 21px;
           width: 75%;
      }
      
}

@media (min-width : 768px) {
      #bookTicketsStep1 .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.ct_alert:after {
           top: 19px;
           width: 85%;
      }
      
}



