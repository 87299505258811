/*************************************************************************/
/*************Manange Overflow of GiftVoucher Index Page*******************/
/*************************************************************************/

.GiftVoucher-page-overflow {
      /* height: 80vh; */
      overflow: hidden;
}


.section__gift-vouchers .card__credit-balance .card-body {
      background-position: -12px 6px;
}

@media (min-width:768px) {
      .section__gift-vouchers .card__credit-balance .card-body {
            background-position: -12px 16px;
      }
}

@media (min-width:991px) {
      .section__gift-vouchers .card__credit-balance .card-body {
            background-position: -12px 40px;
      }
}

@media (min-width:1200px) {
      .section__gift-vouchers .card__credit-balance .card-body {
            background-position: -12px 16px;
      }
}

/*************************************************************************/
/*****************************Flyer Tickets*******************************/
/*************************************************************************/
/* .section__gift-vouchers */


.section__gift-vouchers .bg-yellow {
      background-color: var(--bs-yellow);
}

.section__gift-vouchers .col__no-voucher-history {
      color: var(--bs-red);
}


/*************************************************************************/
/************************* Mobile Issue of TopUp **************************/
/*************************************************************************/


.slidein__gift-voucher.slidein .content__slidein {
      flex: 1;
      height: 100%; 
      min-height: auto;
      padding-bottom: 100px;
}

 /* .slidein__gift-voucher .content__slidein.content-scrollable {
      overflow-y: auto; // Enable vertical scrolling 
      height: 100%; // Make sure it takes full height 
} */

.slidein__gift-voucher form {
      width: 100%;
      height: 100%;
}

.slidein__gift-voucher .content__checkout {
      min-height: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
}

.slidein__gift-voucher #walletTopupStep1,
.slidein__gift-voucher #walletTopupStep2,
.slidein__gift-voucher #walletTopupSuccess {
      flex: 1;
}

.slidein__gift-voucher #walletTopupStep1  > .content__body,
.slidein__gift-voucher #walletTopupStep2 > .content__body,
.slidein__gift-voucher #walletTopupSuccess {
      flex: 1;
      overflow-y: auto;
      /* margin-bottom: 127px; */
}

.slidein__gift-voucher .general-body-padding {
      padding-bottom: 100px;
}

.slidein__gift-voucher .ios-body-padding {
      padding-bottom: 200px;
}

.slidein__gift-voucher #walletTopupStep1 .giftVoucher-lower-footer-body, 
.slidein__gift-voucher #walletTopupStep2 .giftVoucher-lower-footer-body {
      overflow: auto;
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 98;
      background-color: #fff;
}

.slidein__gift-voucher ::-webkit-scrollbar-track,
.giftVoucher-index ::-webkit-scrollbar-track {
      -webkit-box-shadow: none !important;
      background-color: #fff !important;
      border-radius: 10px !important;
}
    
.slidein__gift-voucher ::-webkit-scrollbar,
.giftVoucher-index ::-webkit-scrollbar {
      width: 5px !important;
      height: 5px !important;
      background-color: #fff !important;
}
    
.slidein__gift-voucher  ::-webkit-scrollbar-thumb,
.giftVoucher-index  ::-webkit-scrollbar-thumb {
      background-color: grey !important;
      border-radius: 2px !important;
      transition: 0.3s !important;
}

@media (min-width: 992px) {
      
      .slidein__gift-voucher #walletTopupStep1 .giftVoucher-lower-footer-body, 
      .slidein__gift-voucher #walletTopupStep2 .giftVoucher-lower-footer-body {
           width: 805px;
      }

      .slidein__gift-voucher .general-body-padding {
            padding-bottom: 180px;
      }

}
/*************************************************************************/
/******************************Footer Hide********************************/
/*************************************************************************/
.giftVoucher-index.footer-hide .main-footer {
      display: none;
}

/*************************************************************************/
/******************************Gift Top Up********************************/
/*************************************************************************/

/* Step 1 */
.slidein__gift-voucher .content__checkout .input-group__as-form-control {
      padding: 4px 15px 4px;
}


.slidein__gift-voucher .form-group.d-flex {
      margin-bottom: 0px;
}

.slidein__gift-voucher .form-group.d-flex .form-group:first-child{
      margin-right: 30px;
}

.slidein__gift-voucher .form-datepicker  {
      border: 1px solid #000;
      border-radius: 10px;
}

.slidein__gift-voucher .react-datepicker__input-container input  {
      border-radius: 10px;
      line-height: 24px;
      padding-top: 7px;
      padding-bottom: 8px;
      font-size: 20px;
      line-height: 24px;
      color: #000;
      padding: 11px 15px;
      padding-top: 7px;
      padding-bottom: 8px;
      border: none;
      background: transparent;
}

.slidein__gift-voucher .disabled .react-datepicker__input-container   input {
      background-color: #f4f4f4;
      border-color: #bbb;
}

.slidein__gift-voucher .btn:disabled {
      /* color: #000 !important; */
      color: #fff;
}

.slidein__gift-voucher .btn:disabled .button__text {
      color: #000 !important;
}

.slidein__gift-voucher .form-datepicker::after {
      z-index: -10;
}

.slidein__gift-voucher .form-datepicker.right-space::after {
      z-index: 10;
}


.slidein__gift-voucher .form-control::placeholder {
      color: #aaa;
}

.slidein__gift-voucher .react-datepicker__input-container input::placeholder {
      color: #aaa;
      font-size: 20px;
      letter-spacing: 0.4px;
      line-height: 24px;
      font-family: var(--bs-body-font-family);
}

  /*Step 3 */
.slidein__gift-voucher .reusecard__wrapper .input-group__as-form-control {
      padding: 9px 15px 7px;
}



/*************************************************************************/
/**************************Step 4 Flyer Tickets****************************/
/*************************************************************************/
.slidein__gift-voucher .content__wallet-topup-success .big-heading .ticket-red {
      top: 4%;
      right: 19.95%;
      width: 35px;
      z-index: 1;
      transform: rotate(-65deg) !important;
}



.slidein__gift-voucher .content__wallet-topup-success .big-heading .ticket-blue {
      top: 34%;
      left: 17.25%;
      width: 47px;
      z-index: 1;
}

.slidein__gift-voucher .content__wallet-topup-success .big-heading .ticket-green {
      top: 47.15%;
      right: 41.25%;
      width: 56px;
      z-index: 1;
}

.slidein__gift-voucher .content__wallet-topup-success .big-heading .ticket-orange {
      bottom: -5%;
      left: 13.65%;
      width: 55px;
}

.slidein__gift-voucher .content__wallet-topup-success .big-heading .ticket-yellow {
      bottom: -8.95%;
      left: 28.05%;
      width: 64px;
      z-index: 1;
}

.slidein__gift-voucher .content__wallet-topup-success .big-heading .ticket-aquamarine {
      bottom: -10.5%;
      right: 17.45%;
      width: 45px;
}

/*************************************************************************/
/**************************End Step 4 Flyer Tickets****************************/
/*************************************************************************/


/*************************************************************************/
/**************************index Page Flyer Tickets**************************/
/*************************************************************************/
.giftVoucher-index .content__my-wallet .big-heading .ticket-aquamarine {
      z-index: 1;
      bottom: -7.8%;
      left: 15.4%;
      width: 27px;
}

.giftVoucher-index .content__my-wallet .big-heading .ticket-blue {
      top: 5.5%;
      left: 43.5%;
      width: 23px;
      transform: rotate(-45deg);
}

.giftVoucher-index .content__my-wallet .big-heading .ticket-yellow {
      bottom: -4.9%;
      right: 15%;
      width: 31px;
}


@media (max-width: 768px) {
      .slidein__gift-voucher .react-datepicker
      {
            width: calc(100% - 0px);
      }
    
}

@media(min-width: 768px) {

      .slidein__gift-voucher .content__topup-options .form-group.flex-1 {
            flex: 1;
      }

      .slidein__gift-voucher .reusecard__wrapper .input-group__as-form-control {
            padding: 9px 15px 7px;
      }


      .slidein__gift-voucher .content__wallet-topup-success .big-heading .ticket-blue {
            top: 36.25%;
            left: 22.15%;
            width: 83px;
      }

      .slidein__gift-voucher .content__wallet-topup-success .big-heading .ticket-red {
            top: 3%;
            right: 24.25%;
            width: 62px;
            transform: rotate(-45deg) !important;
      }

      .slidein__gift-voucher .content__wallet-topup-success .big-heading .ticket-green {
            top: 46.5%;
            right: 41.85%;
            width: 98px;
      }

      .slidein__gift-voucher .content__wallet-topup-success .big-heading .ticket-orange {
            bottom: -8.25%;
            left: 20.65%;
            width: 97px;
      }


      .slidein__gift-voucher .content__wallet-topup-success .big-heading .ticket-yellow {
            bottom: -13.75%;
            left: 30.75%;
            width: 112px;
      }

      .slidein__gift-voucher .content__wallet-topup-success .big-heading .ticket-aquamarine {
            bottom: -9.65%;
            right: 22.85%;
            width: 80px;
      }

      .giftVoucher-index .content__my-wallet .big-heading .ticket-aquamarine {
            z-index: 1;
            bottom: -11.8%;
            left: 21.4%;
            width: 35px;
      }

      .giftVoucher-index .content__my-wallet .big-heading .ticket-blue {
            top: 1.5%;
            left: 45.0%;
            width: 35px;
            transform: rotate(-45deg);
      }

      .giftVoucher-index .content__my-wallet .big-heading .ticket-yellow {
            bottom: -8.9%;
            right: 20%;
            width: 42px;
      }
     
}

@media (min-width: 992px) {
      .slidein__gift-voucher .react-datepicker__input-container input {
            padding: 12px 16px 12px;
      }


      .slidein__gift-voucher .react-datepicker__input-container input::placeholder {
            font-size: 20px;
            line-height: 26px;
      }

      .giftVoucher-index .content__my-wallet .big-heading .ticket-aquamarine {
            bottom: -10.75%;
            left: 16%;
            width: 82px;
      }

      .giftVoucher-index .content__my-wallet .big-heading .ticket-blue {
            top: -9.5%;
            left: 51%;
            width: 59px;
            transform: rotate(25deg);
      }

      .giftVoucher-index .content__my-wallet .big-heading .ticket-yellow {
            bottom: -8.9%;
            right: 14.6%;
            width: 81px;
      }

}

@media (min-width: 1200px) {
      .slidein__gift-voucher .react-datepicker__input-container input {
            min-height: 50px;
      }
      .slidein__gift-voucher .react-datepicker__input-container input {
            padding: 11px 16px 11px;
      }


      .giftVoucher-index .content__my-wallet .big-heading .ticket-aquamarine {
            bottom: -19.75%;
            left: 19%;
            width: 82px;
      }
      .giftVoucher-index .content__my-wallet .big-heading .ticket-blue {
            top: -9.5%;
            left: 52%;
            width: 59px;
            transform: rotate(25deg);
      }

      .giftVoucher-index .content__my-wallet .big-heading .ticket-yellow {
            bottom: -8.9%;
            right: 16.6%;
            width: 81px;
      }
       
}

@media (min-width:1400px) {
      .giftVoucher-index .content__my-wallet .big-heading .ticket-aquamarine {
            bottom: -18.75%;
            left: 21%;
            width: 82px;
      }

      .giftVoucher-index .content__my-wallet .big-heading .ticket-blue {
            top: -9.5%;
            left: 52%;
            width: 59px;
            transform: rotate(25deg);
      }

      .giftVoucher-index .content__my-wallet .big-heading .ticket-yellow {
            bottom: -8.9%;
            right: 19.6%;
            width: 81px;
      }
}

@media (min-width:1500px) {
      .giftVoucher-index .content__my-wallet .big-heading .ticket-aquamarine {
            bottom: -19.75%;
            left: 23%;
            width: 82px;
      }

      .giftVoucher-index .content__my-wallet .big-heading .ticket-blue {
            top: -9.5%;
            left: 52%;
            width: 59px;
            transform: rotate(25deg);
      }

      .giftVoucher-index .content__my-wallet .big-heading .ticket-yellow {
            bottom: -8.9%;
            left: 72.6%;
            width: 81px;
      }
}


@media (min-width:1500px) {
      .giftVoucher-index .content__my-wallet .big-heading .ticket-aquamarine {
            bottom: -19.75%;
            left: 25%;
            width: 82px;
      }

      .giftVoucher-index .content__my-wallet .big-heading .ticket-blue {
            top: -9.5%;
            left: 52%;
            width: 59px;
            transform: rotate(25deg);
      }

      .giftVoucher-index .content__my-wallet .big-heading .ticket-yellow {
            bottom: -8.9%;
            left: 71.6%;
            width: 81px;
      }
}




@media (min-width: 1800px) {
     

      .slidein__gift-voucher .react-datepicker__input-container input::placeholder {
            font-size: 25px;
            line-height: 30px;
            letter-spacing: 0;
      }


      .slidein__gift-voucher .content__wallet-topup-success .big-heading .ticket-blue {
            top: 37.25%;
            left: 21.15%;
            width: 83px;
      }

      .slidein__gift-voucher .content__wallet-topup-success .big-heading .ticket-red {
            top: 5%;
            right: 21.25%;
            width: 62px;
      }

      .slidein__gift-voucher .content__wallet-topup-success .big-heading .ticket-green {
            top: 48.5%;
            right: 41.85%;
            width: 98px;
      }

      .slidein__gift-voucher .content__wallet-topup-success .big-heading .ticket-orange {
            bottom: -7.25%;
            left: 20.65%;
            width: 97px;
      }


      .slidein__gift-voucher .content__wallet-topup-success .big-heading .ticket-yellow {
            bottom: -9.75%;
            left: 30.75%;
            width: 112px;
      }

      .slidein__gift-voucher .content__wallet-topup-success .big-heading .ticket-aquamarine {
            bottom: -8.65%;
            right: 22.85%;
            width: 80px;
      }

      .giftVoucher-index .content__my-wallet .big-heading .ticket-aquamarine {
            bottom: -19.75%;
            left: 29%;
      }

      .giftVoucher-index .content__my-wallet .big-heading .ticket-blue {
            top: -9.5%;
            left: 51.6%;
      }

      .giftVoucher-index .content__my-wallet .big-heading .ticket-yellow {
            bottom: -8.9%;
            left: 68.6%;
      }

}

@media (max-width: 391px) {
      #walletTopupSuccess .content__header .big-heading h1 {
            font-size: 90px;
            line-height: 90px;
      }
}



/*************************************************************************/
/**************************** datepicker css *****************************/
/*************************************************************************/
.slidein__gift-voucher #walletTopupStep1 .react-datepicker {
      display: flex;
}
.slidein__gift-voucher #walletTopupStep1 .react-datepicker-popper {
      padding: 0px;
}
.slidein__gift-voucher .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--next {
      right: 80px;
}
.slidein__gift-voucher .react-datepicker__time-container {
      width: 80px;
      padding-bottom: 10px;
      border-left: 1px solid var(--bs-dark);
}
.slidein__gift-voucher .react-datepicker__time-container .react-datepicker__header--time {
      font-size: 16px;
      line-height: 19px;
      height: 39px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      cursor: pointer;
      margin: 0;
      padding: 0;
      font-weight: inherit;
      /* position: absolute; */
      border-bottom: 1px solid var(--bs-dark);
}

.slidein__gift-voucher .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
      height: 38px;
      line-height: 26px;
}

.slidein__gift-voucher .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
      background-color: var(--bs-red);
      color: #fff;
}

.slidein__gift-voucher .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
      background-color: var(--bs-red);
}

@media (min-width: 767px) {
      .slidein__gift-voucher #walletTopupStep1 .react-datepicker-popper {
            width: 362px;
      }
      .slidein__gift-voucher .react-datepicker {
            width: 360px;
      }
      .slidein__gift-voucher .react-datepicker__time-container .react-datepicker__header--time {
            border: none;
            border-bottom: 1px solid var(--bs-dark);
      }
}