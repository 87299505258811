
.section__web-hero {
      padding: 0;
      width: 100%;
      top: 51px;
      background: var(--bs-body-bg);
      z-index: 2;
}

.section__web-hero .big-heading h1 {
      /* font-size: 18vw; */
      font-size: 47px;
      line-height: 56px;
}

.section__web-hero .big-heading .ticket-blue {
      left: 20%;
      top: 69%;
      transform: rotate(110deg);
      z-index: 1;
}

.section__web-hero .big-heading .ticket-aquamarine {
      top: -6%;
      left: 37%;
      transform: rotate(-115deg);
      width: 32px;
      height: auto;
      z-index: 1;
}


.section__web-hero .big-heading .ticket-yellow {
      width: 40px;
      top: -8%;
      left: 66%;
      z-index: 1;
      transform: rotate(10deg);
}

.section__web-hero .big-heading .ticket-red {
      width: 32px;
      height: auto;
      left: 54%;
      top: 74%;
      z-index: 1;
      transform: rotate(10deg);
}


/*************************************************************************/
/************************* Dynamics Sections ******************************/
/*************************************************************************/

.web-home-page-container .dynamics-sections {
      overflow: hidden;
      margin-top: 0px;
      padding: 0;
}

.dynamics-sections .container-fluid{
      padding: 0;
}

.dynamics-sections .section-heading {
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;
      display: flex;
      justify-content: flex-start;
      align-items: center;
}

.dynamics-sections .highligtedShows-section .section-heading{
      border-bottom: none;
}

.dynamics-sections .carousel-section:first-child .section-heading{
      border-top: none;
}

.dynamics-sections .section-heading h2 {
      margin: 0;
      padding: 8px 16px;
      flex: 1;
      font-size: 23px;
      line-height: 28px;
}

.dynamics-sections .highligtedShows-section .section-heading h2 {
      color: #fff;
}

.dynamics-sections .carousel-section {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
}

.dynamics-sections .highligtedShows-section {
      background-color: var(--bs-red);
}

.dynamics-sections .carousel-section .owl-item  {
      width: 100%;
      display: flex;
      flex: 1;
}

.dynamics-sections .carousel-section .owl-item .item {
      padding: 16px;
      padding-right: 0px;
      width: 100%;
}

.dynamics-sections .carousel-section .item .card {
      padding: 0;
      margin: 0;
      overflow: hidden;
}



.dynamics-sections .highligtedShows-section .item .card {
      background-color: transparent;
}

.dynamics-sections .carousel-section .item .card__event-image {
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0;
}

.dynamics-sections .carousel-section .home-carousel-external .card__event-image {
      width: 100%;
      height: auto;
}

.dynamics-sections .carousel-section .item .card__event-image img {
      width: 100%;
      height: 100%;
}

.dynamics-sections .carousel-section .item .card__event-image img.event-image-home-carousel {
      position: absolute;
      top: 0;
      left: 0;
}

.dynamics-sections .carousel-section .item .list__event-tags {
      position: absolute;
      padding: 0;
      margin: 0;
      bottom: 8px;
      right: 8px;
      z-index: 3;
}

.dynamics-sections .carousel-section .item .btn-heart {
      position: absolute;
      top: 8px;
      right: 8px;
      z-index: 3;
      width: 20px;
      height: 18px;
      background: url(../../assets/images/icon-heart-outline-white.svg);
      background-size: 20px 18px;
}

.dynamics-sections .carousel-section .item .btn-heart.active {
      background: url(../../assets/images/icon-heart-red.svg);
      background-position: center;
      background-size: contain;
}


.dynamics-sections .carousel-section .item .list__event-tags > li:not(:first-child) {
      margin-left: 5px;
}


.dynamics-sections .carousel-section .item .list__event-tags .badge {
      background-color: var(--bs-red);
      border-radius: 50px;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      line-height: 14px;
      height: 23px;
      padding: 5px 10px 4px 10px;
}




.dynamics-sections .carousel-section .item .card-body {
      padding: 0;
      margin: 8px 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

}

.carousel-section .item .card-body .card-title {
      margin: 8px 0;
      white-space: nowrap;
      overflow: hidden;
      max-width: 95%;
      font-size: 18px;
      line-height: 21px;
      text-overflow: ellipsis;
      max-width: 100%;
}

.carousel-section .item .card-body .card-title a {
      color: var(--bs-red);
      display: block;
      width: 100%;
      overflow: hidden;
}

.highligtedShows-section .item .card-body .card-title a{
      color: #fff;
}


.carousel-section .item .list-group {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
}

.carousel-section .item .list-group .list-group-item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 4px;
}


.highligtedShows-section .item .list-group .list-group-item {
      color: #fff;
}

.carousel-section .item .list-group .icon-wrapper {
      width: 16px;
      height: 16px;
}

.carousel-section .item .list-group .icon-wrapper img {
      width: 100%;
      height: 100%;
}

.carousel-section .item .list-group  p {
      font-size: 16px;
      line-height: 16px;
}


.flyer-section,
.flyer-wallet-section {
      border-top: 1px solid #000;
      padding: 20px 20px;
}


.flyer-section .content, 
.flyer-wallet-section .content {
      background-color: red;
      padding: 24px 32px;
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
}

.flyer-wallet-section .content {
      align-items: flex-start;
      position: relative;
}

.flyer-section .title-wrapper {
     padding: 0;
     margin: 0;
     margin-bottom: 16px;
}

.flyer-section .title-wrapper h4 {
      margin: 0;
      color: #fff;
      font-size: 45px;
      line-height: 45px;
}

.flyer-wallet-refer .title-wrapper h4 {
      font-size: 45px;
      line-height: 45px;
}

.flyer-section .description-wrapper {
      text-align: center;
      margin-bottom: 16px;
}

.flyer-wallet-section .description-wrapper {
      text-align: left;
}


.flyer-section .description-wrapper p {
      font-size: 18px;
      line-height: 21px;
      color: #fff;
}

.flyer-wallet-refer .description-wrapper p {
      font-size: 23px;
      line-height: 26px;
      font-weight: 350;
}

.flyer-section .buttons-wrapper .btn {
      background-color: transparent;
      border-color: #fff;
      padding: 8px 24px;
      font-size: 18px;
      line-height: 21px;
}

.flyer-wallet-refer .buttons-wrapper .btn {
      padding: 8px 40px;
}

.flyer-wallet-section .image-wrapper {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 32px;
}

.flyer-wallet-section .image-wrapper img {
      width: 100%;
      height: 100%;
}

.flyer-section.flyer-ecologi .image-wrapper  {
      margin-bottom: 16px;
      width: 45px;
      height: auto;
}


.flyer-section.flyer-ecologi .image-wrapper .img {
      width: 100%;
      height: 100%;
}


@media (min-width: 391px) {
      .section__web-hero .big-heading .ticket-blue {
            left: 23%;
            top: 69%;
            transform: rotate(110deg);
            z-index: 1;
      }

      .section__web-hero .big-heading .ticket-aquamarine {
            top: 42%;
            left: 38%;
            transform: rotate(-115deg);
            width: 32px;
            height: auto;
            z-index: 1;
        }
      
      .section__web-hero .big-heading .ticket-red {
            left: 54%;
            top: 72%;
            transform: rotate(10deg);
      }
}


@media (min-width:768px) {

      .section__web-hero .content__header--template {
            padding-top: 17px;
            padding-bottom: 30px;
      }

      .section__web-hero .big-heading h1 {
            /* font-size: 20vw; */
            font-size: 12vw;
            line-height: 1em;
      }

      .section__web-hero .big-heading .ticket-blue {
            left: 23%;
            top: 71%;
            transform: rotate(103deg);
            z-index: 1;
            width: 42px;
      }
      
      .section__web-hero .big-heading .ticket-aquamarine {
            top: 48%;
            left: 39%;
            width: 58px;
      }
      
      
      .section__web-hero .big-heading .ticket-yellow {
            width: 72px;
            top: -6%;
            left: 66%;
      }
      
      .section__web-hero .big-heading .ticket-red {
            width: 58px;
            left: 53.5%;
            top: 78%;
      }


      .web-home-page-container .dynamics-sections {
            /* margin-top: 195px; */
      }

      .dynamics-sections .section-heading h2 {
            font-size: 28px;
            line-height: 32px;
      }

      .dynamics-sections .carousel-section .owl-item .item {
            padding: 16px;
            padding-right: 0px;
      }

      .dynamics-sections .carousel-section .item .card__event-image {
            width: 335px;
      }

      .dynamics-sections .carousel-section .item .list__event-tags .badge {
            font-size: 15px;
            line-height: 18px;
            padding: 3px 10px 10px 10px;
      }
      .carousel-section .item .card-body .card-title {
            font-size: 15px;
            line-height: 18px;
      }

      .carousel-section .item .list-group p {
            font-size: 15px;
            line-height: 18px;
      }

      .dynamics-sections .carousel-section .home-carousel-external .card__event-image {
            width: 180px;
      }


      .flyer-section, .flyer-wallet-section {
            padding: 30px;
      }

      .flyer-wallet-section .image-wrapper {
            right: 16px;
            top: 16px;
            width: 40px;
      }

      .flyer-section .content, .flyer-wallet-section .content {
            padding: 35px;
      }

      .flyer-section .title-wrapper {
            width: 100%;
            margin-bottom: 35px;
      }

      .flyer-section .title-wrapper h4 {
            font-size: 65px;
            line-height: 65px;
            text-align: center;
      }

      .flyer-wallet-section .description-wrapper {
            width: 100%;
      }

      .flyer-section .description-wrapper p {
            font-size: 20px;
            line-height: 24px;
            text-align: center;
      }

      .flyer-section .buttons-wrapper {
            margin-top: 25px;
      }

      .flyer-section .buttons-wrapper .btn {
            font-size: 20px;
            line-height: 24px;
            padding: 9px 29px 10px;
      }
      
      .flyer-section.flyer-ecologi .image-wrapper {
            width: 65px;
            height: auto;
            margin-bottom: 35px;
      }
}

@media (min-width: 820px) {
      .dynamics-sections .carousel-section .item .card__event-image {
            width: 335px;
            height: 202px;
      }
}

@media (min-width:991px) {
      .section__web-hero .big-heading .ticket-aquamarine {
            top: 44%;
            left: 39%;
            width: 58px;
      }

      .section__web-hero .big-heading .ticket-red {
            width: 58px;
            left: 54.5%;
            top: 78%;
      }

      .section__web-hero .big-heading .ticket-yellow {
            width: 72px;
            top: -1%;
            left: 66%;
      }

      .dynamics-sections .carousel-section .item .card__event-image {
            width: 100%;
            height: auto;
      }



      .flyer-section, .flyer-wallet-section {
            /* padding: 65px; */
      }

      .flyer-wallet-section .image-wrapper {
            right: 16px;
            top: 16px;
      }

      .flyer-section .content, .flyer-wallet-section .content {
            padding: 35px;
      }
      

      .flyer-section .title-wrapper {
            width: 100%;
            margin-bottom: 35px;
      }

      .flyer-section .title-wrapper h4 {
            font-size: 65px;
            line-height: 65px;
            text-align: center;
      }

      .flyer-section .description-wrapper p {
            font-size: 20px;
            line-height: 24px;
            text-align: center;
      }
}

@media (min-width:1201px) {



      .section__web-hero .big-heading .ticket-blue {
            left: 24%;
            top: 73%;
            transform: rotate(115deg);
            z-index: 1;
            width: 68px;
      }
      
      .section__web-hero .big-heading .ticket-aquamarine {
            top: 46%;
            left: 40%;
            width: 84px;
      }
      
      .section__web-hero .big-heading .ticket-yellow {
            width: 108px;
            top: -1%;
            left: 66%;
        }
      
      .section__web-hero .big-heading .ticket-red {
            width: 84px;
            left: 54.5%;
            top: 78%;
            transform: rotate(7deg);
      }


      .dynamics-sections .section-heading h2 {
            font-size: 40px;
            line-height: 48px;
      }

     

      .dynamics-sections .carousel-section .owl-item .item {
            padding: 16px;
            padding-right: 0px;
      }

      .dynamics-sections .carousel-section .item .btn-heart {
            top: 12px;
            right: 12px;
      }

      .dynamics-sections .carousel-section .item .card__event-image {
            width: 100%;
            height: auto;
      }

      .dynamics-sections .carousel-section .item .list__event-tags .badge {
            font-size: 18px;
            line-height: 18px;
      }

      .carousel-section .item .card-body .card-title {
            font-size: 25px;
            line-height: 30px;
            text-wrap: wrap;
      }

      .carousel-section .item .list-group p{
            font-size: 20px;
            line-height: 30px;
      }

      .carousel-section .item .list-group .icon-wrapper {
            width: 20px;
            line-height: 20px;
      }

      .flyer-section, .flyer-wallet-section {
            padding: 60px;
      }

      .flyer-wallet-section .image-wrapper {
            right: 24px;
            top: 24px;
      }

      .flyer-section .content, .flyer-wallet-section .content {
            padding: 50px;
      }
      

      .flyer-section .title-wrapper {
            width: 100%;
            margin-bottom: 35px;
      }

      .flyer-section .title-wrapper h4 {
            font-size: 65px;
            line-height: 65px;
            text-align: center;
      }

      .flyer-section .description-wrapper p {
            font-size: 30px;
            line-height: 40px;
            text-align: center;
      }

      .flyer-section .buttons-wrapper {
            margin-top: 25px;
      }
      
}


@media (min-width:1400px) {



      .section__web-hero .big-heading .ticket-blue {
            left: 24%;
            top: 73%;
            transform: rotate(110deg);
            z-index: 1;
            width: 72px;
      }
      
      .section__web-hero .big-heading .ticket-aquamarine {
            top: 48%;
            left: 40%;
            width: 92px;
      }
      
      .section__web-hero .big-heading .ticket-yellow {
            width: 124px;
            top: -3%;
            left: 66%;
      }
      
      .section__web-hero .big-heading .ticket-red {
            width: 92px;
            left: 54%;
            top: 80%;
            transform: rotate(4deg);
      }


      .dynamics-sections .carousel-section .owl-item .item {
            padding: 24px;
            padding-right: 0px;
      }

      .dynamics-sections .carousel-section .item .card__event-image {
            width: 100%;
            height: auto;
      }
}


@media (min-width: 1600px) {
      .section__web-hero .big-heading .ticket-blue {
            left: 24.0%;
            top: 70%;
            transform: rotate(120deg);
            z-index: 1;
            width: 92px;
      }

      .section__web-hero .big-heading .ticket-aquamarine {
            top: 50%;
            left: 39%;
            width: 112px;
      }

      .section__web-hero .big-heading .ticket-red {
            width: 112px;
            left: 54%;
            top: 79%;
      }

      .section__web-hero .big-heading .ticket-yellow {
            width: 135px;
            top: -1%;
            left: 66%;
      }
}


/* Add Booknow button and make fix height for carousel item */

.dynamics-sections .carousel-section  .home-carousel .owl-item {
      height: 410px;
}
.dynamics-sections .carousel-section .home-carousel .owl-item .item {
      height: 100%;
}

.dynamics-sections .carousel-section .home-carousel .item .card {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
}

.dynamics-sections .carousel-section  .external-carousel .owl-stage-outer  {
      padding: 0 !important;
      margin: 0 !important;
      line-height: 0 !important;
}
.dynamics-sections .carousel-section  .external-carousel .owl-stage  {
      padding: 0 !important;
      margin: 0 !important;
      line-height: 0 !important;
      font-size: 0px !important;
}

.dynamics-sections .carousel-section .home-carousel-external .card__event-image {
      height: calc(100% - 32px);
}


@media (min-width: 768px) {
      .dynamics-sections .carousel-section .home-carousel .owl-item {
            height: 412px;
      }
}

@media (min-width: 820px) and (max-width: 990px){
      .dynamics-sections .carousel-section .home-carousel-external .card__event-image {
            height: calc(100% - 32px);
            width: 100%;
      }
}

@media (min-width: 991px) {
      .dynamics-sections .carousel-section .home-carousel .owl-item {
            height: 482px;
      }
}

@media (min-width: 1200px) {
      .dynamics-sections .carousel-section .home-carousel .owl-item {
            height: 552px;
      }
}

@media (min-width: 1400px) {
      .dynamics-sections .carousel-section .home-carousel .owl-item {
            height: 580px;
      }
}

@media (min-width: 1800px) {
      .dynamics-sections .carousel-section .home-carousel .owl-item {
            height: 580px;
      }
}

.dynamics-sections .highligtedShows-section .card__event .card-footer {
      background-color:transparent;
}

.dynamics-sections .highligtedShows-section .btn-outline-primary.hover-primary {
      border-color: #fff;
      color: #fff;
}