
#WebViewPayment {
      display: flex;
      flex: 1;
}

#WebViewPayment .slidein-wrapper {
      display: flex;
      flex: 1;
      top: 0;
      height: 100%;
}


#WebViewPayment .slidein .content__slidein {
      height: 100%;
}

#WebViewPayment .slidein form,
#WebViewPayment .slidein .content__checkout {
      height: 100%;
}

#WebViewPayment #walletTopupStep1 {
      display: flex;
      flex: 1;
}
 
#WebViewPayment #walletTopupStep2{
      display: flex;
      flex: 1;
}

#WebViewPayment #walletTopupSuccess {
      display: flex;
      flex: 1;
}

#WebViewPayment #walletTopupSuccess.step4 .content__header .big-heading h1, 
#WebViewPayment #walletTopupSuccess.step3 .content__header .big-heading h1  {
      font-size: 92px;
      line-height: 94px;
}
/* ----------------------------------------------------------------------------- */
/* ------------------------Web View Step4 Flyer Tickets------------------------- */
/* ----------------------------------------------------------------------------- */

#WebViewPayment #walletTopupSuccess.step4 .big-heading .ticket-blue {
      top: 21%;
      left: 3.25%;
      width: 47px;
      transform: rotate(15deg);
      z-index: 1;
}

#WebViewPayment #walletTopupSuccess.step4 .big-heading .ticket-red {
      top: 2%;
      right: 14.95%;
      width: 35px;
      z-index: 1;
      transform: rotate(-65deg) !important;
}

#WebViewPayment #walletTopupSuccess.step4 .big-heading .ticket-green {
      top: 32.15%;
      right: 30.25%;
      width: 56px;
      z-index: 1;
}

#WebViewPayment #walletTopupSuccess.step4 .big-heading .ticket-orange {
      bottom: -9%;
      left: 10.65%;
      width: 55px;
}

#WebViewPayment #walletTopupSuccess.step4 .big-heading .ticket-aquamarine {
      bottom: -10.5%;
      right: 12.45%;
      width: 45px;
}

@media (max-width: 391px) {
      #WebViewPayment #walletTopupSuccess .content__header .big-heading h1 {
            font-size: 90px;
            line-height: 90px;
      }

      #WebViewPayment #walletTopupSuccess.step4 .content__header .big-heading h1,
      #WebViewPayment #walletTopupSuccess.step3 .content__header .big-heading h1 {
            font-size: 82px;
            line-height: 92px;
      }     

}


/* ----------------------------------------------------------------------------- */
/* ------------------Web View Gift Voucher Terms Flyer Tickets------------------- */
/* ----------------------------------------------------------------------------- */

.modal__gift-voucher-terms .content-wallet-terms-modal .ticket-blue {
      left: 20.6%;
      top: 5.5%;
      width: 18px;
      z-index: 0;
}

.modal__gift-voucher-terms .content-wallet-terms-modal .ticket-green1 {
      left: 74%;
      top: 5.4%;
      width: 22px;
      z-index: 1;
}

.modal__gift-voucher-terms .content-wallet-terms-modal .ticket-AquamarineSm {
      left: 45.6%;
      top: 3.4%;
      width: 24px;
      z-index: 0;
}

.modal__gift-voucher-terms  .content-wallet-terms-modal .ticket-orange1 {
      left: 8.6%;
      top: 0.4%;
      width: 24px;
      z-index: 1;
}

.modal__gift-voucher-terms .content-wallet-terms-modal .ticket-red {
      left: 74.6%;
      top: 1.0%;
      width: 23px;
      z-index: 1;
}

@media (min-width: 391px) {

      .modal__gift-voucher-terms .content-wallet-terms-modal .ticket-blue {
            left: 24.6%;
            top: 6.4%;
            width: 18px;
            z-index: 0;
      }
      
      .modal__gift-voucher-terms .content-wallet-terms-modal .ticket-green1 {
            left: 72%;
            top: 6.2%;
      }
      
      .modal__gift-voucher-terms .content-wallet-terms-modal .ticket-AquamarineSm {
            left: 46.6%;
            top: 3.9%;
            width: 24px;
            z-index: 0;
      }
      
      .modal__gift-voucher-terms  .content-wallet-terms-modal .ticket-orange1 {
            left: 12.6%;
            top: 0.5%;
      }
      
      .modal__gift-voucher-terms .content-wallet-terms-modal .ticket-red {
            left: 76.6%;
            top: 1.1%;
      }

}