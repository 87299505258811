/*--------------------------------------------------------------------------------------------------- */
/*---------------------------------------- Dark Mode for Wallet -------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

:root {
    --bs-dark-mode-background-color: #000;
    --bs-dark-mode-text-color : #fff;
    --bs-dark-mode-border-color : #fff;
}

.dark-mode {
    background-color: var(--bs-dark-mode-background-color);
    color: var(--bs-dark-mode-text-color);
    border-color: var(--bs-dark-mode-border-color);
}

.dark-mode .btn-outline-primary,
.dark-mode .btn,
.dark-mode .btn__back {
    color: var(--bs-dark-mode-text-color) !important;
    border-color: var(--bs-dark-mode-border-color) !important;
}

.dark-mode .content__my-wallet .col__transaction-history {
    border-color: var(--bs-dark-mode-border-color);
}

.dark-mode .slidein-wrapper .slidein {
    background-color: var(--bs-dark-mode-background-color);
}

.dark-mode .content__wallet-topup .content__header {
    border-color: var(--bs-dark-mode-border-color) !important;
}

.dark-mode .content__slidein .btn-close__custom {
    background-image: url(./assets/images/icon-x-37x37-white.svg);
}

.dark-mode  .input-group__as-form-control {
    border-color: var(--bs-dark-mode-border-color);
}

.dark-mode .input-group__as-form-control .input-group-text {
    color: var(--bs-dark-mode-text-color);
}

.dark-mode .form-control {
    color: var(--bs-dark-mode-text-color) !important;
    background-color: var(--bs-dark-mode-background-color);
    border-color: var(--bs-dark-mode-border-color);
}


.dark-mode .content__checkout .content__body--total,
.dark-mode .border-top,
.dark-mode .border-bottom {
    border-color: var(--bs-dark-mode-border-color) !important ;
}

.dark-mode .modal__almost-done .modal-content {
    background-color: var(--bs-dark-mode-background-color);
}


.dark-mode #WebViewWalletModule  .btn-close__custom,
.dark-mode #modalFlexiTermsAndConditions .btn-close__custom {
    background-image: url(./assets/images/icon-x-white.svg);
}
.dark-mode #WebViewWalletModule .modal-content,
.dark-mode #modalFlexiTermsAndConditions .modal-content {
    background-color: var(--bs-dark-mode-background-color);
    color: var(--bs-dark-mode-text-color);
    border-color: var(--bs-dark-mode-border-color);
}

.dark-mode #WebViewWalletModule .card.bg-aqua,
.dark-mode #WebViewWalletModule .card-footer {
  background-color: var(--bs-yellow) !important;
  border-color: var(--bs-yellow) !important;
  color: var(--bs-body-color);
}

/*--------------------------------------------------------------------------------------------------- */
/*-------------------------------------- Dark Mode for Save Card ------------------------------------ */
/*--------------------------------------------------------------------------------------------------- */
.dark-mode .accordion-item{
    background-color: var(--bs-dark-mode-background-color);
}

.dark-mode .accordion__circle-arrow .accordion-body {
    border-color: var(--bs-dark-mode-border-color);
}

.dark-mode #webViewSaveCard .accordion-item__SavedCards .heading {
    border-color: var(--bs-dark-mode-border-color);
}

.dark-mode .modal__my-account-delete-card .modal-content {
    background-color: var(--bs-dark-mode-background-color);
}

/*--------------------------------------------------------------------------------------------------- */
/*------------------------------------ Dark Mode for Gift Voucher ----------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.dark-mode .slidein__gift-voucher #walletTopupStep1 .giftVoucher-lower-footer-body, 
.dark-mode .slidein__gift-voucher #walletTopupStep2 .giftVoucher-lower-footer-body {
    background-color: var(--bs-dark-mode-background-color);
}

.dark-mode .slidein__gift-voucher .btn:disabled .button__text {
    color: var(--bs-dark-mode-text-color) !important;
}


/*--------------------------------------------------------------------------------------------------- */
/*----------------------------------- Dark Mode for Booking Module ------ ---------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.dark-mode #WebViewBookingModule .content__header {
    border-color: var(--bs-dark-mode-border-color) !important;
}

.dark-mode #WebViewBookingModule {
    color: var(--bs-dark-mode-text-color);
}

.dark-mode #bookTicketsStep1 #btnSelectEventDate {
    color: var(--bs-dark-mode-text-color) !important;
}

.dark-mode #bookTicketsStep1  + .content__footer__wrapper .text-decoration-underline {
    color: var(--bs-dark-mode-text-color);
} 

.dark-mode #bookTicketsStep1 .datepicker__trigger,
.dark-mode .ts-wrapper:not(.form-select).single .ts-control {
    background-image: url(./assets/images/icon-arrow-down-white-sm.svg);
}

.dark-mode #WebViewBookingModule .new_content__select-time {
    background-color: var(--bs-dark-mode-background-color);
}

.dark-mode .slidein__book-tickets .react-datepicker, .slidein__gift-voucher .react-datepicker {
    border-color: var(--bs-dark-mode-border-color);
}

.dark-mode .collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__current-month, 
.dark-mode  .slidein__book-tickets .react-datepicker .react-datepicker__month-container .react-datepicker__current-month, 
.dark-mode  .slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__current-month {
    border-color: var(--bs-dark-mode-border-color);
    color: var(--bs-dark-mode-text-color);
}

.dark-mode  .collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__day-names .react-datepicker__day-name,
.dark-mode  .slidein__book-tickets .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__day-names .react-datepicker__day-name, 
.dark-mode  .slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__day-names .react-datepicker__day-name {
    border-color: var(--bs-dark-mode-border-color);
    color: var(--bs-dark-mode-text-color);
}

.dark-mode #WebViewBookingModule .ts-control, 
.dark-mode #WebViewBookingModule #btnSelectEventDate {
    color: var(--bs-dark-mode-text-color);
    background-color: var(--bs-dark-mode-background-color);
    border-color: var(--bs-dark-mode-border-color);
}

.dark-mode .icon__plus::before, 
.dark-mode .icon__plus::after {
    background-color: var(--bs-dark-mode-text-color);
   
}


.dark-mode .content-scrollable.content-scrollable-in-steps .bookTicketsStep1__wrapper .content__body--total, 
.dark-mode .content-scrollable.content-scrollable-in-steps .bookTicketsStep2__wrapper .content__body--total, 
.dark-mode .content-scrollable.content-scrollable-in-steps .bookTicketsStep2__wrapper #btnBackToStep1, 
.dark-mode .content-scrollable.content-scrollable-in-steps .bookTicketsStep3__wrapper .content__body--total, 
.dark-mode .content-scrollable.content-scrollable-in-steps .bookTicketsStep3__wrapper #btnBackToStep2, 
.dark-mode .content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaCard__wrapper .content__body--total, 
.dark-mode .content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaCard__wrapper #btnBackToStep3FromPayViaCard, 
.dark-mode .content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaWallet__wrapper .content__body--total, 
.dark-mode .content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaWallet__wrapper #btnBackToStep3FromPayViaWallet, 
.dark-mode .content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaWalletPlusCard__wrapper .content__body--total, 
.dark-mode .content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaWalletPlusCard__wrapper #btnBackToStep3FromPayViaWalletPlusCard, 
.dark-mode .content-scrollable.content-scrollable-in-steps .bookTicketsStep5OrderSummaryPayViaWalletPlusCard__wrapper .content__body--total, 
.dark-mode .content-scrollable.content-scrollable-in-steps .bookTicketsStep5OrderSummaryPayViaWalletPlusCard__wrapper .content__footer .btn__back.btn {
    background-color: var(--bs-dark-mode-background-color);
}

.dark-mode .content__checkout .content__body--total {
    background-color: var(--bs-dark-mode-background-color);
}

.dark-mode #WebViewBookingModule #bookTicketsStep1 + .content__footer__wrapper .buttons-wrapper, 
.dark-mode #WebViewBookingModule #bookTicketsStep2 + .content__footer__wrapper .buttons-wrapper,
.dark-mode #WebViewBookingModule #bookTicketsStep3 + .content__footer__wrapper .buttons-wrapper, 
.dark-mode #WebViewBookingModule #bookTicketsStep4PayViaWallet + .content__footer__wrapper .buttons-wrapper,
.dark-mode #WebViewBookingModule #bookTicketsStep5OrderSummaryPayViaWalletPlusCard + .content__footer__wrapper .buttons-wrapper, 
.dark-mode #WebViewBookingModule #bookTicketsStep4PayViaWalletPlusCard + .content__footer__wrapper .buttons-wrapper, 
.dark-mode #WebViewBookingModule #bookTicketsStep4PayViaCard + .content__footer__wrapper .buttons-wrapper {
    background-color: var(--bs-dark-mode-background-color);
  }

.dark-mode #WebViewBookingModule #bookTicketsStep2 
.dark-mode .event__date, 
.dark-mode #WebViewBookingModule #bookTicketsStep5OrderSummaryPayViaWalletPlusCard .event__date {
    border-color: var(--bs-dark-mode-border-color);
}

.dark-mode #WebViewBookingModule #bookTicketsStep2 tbody:last-child {
    border-color: var(--bs-dark-mode-border-color) !important;
}
.dark-mode .table-black-theme tbody, 
.dark-mode .table-black-theme td, 
.dark-mode .table-black-theme tfoot, 
.dark-mode .table-black-theme th, 
.dark-mode .table-black-theme thead, 
.dark-mode .table-black-theme tr {
    border-color: var(--bs-dark-mode-border-color);
    color: var(--bs-dark-mode-text-color);
}

.dark-mode #WebViewBookingModule {
    color: var(--bs-dark-mode-text-color);
    background-color: var(--bs-dark-mode-background-color);
    border-color: var(--bs-dark-mode-border-color);
}

.dark-mode #WebViewBookingModule  .modal-content {
    color: var(--bs-dark-mode-text-color);
    background-color: var(--bs-dark-mode-background-color);
    border-color: var(--bs-dark-mode-border-color);
}

.dark-mode #WebViewBookingModule .modal-content tbody, 
.dark-mode #WebViewBookingModule .modal-content td,
.dark-mode #WebViewBookingModule .modal-content tfoot,
.dark-mode #WebViewBookingModule .modal-content th,
.dark-mode #WebViewBookingModule .modal-content thead,
.dark-mode #WebViewBookingModule .modal-content tr {
    color: var(--bs-dark-mode-text-color);
    background-color: var(--bs-dark-mode-background-color);
    border-color: var(--bs-dark-mode-border-color) !important;
}

.dark-mode #WebViewBookingModule  .btn-close__custom {
    background-image: url(./assets/images/icon-x-white.svg);
}
/*--------------------------------------------------------------------------------------------------- */
/*------------------------------------ Membership Guidelines Page ------ ---------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.dark-mode .main__membership-guidelines {
    background-color: var(--bs-dark-mode-background-color);
    border-color: var(--bs-dark-mode-border-color);
    color: var(--bs-dark-mode-text-color);
}

.dark-mode .main__membership-guidelines a {
    border: none;
}