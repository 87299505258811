.uptakeVenue-pageTitle__dummy {
      width: 100%;
      height: 60px;
      border-radius: 10px;
}

.card__uptake--thank-you.uptakeVenue-nhscardbody__dummy {
      height: 120px;
      background: #eee !important;
}

.uptakeVenue-item__dummy .event-image {
      height: 171px;
}

.uptakeVenue-item__dummy .card-title {
      height: 36px;
}

.uptakeVenue-item__dummy .list__uptake-stat {
      height: 55px;
}

.uptakeVenue-item__dummy .heading {
      height: 20px;
}

.uptakeVenue-item__dummy .progress-bar-overlay::before {
      border: none !important; 
      height: 29px;
}
 
.uptakeVenue-item__dummy .buttons-wrapper .btn {
      border: none;
      height: 41px;
}

.uptakeVenue-pageTitle__dummy, .card__uptake--thank-you.uptakeVenue-nhscardbody__dummy, 
.uptakeVenue-item__dummy .event-image, 
.uptakeVenue-item__dummy .card-title,
.uptakeVenue-item__dummy .list__uptake-stat,
.uptakeVenue-item__dummy .heading,
.uptakeVenue-item__dummy .progress-bar-overlay::before,
.uptakeVenue-item__dummy .buttons-wrapper .btn   {
      background: #eee;
      background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
      -webkit-animation: shine 1.5s linear infinite;
}


@media (min-width:412px){
      .uptakeVenue-pageTitle__dummy {
            height: 30px;
      }
    
      .uptakeVenue-item__dummy .event-image {
            height: 194px;
      }

      .uptakeVenue-item__dummy .card-title {
            height: 18px;
      }

      .uptakeVenue-item__dummy .list__uptake-stat {
            height: 63px;
            width: 100%;
      }

}

@media (min-width:540px){

    

}

@media (min-width:720px){
      .uptakeVenue-pageTitle__dummy {
            height: 51px;
      }

      .card__uptake--thank-you.uptakeVenue-nhscardbody__dummy {
            height: 90px;
      }

      .uptakeVenue-item__dummy .event-image {
            height: 425px;
      }

      .uptakeVenue-item__dummy .card-title {
            width: 80%;
      }

      .uptakeVenue-item__dummy .list__uptake-stat {
            width: 95%;
      }
      

}

@media (min-width:912px){
      .uptakeVenue-pageTitle__dummy {
            height: 57px;
      }

      .card__uptake--thank-you.uptakeVenue-nhscardbody__dummy {
            height: 60px;
      }

      .uptakeVenue-item__dummy .event-image {
            height: 478px;
      }

}

@media (min-width:1024px){
      .uptakeVenue-pageTitle__dummy {
            height: 64px;
      }

      .card__uptake--thank-you.uptakeVenue-nhscardbody__dummy {
            height: 72px;
      }

      .uptakeVenue-item__dummy .event-image {
            height: 182px;
      }
 
}


@media (min-width: 1200px) {
      .uptakeVenue-pageTitle__dummy {
            height: 80px;
      }

      .card__uptake--thank-you.uptakeVenue-nhscardbody__dummy {
            height: 84px;
      }

      .uptakeVenue-item__dummy .event-image {
            height: 226px;
      }

      .uptakeVenue-item__dummy .card-title {
            height: 30px;
            width: 80%;
      }

      .uptakeVenue-item__dummy .list__uptake-stat {
            height: 76px;
      }

      .uptakeVenue-item__dummy .heading {
            height: 24px;
      }

      .uptakeVenue-item__dummy .progress-bar-overlay::before {
            height: 36px;
      }
      
}


@media (min-width: 1400px) {
      .uptakeVenue-pageTitle__dummy {
            height: 90px;
      }
}

@media (min-width:1700px){
      .uptakeVenue-pageTitle__dummy {
            height: 108px;
      }

      .uptakeVenue-item__dummy .list__uptake-stat {
            height: 86px;
      }
      
}

@keyframes shine {
      to {
            background-position-x: -200%;
      }
}
      
@keyframes move {

      0%, 100% { 
            left: 0px;
      }
      20% , 60%{
            left: 15px;
      }
      40% , 80%{
            left: -15px;
      }
}

    