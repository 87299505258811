
/*--------------------------------------------------------------------------------------------------- */
/*----------------------------------------- ScrollBar Style ---------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.ajtix-body.c-scrollbar_thumb {
      background-color: var(--bs-ajtix-red);
}
 
/* Handle */
.ajtix-body::-webkit-scrollbar-thumb {
      background: var(--bs-ajtix-red) !important ; 
}
    
/* Handle on hover */
.ajtix-body::-webkit-scrollbar-thumb:hover {
      background: var(--bs-ajtix-red) !important; 
}
.ajtix-body .c-scrollbar_thumb {
      background-color: var(--bs-ajtix-red);
}

/*--------------------------------------------------------------------------------------------------- */
/*---------------------------------------------- Header --------------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.ajtix-main-header .nav-link,
.ajtix-main-header .dropdown-item{
      color: #000;
      
}
.ajtix-main-header .main-header__navbar .navbar-brand::before {
      background-image: url("./assets/images/Ajtix-logo-mobile.png");
      height: 30px;
      width: 200px;
}

.ajtix-main-header .main-header__navbar #btnLogin,
.ajtix-main-header .main-header__navbar .nav-link.btn-primary {
      background-color: var(--bs-ajtix-red);
      color: #fff;
      border-color: var(--bs-ajtix-red);
}

.ajtix-main-header .main-header__navbar #btnLogin:active,
.ajtix-main-header .main-header__navbar #btnLogin:hover,
.ajtix-main-header .main-header__navbar #btnLogin:focus,
.ajtix-main-header .main-header__navbar .nav-link.btn-primary:active,
.ajtix-main-header .main-header__navbar .nav-link.btn-primary:hover,
.ajtix-main-header .main-header__navbar .nav-link.btn-primary:focus {
      background-color: #fff;
      color: var(--bs-ajtix-red);
      border-color: var(--bs-ajtix-red);
}

.ajtix-main-header .main-header__navbar .nav-link.btn-primary:hover::before,
.ajtix-main-header .main-header__navbar .nav-link.btn-primary:active::before, 
.ajtix-main-header .main-header__navbar .nav-link.btn-primary:focus::before {
      background-color: var(--bs-ajtix-red);
}

.ajtix-main-header .has-icon:hover .icon-bg.icon-wallet,
.ajtix-main-header .has-icon:active .icon-bg.icon-wallet, 
.ajtix-main-header .has-icon:focus .icon-bg.icon-wallet {
      background-image: url(./assets/images/icon-wallet-red-ajtix.svg);
}

.ajtix-main-header .main-header__navbar .nav-link:active, 
.ajtix-main-header .main-header__navbar .nav-link:hover,
.ajtix-main-header .main-header__navbar .nav-link:focus {
      color: var(--bs-ajtix-red);
}

.ajtix-main-header .btn-primary + .dropdown-menu > li .dropdown-item:hover::before {
      background-image: url(./assets/images/icon-arrow-right-22x21-red-ajtix.svg);
}



@media (max-width: 1199px) {

      .ajtix-main-header .main-header__navbar .dropdown-item:focus, .ajtix-main-header  .main-header__navbar .dropdown-item:hover {
            color: var(--bs-ajtix-red);
      }

      
}

@media (min-width: 1200px) {

      .ajtix-main-header .btn-primary + .dropdown-menu > li .dropdown-item {
            background-color: var(--bs-ajtix-red);
            border-color: var(--bs-ajtix-red);
            border-bottom-color: #fff;
      }
      

      .ajtix-main-header .btn-primary + .dropdown-menu > li .dropdown-item:hover,
      .ajtix-main-header .btn-primary + .dropdown-menu > li .dropdown-item:active,
      .ajtix-main-header .btn-primary + .dropdown-menu > li .dropdown-item:focus {
            color: var(--bs-ajtix-red);
            background-color: #fff;
            border-bottom-color: var(--bs-ajtix-red);
      } 

      .ajtix-main-header .dropdown-toggle.btn-primary:hover::after, 
      .ajtix-main-header .dropdown-toggle.btn-primary:active::after,
      .ajtix-main-header .dropdown-toggle.btn-primary:focus::after {
            background-image: url(./assets/images/icon-caret-down-red-ajtix.svg);
      }

}

/*--------------------------------------------------------------------------------------------------- */
/*---------------------------------------------- Footer --------------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.ajtix-main-footer {
      background-color: var(--bs-body-bg);
      padding: 16px;
      padding-top: 0px;
      color: var(--bs-body-color);
}

.ajtix-main-footer .container__footer-menu {
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;
      padding: 20px 19px 15px 20px;
}

.ajtix-main-footer .container__footer-menu.border-top-none {
      border-top: 0px solid #000;
}


.ajtix-main-footer .ajtix-footer-logo {
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
}

.ajtix-main-footer .ajtix-footer-logo-wrapper {
      width: 150px;
      height: auto;
}

.ajtix-main-footer .ajtix-footer-logo-wrapper img {
      width: 100%;
      height: 100%;
}

.ajtix-main-footer .col__footer-text {
      padding: 0;
      margin: 0;
}

.ajtix-main-footer .col__footer-text ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 0;
      margin-top: 24px;
}

.ajtix-main-footer .col__footer-text ul li {
      margin-bottom: 8px;
      font-size: 15px;
      line-height: 18px;
}

.ajtix-main-footer .col__footer-text ul a {
      color: #000;
      text-align: center;
      margin: 0;
}

.ajtix-main-footer .col__footer-text ul a:hover {
      color: var(--bs-ajtix-red);
}

.ajtix-main-footer .container__copyright .d-flex {
      justify-content: center;
      font-size: 15px;
      line-height: 18px;
}


@media (min-width: 1200px) {

      .ajtix-main-footer .col__footer-text {
            display: flex;
            justify-content: center;
      }

      .ajtix-main-footer .col__footer-text ul {
            width: 70%;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 36px;
      }

      .ajtix-main-footer .col__footer-text ul li,
      .ajtix-main-footer .container__copyright .d-flex {
            margin-bottom: 0px;
            font-size: 20px;
            line-height: 24px;
      }

      

     
}

/*--------------------------------------------------------------------------------------------------- */
/*---------------------------------------------- Home Page --------------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */



.ajtix-main__home {
      padding-top: 51px;
}

.ajtix-main__home-seat-filler {
      padding: 1rem;
      padding-bottom: 0;
}

.ajtix-main__home .content .hero {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
}

.ajtix-main__home .hero__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
}

.ajtix-main__home .hero__content .hero-header {
      margin-bottom: 3rem;
}     


.ajtix-main__home .hero__content .big-heading h2 {
      font-size: 43px;
      line-height: 54px;
      margin: 0;
}

.ajtix-main__home .hero__content .hero-body {
      margin-bottom: 3rem;
}


.ajtix-main__home .hero__content .hero-cta {
      margin-bottom: 3rem;
}

.ajtix-main__home .hero__content .hero-cta .btn {
      background: #fff;
      color: var(--bs-body-color);
      border-color: var(--bs-body-color);
      font-weight: 400;
}

.ajtix-main__home .hero__image {
      display: flex;
}

.ajtix-main__home .hero__image img {
      width: 100%;
      height: auto;
      margin-bottom: 3rem;
      border-radius: 1rem
}


.ajtix-join-our-community {
      padding: 0 1rem;
      margin-bottom: 3rem;
}

.ajtix-join-our-community .container-fluid {
      background-color: var(--bs-ajtix-red);
      border-radius: 0.5rem;
}


.ajtix-join-our-community .container-fluid .header-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
}

.ajtix-join-our-community .container-fluid .header-wrapper h1 {
      margin: 0;
      font-size: 42px;
      line-height: 46px;
      text-align: center;
}

.ajtix-join-our-community .container-fluid .header-wrapper + hr {
      flex: 1;
      border-top: 1px solid #000;
      margin: 1rem;
}

.ajtix-join-our-community .container-fluid .hero {
      padding: 1rem;
      padding-top: 0;
      
}

.ajtix-join-our-community .container-fluid .hero__image {
      margin: 0;
      padding: 0;
      margin-bottom: 1.5rem;
}

.ajtix-join-our-community .container-fluid .hero__image  img {
      margin: 0;
      padding: 0;
      width: 100%;
      height: auto;
      border-radius: 1rem;
}

.ajtix-join-our-community .container-fluid .hero__content {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
}

.ajtix-join-our-community .container-fluid .hero-header {
      margin: 0;
      margin-bottom: 1.5rem;
      width: 100%;
      
}

.ajtix-join-our-community .container-fluid .hero-header h2 {
      font-size: 40px;
      line-height: 48px;
      text-align: left;
}

.ajtix-join-our-community .container-fluid .hero-body {
      margin: 0;
      margin-bottom: 1.5rem;
}

.ajtix-join-our-community .container-fluid .hero-body p {
      font-size: 18px;
      line-height: 22px;
}

.ajtix-join-our-community .container-fluid .hero-cta {
      margin: 0;
      margin-bottom: 1.5rem;
}

.ajtix-join-our-community .container-fluid .hero-cta .btn {
      background-color: transparent;
}



.membership-free {
      margin: 0;
      padding: 0;
      margin-bottom: 3rem;
}


.membership-free .container__block .row {
      display: flex;
      flex-direction: row;
      margin-bottom: 0.5rem;
}

.membership-free .container__block .row h1 {
      width: 70%;
      margin: 0;
      padding: 0;
      text-align: center;
      font-size: 43px;
      line-height: 54px;
}

.membership-free .container__block .row:nth-child(2) h1 {
      width: 40%;
}

.membership-free .container__block .row  > div {
      width: 30%;
      background-color: var(--bs-ajtix-red);
}

.membership-free .container__block .row:first-child > div {
     border-top-left-radius: 0.5rem;
     border-bottom-left-radius: 0.5rem;
}

.membership-free .container__block .row:nth-child(2) > div {
      width: 60%;
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
}

.membership-free .container__block .row:last-child {
      margin-top: 2rem;
}

.membership-free .container__block .row .hero {
      display: flex;
      flex: 1;
      background: transparent;
}

.membership-free .container__block .row .hero-body {
      margin-bottom: 2rem;
      display: flex;
      justify-content: center;
}

.membership-free .container__block .row .hero-body p {
      width: 90%;
      text-align: center;
      font-size: 18px;
      line-height: 22px;
}

.membership-free .container__block .row .hero-cta {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 0;
}




.ajtix-supporting {
      margin: 0;
      padding: 0;
      margin-bottom: 3rem;
}

.ajtix-supporting .container__block {
      padding : 0 1rem;
}

.ajtix-supporting .container__block .row {
      border-radius: 1rem;
      overflow: hidden;
      background-color: var(--bs-ajtix-red);
}

.ajtix-supporting .hero {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
}


.ajtix-supporting .hero .hero__image img {
      margin: 0;
      padding: 0;
}

.ajtix-supporting .hero .hero__content {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      padding: 1.5rem;
}

.ajtix-supporting .hero .hero-header,
.ajtix-supporting .hero .hero-body {
      margin-bottom: 1.5rem;
}

.ajtix-supporting .hero .hero-header h2 {
      font-size: 40px;
      line-height: 48px;
      text-align: left;
}

.ajtix-supporting .hero .hero-body p {
      font-size: 18px;
      line-height: 22px;
}

.ajtix-supporting .hero .hero-cta{
      margin: 0;
      margin-top: 2rem;
      margin-bottom: 1rem;
}

.ajtix-supporting .hero .hero-cta .btn {
      background-color: transparent;
}




.ajtix-getting-started {
      margin: 0;
      padding: 0;
      margin-bottom: 3rem;
}

.ajtix-getting-started .container__block {
      padding : 0 1rem;
}

.ajtix-getting-started .content__body {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
}

.ajtix-getting-started .content__body .item {
      width: 100%;
      padding: 0.7rem;
      border: 1px solid #000;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
     
}

.ajtix-getting-started .content__body .item:not(:last-child) {
      border-bottom: none;
}

.ajtix-getting-started .content__body .item:first-child {
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
}

.ajtix-getting-started .content__body .item:last-child {
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
}

.ajtix-getting-started .content__body .item .item-header {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
}

.ajtix-getting-started .content__body .item .item-image-wrapper {
      width: auto;
      height: 42px;
      margin: 1rem 0;
      margin-top: 1.5rem;
}

.ajtix-getting-started .content__body .item .item-image-wrapper img {
      width: 100%;
      height: 100%;
}

.ajtix-getting-started .content__body .item .item-header h2 {
      font-weight: 400;
      font-size: 28px;
      line-height: 32px;
      margin-bottom: 1.5rem;
}

.ajtix-getting-started .content__body .item .item-body {
      margin-bottom: 1.5rem;
}

.ajtix-getting-started .content__body .item .item-body p {
      text-align: center;
      font-size: 18px;
      line-height: 20px;
}

.ajtix-getting-started .hero__content {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
}



.ajtix-about-us {
      margin: 0;
      padding: 0;
      margin-bottom: 3rem;

}

.ajtix-about-us .container__block {
      padding: 0 1rem;
     
}

.ajtix-about-us .hero {
      background-color: var(--bs-ajtix-red);
      border-radius: 2rem;
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
}

.ajtix-about-us .hero__content {
      display: flex;
      flex-direction: column;
}

.ajtix-about-us .hero__content .hero-header {
      width: 100%;
      margin-bottom: 2rem;
}

.ajtix-about-us .hero__content .hero-header h2 {
      text-align: center;
}

.ajtix-about-us .hero__content .hero-body {
      margin-bottom: 3rem;
}

.ajtix-about-us .hero .hero-body p {
      font-size: 18px;
      line-height: 22px;
}
.ajtix-about-us .hero .hero__image {
      flex: 1;
}

.ajtix-about-us .hero .hero__image img {
      width: 100%;
      height: auto;
      margin: 0;
      border-radius: 1rem;
      /* border-radius: 1.5rem; */
}

@media (min-width: 768px) {
      .membership-free .container__block .row {
            margin-bottom: 1rem;
      }

      .membership-free .container__block .row h1 {
            font-size: 85px;
            line-height: 92px;
      }

      .membership-free .container__block .row:last-child {
            margin-top: 2.5rem;
      }

      .membership-free .container__block .row .hero-body {
            margin-bottom: 2.5rem;
      }

      .membership-free .container__block .row .hero-body p {
            width: 70%;
      }
      
}


@media (min-width:820px) {
      .ajtix-main__home-seat-filler {
            padding: 1.5rem;
            margin: 0 !important;
      }

      .ajtix-main__home .content .hero {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
      }

      .ajtix-main__home .hero__content {
            width: 46%;
      }

      .ajtix-main__home .hero__content .hero-header {
            margin-bottom: 1.5rem;
      }

      .ajtix-main__home .hero__content .big-heading h2 {
            font-size: 30px;
            line-height: 36px;
      }

      .ajtix-main__home .hero__content .hero-body {
            margin-bottom: 1.5rem;
      }
      
      .ajtix-main__home .hero__content .hero-cta {
            margin-bottom: 0px;
      }

      .ajtix-main__home .hero__image {
            width: 50%;
      }

      .ajtix-main__home .hero__image img {
            margin-bottom: 0;
      }




      .ajtix-join-our-community {
            padding: 0 1.5rem;
      }
      .ajtix-join-our-community .container-fluid .header-wrapper {
            padding-bottom: 0;
      }

      .ajtix-join-our-community .container-fluid .header-wrapper h1 {
            font-size: 62px;
            line-height: 68px;
      }

      .ajtix-join-our-community .container-fluid .header-wrapper + hr {
            margin: 1.5rem;
      }

      .ajtix-join-our-community .container-fluid .hero {
            padding: 1.5rem;
            padding-top: 0;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
      }

      .ajtix-join-our-community .container-fluid .hero__image {
            margin: 0;
            width: 48%;
      }

      .ajtix-join-our-community .container-fluid .hero__content {
            width: 46%;
      }

      .ajtix-join-our-community .container-fluid .hero-header {
            margin-bottom: 1rem;
      }

      .ajtix-join-our-community .container-fluid .hero-header h2 {
            font-size: 30px;
            line-height: 34px;
      }

      .ajtix-join-our-community .container-fluid .hero-body {
            margin-bottom: 1rem;
      }

      .ajtix-join-our-community .container-fluid .hero-body p {
            font-size: 14px;
            line-height: 16px;
      }


      .membership-free .container__block .row .hero .hero__content {
            width: 100%;
      }




      .ajtix-supporting .container__block {
            padding: 0 1.5rem;
      }

      .ajtix-supporting .container__block .row  {
            background-color: transparent;
      }

      .ajtix-supporting .hero {
            flex-direction: row;
            background-color: var(--bs-ajtix-red);
            align-items: flex-start;
      }

      .ajtix-supporting .hero .hero__image {
            width: 52%;
      }

      .ajtix-supporting .hero .hero__content {
            flex: 1;
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 2rem;
      }

      .ajtix-supporting .hero .hero-header {
            padding-top: 1rem;
            margin-bottom: 1rem;
            width: 100%;
      }

      .ajtix-supporting .hero .hero-header h2 {
            font-size: 30px;
            line-height: 34px;
      }

      .ajtix-supporting .hero .hero-body {
            margin-bottom: 1rem;
      }

      .ajtix-supporting .hero .hero-body p {
            font-size: 14px;
            line-height: 16px;
      }


      .ajtix-supporting .hero .hero-cta {
            margin: 0;
            margin-bottom: 0.5rem;
      }


      .ajtix-getting-started .container__block {
            padding: 0 1.5rem;
      }

      .ajtix-getting-started .content__body .item .item-body {
            width: 95%;
      }

      .ajtix-getting-started .hero__content {
            width: 100%;
      }




      .ajtix-about-us .container__block {
            padding: 0 1.5rem;
      
      }

      .ajtix-about-us .hero {
            border-radius: 1.5rem;
            padding: 2.5rem;
            flex-direction: row;
            align-items: flex-start;
      }

      .ajtix-about-us .hero__content {
            width: 55%;
      }

      .ajtix-about-us .hero__content .hero-header {
            width: 100%;
            margin-bottom: 1.5rem;
      }

      .ajtix-about-us .hero__content .hero-header h2 {
            font-size: 30px;
            line-height: 30px;
            text-align: left;
      }

      .ajtix-about-us .hero__content .hero-body {
            margin-bottom: 0;
            padding-right: 1rem;
      }

      .ajtix-about-us .hero .hero-body p {
            font-size: 14px;
            line-height: 16px;
      }
      .ajtix-about-us .hero .hero__image {
            flex: 1;
            width: 45%;
      }

      .ajtix-about-us .hero .hero__image img {
            width: 100%;
            height: auto;
            margin: 0;
            border-radius: 1.5rem;
      }
      
}

@media (min-width:1024px) {

      .ajtix-main__home .hero__content .hero-header {
            margin-bottom: 2rem;
      }

      .ajtix-main__home .hero__content .big-heading h2 {
            font-size: 40px;
            line-height: 48px;
      }


      .ajtix-main__home .hero__content .hero-body {
            margin-bottom: 2rem;
      }

      .ajtix-join-our-community .container-fluid .header-wrapper h1 {
            font-size: 76px;
            line-height: 82px;
      }

      .ajtix-join-our-community .container-fluid .hero-header {
            margin-bottom: 2rem;
      }

      .ajtix-join-our-community .container-fluid .hero-header h2 {
            font-size: 40px;
            line-height: 48px;
      }

      .ajtix-join-our-community .container-fluid .hero-body {
            margin-bottom: 2rem;
      }

      .ajtix-join-our-community .container-fluid .hero-cta {
            margin-top: 2.5rem;
      }


      
      .membership-free .container__block .row h1 {
            font-size: 106px;
            line-height: 115px;
      }

      .membership-free .container__block .row:last-child {
            margin-top: 3rem;
      }

      .membership-free .container__block .row .hero-body {
            margin-bottom: 2.5rem;
      }

      .membership-free .container__block .row .hero-body p {
            width: 60%;
      }



      



      .ajtix-supporting .hero .hero__content {
            padding-left: 2.5rem;
      }

      .ajtix-supporting .hero .hero-header {
            padding-top: 2rem;
            margin-bottom: 2rem;
      }

      .ajtix-supporting .hero .hero-header h2 {
            font-size: 40px;
            line-height: 48px;
      }

      .ajtix-supporting .hero .hero-body {
            margin-bottom: 0;
      }

      .ajtix-supporting .hero .hero-body p {
            font-size: 14px;
            line-height: 16px;
      }


      .ajtix-supporting .hero .hero-cta {
            margin: 0;
            margin-top: 2.5rem;
      }





      .ajtix-getting-started .content__body {
            flex-direction: row;
            align-items: stretch;
            justify-content: flex-start;
      }

      .ajtix-getting-started .content__body .item {
            border-right: none;
            border-bottom: 1px solid #000 !important;
      }
      
      .ajtix-getting-started .content__body .item:last-child {
            border-right: 1px solid #000;
      }

      .ajtix-getting-started .content__body .item:first-child {
            border-radius: 0;
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
      }

      .ajtix-getting-started .content__body .item:last-child {
            border-radius: 0;
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
      }

      .ajtix-getting-started .content__body .item {
            padding: 0;
            justify-content: flex-start;
      }

      .ajtix-getting-started .content__body .item .item-header {
            justify-content: flex-start;
            margin-top: 1.5rem;
      }

      .ajtix-getting-started .content__body .item .item-image-wrapper {
            height: 28px;
            margin: 0.5rem 0;
      }

      .ajtix-getting-started .content__body .item .item-header h2 {
            font-size: 18px;
            line-height: 22px;
      }

      .ajtix-getting-started .content__body .item .item-body {
            margin-bottom: 2.5rem;
      }

      .ajtix-getting-started .content__body .item .item-body p {
            font-size: 14px;
            line-height: 16px;
      }



      .ajtix-about-us .hero {
            border-radius: 1rem;
            padding: 3rem;
      }

      .ajtix-about-us .hero__content {
            width: 55%;
      }

      .ajtix-about-us .hero__content .hero-header {
            width: 100%;
            margin-bottom: 2.5rem;
      }

      .ajtix-about-us .hero__content .hero-header h2 {
            font-size: 40px;
            line-height: 48px;
      }

      .ajtix-about-us .hero__content .hero-body {
            padding-right: 4rem;
      }

      .ajtix-about-us .hero .hero-body p {
            font-size: 14px;
            line-height: 16px;
      }
      
      .ajtix-about-us .hero .hero__image {
            flex: 1;
            width: 45%;
      }

      .ajtix-about-us .hero .hero__image img {
            width: 100%;
            height: auto;
            margin: 0;
            border-radius: 1.5rem;
      }

      
}


@media (min-width:1200px) {

      .ajtix-main__home {
            padding-top: 91px;
      }

      .ajtix-main__home-seat-filler {
            padding: 2.5rem;
      }

      .ajtix-main__home .hero__content {
            width: 42%;
      }

      .ajtix-main__home .hero__content .hero-header {
            margin-bottom: 2.5rem;
      }

      .ajtix-main__home .hero__content .big-heading h2 {
            font-size: 46px;
            line-height: 54px;
      }

      .ajtix-main__home .hero__content .hero-body {
            margin-bottom: 2.5rem;
      }
     

      .ajtix-main__home .hero__content .hero-body p {
            font-size: 23px;
            line-height: 30px;
      }

      .ajtix-main__home .hero__image {
            width: 50%;
      }

      .ajtix-join-our-community {
            padding: 0 2.5rem;
      }

      .ajtix-join-our-community .container-fluid {
            border-radius: 1rem;
      }

      .ajtix-join-our-community .container-fluid .header-wrapper {
            padding: 1.5rem;
            padding-bottom: 0;
      }

      .ajtix-join-our-community .container-fluid .header-wrapper h1 {
            font-size: 95px;
            line-height: 102px;
      }
      
      .ajtix-join-our-community .container-fluid .header-wrapper + hr {
            margin: 2rem;
            margin-left: 2.5rem;
            margin-right: 2.5rem;
      }

      .ajtix-join-our-community .container-fluid .hero {
            padding: 2.5rem;
            padding-top: 0;
      }

      .ajtix-join-our-community .container-fluid .hero-header {
            margin-bottom: 1.5rem;
      }

      .ajtix-join-our-community .container-fluid .hero-header h2 {
            font-size: 52px;
            line-height: 56px;
      }

      .ajtix-join-our-community .container-fluid .hero-body {
            margin-bottom: 1.5rem;
      }

      .ajtix-join-our-community .container-fluid .hero-body p {
            font-size: 21px;
            line-height: 25px;
      }

      .ajtix-join-our-community .container-fluid .hero-cta {
            margin-top: 1.5rem;
      }


      .membership-free {
            margin-bottom: 4rem;
      }

      .membership-free .container__block .row {
            margin-bottom: 1.5rem;
      }

      .membership-free .container__block .row h1 {
            font-size: 130px;
            line-height: 140px;
            padding-left: 2.5rem;
            text-align: left;
      }

      .membership-free .container__block .row:nth-child(2) h1 {
            width: 33%;
      }

      .membership-free .container__block .row:first-child > div {
            border-top-left-radius: 1rem;
            border-bottom-left-radius: 1rem;
        }

      .membership-free .container__block .row:nth-child(2) > div {
            border-top-right-radius: 1rem;
            border-bottom-right-radius: 1rem;
      }

      .membership-free .container__block .row:nth-child(2) > div {
            width: 67%;
      }

      .membership-free .container__block .row:last-child {
            margin-top: 4rem;
      }

      .membership-free .container__block .row .hero-body {
            margin-bottom: 2.5rem;
      }

      .membership-free .container__block .row .hero-body p {
            font-size: 23px;
            line-height: 30px;
      }



      .ajtix-supporting {
            margin-bottom: 4rem;
      }

      .ajtix-supporting .container__block {
            padding: 0 2.5rem;
      }

      .ajtix-supporting .hero .hero__content {
            padding-left: 3rem;
      }


      .ajtix-supporting .hero .hero-header h2 {
            font-size: 52px;
            line-height: 56px;
      }

      .ajtix-supporting .hero .hero-body {
            margin-bottom: 0;
      }

      .ajtix-supporting .hero .hero-body p {
            font-size: 21px;
            line-height: 25px;
      }

      .ajtix-supporting .hero .hero-cta {
            margin: 0;
            margin-top: 2.5rem;
      }



      .ajtix-getting-started {
            margin-bottom: 4rem;
      }

      .ajtix-getting-started .container__block {
            padding: 0 2.5rem;
      }

      .ajtix-getting-started .content__body .item:first-child {
            border-radius: 0;
            border-top-left-radius: 0.7rem;
            border-bottom-left-radius: 0.7rem;
      }

      .ajtix-getting-started .content__body .item:last-child {
            border-radius: 0;
            border-top-right-radius: 0.7rem;
            border-bottom-right-radius: 0.7rem;
      }


      .ajtix-getting-started .content__body .item .item-header {
            justify-content: flex-start;
            margin-top: 2rem;
      }

      .ajtix-getting-started .content__body .item .item-image-wrapper {
            height: 32px;
            margin: 0.5rem 0;
      }

      .ajtix-getting-started .content__body .item .item-header h2 {
            font-size: 22px;
            line-height: 26px;
            margin-bottom: 2rem;
      }

      .ajtix-getting-started .content__body .item .item-body {
            margin-bottom: 2.5rem;
      }

      .ajtix-getting-started .content__body .item .item-body {
            width: 88%;
      }

      .ajtix-getting-started .content__body .item .item-body p {
            font-size: 16px;
            line-height: 18px;
      }

      .ajtix-getting-started .hero__content {
            width: 100%;
      }



      .ajtix-about-us .container__block {
            padding: 0 2.5rem;
      }

      .ajtix-about-us .hero {
            padding: 3rem 4rem;
      }

      .ajtix-about-us .hero__content {
            width: 60%;
      }

      .ajtix-about-us .hero__content .hero-header {
            width: 100%;
            margin-bottom: 2rem;
      }

      .ajtix-about-us .hero__content .hero-header h2 {
            font-size: 52px;
            line-height: 56px;
      }

      .ajtix-about-us .hero__content .hero-body {
            padding-right: 4rem;
            margin-bottom: 0;
      }

      .ajtix-about-us .hero .hero-body p {
            font-size: 21px;
            line-height: 25px;
      }
      
      .ajtix-about-us .hero .hero__image {
            flex: 1;
            width: 40%;
      }

      .ajtix-about-us .hero .hero__image img {
            border-radius: 1rem;
      }

}

@media (min-width:1400px) {

      .ajtix-main__home .hero__content {
            width: 44%;
      }

      .ajtix-main__home .hero__image {
            width: 46%;
      }

      .ajtix-main__home-seat-filler {
            padding: 3rem;
      }

      .ajtix-main__home .hero__content .hero-header {
            margin-bottom: 3rem;
      }

      .ajtix-main__home .hero__content .big-heading h2 {
            font-size: 52px;
            line-height: 62px;
      }

      .ajtix-main__home .hero__content .hero-body {
            margin-bottom: 3rem;
      }



      .ajtix-join-our-community {
            padding: 0 3rem;
      }

      .ajtix-join-our-community .container-fluid .header-wrapper {
            padding: 2rem;
            padding-bottom: 0;
      }

      .ajtix-join-our-community .container-fluid .header-wrapper h1 {
            font-size: 105px;
            line-height: 112px;
      }

      .ajtix-join-our-community .container-fluid .header-wrapper + hr {
            margin-left: 3rem;
            margin-right: 3rem;
      }

      .ajtix-join-our-community .container-fluid .hero {
            padding: 3rem;
            padding-top: 0;
      }

      .ajtix-join-our-community .container-fluid .hero-header,
      .ajtix-join-our-community .container-fluid .hero-body {
            margin-bottom: 1.5rem;
      }

      .ajtix-join-our-community .container-fluid .hero-body p {
            font-size: 21px;
            line-height: 25px;
      }

      .ajtix-join-our-community .container-fluid .hero-cta {
            margin-top: 3.5rem;
      }


      .membership-free .container__block .row .hero-body p {
            width: 53%;
      }




      .ajtix-supporting {
            margin-bottom: 4rem;
      }

      .ajtix-supporting .container__block {
            padding: 0 3rem;
      }

      .ajtix-supporting .hero .hero-header h2 {
            font-size: 52px;
            line-height: 62px;
      }

      .ajtix-supporting .hero .hero-body {
            margin-bottom: 0;
      }





      .ajtix-getting-started .container__block {
            padding: 0 3rem;
      }

      .ajtix-getting-started .content__body .item .item-header {
            justify-content: flex-start;
            margin-top: 2rem;
      }

      .ajtix-getting-started .content__body .item .item-image-wrapper {
            height: 36px;
            margin: 0.5rem 0;
      }

      .ajtix-getting-started .content__body .item .item-header h2 {
            font-size: 22px;
            line-height: 26px;
            margin-bottom: 2rem;
      }

      .ajtix-getting-started .content__body .item .item-body {
            margin-bottom: 2.5rem;
      }

      .ajtix-getting-started .content__body .item .item-body {
            width: 80%;
        }

      .ajtix-getting-started .content__body .item .item-body p {
            font-size: 16px;
            line-height: 18px;
      }

      .ajtix-getting-started .hero__content {
            width: 100%;
      }




      .ajtix-about-us {
            margin-bottom: 4rem;
      }

      .ajtix-about-us .container__block {
            padding: 0 3rem;
      }


      .ajtix-about-us .hero__content {
            width: 58%;
      }

      .ajtix-about-us .hero__content .hero-header {
            margin-bottom: 2rem;
      }

      .ajtix-about-us .hero__content .hero-header h2 {
            font-size: 52px;
            line-height: 62px;
      }

      .ajtix-about-us .hero__content .hero-body {
            padding-right: 4rem;
            margin-bottom: 0;
      }

      .ajtix-about-us .hero .hero-body p {
            font-size: 21px;
            line-height: 25px;
      }
      
      .ajtix-about-us .hero .hero__image {
            flex: 1;
            width: 42%;
      }

}

@media (min-width:1600px) {

      .ajtix-main__home-seat-filler {
            padding: 3.5rem;
            padding-bottom: 5.5rem;
      }

      .ajtix-main__home .hero__content {
            width: 44%;
      }

      .ajtix-main__home .hero__image {
            width: 48%;
      }

      .ajtix-main__home .hero__content .hero-header {
            margin-bottom: 3.5rem;
      }

      .ajtix-main__home .hero__content .big-heading h2 {
            font-size: 58px;
            line-height: 68px;
      }

      .ajtix-main__home .hero__content .hero-body {
            margin-bottom: 3.5rem;
      }

      .ajtix-main__home .hero__content .hero-body p {
            font-size: 28px;
            line-height: 35px;
      }



      .ajtix-join-our-community {
            padding: 0 3.5rem;
      }

      

      .ajtix-join-our-community .container-fluid .header-wrapper {
            padding: 2.5rem;
            padding-bottom: 0;
      }

      .ajtix-join-our-community .container-fluid .header-wrapper h1 {
            font-size: 135px;
            line-height: 145px;
      }

      .ajtix-join-our-community .container-fluid .header-wrapper + hr {
            margin-bottom: 3rem;
            margin-left: 3.5rem;
            margin-right: 3.5rem;
      }

      .ajtix-join-our-community .container-fluid .hero {
            padding: 3.5rem;
            padding-top: 0;
      }

      .ajtix-join-our-community .container-fluid .hero-header,
      .ajtix-join-our-community .container-fluid .hero-body {
            margin-bottom: 2.5rem;
      }

      .ajtix-join-our-community .container-fluid .hero-header h2 {
            font-size: 65px;
            line-height: 75px;
      }

      .ajtix-join-our-community .container-fluid .hero-body p {
            font-size: 28px;
            line-height: 35px;
      }

      .ajtix-join-our-community .container-fluid .hero-cta {
            /* position: absolute; */
            /* bottom: 2.5rem; */
            position: relative;
            margin-top: 0;
      }



      .membership-free .container__block .row {
            margin-bottom: 2rem;
      }

      .membership-free .container__block .row h1 {
            font-size: 195px;
            line-height: 215px;
            padding-left: 3rem;
            text-align: left;
      }

      .membership-free .container__block .row .hero-body {
            margin-bottom: 2.5rem;
            width: 100%;
      }

      .membership-free .container__block .row .hero-body p {
            width: 60%;
            font-size: 36px;
            line-height: 42px;
      }



      .ajtix-supporting .container__block {
            padding: 0 3.5rem;
      }

      .ajtix-supporting .hero .hero__content {
            padding-left: 4rem;
      }


      .ajtix-supporting .hero .hero-header h2 {
            font-size: 65px;
            line-height: 75px;
      }

      .ajtix-supporting .hero .hero-body {
            margin-bottom: 0;
      }

      .ajtix-supporting .hero .hero-body p {
            font-size: 28px;
            line-height: 35px;
      }

      .ajtix-supporting .hero .hero-cta {
            margin: 0;
            margin-top: 3.5rem;
      }


      .ajtix-getting-started .container__block {
            padding: 0 3.5rem;
      }

      .ajtix-getting-started .content__body .item .item-header {
            justify-content: flex-start;
            margin-top: 2.5rem;
      }

      .ajtix-getting-started .content__body .item .item-image-wrapper {
            height: 42px;
            margin: 0.75rem 0;
      }

      .ajtix-getting-started .content__body .item .item-header h2 {
            font-size: 26px;
            line-height: 32px;
            margin-bottom: 2rem;
      }

      .ajtix-getting-started .content__body .item .item-body {
            margin-bottom: 2.5rem;
      }

      .ajtix-getting-started .content__body .item .item-body {
            width: 94%;
      }

      .ajtix-getting-started .content__body .item .item-body p {
            font-size: 23px;
            line-height: 30px;
      }

      .ajtix-getting-started .hero__content {
            width: 100%;
      }




      .ajtix-about-us .container__block {
            padding: 0 3.5rem;
      }


      .ajtix-about-us .hero__content {
            width: 60%;
      }

      .ajtix-about-us .hero__content .hero-header {
            margin-bottom: 2rem;
      }

      .ajtix-about-us .hero__content .hero-header h2 {
            font-size: 65px;
            line-height: 75px;
      }

      .ajtix-about-us .hero__content .hero-body {
            padding-right: 6rem;
            margin-bottom: 0;
      }

      .ajtix-about-us .hero .hero-body p {
            font-size: 28px;
            line-height: 35px;
      }
      
      .ajtix-about-us .hero .hero__image {
            flex: 1;
            width: 40%;
      }
}

/*------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */
/*------------------------------------------------------------------------------------------ Ajtix Theme --------------------------------------------------------------------------------------------------------------- */
/*------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */


/*--------------------------------------------------------------------------------------------------- */
/*--------------------------------------------- Common --------------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */
.ajtix-wrapper .form-label-error {
      color: var(--bs-ajtix-red) !important;
}

.ajtix-wrapper .red {
      color: var(--bs-ajtix-red) !important;
}

.ajtix-wrapper a {
      color: var(--bs-ajtix-red);
}

.ajtix-wrapper .btn-primary {
      background-color: var(--bs-ajtix-red);
      border-color: var(--bs-ajtix-red);
      color: #fff;
}

.ajtix-wrapper .btn-primary:hover,
.ajtix-wrapper .btn-primary:active,
.ajtix-wrapper .btn-primary:focus {
      background-color: #fff;
      color: var(--bs-ajtix-red);
      border-color: var(--bs-ajtix-red);
}


.ajtix-wrapper .btn-heart.active {
      background-image: url(./assets/images/icon-heart-red-ajtix.svg);
}

.ajtix-wrapper .card__event-image .badge {
      background-color: var(--bs-ajtix-red) !important;
}

.ajtix-wrapper .card__event-image .badge__ticket-sold-out {
      background-color: transparent !important;
}

.ajtix-wrapper .container__notification--alert {
      background-color: var(--bs-ajtix-red);
}

/*--------------------------------------------------------------------------------------------------- */
/*--------------------------------------- Register & Login Page ------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.ajtix-wrapper .section__login .content__body .col__right .row__cta .forgot-password-link {
      color: var(--bs-ajtix-red);
}

.ajtix-wrapper .modal__registration-verification .content__body .form-group__button .invalid-mobile-number,
.ajtix-wrapper #modalVerificationCode .invalid-otp {
      color: var(--bs-ajtix-red);

}

.ajtix-wrapper .ts-dropdown .active,
.ajtix-wrapper .ts-dropdown .active:hover,
.ajtix-wrapper .ts-dropdown .option:hover {
      color: var(--bs-ajtix-red);
}

.ajtix-wrapper .content__register-part2 .form-group .list__tags .checkedLabel {
      background-color: var(--bs-ajtix-red);
      color: #fff;
      border-color: var(--bs-ajtix-red);
}

.ajtix-wrapper .content__register-part2 .icon-tooltip-info {
      color: var(--bs-ajtix-red);
      border-color: var(--bs-ajtix-red);
}

.ajtix-wrapper .content__register-part2 .error-exists {
      color: var(--bs-ajtix-red);
}
/*--------------------------------------------------------------------------------------------------- */
/*----------------------------------------- Contact Us Page  ---------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */


.ajtix-wrapper .main__contact-us .content__footer .p-accordion-btn a {
      color: var(--bs-ajtix-red) !important;
}

.ajtix-wrapper  .section__contact-us .btn-outline-primary.hover-primary:hover,
.ajtix-wrapper  .section__contact-us .btn-outline-primary.hover-primary:focus,
.ajtix-wrapper  .section__contact-us .btn-outline-primary.hover-primary:active {
      background-color: var(--bs-ajtix-red);
      border-color: var(--bs-ajtix-red);
      color: #fff;
}


.ajtix-wrapper  .section__contact-us .custom_contact_button {
      border-color: var(--bs-ajtix-red) !important;
      color: var(--bs-ajtix-red) !important;
}

.ajtix-wrapper  .section__contact-us .custom_contact_button:hover,
.ajtix-wrapper  .section__contact-us .custom_contact_button:active,
.ajtix-wrapper  .section__contact-us .custom_contact_button:focus {
      color: #fff !important;
      background-color: var(--bs-ajtix-red) !important;
}

.ajtix-wrapper .main__contact-us .section__no-my-bookings {
      color: var(--bs-ajtix-red);
}

.ajtix-wrapper .main__contact-us .btn.btn-primary:hover, 
.ajtix-wrapper .main__contact-us .btn.btn-primary:active,
.ajtix-wrapper .main__contact-us .btn.btn-primary:focus {
      background-color: #fff;
      color: var(--bs-ajtix-red);
      border-color: var(--bs-ajtix-red);
}

/*--------------------------------------------------------------------------------------------------- */
/*------------------------------------------- Privacy Page  ----------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

/*--------------------------------------------------------------------------------------------------- */
/*--------------------------------------- Membership Guidelines  ------------------------------------ */
/*--------------------------------------------------------------------------------------------------- */
.ajtix-wrapper .main__membership-guidelines.bg-red {
      background-color: var(--bs-ajtix-red) !important;
}


.ajtix-wrapper  .main__membership-guidelines.bg-red p, .ajtix-wrapper  .main__membership-guidelines.bg-red h2 {
      color: var(--bs-body-color);
}

.ajtix-wrapper .bg-red .vr {
      background-color: #000;
}
/*--------------------------------------------------------------------------------------------------- */
/*-------------------------------------------- Terms Page  ------------------------------------------ */
/*--------------------------------------------------------------------------------------------------- */

/*--------------------------------------------------------------------------------------------------- */
/*-------------------------------------------- Faqs Page  ------------------------------------------ */
/*--------------------------------------------------------------------------------------------------- */

/*--------------------------------------------------------------------------------------------------- */
/*----------------------------------------- Access needs Page  --------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

/*--------------------------------------------------------------------------------------------------- */
/*------------------------------------------- New Look Page  ---------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.ajtix-wrapper .bg-red {
      background-color: var(--bs-ajtix-red) !important;
}

.ajtix-wrapper section.bg-red .btn-primary {
      background-color: var(--bs-ajtix-red);
}

.ajtix-wrapper section.bg-red .btn-primary:hover,
.ajtix-wrapper section.bg-red .btn-primary:focus
 {
      background-color: #fff;
      border-color: #fff;
      color: var(--bs-ajtix-red);
  }

/*--------------------------------------------------------------------------------------------------- */
/*-----------------------------------------  Current Listing  --------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */


.ajtix-wrapper .section__current-listings .ts-dropdown .active, .ajtix-current-listing .ts-dropdown .option:hover {
      color: var(--bs-ajtix-red);
}

.ajtix-wrapper .section__current-listings .owl-carousel__event-tags .badge-outline{
      border-color: var(--bs-ajtix-red);
      color: var(--bs-ajtix-red);
}

.ajtix-wrapper .section__current-listings #collapseListingFilters .list__event-tags-wrapper .list__event-tags div .badge.active {
      background-color: var(--bs-ajtix-red);
      border-color: var(--bs-ajtix-red);
      color: #fff;
}

.ajtix-wrapper .section__current-listings #collapseListingFilters .list__event-tags-wrapper .list__event-tags div .badge.active:hover,
.ajtix-wrapper .section__current-listings #collapseListingFilters .list__event-tags-wrapper .list__event-tags div .badge.active:focus {
      background-color: var(--bs-ajtix-red);
      border-color: var(--bs-ajtix-red);
      color: #fff;
}

.ajtix-wrapper .section__current-listings #collapseListingFilters .list__event-tags-wrapper .list__event-tags div:hover .badge-outline {
      border-color: var(--bs-ajtix-red);
      color: var(--bs-ajtix-red);
}

.ajtix-wrapper .section__current-listings #collapseListingFilters #selectLocation + .ts-wrapper .ts-dropdown.multi .selected {
      color: var(--bs-ajtix-red);
}

.ajtix-wrapper .section__current-listings #collapseListingFilters #selectLocation + .ts-wrapper .ts-control>div.item {
      background-color: var(--bs-ajtix-red);
}

.ajtix-wrapper .section__current-listings .collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected, 
.ajtix-wrapper .section__current-listings .slidein__book-tickets .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected, 
.ajtix-wrapper .section__current-listings .slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected {
      color: #fff;
}

.ajtix-wrapper .section__current-listings .collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected:hover, 
.ajtix-wrapper .section__current-listings .slidein__book-tickets .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected:hover, 
.ajtix-wrapper .section__current-listings .slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected:hover {
      color: var(--bs-ajtix-red);
}


.ajtix-wrapper .section__current-listings .collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected::before, 
.ajtix-wrapper .section__current-listings .slidein__book-tickets .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected::before, 
.ajtix-wrapper .section__current-listings .slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected::before {
     background-color: var(--bs-ajtix-red);
     border-color: var(--bs-ajtix-red);
}


.ajtix-wrapper .section__current-listings .collapse__listing-filters .listing-filters .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.react-datepicker__day--selected:hover::before,
.ajtix-wrapper .section__current-listings .slidein__book-tickets .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.react-datepicker__day--selected:hover::before, 
.ajtix-wrapper .section__current-listings .slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.react-datepicker__day--selected:hover::before {
      background-color: #fff;
      border-color: var(--bs-ajtix-red);
      color: var(--bs-ajtix-red);
}     

.ajtix-wrapper .section__current-listings .card__event .card-title a:hover {
      color: var(--bs-ajtix-red);
}

.ajtix-wrapper .section__current-listings  .btn-outline-primary.hover-primary:hover {
      background-color: var(--bs-ajtix-red);
      border-color: var(--bs-ajtix-red);
      color: #fff;
}

.ajtix-wrapper .current-listing-spinner {
      color: var(--bs-ajtix-red) !important;
}

.ajtix-wrapper .slidein__book-tickets .ct_widget_spinner__alone, 
.ajtix-wrapper .slidein__wallet-topup .ct_widget_spinner__alone {
      color: var(--bs-ajtix-red);
}
/*--------------------------------------------------------------------------------------------------- */
/*------------------------------------------  Event Details  ---------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.ajtix-wrapper .section__event-details .badge.bg-danger, 
.ajtix-wrapper .section__event-details .badge.bg-red {
      background-color: var(--bs-ajtix-red) !important;
      border-color: var(--bs-ajtix-red) !important;
}

/*--------------------------------------------------------------------------------------------------- */
/*------------------------------------------  Booking Module  ---------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */


.ajtix-wrapper .booking-module-slidein-wrapper .content__checkout .content__footer .btn, 
.ajtix-wrapper .booking-module-slidein-wrappe .content__checkout .content__footer #btnContinueStep2.btn-primary:hover,
.ajtix-wrapper .booking-module-slidein-wrapper .content__checkout .content__footer .btn, 
.ajtix-wrapper .booking-module-slidein-wrappe .content__checkout .content__footer #btnContinueStep2.btn-primary:active,
.ajtix-wrapper .booking-module-slidein-wrapper .content__checkout .content__footer .btn, 
.ajtix-wrapper .booking-module-slidein-wrappe .content__checkout .content__footer #btnContinueStep2.btn-primary:focus {
      background-color: var(--bs-ajtix-red);
      color: #fff;
}

.ajtix-wrapper .booking-module-slidein-wrapper .content-scrollable.content-scrollable-in-steps .bookTicketsStep2__wrapper #btnBackToStep1,
.ajtix-wrapper .booking-module-slidein-wrapper .content-scrollable.content-scrollable-in-steps .bookTicketsStep3__wrapper #btnBackToStep2,
.ajtix-wrapper .booking-module-slidein-wrapper .content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaWallet__wrapper #btnBackToStep3FromPayViaWallet,
.content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaWalletPlusCard__wrapper #btnBackToStep3FromPayViaWalletPlusCard,
.content-scrollable.content-scrollable-in-steps .bookTicketsStep5OrderSummaryPayViaWalletPlusCard__wrapper .content__footer .btn__back.btn,
.content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaCard__wrapper #btnBackToStep3FromPayViaCard {
      color: #000;
}

.ajtix-wrapper .booking-module-slidein-wrapper .content-scrollable.content-scrollable-in-steps .bookTicketsStep2__wrapper #btnBackToStep1:hover,
.ajtix-wrapper .booking-module-slidein-wrapper .content-scrollable.content-scrollable-in-steps .bookTicketsStep3__wrapper #btnBackToStep2:hover,
.ajtix-wrapper .booking-module-slidein-wrapper .content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaWallet__wrapper #btnBackToStep3FromPayViaWallet:hover,
.ajtix-wrapper  .content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaWalletPlusCard__wrapper #btnBackToStep3FromPayViaWalletPlusCard:hover,
.ajtix-wrapper  .content-scrollable.content-scrollable-in-steps .bookTicketsStep5OrderSummaryPayViaWalletPlusCard__wrapper .content__footer .btn__back.btn:hover,
.ajtix-wrapper  .content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaCard__wrapper #btnBackToStep3FromPayViaCard:hover {
      color: var(--bs-ajtix-red);
}

.ajtix-wrapper .booking-module-slidein-wrapper #btnPaymentMethodFullWalletCredit:hover,
.ajtix-wrapper .booking-module-slidein-wrapper #btnPaymentMethodFullWalletCredit:focus,
.ajtix-wrapper .booking-module-slidein-wrapper #btnPaymentMethodFullWalletCredit:active,
.ajtix-wrapper .booking-module-slidein-wrapper #btnPaymentMethodPartWalletCredit:hover,
.ajtix-wrapper .booking-module-slidein-wrapper #btnPaymentMethodPartWalletCredit:focus,
.ajtix-wrapper .booking-module-slidein-wrapper #btnPaymentMethodPartWalletCredit:active,
.ajtix-wrapper .booking-module-slidein-wrapper #btnRestartBooking:hover,
.ajtix-wrapper .booking-module-slidein-wrapper #btnRestartBooking:focus,
.ajtix-wrapper .booking-module-slidein-wrapper #btnRestartBooking:active  {
      background-color: #fff;
      color: var(--bs-ajtix-red);
      border-color: var(--bs-ajtix-red);
}


.ajtix-wrapper .booking-module-slidein-wrapper .anchor-style {
      color: var(--bs-ajtix-red) !important;
}

.ajtix-wrapper .booking-module-slidein-wrapper .btn-circle-x:hover, 
.ajtix-wrapper .booking-module-slidein-wrapper .btn__plus-to-x:hover {
      background-color: var(--bs-ajtix-red);
      border-color: var(--bs-ajtix-red);
}


.ajtix-wrapper .booking-module-slidein-wrapper  .btn-outline-primary:hover, 
.ajtix-wrapper .booking-module-slidein-wrapper  .btn-check:active + .btn-outline-primary, 
.ajtix-wrapper .booking-module-slidein-wrapper  .btn-check:checked + .btn-outline-primary, 
.ajtix-wrapper .booking-module-slidein-wrapper  .btn-outline-primary.active, 
.ajtix-wrapper .booking-module-slidein-wrapper  .btn-outline-primary.dropdown-toggle.show, 
.ajtix-wrapper .booking-module-slidein-wrapper  .btn-outline-primary:active {
      color: var(--bs-ajtix-red);
      border-color: var(--bs-ajtix-red);
}

.ajtix-wrapper .booking-module-slidein-wrapper .btn__payment-method:hover {
      border-color: var(--bs-ajtix-red);
      box-shadow: 0 0 0 1px var(--bs-ajtix-red);
}

.ajtix-wrapper .booking-module-slidein-wrapper .btn__payment-method:hover .sub-heading__upper, 
.ajtix-wrapper .booking-module-slidein-wrapper .btn__payment-method:hover .heading, 
.ajtix-wrapper .booking-module-slidein-wrapper .btn__payment-method:hover .wallet-balance {
      color: var(--bs-ajtix-red);
}


.ajtix-wrapper .booking-module-slidein-wrapper .btn__payment-method .button__icon {
      background-color: var(--bs-ajtix-red);
}


.ajtix-wrapper .booking-module-slidein-wrapper .btn__payment-method:hover .button__arrow {
      background-color: var(--bs-ajtix-red);
      border-color: var(--bs-ajtix-red);
}

.ajtix-wrapper .booking-module-slidein-wrapper .btn-outline-primary.hover-primary:hover {
      background-color: var(--bs-ajtix-red);
      color: #fff;
}

/*--------------------------------------------------------------------------------------------------- */
/*------------------------------------------ Refer Friend Page ---------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */




.ajtix-wrapper .section__refer-friend .input-group__invite-link .btn:hover, 
.ajtix-wrapper .section__refer-friend .input-group__invite-link .btn:focus {
      background-color: #fff;
      color: var(--bs-ajtix-red);
      border-color: var(--bs-ajtix-red);
}

/*--------------------------------------------------------------------------------------------------- */
/*----------------------------------------My Account Home Page -------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.ajtix-wrapper .section__my-account .nav-link__card{
      background-color: var(--bs-ajtix-red);
      border-color: var(--bs-ajtix-red);
      color: #fff;
}

.ajtix-wrapper .section__my-account .nav-link__card:hover,
.ajtix-wrapper .section__my-account .nav-link__card:active,
.ajtix-wrapper .section__my-account .nav-link__card:focus {
      background-color: #fff;
      color: var(--bs-ajtix-red);
      border-color: var(--bs-ajtix-red);
}

/*--------------------------------------------------------------------------------------------------- */
/*------------------------------------------ My Account Page ---------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.ajtix-wrapper .section__my-account .emailPrefrenceForm .error-exists {
      color: var(--bs-ajtix-red);
}

.ajtix-wrapper .section__my-account .emailPrefrenceForm .form-group__your-interests .list__tags .checkedLabel {
      background-color: var(--bs-ajtix-red);
      border-color: var(--bs-ajtix-red);
}


.ajtix-wrapper .section__my-account .icon-tooltip-info {
      color: var(--bs-ajtix-red);
      border-color: var(--bs-ajtix-red);
}

.ajtix-wrapper .section__my-account .ts-dropdown .active, 
.ajtix-wrapper .section__my-account .ts-dropdown .create:hover, 
.ajtix-wrapper .section__my-account .ts-dropdown .option:hover {
      color: var(--bs-ajtix-red);
}

.ajtix-wrapper .emailPrefrenceForm .form-group__list-tags .list__tags .checkedLabel, 
.ajtix-wrapper .emailPrefrenceForm .form-group__your-interests .list__tags .checkedLabel {
      background-color: var(--bs-ajtix-red);
      border-color: var(--bs-ajtix-red);
}

.ajtix-wrapper .modal__my-account-delete-card .modal-content  {
      background-image: none;
}

/*--------------------------------------------------------------------------------------------------- */
/*--------------------------------- My Booking & Manage Booking Page --------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.ajtix-wrapper .slidein__manage-booking .btn__payment-method:hover{
      border-color: var(--bs-ajtix-red);
      box-shadow: 0 0 0 1px var(--bs-ajtix-red);
}

.ajtix-wrapper .slidein__manage-booking  .btn__payment-method:hover .sub-heading__upper, 
.ajtix-wrapper .slidein__manage-booking  .btn__payment-method:hover .heading, 
.ajtix-wrapper .slidein__manage-booking  .btn__payment-method:hover .wallet-balance {
      color: var(--bs-ajtix-red);
}

.ajtix-wrapper .slidein__manage-booking .btn__payment-method:hover .button__arrow {
      background-color: var(--bs-ajtix-red);
      border-color: var(--bs-ajtix-red);
}

.ajtix-wrapper .slidein__manage-booking .ts-dropdown .active, 
.ajtix-wrapper .slidein__manage-booking .ts-dropdown .create:hover, 
.ajtix-wrapper .slidein__manage-booking .ts-dropdown .option:hover {
      color: var(--bs-ajtix-red);
}

.ajtix-wrapper .section__my-bookings .section__no-my-bookings {
      color: var(--bs-ajtix-red);
}

/*--------------------------------------------------------------------------------------------------- */
/*------------------------------------- My Favourites Page ----------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */


.ajtix-wrapper .section__my-favourites .section__no-my-favourites {
      color: var(--bs-ajtix-red);
}

.ajtix-wrapper .section__my-favourites .card__event .card-title a:hover {
      color: var(--bs-ajtix-red);
}

.ajtix-wrapper .section__my-favourites .btn-outline-primary.hover-primary:hover {
      background-color: var(--bs-ajtix-red);
      border-color: var(--bs-ajtix-red);
      color: #fff;
}


/*--------------------------------------------------------------------------------------------------- */
/*------------------------------------ My Wallet & Gift Voucher -------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.ajtix-wrapper .section__my-wallet .card__credit-balance {
      border-color: var(--bs-ajtix-red);
      background-color: var(--bs-ajtix-red);
}

.ajtix-wrapper .content__my-wallet .p-top-up .btn:hover,
.ajtix-wrapper .content__my-wallet .p-top-up .btn:active,
.ajtix-wrapper .content__my-wallet .p-top-up .btn:focus {
      background-color: var(--bs-ajtix-red);
      color: #fff;
      border-color: var(--bs-ajtix-red);
}


.ajtix-wrapper .slidein__wallet-topup .bottom-text span:last-child {
      color: var(--bs-ajtix-red) !important;
}

.ajtix-wrapper .slidein__wallet-topup .btn__payment-method:hover {
      border-color: var(--bs-ajtix-red);
      box-shadow: 0 0 0 1px var(--bs-ajtix-red);
}

.ajtix-wrapper .slidein__wallet-topup .btn__payment-method:hover .heading {
      color: var(--bs-ajtix-red);
}

.ajtix-wrapper .slidein__wallet-topup .btn__payment-method:hover .sub-heading__upper {
      color: var(--bs-ajtix-red);
}

.ajtix-wrapper .slidein__wallet-topup .btn__payment-method:hover .button__arrow {
      background-color: var(--bs-ajtix-red);
      border-color: var(--bs-ajtix-red);
}

.ajtix-wrapper .slidein__wallet-topup .btn__payment-method .button__icon--debitcredit,
.ajtix-wrapper .slidein__wallet-topup .btn__payment-method .button__icon--wallet
 {
      background-color: var(--bs-ajtix-red);
}

.ajtix-wrapper .slidein__wallet-topup #btnWalletTopUp:hover,
.ajtix-wrapper .slidein__wallet-topup #btnWalletTopUp:active,
.ajtix-wrapper .slidein__wallet-topup #btnWalletTopUp:focus {
      background-color: var(--bs-ajtix-red);
      color: #fff;
}

.ajtix-wrapper .slidein__wallet-topup #btnBackToStep1:hover {
      color: var(--bs-ajtix-red);
}

.ajtix-wrapper  .col__transaction-history .col__no-transaction-history {
      background-color: var(--bs-ajtix-red);
}
.ajtix-wrapper  .col__transaction-history .btn-outline-primary.hover-primary:hover,
.ajtix-wrapper  .col__transaction-history .btn-outline-primary.hover-primary:active,
.ajtix-wrapper  .col__transaction-history .btn-outline-primary.hover-primary:focus
 {
      background-color: var(--bs-ajtix-red);
}

.ajtix-wrapper .slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected {
      color: #fff;
}

.ajtix-wrapper .slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected::before {
      background-color: var(--bs-ajtix-red);
      border-color: var(--bs-ajtix-red);
}   

.ajtix-wrapper .slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected:hover::before {
      background-color: #fff;
      border-color: var(--bs-ajtix-red) !important;
}  

.ajtix-wrapper .slidein__gift-voucher .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected:hover {
      color: var(--bs-ajtix-red);
}
/*--------------------------------------------------------------------------------------------------- */
/*-------------------------------------- Leave Feedback page ---------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.ajtix-wrapper .section__leave-feedback .error-exists{
      color: var(--bs-ajtix-red);
}

.ajtix-wrapper .section__leave-feedback .btn-outline-primary.hover-primary:hover,
.ajtix-wrapper .section__leave-feedback .btn-outline-primary.hover-primary:active,
.ajtix-wrapper .section__leave-feedback  .form-check-input:checked + .btn-outline-primary.hover-primary {
      background-color: var(--bs-ajtix-red);
      border-color: var(--bs-ajtix-red);
      color: #fff;
}


.ajtix-wrapper .section__leave-feedback .form-group__cta button:first-child:hover {
      color: var(--bs-ajtix-red);
      border-color: var(--bs-ajtix-red);
}

.ajtix-wrapper .container__leave-feedback:after, .ajtix-wrapper .container__leave-feedback:before {
      background-image: none;
}
/*--------------------------------------------------------------------------------------------------- */
/*-------------------------------------- Feedback Survey page ---------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.ajtix-wrapper .section__survey .step3 .form-group__heading .brand-color{
      color: var(--bs-ajtix-red);
}

/*--------------------------------------------------------------------------------------------------- */
/*--------------------------------- Uptake & Uptake by Venue page ----------------------------------- */
/*--------------------------------------------------------------------------------------------------- */


.ajtix-wrapper .main__uptake .content__uptake .list__uptake-details .list-item__allocated .heading, 
.ajtix-wrapper .main__uptake .content__uptake .list__uptake-details .list-item__booked .heading {
      color: var(--bs-ajtix-red);
}

.ajtix-wrapper .main__uptake .list__uptake-details .heading {
      color: var(--bs-ajtix-red);
}

.ajtix-wrapper .main__uptake .content__uptake-event .p-important-wrapper {
      border-top-color: var(--bs-ajtix-red);
}

.ajtix-wrapper .main__uptake .progress-bar-overlay .progress-bar {
      background-color: var(--bs-ajtix-red);
}


/*--------------------------------------------------------------------------------------------------- */
/*------------------------------------ Reactive Account & Modal ------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.ajtix-wrapper .reactive-account #btnBackToStep3FromPayViaCard:hover{
      color: var(--bs-ajtix-red);
}

.ajtix-wrapper .reactive-account #btnContinueStep5 {
      background-color: var(--bs-ajtix-red);
      color: #fff;
}

/*--------------------------------------------------------------------------------------------------- */
/*------------------------------------------- OTP Modal -------------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.ajtix-wrapper .form-group__cta.d-flex .btn {
      background-color: var(--bs-ajtix-red);
      color: #fff;
      border-color: var(--bs-ajtix-red);
}

.ajtix-wrapper#modalVerificationCode .invalid-otp {
      color: var(--bs-ajtix-red);
}

/*--------------------------------------------------------------------------------------------------- */
/*---------------------------------- Underrepresented Communities ----------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.ajtix-wrapper .main__underrepresented_communities .owl-carousel__event-tags .badge-outline{
      color: var(--bs-ajtix-red);
      border-color: var(--bs-ajtix-red);
}

.ajtix-wrapper .main__underrepresented_communities .list-underrepresented-detail .heading {
      color: var(--bs-ajtix-red);
}

.ajtix-wrapper .card__uptake--carousel .logos-container .p-registered {
      padding-left: 98px;
}

@media (min-width: 576px) {
      .ajtix-wrapper .card__uptake--carousel .logos-container .p-registered {
            padding-left: 74px;
      }  
}

@media (min-width: 1200px) {
      .ajtix-wrapper .card__uptake--carousel .logos-container .p-registered {
            padding-left: 86px;
      }  
}


/*--------------------------------------------------------------------------------------------------- */
/*-------------------------------------- Member Feedback Report ------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.ajtix-wrapper .section__feedback-report .ct_bar_section {
      background-color: var(--bs-ajtix-red);
}

.ajtix-wrapper .section__feedback-report .ct_progress_sub {
      background-color: var(--bs-ajtix-red);
}

.ajtix-wrapper .section__feedback-report .ct_report_question_sub,
.ajtix-wrapper .section__feedback-report .ct_percentage {
      color: var(--bs-ajtix-red);
}

.ajtix-wrapper .ct_slider,
.ajtix-wrapper  .slider_div_right,
.ajtix-wrapper .slider_div_left{
      background-color: var(--bs-ajtix-red);
}

/*--------------------------------------------------------------------------------------------------- */
/*------------------------------------------ ETicket Page ------------------------------------------ */
/*--------------------------------------------------------------------------------------------------- */
.ajtix-wrapper .section__etickets .qr_container .download_btn_container .btn-group .download-ticket-btn {
      background-color: var(--bs-ajtix-red);
}

.ajtix-wrapper .section__etickets .help-link-block a.help-link {
      color: var(--bs-ajtix-red);
}

.ajtix-wrapper .section__etickets .button-card-wrapper .sharebtn {
      color:  var(--bs-ajtix-red);
}

/*--------------------------------------------------------------------------------------------------- */
/*------------------------------------------ Cookies Page ------------------------------------------ */
/*--------------------------------------------------------------------------------------------------- */
.ajtix-wrapper.modal-cookies .form-group__cta .btn.reject {
      background-color: transparent;
      color: var(--bs-ajtix-red);
}