
.main__landing .section__landing-hero {
      margin: 0;
      padding: 0;

}

.main__landing .section__landing-hero .container-fluid {
      margin: 0;
      padding: 0;
}

.main__landing .section__landing-hero .container-fluid .image-wrapper {
      width: 100%;
}

.main__landing .section__landing-hero .container-fluid .image-wrapper img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      
}




/* section__ecologi */
.main__landing .section__ecologi {
      margin: 0;
      padding: 0;
}

.main__landing .section__ecologi .container__block {
      margin: 0;
      padding: 50px 30px;
}

.main__landing .section__ecologi .content__ecologi .content__header h2{
      font-size: 58px;
      line-height: 65px;
}

.main__landing .section__ecologi .content__ecologi .content__header  [data-scroll].ticket.is-inview.ticket-aquamarine {
      transform: rotate(-50deg);
      top: -8%;
      left: 179px;
      position: absolute;
      width: 42px;
      height: auto;
      z-index: -1;
  }

.main__landing .section__ecologi .content__ecologi .content__header  [data-scroll].ticket.is-inview.ticket-green {
      transform: rotate(60deg);
      top: 87%;
      left: 169px;
      position: absolute;
      width: 58px;
      height: auto;
      z-index: -1;
  }

.main__landing .section__ecologi .content__ecologi .content__header  [data-scroll].ticket.is-inview.ticket-yellow {
      transform: rotate(45deg);
      top: 22%;
      left: 266px;
      position: absolute;
      width: 58px;
      height: auto;
      z-index: 1;
}

.main__landing .section__ecologi .content__ecologi .content__header [data-scroll].ticket.is-inview.ticket-blue {
      transform: rotate(5deg);
      top: 24%;
      left: 93px;
      position: absolute;
      width: 36px;
      height: auto;
      z-index: 1;
}

.main__landing .section__ecologi .content__ecologi .content__header [data-scroll].ticket.is-inview.ticket-orange {
      transform: rotate(-30deg);
      top: 91%;
      left: 1px;
      position: absolute;
      width: 32px;
      height: auto;
      z-index: -1;
}


.main__landing .section__ecologi .content__body {
      margin: 0;
      padding: 0;
}


.main__landing .section__ecologi .content__body .features__impact {
      margin: 0;
      padding: 0;
     
}

.main__landing .section__ecologi .content__body .feature.feature__impact {
      width: 150px;
}


.main__landing .section__ecologi .feature__impact .feature__icon {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 25px;
      padding-bottom: 25px;
      height: 120px;

}
.main__landing .section__ecologi .feature__impact  .feature__icon + .p-registered {
      position: absolute;
      top: 34%;
      text-align: center;
      left: 50%;
      width: 100%;
      text-align: center;
      padding-left: 14%;
      transform: translateX(-50%);
      font-size: 8px;
}

.main__landing .section__ecologi .feature__impact .feature__icon .icon {
      width: 100%;
}

.main__landing .section__ecologi .feature__impact .feature__icon h4 {
      /* color: var(--bs-red); */
      font-weight: bold;
      font-size: 45px;
      line-height: 52px;
      margin: 0;
      padding: 0;
}

.main__landing .section__ecologi .feature__impact .feature__body p{
      font-weight: bold;
      line-height: 17px;
}

@media(min-width:414px) {
      .main__landing .section__ecologi .content__body .feature.feature__impact {
            width: 160px;
      }
}


@media(max-width : 767px) {
      .main__landing .section__landing-hero .content__carousel-home-hero {
            margin-top: 55px;
            margin-bottom: 55px;
      }
      
      .main__landing .section__landing-hero .content__carousel-home-hero .content__body {
            display: flex;
            justify-content: center;
            align-items: center;
      }
      
      .main__landing .section__landing-hero .owl-carousel__home-hero .item {
            background-color: var(--bs-red);
            height: 460px;
            width: 80%;
            margin-left: 30px;
            border-radius: 40px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding-top: 25px;
            position: relative;
            margin-bottom: 240px;
      }     
      
      
      .main__landing .section__landing-hero .owl-carousel__home-hero .item h2 {
            color: #fff;
            font-size: 28px;
            line-height: 33px;
            text-align: center;
            position: static;
      }
      
      .main__landing .section__landing-hero .owl-carousel__home-hero .item .hero__image {
            position: absolute;
            top: 25%;
            left: -2%;
            width: 100%;
            height: auto;
            background-color: transparent;
      }
      
      .main__landing .section__landing-hero .owl-carousel__home-hero .owl-dots button span{
            background-color: var(--bs-red);
            opacity: 0.5;
      }     
      
      .main__landing .section__landing-hero .owl-carousel__home-hero .owl-dots .owl-dot.active span {
            opacity: 1 !important;
      }

      .main__landing .section__landing-steps {
            padding: 0;
            margin: 0;
      }

      .section__landing-steps .container__block {
            padding: 40px;
      }

      .section__landing-steps .content-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
      }

      .section__landing-steps .heading-container  {
            margin: 0;
            padding: 0;
      }

      .section__landing-steps .heading-container h1 {
            font-size: 38px;
            line-height: 43px;
            font-weight: bold;
      }

      .section__landing-steps .heading-container [data-scroll].ticket.is-inview.ticket-aquamarine {
            transform: rotate(109deg);
            top: 26%;
            left: 49px;
            position: absolute;
            width: 28px;
            height: auto;
            z-index: 1;
      }

      .section__landing-steps .heading-container [data-scroll].ticket.is-inview.ticket-yellow {
            transform: rotate(69deg);
            top: 56%;
            left: 35px;
            position: absolute;
            width: 28px;
            height: auto;
            z-index: 1;
      }

      .section__landing-steps .heading-container [data-scroll].ticket.is-inview.ticket-orange {
            transform: rotate(115deg);
            top: 90%;
            left: 239px;
            position: absolute;
            width: 34px;
            height: auto;
            z-index: 1;
      }

      .section__landing-steps .steps-container {
            padding: 0;
            margin: 0;
            margin-top: 30px;
      }

      .section__landing-steps .steps-container ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
      }

      .section__landing-steps .steps-container ul li {
            margin: 0;
            padding: 0;
            margin-left: -10px;
      }

      .section__landing-steps .steps-container ul li h4 {
            font-size: 20px;
            font-weight: bold;
            line-height: 28px;
            margin: 0;
            margin-bottom: 5px;
      }

      .section__landing-steps .icon-container {
            width: 60px;
            height: 60px;
            margin-top: 30px;
            margin-left: -10px;
      }

      .section__landing-steps .icon-container img {
            width: 100%;
            height: 100%;
      }


      /* section voucher */
      .section__voucher {
            margin: 0;
            padding: 0;
      }

      .section__voucher .container__block {
            margin: 0;
            padding: 0;
            padding: 15px 20px;

      }

      .section__voucher .content-container {
            margin: 0;
            padding: 0;
            background-color: var(--bs-red);
            border-radius: 40px;
            padding: 30px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
      }

      .section__voucher .content-container h1 {
            margin: 0;
            padding: 0;
            color: #fff;
            font-size: 54px;
            font-weight: bold;
            line-height: 60px;
      }

      .section__voucher .content-container p {
            color: #fff;
            margin: 0;
            padding: 0;
            font-size: 16px;
            line-height: 22px;
            margin-top: 30px;
      }

      .section__voucher .content-container p a {
            color: #fff;
      }

      

}

@media (max-width: 767px) {

      .main__landing .section__landing-hero .container-fluid .qr-container {
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 32px;
            padding-bottom: 32px;
      }

      .main__landing .section__landing-hero .container-fluid .qr-container .background-container {
            display: flex;
            flex-direction: column;
            padding: 0;
            margin: 0;
      }

      .main__landing .section__landing-hero .container-fluid .qr-container h2 {
            font-size: 38px;
            font-weight: 550;
      }

      .main__landing .section__landing-hero .container-fluid .qr-container h2 span {
            color: var(--bs-red);
      }

      .main__landing .section__landing-hero .container-fluid .qr-container p {
            font-size: 20px;
            line-height: 24px;
            margin-top: 24px;
      
      }

      .main__landing .section__landing-hero .container-fluid .qr-container hr {
            width: '100%';
            height: 2px;
            margin: 20px 0;
      }

      .main__landing .section__landing-hero .container-fluid .qr-code-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
      }
      
      

      .main__landing .section__landing-hero .container-fluid .qr-code-container .qr-image-wrapper {
            width: 35%;
      }

      .main__landing .section__landing-hero .container-fluid .qr-code-container .qr-image-wrapper img {
            width: 100%;
            height: 100%;
      }

      .main__landing .section__landing-hero .container-fluid .qr-code-container p {
            font-size: 12px;
            margin-top: 10px;
            margin-left: 30px;
      }

      .main__landing .section__landing-hero .container-fluid .hero-cta {
            margin-top: 42px;
            margin-bottom: 10px;
      }

      .main__landing .section__landing-hero .container-fluid .hero-cta .buttons-wrapper {
            flex-direction: row;
      } 

      .main__landing .section__landing-hero .container-fluid .hero-cta .buttons-wrapper {
            flex-direction: column;
      }


       
}

@media (min-width :768px) and (max-width: 1200px) {

      .main__landing .section__landing-hero .container-fluid .qr-container {
            padding: 40px;
            padding-top: 0;
            padding-bottom: 0;
            margin-top: 40px;
            margin-bottom: 40px;
      }

      .main__landing .section__landing-hero .container-fluid .qr-container .background-container {
            display: flex;
            padding: 0;
            flex-direction: row;
            width: 100%;
      }

      .main__landing .section__landing-hero .qr-container .background-container .text-container {
            width: 70%;
            border-right: 2px solid #000;
            padding-right: 15px;
      }

      .main__landing .section__landing-hero .container-fluid .qr-container .background-container h2 {
            /* font-size: 45px; */
            font-weight: 700;
            /* line-height: 50px; */
            line-height: 47px;
            font-size: 42px;
            margin-bottom: 16px;
      }

      .main__landing .section__landing-hero .container-fluid .qr-container h2 span {
            color: var(--bs-red);
      }

      

      .main__landing .section__landing-hero .container-fluid .qr-container .break-tag {
            display: inline !important;
      }

      .main__landing .section__landing-hero .container-fluid .qr-container p {
            font-size: 18px;
            line-height: 23px;
            margin-top: 16px;
      }
        

      .main__landing .section__landing-hero .qr-container .background-container hr {
            display: none;
      }

      .main__landing .section__landing-hero .container-fluid .qr-container .qr-code-container {
            display: flex;
            flex-direction: column;
            width: 30%;
            align-items: center;
      }

      .main__landing .section__landing-hero .container-fluid .qr-container .qr-image-wrapper {
            height: auto;
            margin-left: 16px;
            width: 156px;
      }

      .main__landing .section__landing-hero .container-fluid .qr-container .qr-image-wrapper img {
            width: 100%;
            height: 100%;
      }

      .main__landing .section__landing-hero .container-fluid .qr-container .qr-code-container p {
            margin: 0;
            padding: 0;
            font-size: 16px;
            line-height: 21px;
            width: 175px;
            margin-left: 55px;
      }

      .main__landing .section__landing-hero .container-fluid .qr-container .hero-cta {
            margin-top: 25px;
      }

      .main__landing .section__landing-hero .container-fluid .qr-container .hero-cta a {
            font-size: 20px;
            /* line-height: 48px; */
      }
      
}

@media  (min-width : 768px) {

      
      .section__landing-hero .content__carousel-home-hero .content__body-desktop {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: stretch;
            padding: 42px;
            margin-bottom: 18%;
      }

      .section__landing-hero .content__carousel-home-hero .content__body-desktop .item {
            width: 30%;
            background-color: var(--bs-red);
            position: relative;
            height: 350px;
            border-radius: 25px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            overflow: visible;
      }

      .section__landing-hero .content__carousel-home-hero .content__body-desktop .item .hero__image {
            flex: 1;
            height: auto;
            position: absolute;
            top: 28%;
            left: -2%;
      }

      .section__landing-hero .content__carousel-home-hero .content__body-desktop .item h2 {
            font-size: 28px;
            font-weight: bold;
            color: #fff;
            text-align: center;
            margin-top: 20px;
      }

      .main__landing .section__landing-hero .content__carousel-home-hero .content__body-desktop .item .hero__image img {
            width: 100%;
            height: 100%;
      }

      /* section landing steps */


      .main__landing .section__landing-steps {
            padding: 0;
            margin: 0;
      }

      .section__landing-steps .container__block {
            padding: 40px 30px;
      }

      .section__landing-steps .content-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            position: relative;
      }

      .section__landing-steps .heading-container  {
            margin: 0;
            padding: 0;
      }

      .section__landing-steps .heading-container h1 {
            font-size: 42px;
            line-height: 48px;
            font-weight: bold;
      }

      .section__landing-steps .heading-container [data-scroll].ticket.is-inview.ticket-aquamarine {
            transform: rotate(109deg);
            top: 38%;
            left: 180px;
            position: absolute;
            width: 28px;
            height: auto;
            z-index: 1;
        }

        .section__landing-steps .heading-container [data-scroll].ticket.is-inview.ticket-yellow {
            transform: rotate(69deg);
            top: 83%;
            left: 52px;
            position: absolute;
            width: 28px;
            height: auto;
            z-index: 1;
      }

      .section__landing-steps .heading-container [data-scroll].ticket.is-inview.ticket-orange {
            transform: rotate(135deg);
            top: 84%;
            left: 274px;
            position: absolute;
            width: 36px;
            height: auto;
            z-index: 1;
      }

      .section__landing-steps .heading-container h1 .one {
            display: none;
      }

      .section__landing-steps .steps-container {
            padding: 0;
            margin: 0;
            margin-bottom: 0;
      }

      .section__landing-steps .steps-container ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
      }

      .section__landing-steps .steps-container ul li {
            margin: 0;
            padding: 0;
            margin-left: 0;
      }

      .section__landing-steps .steps-container ul li h4 {
            font-size: 28px;
            line-height: 38px;
            font-weight: bold;
            margin: 0;
            margin-bottom: 5px;
      }

      .section__landing-steps .icon-container {
            position: absolute;
            width: 60px;
            height: 60px;
            margin-top: 30px;
            bottom: 10%;
      }

      .section__landing-steps .icon-container img {
            width: 100%;
            height: 100%;
      }


      /* section voucher */
      .section__voucher {
            margin: 0;
            padding: 0;
      }

      .section__voucher .container__block {
            margin: 0;
            padding: 0;
            padding: 30px 50px;

      }

      .section__voucher .content-container {
            margin: 0;
            padding: 0;
            background-color: var(--bs-red);
            border-radius: 24px;
            padding: 35px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
      }

      .section__voucher .content-container h1 {
            margin: 0;
            padding: 0;
            color: #fff;
            font-size: 62px;
            font-weight: bold;
            line-height: 70px;
      }

      .section__voucher .content-container h1 .one,
      .section__voucher .content-container h1 .two,
      .section__voucher .content-container h1 .three {
            display: none;
      }

      .section__voucher .content-container p {
            color: #fff;
            margin: 0;
            padding: 0;
            font-size: 25px;
            line-height: 32px;
            margin-top: 30px;
      }

      .section__voucher .content-container p a {
            color: #fff;
      }




      

      /* section__ecologi */

      .main__landing .section__ecologi .container__block {
            margin: 0;
            padding: 90px 30px;
      }

      .main__landing .section__ecologi .content__ecologi .content__header h2{
            font-size: 97px;
            line-height: 105px;
      }

      .main__landing .section__ecologi .content__ecologi .content__header  [data-scroll].ticket.is-inview.ticket-aquamarine {
            transform: rotate(-50deg);
            top: -6%;
            left: 361px;
            position: absolute;
            width: 62px;
            height: auto;
            z-index: -1;
      }
      
      .main__landing .section__ecologi .content__ecologi .content__header  [data-scroll].ticket.is-inview.ticket-green {
            transform: rotate(60deg);
            top: 88%;
            left: 342px;
            position: absolute;
            width: 80px;
            height: auto;
            z-index: -1;
      }
      
      .main__landing .section__ecologi .content__ecologi .content__header [data-scroll].ticket.is-inview.ticket-yellow {
            transform: rotate(45deg);
            top: 23%;
            left: 505px;
            position: absolute;
            width: 80px;
            height: auto;
            z-index: 1;
      }
      
      .main__landing .section__ecologi .content__ecologi .content__header [data-scroll].ticket.is-inview.ticket-blue {
            transform: rotate(5deg);
            top: 25%;
            left: 210px;
            position: absolute;
            width: 50px;
            height: auto;
            z-index: 1;
      }
      
      .main__landing .section__ecologi .content__ecologi .content__header [data-scroll].ticket.is-inview.ticket-orange {
            transform: rotate(-30deg);
            top: 91%;
            left: 63px;
            position: absolute;
            width: 48px;
            height: auto;
            z-index: -1;
      }


      .main__landing .section__ecologi .content__body .features__impact {
            column-gap: 50px;
            row-gap: 50px;
      }

      .main__landing .section__ecologi .content__body .feature.feature__impact {
            width: 300px;
      }


      .main__landing .section__ecologi .feature__impact .feature__icon {
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 25px;
            padding-bottom: 25px;
            height: 220px;

      }

      .main__landing .section__ecologi .feature__impact  .feature__icon + .p-registered {
            top: 34%;
            left: 50%;
            text-align: center;
            padding-left: 7%;
      }

      .main__landing .section__ecologi .feature__impact .feature__icon .icon {
            width: 100%;
      }

      .main__landing .section__ecologi .feature__impact .feature__icon h4 {
            font-size: 90px;
            line-height: 98px;
      }

      .main__landing .section__ecologi .feature__impact .feature__body p{
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
      }

}

@media (min-width: 820px) {
      .main__landing .section__ecologi .content__ecologi .content__header  .ticket-aquamarine {
            top: -6%;
            left: 382px;
      }

      .main__landing .section__ecologi .content__ecologi .content__header  .ticket-green {
            top: 88%;
            left: 360px;
      }

      .main__landing .section__ecologi .content__ecologi .content__header  .ticket-yellow {
            top: 23%;
            left: 527px;
      }

      .main__landing .section__ecologi .content__ecologi .content__header .ticket-blue {
            top: 25%;
            left: 232px;
      }

      .main__landing .section__ecologi .content__ecologi .content__header .ticket-orange {
            top: 92%;
            left: 82px;
      }
}


@media  (min-width : 991px) {

      .section__landing-hero .content__carousel-home-hero .content__body-desktop {
            padding: 42px;
            margin-bottom: 24%;
      }

      .section__landing-hero .content__carousel-home-hero .content__body-desktop .item {
            height: 430px;
            border-radius: 30px;
      }

      .section__landing-hero .content__carousel-home-hero .content__body-desktop .item .hero__image {
            flex: 1;
            height: auto;
            position: absolute;
            top: 28%;
            left: -2%;
      }

      .section__landing-hero .content__carousel-home-hero .content__body-desktop .item h2 {
            font-size: 32px;
            line-height: 37px;
            margin-top: 24px;
      }


       /* section landing steps */

      .section__landing-steps .heading-container h1 {
            font-size: 52px;
            line-height: 58px;
            font-weight: bold;
      }

      .section__landing-steps .heading-container [data-scroll].ticket.is-inview.ticket-aquamarine {
            transform: rotate(109deg);
            top: 38%;
            left: 224px;
            position: absolute;
            width: 38px;
            height: auto;
            z-index: 1;
      }

      .section__landing-steps .heading-container [data-scroll].ticket.is-inview.ticket-yellow {
            transform: rotate(69deg);
            top: 82%;
            left: 68px;
            position: absolute;
            width: 38px;
            height: auto;
            z-index: 1;
      }

      .section__landing-steps .heading-container [data-scroll].ticket.is-inview.ticket-orange {
            transform: rotate(135deg);
            top: 89%;
            left: 341px;
            position: absolute;
            width: 52px;
            height: auto;
            z-index: 1;
      }
     
      .section__landing-steps .steps-container ul li h4 {
            font-size: 32px;
            line-height: 42px;
            font-weight: bold;
            margin: 0;
            margin-bottom: 10px;
      }

      .section__landing-steps .icon-container {
            width: 80px;
            height: 80px;
            margin-top: 0;
            bottom: 7%;
      }


      /* section voucher */

      .section__voucher .container__block {
            margin: 0;
            padding: 0;
            padding: 40px 65px;

      }

      .section__voucher .content-container h1 {
            font-size: 80px;
            line-height: 90px;
      }

      .section__voucher .content-container p {
            font-size: 32px;
            line-height: 40px;
            margin-top: 40px;
      }


      /* section__ecologi */

      .main__landing .section__ecologi .container__block {
            margin: 0;
            padding: 60px 30px;
            padding-bottom: 0px;
      }

      .main__landing .section__ecologi .content__ecologi .content__header{
            margin-bottom: 85px;
      }
      .main__landing .section__ecologi .content__ecologi .content__header h2{
            font-size: 85px;
            line-height: 88px;
      }

      
      .main__landing .section__ecologi .content__ecologi .content__header  [data-scroll].ticket.is-inview.ticket-aquamarine {
            top: 102%;
            left: 304px;
            z-index: 1;
            width: 50px;
      }

      .main__landing .section__ecologi .content__ecologi .content__header  [data-scroll].ticket.is-inview.ticket-green {
            top: 88%;
            left: 81%;
            transform: rotate(120deg);
            z-index: 1;
            width: 70px;
      }

      .main__landing .section__ecologi .content__ecologi .content__header  [data-scroll].ticket.is-inview.ticket-yellow {
            top: -15%;
            left: 655px;
            width: 70px;
            transform: rotate(60deg);
            z-index: -1;
      }

      .main__landing .section__ecologi .content__ecologi .content__header [data-scroll].ticket.is-inview.ticket-blue {
            top: 38%;
            left: 438px;
            width: 42px;
            transform: rotate(15deg);
      }

      .main__landing .section__ecologi .content__ecologi .content__header [data-scroll].ticket.is-inview.ticket-orange {
            top: 44%;
            left: 127px;
            width: 32px;
            z-index: 1;
        }

      .main__landing .section__ecologi .content__ecologi .content__header h2 .one {
            display: none;
      }

      .main__landing .section__ecologi .content__body {
            margin-bottom: 105px;
      }

      .main__landing .section__ecologi .content__body .features__impact {
            column-gap: 25px;
            row-gap: 25px;
      }

      .main__landing .section__ecologi .content__body .feature.feature__impact {
            width: 280px;
      }


      .main__landing .section__ecologi .feature__impact .feature__icon {
            padding-left: 30px;
            padding-right: 30px;
            padding-top: 25px;
            padding-bottom: 25px;
            height: 170px;

      }

      .main__landing .section__ecologi .feature__impact  .feature__icon + .p-registered {
            top: 42%;
            left: 50%;
            text-align: center;
            padding-left: 12%;
      }

      .main__landing .section__ecologi .feature__impact .feature__icon .icon {
            width: 100%;
      }

      .main__landing .section__ecologi .feature__impact .feature__icon h4 {
            font-size: 90px;
            line-height: 98px;
      }

      .main__landing .section__ecologi .feature__impact .feature__body {
            padding: 10px;
      }

      .main__landing .section__ecologi .feature__impact .feature__body p{
            font-size: 16px;
            line-height: 22px;
            font-weight: 550;
      }

}


@media  (min-width : 1201px) {

      .section__landing-hero .content__carousel-home-hero .content__body-desktop {
            padding: 42px;
            margin-bottom: 26%;
      }

      .section__landing-hero .content__carousel-home-hero .content__body-desktop .item {
            height: 560px;
            border-radius: 30px;
      }

      .section__landing-hero .content__carousel-home-hero .content__body-desktop .item .hero__image {
            flex: 1;
            height: auto;
            position: absolute;
            top: 32%;
            left: -2%;
      }

      .section__landing-hero .content__carousel-home-hero .content__body-desktop .item h2 {
            font-size: 51px;
            line-height: 57px;
            margin-top: 30px;
      }



       
      


}




@media (min-width : 1201px) {
      
      .main__landing .section__landing-hero .container-fluid {
            position: relative;
      }

      .main__landing .section__landing-hero .container-fluid .qr-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 60%;
            margin-top: 50px;
            display: flex;
            flex-direction: column;
            /* margin-left: 80px; */
            /* max-width: 830px; */
            margin-left: 60px;
            max-width: 54%;
      }

      .main__landing .section__landing-hero .container-fluid .qr-container .background-container {
            background-color: var(--bs-white);
            border-radius: 25px;
            display: flex;
            padding: 30px 25px;
            flex: 1;
      }

      .main__landing .section__landing-hero .container-fluid .qr-container .background-container h2 {
            /* font-size: 45px; */
            font-weight: 700;
            /* line-height: 50px; */
            line-height: 47px;
            font-size: 42px;
            margin-bottom: 16px;
      }

      .main__landing .section__landing-hero .container-fluid .qr-container h2 span {
            color: var(--bs-red);
      }

      .main__landing .section__landing-hero .container-fluid .qr-container .break-tag {
            display: inline !important;
      }

      

      .main__landing .section__landing-hero .container-fluid .qr-container p {
            /* font-size: 21px; */
            /* line-height: 27px; */
            /* margin-top: 24px; */
            
            font-size: 18px;
            line-height: 23px;
            margin-top: 16px;
      }

      .main__landing .section__landing-hero .container-fluid .qr-container .background-container hr {
            width: '100%';
            height: 2px;
            margin: 20px 0;
      }

      .main__landing .section__landing-hero .container-fluid .qr-code-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
      }

      .main__landing .section__landing-hero .container-fluid .qr-code-container .qr-image-wrapper {
            width: 35%;
      }
      
      .main__landing .section__landing-hero .container-fluid .qr-code-container .qr-image-wrapper img {
            width: 100%;
            height: 100%;
      }
      

      .main__landing .section__landing-hero .container-fluid .qr-code-container p {
            font-size: 12px;
            margin-top: 10px;
            margin-left: 30px;
      }

      .main__landing .section__landing-hero .container-fluid .qr-container .text-container {
            border-right: 2px solid #000;
            width: 70%;
            padding-right: 8px;
      }

      .main__landing .section__landing-hero .container-fluid .qr-container .qr-code-container {
            display: flex;
            flex-direction: column;
            width: 30%;
            /* align-items: flex-start; */
            align-items: center;

      }

      .main__landing .section__landing-hero .container-fluid .qr-container .qr-image-wrapper {
            /* width: 175px; */
            height: auto;
            /* margin-left: 30px; */
            margin-left: 16px;
            width: 156px;
      }

      .main__landing .section__landing-hero .container-fluid .qr-container .qr-image-wrapper img {
            width: 100%;
            height: 100%;
      }

      .main__landing .section__landing-hero .container-fluid .qr-container .qr-code-container p {
            margin: 0;
            padding: 0;
            font-size: 16px;
            line-height: 21px;
            /* margin-left: 44px; */
            width: 175px;
            margin-left: 55px;
      }

      .main__landing .section__landing-hero .container-fluid .qr-container .hero-cta {
            /* margin-top: 35px; */
            margin-top: 25px;
      }

      .main__landing .section__landing-hero .container-fluid .qr-container .hero-cta a {
            font-size: 23px;
            line-height: 48px;
      }

      .main__landing .section__landing-hero .container-fluid .qr-container .hero-cta .btn{
            background-color: #fff;
            color: var(--bs-red);
      }
      
      .main__landing .section__landing-hero .container-fluid .qr-container .hero-cta .icon-apple-as-bg {
            background-image: url(../../assets/images/icon-apple-red.png);
      }

      .main__landing .section__landing-hero .container-fluid .qr-container .hero-cta .icon-googleplay-as-bg {
            background-image: url(../../assets/images/icon-google-play-store-red.png);
      }

      .main__landing .section__landing-hero .container-fluid .qr-container .hero-cta .btn:hover {
            background-color: var(--bs-red);
            color: #fff;
      }

      .main__landing .section__landing-hero .container-fluid .qr-container .hero-cta .btn:hover  .icon-apple-as-bg {
            background-image: url(../../assets/images/icon-apple-white.png);
      }

      .main__landing .section__landing-hero .container-fluid .qr-container .hero-cta .btn:hover .icon-googleplay-as-bg  {
            background-image: url(../../assets/images/icon-google-play-store-white.png);
      }


      /* section landing steps */
      
      .section__landing-steps .heading-container h1 {
            font-size: 65px;
            line-height: 72px;
            font-weight: bold;
      }

      .section__landing-steps .heading-container [data-scroll].ticket.is-inview.ticket-aquamarine {
            transform: rotate(-60deg);
            top: 56%;
            left: 216px;
            position: absolute;
            width: 48px;
            height: auto;
            z-index: 1;
      }

      .section__landing-steps .heading-container [data-scroll].ticket.is-inview.ticket-orange {
            transform: rotate(-46deg);
            top: 94%;
            left: 350px;
            position: absolute;
            width: 66px;
            height: auto;
            z-index: 1;
      }

      
      .section__landing-steps .heading-container [data-scroll].ticket.is-inview.ticket-yellow {
            transform: rotate(69deg);
            top: 82%;
            left: 86px;
            position: absolute;
            width: 48px;
            height: auto;
            z-index: 1;
      }


      .section__landing-steps .steps-container ul li h4 {
            font-size: 32px;
            line-height: 42px;
            font-weight: bold;
            margin: 0;
            margin-bottom: 15px;
      }

      .section__landing-steps .icon-container {
            position: absolute;
            width: 90px;
            height: 90px;
            margin-top: 0;
            bottom: 7%;
      }


      /* section voucher */

      .section__voucher .container__block {
            margin: 0;
            padding: 0;
            padding: 50px 60px;

      }

      .section__voucher .content-container {
            border-radius: 24px;
            padding: 50px;
      }

      .section__voucher .content-container h1 {
            font-size: 95px;
            line-height: 105px;
      }

      .section__voucher .content-container p {
            font-size: 38px;
            line-height: 47px;
            letter-spacing: 2px;
            margin-top: 40px;
      }

      /* section__ecologi */
      .main__landing .section__ecologi .container__block {
            margin: 0;
            padding: 60px 30px;
            padding-bottom: 0px;
      }

      .main__landing .section__ecologi .content__ecologi .content__header{
            margin-bottom: 85px;
            display: flex;
            justify-content: center;
      }

      .main__landing .section__ecologi .content__ecologi .content__header .big-heading {
            width: 950px;
      }

      .main__landing .section__ecologi .content__ecologi .content__header h2{
            font-size: 85px;
            line-height: 93px;
      }

      .main__landing .section__ecologi .content__ecologi .content__header  [data-scroll].ticket.is-inview.ticket-aquamarine {
            top: 100%;
            left: 295px;
            z-index: 1;
            width: 50px;
      }

      .main__landing .section__ecologi .content__ecologi .content__header  [data-scroll].ticket.is-inview.ticket-green {
            top: 86%;
            left: 81%;
            transform: rotate(120deg);
            z-index: 1;
            width: 70px;
      }

      .main__landing .section__ecologi .content__ecologi .content__header  [data-scroll].ticket.is-inview.ticket-yellow {
            top: -14%;
            left: 68%;
            width: 70px;
            transform: rotate(60deg);
            z-index: -1;
      }

      .main__landing .section__ecologi .content__ecologi .content__header [data-scroll].ticket.is-inview.ticket-blue {
            top: 36%;
            left: 434px;
            width: 42px;
            transform: rotate(5deg);
      }

      .main__landing .section__ecologi .content__ecologi .content__header [data-scroll].ticket.is-inview.ticket-orange {
            top: 43%;
            left: 13%;
            width: 32px;
      }

      .main__landing .section__ecologi .content__ecologi .content__header h2 .one {
            display: none;
      }

      .main__landing .section__ecologi .content__body {
            margin-bottom: 105px;
      }

      .main__landing .section__ecologi .content__body .features__impact {
            column-gap: 25px;
            row-gap: 25px;
      }

      .main__landing .section__ecologi .content__body .feature.feature__impact {
            width: 340px;
      }


      .main__landing .section__ecologi .feature__impact .feature__icon {
            padding-left: 35px;
            padding-right: 35px;
            padding-top: 35px;
            padding-bottom: 35px;
            height: 200px;

      }

      .main__landing .section__ecologi .feature__impact .feature__icon .icon {
            width: 250px;
      }

      .main__landing .section__ecologi .feature__impact .feature__icon h4 {
            font-size: 90px;
            line-height: 98px;
      }

      .main__landing .section__ecologi .feature__impact .feature__body {
            padding: 25px;
      }

      .main__landing .section__ecologi .feature__impact .feature__body p{
            font-size: 20px;
            line-height: 26px;
            font-weight: 550;
      }

      

}

@media (min-width : 1401px) {

      .main__landing .section__landing-hero .container-fluid .qr-container {
            width: 60%;
            margin-top: 50px;
            margin-left: 60px;
            max-width: 55%;
      }

      .main__landing .section__landing-hero .container-fluid .qr-container .text-container {
            padding-right: 10px;
      }

      .main__landing .section__landing-hero .container-fluid .qr-container .background-container h2 {
            font-size: 45px;
            line-height: 50px;
            margin-bottom: 20px;
      }
      .main__landing .section__landing-hero .container-fluid .qr-container p {
            font-size: 21px;
            line-height: 27px;
            margin-top: 24px;
      }

      .main__landing .section__landing-hero .container-fluid .qr-container .qr-image-wrapper {
            width: 175px;
            height: auto;
            margin-left: 25px;
      }

      .main__landing .section__landing-hero .container-fluid .qr-container .hero-cta {
            margin-top: 35px;
      }

      .section__landing-hero .content__carousel-home-hero .content__body-desktop {
            padding: 42px;
            margin-bottom: 22%;
      }

      .section__landing-hero .content__carousel-home-hero .content__body-desktop .item {
            height: 640px;
            border-radius: 30px;
      }

      .section__landing-hero .content__carousel-home-hero .content__body-desktop .item .hero__image {
            flex: 1;
            height: auto;
            position: absolute;
            top: 28%;
            left: -2%;
      }

      .section__landing-hero .content__carousel-home-hero .content__body-desktop .item h2 {
            font-size: 51px;
            line-height: 57px;
            margin-top: 30px;
      }


       /* section landing steps */

       .section__landing-steps .container__block {
            padding: 80px 60px;
      }
      
       .section__landing-steps .heading-container h1 {
            font-size: 85px;
            line-height: 93px;
            font-weight: bold;
      }

      .section__landing-steps .heading-container [data-scroll].ticket.is-inview.ticket-yellow {
            transform: rotate(69deg);
            top: 84%;
            left: 108px;
            position: absolute;
            width: 48px;
            height: auto;
            z-index: 1;
      }

      .section__landing-steps .heading-container [data-scroll].ticket.is-inview.ticket-aquamarine {
            transform: rotate(-60deg);
            top: 54%;
            left: 289px;
            position: absolute;
            width: 56px;
            height: auto;
            z-index: 1;
      }

      .section__landing-steps .heading-container [data-scroll].ticket.is-inview.ticket-orange {
            transform: rotate(-46deg);
            top: 90%;
            left: 460px;
            position: absolute;
            width: 66px;
            height: auto;
            z-index: 1;
      }


      .section__landing-steps .steps-container ul li h4 {
            font-size: 50px;
            line-height: 65px;
            font-weight: bold;
            margin: 0;
            margin-bottom: 15px;
      }

      .section__landing-steps .icon-container {
            position: absolute;
            width: 100px;
            height: 100px;
            margin-top: 0;
            bottom: 10%;
      }

      /* section voucher */
      .section__voucher .container__block {
            margin: 0;
            padding: 0;
            padding: 60px 70px;

      }


      .section__voucher .content-container h1 {
            font-size: 112px;
            line-height: 124px;
      }

      .section__voucher .content-container p {
            font-size: 42px;
            line-height: 50px;
            letter-spacing: 2px;
            margin-top: 45px;
      }

      /* section__ecologi */
      .main__landing .section__ecologi .container__block {
            margin: 0;
            padding: 90px 30px;
            padding-bottom: 0px;
      }

      .main__landing .section__ecologi .content__ecologi .content__header{
            margin-bottom: 115px;
            display: flex;
            justify-content: center;
      }

      .main__landing .section__ecologi .content__ecologi .content__header .big-heading {
            width: 1250px;
      }

      .main__landing .section__ecologi .content__ecologi .content__header h2{
            font-size: 122px;
            line-height: 132px;
      }

      .main__landing .section__ecologi .content__ecologi .content__header  [data-scroll].ticket.is-inview.ticket-aquamarine {
            top: 100%;
            left: 30%;
            z-index: 1;
            width: 70px;
      }

      .main__landing .section__ecologi .content__ecologi .content__header  [data-scroll].ticket.is-inview.ticket-green {
            top: 87%;
            left: 83%;
            transform: rotate(120deg);
            z-index: 1;
            width: 85px;
      }

      .main__landing .section__ecologi .content__ecologi .content__header [data-scroll].ticket.is-inview.ticket-yellow {
            top: -8%;
            left: 70%;
            width: 85px;
            transform: rotate(60deg);
            z-index: -1;
      }

      .main__landing .section__ecologi .content__ecologi .content__header [data-scroll].ticket.is-inview.ticket-blue {
            top: 36%;
            left: 574px;
            width: 46px;
            transform: rotate(5deg);
      }

      .main__landing .section__ecologi .content__ecologi .content__header [data-scroll].ticket.is-inview.ticket-orange {
            top: 43%;
            left: 10%;
            width: 42px;
      }

      .main__landing .section__ecologi .content__ecologi .content__header h2 .one {
            display: none;
      }

      .main__landing .section__ecologi .content__body {
            margin-bottom: 130px;
      }

      .main__landing .section__ecologi .content__body .features__impact {
            column-gap: 30px;
            row-gap: 30px;
      }

      .main__landing .section__ecologi .content__body .feature.feature__impact {
            width: 390px;
      }


      .main__landing .section__ecologi .feature__impact .feature__icon {
            padding-left: 35px;
            padding-right: 35px;
            padding-top: 35px;
            padding-bottom: 35px;
            height: 230px;

      }

      .main__landing .section__ecologi .feature__impact  .feature__icon + .p-registered {
            top: 34%;
            left: 50%;
            text-align: center;
            padding-left: 14%;
      }

      .main__landing .section__ecologi .feature__impact .feature__icon .icon {
            width: 280px;
      }

      .main__landing .section__ecologi .feature__impact .feature__icon h4 {
            font-size: 125px;
            line-height: 135px;
      }

      .main__landing .section__ecologi .feature__impact .feature__body {
            padding: 40px;
      }

      .main__landing .section__ecologi .feature__impact .feature__body p{
            font-size: 24px;
            line-height: 30px;
            font-weight: 550;
      }


}

@media (min-width : 1600px) {

      .main__landing .section__landing-hero .container-fluid .qr-container {
            max-width: 835px;
            margin-left: 80px;
      }

      .main__landing .section__landing-hero .container-fluid .qr-container .background-container {
            padding: 30px 30px;
      }

      .section__landing-hero .content__carousel-home-hero .content__body-desktop {
            padding: 42px;
            margin-bottom: 22%;
      }

      .section__landing-hero .content__carousel-home-hero .content__body-desktop .item {
            height: 680px;
            border-radius: 40px;
      }

      .section__landing-hero .content__carousel-home-hero .content__body-desktop .item .hero__image {
            flex: 1;
            height: auto;
            position: absolute;
            top: 26%;
            left: -2%;
      }

      .section__landing-hero .content__carousel-home-hero .content__body-desktop .item h2 {
            font-size: 51px;
            margin-top: 30px;
      }

      /* section voucher */
      .section__voucher .container__block {
            padding: 80px 150px;
      }

      .section__voucher .content-container { 
            border-radius: 48px;
            padding: 80px;
      }

      .section__voucher .content-container h1 {
            font-size: 122px;
            line-height: 135px;
      }

      .section__voucher .content-container h1 .two {
            display: block;
      }

      .section__voucher .content-container p {
            font-size: 45px;
            line-height: 66px;
            /* letter-spacing: 0px; */
            word-spacing: 0px;
            margin-top: 60px;
        }

      /* section ecologi */
      .main__landing .section__ecologi .container__block {
            margin: 0;
            padding: 120px 50px;
            padding-bottom: 0px;
      }

      .main__landing .section__ecologi .content__ecologi .content__header{
            margin-bottom: 130px;
      }

      .main__landing .section__ecologi .content__ecologi .content__header .big-heading {
            width: 1350px;
      }

      .main__landing .section__ecologi .content__ecologi .content__header h2{
            font-size: 130px;
            line-height: 140px;
            /* letter-spacing: 3px; */
      }
     

      .main__landing .section__ecologi .content__ecologi .content__header  [data-scroll].ticket.is-inview.ticket-aquamarine {
            top: 103%;
            left: 29%;
            z-index: 1;
            width: 80px;
      }

      .main__landing .section__ecologi .content__ecologi .content__header  [data-scroll].ticket.is-inview.ticket-green {
            top: 87%;
            left: 83%;
            transform: rotate(120deg);
            z-index: 1;
            width: 105px;
      }

      .main__landing .section__ecologi .content__ecologi .content__header  [data-scroll].ticket.is-inview.ticket-yellow {
            top: -12%;
            left: 70%;
            width: 105px;
            transform: rotate(60deg);
            z-index: -1;
      }

      .main__landing .section__ecologi .content__ecologi .content__header [data-scroll].ticket.is-inview.ticket-blue {
            top: 38%;
            left: 619px;
            width: 60px;
            transform: rotate(5deg);
      }

      .main__landing .section__ecologi .content__ecologi .content__header [data-scroll].ticket.is-inview.ticket-orange {
            top: 44%;
            left: 10%;
            width: 46px;
      }

      .main__landing .section__ecologi .content__ecologi .content__header h2 .one {
            display: none;
      }

      .main__landing .section__ecologi .content__body {
            margin-bottom: 160px;
      }

      .main__landing .section__ecologi .content__body .features__impact {
            column-gap: 40px;
            row-gap: 40px;
      }

      .main__landing .section__ecologi .content__body .feature.feature__impact {
            width: 505px;
      }


      .main__landing .section__ecologi .feature__impact .feature__icon {
            padding: 0;
            height: 290px;

      }

      .main__landing .section__ecologi .feature__impact  .feature__icon + .p-registered {
            top: 38%;
            left: 50%;
            text-align: center;
            padding-left: 18%;
            font-size: 12px;
      }
      

      .main__landing .section__ecologi .feature__impact .feature__icon .icon {
            width: 330px;
      }

      .main__landing .section__ecologi .feature__impact:first-child .feature__icon .icon {
            width: 317px;
      }

   

      .main__landing .section__ecologi .feature__impact .feature__icon h4 {
            font-size: 130px;
            line-height: 140px;
      }

      .main__landing .section__ecologi .feature__impact .feature__body {
            padding: 50px;
      }

      .main__landing .section__ecologi .feature__impact .feature__body p{
            font-size: 26px;
            line-height: 33px;
            font-weight: 550;
      }

}


@media (min-width : 1800px) {
      .section__landing-hero .content__carousel-home-hero .content__body-desktop {
            padding: 42px;
            margin-bottom: 24%;
      }

      .section__landing-hero .content__carousel-home-hero .content__body-desktop .item {
            height: 750px;
            border-radius: 40px;
            max-width: 548px;
      }

      .section__landing-hero .content__carousel-home-hero .content__body-desktop .item .hero__image {
            flex: 1;
            height: auto;
            position: absolute;
            top: 26%;
            left: -2%;
      }

      .section__landing-hero .content__carousel-home-hero .content__body-desktop .item h2 {
            font-size: 51px;
            line-height: 57px;
            margin-top: 30px;
      }

}