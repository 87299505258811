
.OwlCarouselEventsItemDummy .card__event .card__event-image .event-image {
      width: 280px;
      height: 141px;
      border-radius: 30px;
}

.OwlCarouselEventsItemDummy .card__event .card__event-image .list__event-tags li {
      width: 102px;
      height: 23px;
      background: #fff !important;
      border-radius: 5px;
}

.OwlCarouselEventsItemDummy .card__event .card__event-image .event-image,
.OwlCarouselEventsItemDummy .card__event .card__event-image .list__event-tags li {
      background: #eee;
      background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
      -webkit-animation: shine 1.5s linear infinite;

}

@media (min-width:720px){
      .OwlCarouselEventsItemDummy .card__event .card__event-image .event-image {
            width: 300px;
            height: 151px;
      }
      
}     

@media (min-width:1000px) {
      .OwlCarouselEventsItemDummy .card__event .card__event-image .list__event-tags li {
            width: 102px;
            height: 26px;
      }
}


@media (min-width: 1200px) {
      
      .OwlCarouselEventsItemDummy .card__event .card__event-image .event-image {
            width: 360px;
            height: 181px;
      }

      .OwlCarouselEventsItemDummy .card__event .card__event-image .list__event-tags li {
            width: 115px;
            height: 27px;
      }
      
}

@media (min-width: 1400px) {
     
      .OwlCarouselEventsItemDummy .card__event .card__event-image .event-image {
            width: 420px;
            height: 212px;
      }
}

@media (min-width:1600px){
      .OwlCarouselEventsItemDummy .card__event .card__event-image .event-image {
            width: 536px;
            height: 270px;
      }
}

@keyframes shine {
      to {
            background-position-x: -200%;
      }
}
      
@keyframes move {

      0%, 100% { 
            left: 0px;
      }
      20% , 60%{
            left: 15px;
      }
      40% , 80%{
            left: -15px;
      }
}

    