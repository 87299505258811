
.MyBookingItemDummy .card__event .card__event-image .event-image {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      min-height: 100px;
}

.MyBookingItemDummy .card__event .card-body .card-title {
      width: 100%;
      height: 15px;
}

.MyBookingItemDummy .card__event .card-body  .list__event-details li {
      width: 80%;
      height: 15px;
      margin-top: 0.2rem;
}

.MyBookingItemDummy .card__event .card-footer .btn.btn-primary,
.MyBookingItemDummy .card__event .card-footer .btn-view-ticket,
.MyBookingItemDummy .card__event .card-footer .btn-manage-booking {
      height: 34px;
      border: #eee;
}

.MyBookingItemDummy .card__event .card__event-image .event-image, 
.MyBookingItemDummy .card__event .card-body .card-title,
.MyBookingItemDummy .card__event .card-body  .list__event-details li,
.MyBookingItemDummy .card__event .card-footer .btn.btn-primary,
.MyBookingItemDummy .card__event .card-footer .btn-view-ticket,
.MyBookingItemDummy .card__event .card-footer .btn-manage-booking
{
      background: #eee;
      background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
      -webkit-animation: shine 1.5s linear infinite;

}

@media (min-width:720px){
      .MyBookingItemDummy .card__event .card__event-image .event-image {
            min-height: 137px;
      }

      .MyBookingItemDummy .card__event .card-body .card-title {
            width: 100%;
            height: 34px;
      }

      .MyBookingItemDummy .card__event .card-body  .list__event-details li {
            height: 26px;
            width: 65%;
      }
      
      .MyBookingItemDummy .card__event .card-footer .btn.btn-primary,
      .MyBookingItemDummy .card__event .card-footer .btn-view-ticket,
      .MyBookingItemDummy .card__event .card-footer .btn-manage-booking {
            height: 42px;
      }
      
}

@media (min-width:1024px){
      .MyBookingItemDummy .card__event .card__event-image .event-image {
            min-height: 158px;
      }

      .MyBookingItemDummy .card__event .card-body  .list__event-details li {
            height: 32px;
            width: 65%;
      }
      
}


@media (min-width: 1200px) {
      
}

@media (min-width:1700px){
      .MyBookingItemDummy .card__event .card__event-image .event-image {
            min-height: 190px;
      }

      .MyBookingItemDummy .card__event .card-body .card-title {
            width: 100%;
            height: 40px;
      }

      .MyBookingItemDummy .card__event .card-body  .list__event-details li {
            height: 40px;
            width: 65%;
      }
      
}

@keyframes shine {
      to {
            background-position-x: -200%;
      }
}
      
@keyframes move {

      0%, 100% { 
            left: 0px;
      }
      20% , 60%{
            left: 15px;
      }
      40% , 80%{
            left: -15px;
      }
}

    