
.event-dummyImage.event-image {
      width: 100%;
      height: 180px;
      border-radius: 0px;
}

.event__name-dummyTitle {
      height: 25px;
}

.event__location.event__location-dummyLocation {
      height: 20px;
}

.list__event-dummyTags {
      width: 62px;
      height: 19px;
      border-radius: 50px;
}

.list__event-dummyTags.tabInfo {
      width: 80px;
      height: 24px;
}

.event__body-dummyDescription p{
      height:300px;
      margin-bottom: 1rem;
}

.nav-link.nav-dummylink {
      height: 100%;
      width: 100%;
      min-height: 37px;
}

.nav-link.nav-dummylink.borderBottom {
      border-bottom: none;
}

.list-group-dummyItem .icon-wrapper-dummy {
      height: 16px;
      width: 16px;
}

.list-group-dummyItem .dummyP {
      height: 20px !important;
      width:100%;
}

.heading.dummyHeading {
      height: 20px;
}

.collection-instruction-dummy {
      height: 140px;
}

.btn.dummyButton {
      height: 60px;
      border-color: #fff;
}

.event-dummyImage.event-image,
.event__name.event__name-dummyTitle,
.event__location.event__location-dummyLocation,
.list__event-dummyTags ,
.event__body-dummyDescription,
.nav-link.nav-dummylink,
.list-group-dummyItem .icon-wrapper-dummy,
.list-group-dummyItem .dummyP,
.heading.dummyHeading,
.collection-instruction-dummy,
.btn.dummyButton 
{
      background: #eee;
      background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
      -webkit-animation: shine 1.5s linear infinite;

}

@media (min-width:390px){
      .event-dummyImage.event-image {
            height: 188px;
      }
}


@media (min-width:412px){
      .event-dummyImage.event-image {
            height: 201px;
      }

      .collection-instruction-dummy {
            height: 120px;
      }
}

@media (min-width:540px){
      
      .event-dummyImage.event-image {
            height: 268px;
      }

}

@media (min-width:720px){
      
      .event-dummyImage.event-image {
            height: 390px;
      }

      .event__name-dummyTitle {
            height: 40px;
      }

      .event__location.event__location-dummyLocation {
            height: 25px;
      }

      .list__event-dummyTags {
            width: 77px;
            height: 28px;
      }

      .event__body-dummyDescription p{
            height: 300px;
      }
      
      .list-group-dummyItem .dummyP {
            height: 19px ;
            width:40%;
      }

      .collection-instruction-dummy {
            height: 60px;
      }

}

@media (min-width:912px){
      
      .event-dummyImage.event-image {
            height: 467px;
      }

}

@media (min-width:1024px){
      .event-dummyImage.event-image {
            height: 305px;
      }

      .event__body-dummyDescription p{
            height: 300px;
      }

      .nav-link.nav-dummylink {
            height: 43px;
            width: 155px;
      }

      .list-group-dummyItem .dummyP {
            height: 19px !important;
            width:100%;
      }

      .collection-instruction-dummy {
            height: 260px;
      }

      .btn.dummyButton {
            height: 45px;
      }

      .list__event-dummyTags.tabInfo {
            width: 81px;
            height: 28px;
      }
      
}


@media (min-width: 1200px) {
      .event-dummyImage.event-image {
            height: 192px;
      }

      .event__name-dummyTitle {
            height: 80px;
      }

      .list__event-dummyTags {
            width: 102px;
            height: 35px;
      }
      .event__body-dummyDescription p{
            height: 550px;
      }
      
      .list-group-dummyItem .icon-wrapper-dummy {
            height: 26px;
            width: 26px;
      }

      .list-group-dummyItem .dummyP {
            height: 30px !important;
            width:100%;
      }
      .heading.dummyHeading {
            height: 25px;
      }

      .collection-instruction-dummy {
            height: 234px;
      }

      .btn.dummyButton {
            height: 52px;
      }
      
}

@media (min-width: 1400px) {
      .collection-instruction-dummy {
            height: 208px;
      }
      
    
}

@media (min-width:1700px){
      .event-dummyImage.event-image {
            height: 244px;
      }

      .event__name-dummyTitle {
            height: 120px;
      }

      .event__body-dummyDescription p{
            height: 550px;
      }

      .nav-link.nav-dummylink {
            width: 157px;
            height: 55px;
      }

      .list-group-dummyItem .dummyP {
            margin-bottom: 0px;
      }

      .collection-instruction-dummy {
            height: 182px;
      }

      .btn.dummyButton {
            height: 61px;
      }
}

@keyframes shine {
      to {
            background-position-x: -200%;
      }
}
      
@keyframes move {

      0%, 100% { 
            left: 0px;
      }
      20% , 60%{
            left: 15px;
      }
      40% , 80%{
            left: -15px;
      }
}

    