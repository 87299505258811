/* ==========================================================================
   Responsive
   ========================================================================== */
@media (min-width: 576px) {
  .content__NHSCT .btn {
    border-width: 1px;
    min-width: 350px;
  }

  .section__contact-us .form-group__cta.flex-sm-row {
    column-gap: 30px;
  }

  /*!
       * .content__my-wallet 576
       */
  .content__my-wallet .p-top-up .btn {
    margin-left: 20px;
  }

  /*!
       * .content__uptake 576
       */
  .card__uptake--details .list__uptake-details + .buttons-wrapper {
    margin-top: 0;
  }

  .card__uptake--details .list__uptake-details {
    width: 70%;
  }

  .card__uptake--details .buttons-wrapper {
    width: 30%;
  }

  .card__uptake--carousel .logos-wrapper,
  .card__uptake--carousel .logos-container .p-registered {
    width: 294px;
    margin-left: auto;
    margin-right: auto;
  }

  .card__uptake--carousel .logos-container .p-registered {
    padding-left: 0;
  }

  .card__uptake--details .list__uptake-details + .buttons-wrapper {
    width: 200px;
    justify-content: end;
    padding-right: 10px;
  }

  .card__uptake--details .btn {
    min-width: 113px;
    padding-left: 26px;
    padding-right: 26px;
  }

  .modal-dialog {
    margin-left: auto;
    margin-right: auto;
  }

  .modal__modal__almost-done .modal-dialog,
  .modal__my-account-delete-card .modal-dialog {
    max-width: 420px;
  }

  /*!
       * .section__reactive-account 576
       */
  .content__reactive-account .big-heading h1,
  .content__reactive-account .big-heading .h1 {
    font-size: 10vw;
  }
}

/* END 576*/
@media (min-width: 768px) {
  .border-top-md-0 {
    border-top-width: 0 !important;
  }

  .border-bottom-md-0 {
    border-bottom-width: 0 !important;
  }

  .border-top-md {
    border-top: 1px solid #000 !important;
  }

  .border-right-md {
    border-right: 1px solid #000 !important;
  }

  .border-bottom-md {
    border-bottom: 1px solid #000 !important;
  }

  .border-left-md {
    border-left: 1px solid #000 !important;
  }

  .d-flex.two-cols-md > * {
    flex-shrink: 0;
    width: 50%;
  }

  .d-flex.two-cols-md.gap-4 > * {
    width: calc(50% - 0.75rem);
  }

  .letter-row .letter {
    font-size: 56px;
    margin-left: -4px;
  }

  .datepicker-dropdown,
  .datepicker-inline {
    width: 282px;
  }

  h1,
  .h1 {
    font-size: 5vw;
  }

  h2,
  .h2 {
    font-size: 3.5vw;
  }

  .big-heading h1,
  .big-heading .h1,
  .big-heading h2,
  .big-heading .h2 {
    font-size: 6.3vw;
    line-height: 1em;
  }

  .container__block,
  .container__block > .row > * {
    padding-right: 0;
    padding-left: 0;
  }

  .container__block > .row {
    margin-right: 0;
    margin-left: 0;
  }

  .accordion-line-separated .accordion-body {
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 26px;
  }

  .form-label {
    margin-bottom: 10px;
  }

  .form-group,
  .form-groups-wrapper {
    margin-bottom: 26px;
  }

  .form-groups-wrapper.flex-lg-row,
  .form-groups-wrapper.flex-xl-row,
  .form-groups-wrapper.flex-xxl-row {
    margin-bottom: 0;
  }

  .icon-apple-as-bg {
    background-size: 28px auto;
    width: 28px;
    height: 28px;
  }

  .icon-googleplay-as-bg {
    background-size: 23px auto;
    width: 23px;
    height: 25px;
  }

  /*!
       * .owl-carousel__home-hero 768
       */
  .owl-carousel__home-hero .hero {
    flex-direction: row;
    align-items: stretch;
  }

  .owl-carousel__home-hero .hero-header {
    padding-bottom: 30px;
  }

  .owl-carousel__home-hero .hero-body {
    margin-bottom: 30px;
  }

  .owl-carousel__home-hero .hero-header h1 {
    font-size: 7.8vw;
    line-height: 0.9em;
  }

  .owl-carousel__home-hero .hero__image,
  .owl-carousel__home-hero .hero__content {
    height: inherit;
    width: 50%;
  }

  .owl-carousel__showsTicker .show__cat {
    font-size: 40px;
    line-height: 42px;
  }

  .content__header--template {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .content__body--template {
    padding: 30px;
  }

  .feature__icon .icon-c {
    top: 4px;
  }

  .feature__icon .icon-ticket {
    top: 0;
    left: 2px;
    width: 67px;
  }

  .feature__icon .icon-search {
    top: 0;
    right: 0;
  }

  .feature__icon .icon-location-pin {
    top: 0;
    left: 6px;
    width: 32px;
  }

  main {
    min-height: calc(100vh - 388px);
  }

  .content__carousel-home-hero .owl-nav {
    bottom: 10px;
  }

  .content__for-venues .content__header,
  .content__for-members .content__header,
  .content__just-few-steps .content__header,
  .content__just-few-steps .content__body,
  .main__about-us .content__header,
  .main__about-us .content__body {
    margin-bottom: 5vh;
  }

  .content__what-we-do .big-heading h1,
  .content__what-we-do .big-heading .h1,
  .content__questions .big-heading h1,
  .content__questions .big-heading .h1 {
    letter-spacing: -1px;
  }

  .section__what-we-do {
    padding-top: 63px;
    padding-bottom: 62px;
  }

  /*!
       * .main__new-look 768
       */
  .section__new-look {
    padding-top: 63px;
    padding-bottom: 80px;
  }

  .main__new-look .content__new-look .big-heading h1,
  .main__new-look .content__new-look .big-heading .h1 {
    font-size: 14vw;
  }

  .main__new-look .section__ticket-booking-steps,
  .main__new-look .section__what-we-do {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .main__new-look .container__for-members .image1 {
    top: 16%;
  }

  .main__new-look .container__for-venues {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .main__new-look .section__faq .content__header {
    margin-bottom: 5vh;
  }

  .main__new-look .section__faq {
    padding-bottom: 80px;
  }

  .main__new-look .section__faq .big-heading h2,
  .main__new-look .section__faq .big-heading .h2 {
    font-size: 15vw;
  }

  /*!
       * .content__for-venues 768
       */
  .content__for-venues .big-heading h2,
  .content__for-members .big-heading h2 {
    font-size: 4.3vw;
  }

  .content__for-venues .sub-heading,
  .content__for-members .sub-heading {
    font-size: 28px;
    line-height: 40px;
  }

  .content__for-venues .sub-heading-upper,
  .content__for-members .sub-heading-upper {
    margin-bottom: 15px;
  }

  .container__for-venues {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .content__for-venues .ticket-aquamarine {
    left: 5%;
  }

  /*!
       * .content__for-members 768
       */
  .container__for-members {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .container__for-members .image2 {
    top: 12%;
    left: 3%;
    width: 48%;
  }

  .container__for-members .image3 {
    top: 0;
    left: 0;
    width: 90%;
    margin-top: 120px;
  }

  .content__membership-is-free .big-heading .h1 {
    font-size: 8.5vw;
  }

  .content__membership-is-free {
    padding-top: 8vh;
  }

  /*!
       * .section__ticket-booking-steps 768
       */
  .content__just-few-steps {
    padding-right: 60px;
    padding-left: 60px;
  }

  .content__ticket-booking-steps {
    padding-right: 100px;
    padding-left: 60px;
  }

  .feature__ticket-booking-step + .buttons-wrapper {
    padding-left: 96px;
  }

  .content__ticket-booking-steps .buttons-wrapper .icon-wrapper {
    margin-left: 13px;
  }

  .content__ticket-booking-steps .buttons-wrapper .icon-arrow-left-red {
    width: 35px;
  }

  .section__ticket-booking-steps .content__left .content__header {
    margin-bottom: 40px;
  }

  .section__ticket-booking-steps .content__left .content__body {
    padding-bottom: 40px;
  }

  .feature__ticket-booking-step {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 65px;
  }

  .feature__ticket-booking-step .step {
    letter-spacing: 1.75px;
    min-width: 67px;
  }

  .feature__ticket-booking-step .step,
  .feature__ticket-booking-step .heading {
    font-size: 35px;
  }

  .feature__ticket-booking-step .feature-heading {
    margin-bottom: 8px;
  }

  .feature__ticket-booking-step {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  /*!
       * .content__NHSCT 768
       */
  .main__about-us .content {
    max-width: 90%;
  }

  .content__NHSCT {
    padding-bottom: 9vh;
  }

  p + .features__impact {
    margin-top: 5vh;
  }

  .features__impact {
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 30px;
  }

  .feature__impact {
    font-size: 20px;
    line-height: 25px;
    width: 44%;
  }

  .feature__impact .heading {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 10px;
  }

  .feature__impact .feature__icon {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .feature__impact .feature__icon .icon {
    width: 130px;
  }

  .feature__impact .feature__body {
    padding: 20px;
  }

  /*!
       * .section__our-numbers 768
       */
  .main__about-us .section__our-numbers {
    padding-top: 0;
    padding-bottom: 50px;
  }

  .content__our-numbers .heading {
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 15px;
  }

  .heading + .list__stats {
    margin-top: 5vh;
  }

  .list__stats {
    gap: 30px;
  }

  .list__stats .item {
    font-size: 16px;
    line-height: 20px;
  }

  .list__stats .item__heading {
    font-size: 7vw;
    line-height: 1em;
    margin-bottom: 0;
  }

  /*!
       * .section__our-values 768
       */
  .list__values .number {
    width: 50px;
    height: 50px;
  }

  .list__values .item__number {
    width: 70px;
  }

  .list__values .item__text {
    padding-top: 10px;
  }

  .main__about-us .content__thanks-to-supporters .btn {
    min-width: 298px;
  }

  /*!
       * .section__listing-show-steps 768
       */
  .section__listing-show-steps .content__header {
    margin-bottom: 5vh;
  }

  /*!
       * Guidelines page 768
       */
  .main__membership-guidelines .big-heading h1,
  .main__membership-guidelines .big-heading .h1 {
    font-size: 9vw;
    line-height: 1em;
  }

  .main__membership-guidelines .big-heading h2,
  .main__membership-guidelines .big-heading .h2 {
    font-size: 6.3vw;
    line-height: 1em;
  }

  /*.main__membership-guidelines .screen07 {
        background-image: url(../images/tickets/bg-tickets-guidelines-desktop.svg);
        background-size: 768px auto;
      }*/
  .main__membership-guidelines .mg-screen .content__body {
    max-width: 680px;
  }

  .form-group__verification-code {
    column-gap: 17px;
    margin-bottom: 45px;
  }

  .form-group__verification-code .form-control {
    font-size: 28px;
    height: 54px;
    width: 52px;
  }

  .modal__registration-verification .form-group__cta + p {
    margin-top: 44px;
  }

  .modal__registration-verification .modal-body {
    padding: 50px 27px 44px 27px;
  }

  /*!
       * .content__my-account 768
       */
  .content__my-account {
    padding-left: 30px;
    padding-right: 30px;
  }

  .content__my-account-home .content__header {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .content__my-account .big-heading h1 + .text-muted {
    font-size: 20px;
    line-height: 25px;
    margin-top: 0;
  }

  .content__my-account .fc__mobileNumberOnRecord,
  .content__my-account .fc__cardOnRecord {
    max-width: 282px;
  }

  .content__my-account .fc__CVC {
    max-width: 106px;
  }

  .content__my-account-home .big-heading .ticket-blue {
    top: -23%;
    left: 13.675%;
    width: 65px;
  }

  .content__my-account-home .big-heading .ticket-yellow {
    bottom: -51.95%;
    left: 51.6%;
    width: 61px;
  }

  .content__my-account-home .big-heading .ticket-green {
    top: 4.9%;
    right: 5.9%;
    width: 84px;
  }

  .content__my-account-home .ticket-orange {
    top: 59.4%;
    left: -2.85%;
    width: 108px;
  }

  .content__my-account-home .ticket-aquamarine {
    position: absolute;
    bottom: -10.1%;
    right: 20.6%;
    width: 114px;
    transform: translateY(50px);
    transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1),
      transform 2s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .content__my-account {
    padding-left: 40px;
    padding-right: 40px;
  }

  .content__my-account .big-heading h1 {
    font-size: 30px;
    line-height: 35px;
  }

  .content__my-account-home .big-heading .h4 {
    font-size: 50px;
    line-height: 60px;
  }

  .content__my-account .nav-tabs.flex-column {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .content__my-account-home .list-nav-link-card > li {
    min-height: 200px;
  }

  .list-nav-link-card.flex-md-row {
    gap: 30px;
  }

  .list-nav-link-card.align-items-md-stretch .nav-link__card {
    height: 100%;
  }

  .list-nav-link-card.flex-md-row > li {
    flex-shrink: 0;
    width: calc(50% - 15px);
  }

  .content__my-account-home .nav-link__card {
    padding: 30px;
    font-size: 25px;
    line-height: 30px;
  }

  .content__my-account-home .nav-link__heading {
    font-size: 5vw;
    line-height: 1em;
  }

  .content__my-account .tab-content .heading__your-area-2,
  .content__my-account .accordion-collapse .heading__your-area-2 {
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 24px;
  }

  .modal__form-modal h2 {
    margin-bottom: 14px;
  }

  .modal__almost-done .modal-content {
    background-image: url(../images/tickets/bg-tickets-almost-done.svg);
    background-position: 32px 11px;
    background-size: 347px auto;
  }

  .modal__my-account-almost-done .modal-content {
    background-image: url(../images/tickets/bg-tickets-almost-done-my-account.svg);
    background-position: 16px 11px;
    background-size: 387px auto;
  }

  .modal__my-account-delete-card .modal-content {
    background-image: url(../images/tickets/bg-tickets-delete-success.svg);
    background-position: 34px 16px;
    background-size: 356px auto;
  }

  .modal__almost-done .modal-body {
    padding-top: 7px;
    min-height: 370px;
  }

  .modal__my-account-almost-done .modal-body {
    min-height: 395px;
  }

  .modal__my-account-delete-card .modal-body {
    min-height: 301px;
  }

  /*!
       * .content__current-listings 768
       */
  .card__event {
    padding: 24px 24px;
  }

  .listing-filters {
    padding: 0 26px 20px;
  }

  .btn__listings-filter {
    padding: 3px 29px 4px 29px;
  }

  .content__current-listings .owl-carousel__events .event-image {
    width: 300px;
    border-radius: 16px;
  }

  .content__current-listings .content__header {
    padding-top: 17px;
    padding-bottom: 30px;
  }

  .content__current-listings .big-heading h1,
  .content__current-listings .big-heading .h1,
  .content__my-bookings .big-heading h1,
  .content__my-bookings .big-heading .h1,
  .content__my-favourites .big-heading h1,
  .content__my-favourites .big-heading .h1,
  .content__what-we-do .big-heading h1,
  .content__what-we-do .big-heading .h1,
  .content__questions .big-heading h1,
  .content__questions .big-heading .h1,
  .content__our-values .big-heading h1,
  .content__our-values .big-heading .h1 {
    font-size: 12vw;
    line-height: 1em;
  }

  .content__current-listings .listing-filters .form-groups-wrapper {
    margin-bottom: 15px;
  }

  .content__current-listings .listing-filters .form-groups-wrapper-4 {
    column-gap: 20px;
    row-gap: 20px;
  }

  .content__current-listings
    .listing-filters
    .form-groups-wrapper-4
    .form-group {
    width: calc(50% - 10px);
  }

  .content__current-listings
    .listing-filters
    .form-groups-wrapper-4
    .form-control,
  .content__current-listings
    .listing-filters
    .form-groups-wrapper-4
    .form-datepicker {
    width: 100%;
  }

  .current-listings::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #000;
  }

  .current-listings.two-cols-md .item {
    width: 50%;
  }

  .current-listings.two-cols-md .item:nth-child(odd) {
    border-right: 1px solid #000;
  }

  .current-listings.align-items-md-stretch .card__event {
    height: 100%;
  }

  /*!
       * .content__event-details 768
       */
  .section__event-details {
    padding-top: 30px;
  }

  hr.event__name-hr {
    height: 2px;
  }

  .event .event__name {
    font-size: 32px;
    letter-spacing: 0.4px;
    line-height: 40px;
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .event__location {
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 25px;
    margin-bottom: 10px;
  }

  .event__header-text .badge {
    font-size: 15px;
    letter-spacing: 0.36px;
    padding-top: 6px;
    padding-bottom: 5px;
  }

  /*!
       * .content__slidein 768
       */
  .slidein-wrapper {
    height: calc(100vh - 51px);
  }

  /*!
       * .content__checkout 768
       */
  .content__checkout .big-heading h1,
  .content__checkout .big-heading .h1,
  .content__checkout .big-heading h2,
  .content__checkout .big-heading .h2 {
    font-size: 160px;
    line-height: 160px;
  }

  .content__booking-success .big-heading .ticket-blue {
    top: -10px;
    left: 30.5%;
    width: 83px;
  }

  .content__booking-success .big-heading .ticket-red {
    top: 25.5%;
    left: 37.35%;
    width: 108px;
  }

  .content__booking-success .big-heading .ticket-yellow {
    top: 27.95%;
    right: 9.5%;
    width: 115px;
    z-index: 1;
  }

  .content__booking-success .big-heading .ticket-green {
    bottom: 36.25%;
    right: 26.55%;
    width: 98px;
  }

  .content__booking-success .big-heading .ticket-orange {
    bottom: 28.5%;
    left: 19.95%;
    width: 96px;
  }

  .content__booking-success .big-heading .ticket-aquamarine {
    bottom: -22px;
    right: 33.9%;
    width: 80px;
    z-index: 1;
  }

  .content__booking-timeout .big-heading h1,
  .content__booking-timeout .big-heading .h1,
  .content__booking-timeout .big-heading h2,
  .content__booking-timeout .big-heading .h2 {
    font-size: 272px;
    line-height: 239px;
  }

  .content__booking-success .big-heading h1,
  .content__booking-success .big-heading .h1,
  .content__booking-success .big-heading h2,
  .content__booking-success .big-heading .h2 {
    font-size: 160px;
    line-height: 1em;
  }

  .content__booking-timeout .big-heading .emoji-yellow {
    top: 3.8%;
    left: 50.05%;
    width: 67px;
  }

  .content__booking-timeout .big-heading .emoji-aquamarine {
    top: 14.2%;
    left: 10.2%;
    width: 90px;
  }

  .content__booking-timeout .big-heading .emoji-blue {
    top: 41.15%;
    right: 8.2%;
    width: 84px;
  }

  .content__booking-timeout .big-heading .emoji-green {
    top: 46.8%;
    left: 40.5%;
    width: 92px;
  }

  .content__booking-timeout .big-heading .emoji-orange {
    bottom: 7%;
    right: 17.4%;
    width: 90px;
  }

  .content__booking-timeout .big-heading .emoji-red {
    bottom: -6.45%;
    left: 18.75%;
    width: 92px;
  }

  .content__booking-timeout .big-heading .emoji {
    z-index: 1;
  }

  .content__booking-timeout .content__header {
    padding-top: 10vh;
  }

  .promo-code__form-group .form-control {
    min-width: 473px;
  }

  .content__select-time--wrapper {
    position: relative;
  }

  .content__select-time > .col__right {
    margin-top: 0;
    padding-left: 20px;
  }

  .content__wallet-topup-success .content__body .btn-primary,
  .content__booking-success .content__body .btn-primary {
    min-width: 410px;
  }

  .content__booking-timeout .content__body .btn-primary {
    min-width: 296px;
  }

  .wallet-balance__text .wallet-balance,
  .content__checkout .input-group__as-form-control .input-group-text,
  .content__checkout .input-group__as-form-control .form-control {
    font-size: 30px;
    line-height: 36px;
  }

  .content__booking-success .content__header,
  .content__booking-timeout .content__header {
    margin-bottom: 7vh;
  }

  .content__booking-timeout .content__body .buttons-wrapper,
  .content__booking-success .content__body .buttons-wrapper {
    margin-top: 7vh;
  }

  /*!
       * .content__my-wallet 768
       */
  .content__my-wallet .big-heading h1,
  .content__my-wallet .big-heading .h1 {
    font-size: 9.5vw;
  }

  .content__my-wallet .col__top-up-info,
  .content__my-wallet .col__transaction-history {
    padding: 40px 30px;
  }

  .card__credit-balance .card-body {
    background-image: url(../images/icon-wallet-md-white.svg);
    background-position: -17px 24px;
    background-size: 136px auto;
    padding: 20px 20px 18px 150px;
  }

  .card__credit-balance .card-body .h3 {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 0;
  }

  .card__credit-balance .card-body .wallet-balance {
    font-size: 48px;
    line-height: 54px;
  }

  .card__transaction-summary .transaction-amount {
    font-size: 25px;
  }

  .card__transaction-summary .card-footer__hr {
    margin-bottom: 10px;
  }

  .card__transaction-summary + .card__transaction-summary {
    margin-top: 20px;
  }

  /*!
       * .content__wallet-topup 768
       */
  .content__wallet-topup-success .content__header {
    margin-bottom: 56px;
    padding-top: 120px;
  }

  .content__wallet-topup-success .big-heading .ticket-red {
    top: 5%;
    right: 41.25%;
    width: 62px;
  }

  .content__wallet-topup-success .big-heading .ticket-blue {
    top: 24.25%;
    left: 26.15%;
    width: 83px;
  }

  .content__wallet-topup-success .big-heading .ticket-green {
    top: 34.5%;
    right: 29.85%;
    width: 98px;
  }

  .content__wallet-topup-success .big-heading .ticket-orange {
    bottom: 20.75%;
    left: 7.65%;
    width: 97px;
  }

  .content__wallet-topup-success .big-heading .ticket-yellow {
    bottom: -7.75%;
    left: 34.75%;
    width: 112px;
  }

  .content__wallet-topup-success .big-heading .ticket-aquamarine {
    bottom: -3.65%;
    right: 29.85%;
    width: 80px;
  }

  /*!
       * .content__my-bookings 768
       */
  .content__my-bookings .content__header {
    padding-top: 20px;
    padding-bottom: 31px;
  }

  .list__my-bookings .card__event .card-body {
    font-size: 25px;
    letter-spacing: 0.4px;
    line-height: 35px;
  }

  .list__my-bookings .card__event .card__event-image {
    width: 240px;
  }

  .list__my-bookings .card__event .list__event-details {
    font-size: 22px;
    line-height: 26px;
  }

  .list__my-bookings .card__event .list__event-details > li {
    margin-bottom: 5px;
  }

  .content__my-bookings .list__my-bookings .card__event .card-body,
  .content__contact-us .list__my-bookings .card__event .card-body {
    padding-left: 30px;
  }

  .content__my-bookings .list__my-bookings .buttons-wrapper {
    flex-direction: row;
  }

  .list__my-bookings .card__event .card-title {
    font-size: 28px;
    line-height: 34px;
    margin-top: -2px;
    margin-bottom: 8px;
  }

  .list__my-bookings .card-body__cta .btn {
    width: 203px;
  }

  .content__my-bookings .list__my-bookings .card__event,
  .content__contact-us .list__my-bookings .card__event {
    padding: 30px;
  }

  .content__my-bookings .list__my-bookings .card-body__details,
  .content__my-bookings .list__my-bookings .card-body__cta {
    padding-left: 30px;
  }

  .content__manage-booking-other-requests-received .big-heading .ticket-green {
    top: -7.95%;
    left: 24.85%;
    width: 67px;
  }

  .content__manage-booking-other-requests-received .big-heading .ticket-red {
    bottom: -16.25%;
    left: 17.15%;
    width: 76px;
  }

  .content__manage-booking-other-requests-received .big-heading .ticket-blue {
    top: 41.1%;
    left: 48%;
    width: 80px;
  }

  .content__manage-booking-other-requests-received
    .big-heading
    .ticket-aquamarine {
    bottom: -17.25%;
    right: 27.75%;
    width: 68px;
  }

  .content__manage-booking-other-requests-received .big-heading .ticket-orange {
    top: 30.65%;
    right: 6.35%;
    width: 80px;
  }

  .content__manage-booking-other-requests-received .big-heading h1 {
    font-size: 14vw;
    line-height: 0.95em;
  }

  .content__manage-booking-other-requests-received .content__header {
    margin-bottom: 56px;
    padding-top: 158px;
  }

  .content__manage-booking-other-requests-received p + .buttons-wrapper {
    margin-top: 60px;
  }

  .content__screen01 .big-heading .ticket-orange {
    left: 20%;
  }

  /*!
       * .content__my-favourites 768
       */
  /*!
       * .content__leave-feedback 768
       */
  .content__leave-feedback .card__event .card-title {
    font-size: 40px;
    line-height: 1em;
  }

  .content__leave-feedback .content__body {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .content__leave-feedback .card__event .card__event-image,
  .content__leave-feedback .card__event {
    margin-bottom: 40px;
  }

  .content__leave-feedback .form-group__heading {
    font-size: 30px;
    letter-spacing: 0.6px;
    line-height: 36px;
    margin-bottom: 30px;
  }

  .content__leave-feedback .form-group__heading + div {
    margin-top: 25px;
  }

  .content__leave-feedback .btn-check-wrapper .btn-sm {
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 24px;
    padding-top: 7px;
    padding-bottom: 6px;
  }

  .content__leave-feedback .list__btn-checks {
    gap: 13px 29px;
  }

  .content__leave-feedback .list__btn-checks-circle .btn-sm {
    padding: 6px 9px;
  }

  .content__leave-feedback .btn-check-wrapper {
    margin-bottom: 19px;
  }

  .content__leave-feedback .form-group__cta .btn {
    min-width: 281px;
  }

  .content__leave-feedback .list__btn-checks-circle {
    gap: 34px;
  }

  .content__leave-feedback .list__btn-checks + p {
    margin-top: 20px;
  }

  .content__leave-feedback .form-group + .form-group__cta {
    margin-top: 91px;
  }

  .content__leave-feedback .input-group__amount {
    width: 342px;
  }

  /*!
       * .content__refer-friend 768
       */
  .content__refer-friend .big-heading h1,
  .content__refer-friend .big-heading .h1 {
    font-size: 11vw;
    line-height: 1em;
  }

  .btn__modal-terms-conditions .btn__text {
    margin-left: 37px;
    margin-right: 36px;
  }

  .content__refer-friend .card__invite-link {
    width: 80%;
  }

  /*!
       * .content__faq 768
       */
  .accordion-button {
    font-size: 30px;
    letter-spacing: 0.6px;
    line-height: 35px;
  }

  .accordion-body {
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 26px;
  }

  .accordion-line-separated .accordion-button {
    padding-top: 21px;
    padding-bottom: 20px;
  }

  .accordion-line-separated .accordion-body {
    padding-top: 3px;
    padding-right: 49px;
    padding-bottom: 24px;
  }

  .accordion-button::after {
    background-image: url(../images/icon-arrow-down-black.svg);
    width: 31px;
    height: 34px;
    background-size: 31px;
  }

  .accordion-line-separated .accordion-button {
    font-weight: 400;
  }

  .content__faq .big-heading h1,
  .content__faq .big-heading .h1,
  .content__faq .big-heading h2,
  .content__faq .big-heading .h2 {
    font-size: 6.3vw;
    line-height: 1em;
  }

  .content__faq .content__header {
    padding-top: 30px;
    padding-bottom: 45px;
  }

  /*!
       * .content__register 768
       */
  .content__register .col__left .flex-md-row > .wrapper {
    width: 60%;
  }

  .content__register .col__left .feature,
  .content__contact-us .col__left .feature {
    margin-top: 0;
    padding-left: 60px;
    width: 290px;
  }

  .content__register-part2 .ts-wrapper.form-control {
    max-width: 546px;
  }

  .content__register .row__cta {
    margin-top: 30px;
  }

  .content__register-part2 .row__form-groups .form-group {
    margin-bottom: 30px;
  }

  .content__register-last-part .buttons-wrapper .btn {
    min-width: 354px;
  }

  /*!
       * .content__contact-us 768
       */
  .contact-clues .btn-check-wrapper {
    margin-bottom: 20px;
  }

  .content__contact-us .col__left .feature {
    padding-top: 10px;
  }

  .content__contact-us .card__event {
    padding: 0;
  }

  .content__contact-us .content__event-details .card__event .card__event-image {
    width: 300px;
  }

  .content__contact-form textarea {
    min-height: 200px;
  }

  .content__contact-form--problem textarea {
    min-height: 167px;
  }

  .content__contact-form .form-groups-wrapper.flex-md-row {
    column-gap: 30px;
  }

  .content__contact-form .form-groups-wrapper.flex-lg-column .form-group {
    width: 100%;
  }

  .content__help-with-show .content__header .heading,
  .content__contact-us-faq .content__header .heading {
    font-size: 30px;
    letter-spacing: 0.6px;
    line-height: 40px;
  }

  .content__contact-us-faq .content__header .heading {
    color: #000;
  }

  .content__contact-us-faq .p-accordion-btn {
    font-size: 30px;
    letter-spacing: 0.6px;
    line-height: 35px;
  }

  .content__contact-us-faq .content__footer {
    padding-top: 30px;
  }

  .content__contact-us-faq .buttons-wrapper {
    margin-top: 30px;
  }

  .modal__message-received .modal-content {
    background-image: url(../images/tickets/bg-tickets-message-received.svg);
    background-position: 15px 11px;
    background-repeat: no-repeat;
    background-size: 388px auto;
  }

  .modal__message-received .big-heading h1,
  .modal__message-received .big-heading .h1,
  .modal__message-received .big-heading h2,
  .modal__message-received .big-heading .h2 {
    font-size: 67px;
    letter-spacing: 0;
    line-height: 63px;
  }

  .modal__message-received .content__header {
    padding-top: 49px;
    padding-bottom: 16px;
  }

  .modal__message-received .modal-dialog {
    max-width: 420px;
    display: flex;
    align-items: center;
    min-height: calc(100% - 3.5rem);
  }

  .modal__message-received .modal-body {
    min-height: 441px;
  }

  .modal__message-received .form-group__cta {
    margin-top: 27px;
  }

  /*!
       * .content__no-working-page 768
       */
  .content__no-working-page .big-heading h1,
  .content__no-working-page .big-heading .h1 {
    font-size: 8vw;
    line-height: 1.2em;
  }

  .content__no-working-page .big-heading .emoji-red {
    top: -16.6%;
    left: 8.7%;
    width: 69px;
  }

  .content__no-working-page .big-heading .emoji-aquamarine {
    top: 21%;
    left: 20.85%;
    width: 67px;
  }

  .content__no-working-page .big-heading .emoji-yellow {
    top: -6.5%;
    left: 53.4%;
    width: 49px;
  }

  .content__no-working-page .big-heading .emoji-blue {
    width: 63px;
  }

  .content__no-working-page .big-heading .emoji-green {
    width: 69px;
  }

  .content__no-working-page .big-heading .emoji-orange {
    bottom: -14.5%;
    right: 24.35%;
    width: 67px;
  }

  .content__no-working-page .content__header {
    padding-top: 150px;
    padding-bottom: 150px;
    padding-right: 50px;
    padding-left: 50px;
  }

  .content__no-working-page .content__header .big-heading {
    margin-bottom: 71px;
  }

  /*!
       * .content__uptake 768
       */
  .main__uptake .content__uptake {
    padding-top: 0;
  }

  .content__uptake-event .card__event {
    margin-bottom: 20px;
  }

  .content__uptake-details .card__uptake {
    margin-bottom: 20px;
  }

  .list__uptake-details {
    gap: 5px 15px;
  }

  .list__uptake-details > li {
    width: 48%;
  }

  .content__uptake .list__uptake-details .heading {
    min-width: 60px;
  }

  .content__uptake .list__uptake-details .list-item__allocated .heading,
  .content__uptake .list__uptake-details .list-item__booked .heading {
    min-width: 80px;
  }

  .list__uptake-details > .list-item__status {
    order: 3;
  }

  .list__uptake-details > .list-item__allocated {
    order: 2;
  }

  .content__uptake-event .card__event .card-title {
    margin-bottom: 0;
    padding-right: 30px;
  }

  .content__uptake-event .row-event-title-status {
    align-items: self-start;
  }

  .content__uptake-event .row-event-title-status,
  .content__uptake-event .row-uptake-list-progress,
  .content__uptake-event .row-notice-buttons {
    flex-direction: row;
    justify-content: space-between;
  }

  .content__uptake-event .row-uptake-list-progress {
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .content__uptake-event .card-body__row .col.order-md-1 {
    max-width: 280px;
  }

  .content__uptake-event .card-body__row .col.order-md-2 {
    padding-left: 60px;
  }

  .content__uptake-event .p-important-wrapper {
    margin-bottom: 5px;
  }

  .card__uptake--details .list__uptake-details {
    width: calc(100% - 200px);
  }

  .card__uptake--details .card-body {
    justify-content: space-between;
  }

  .content__uptake-venues .row-uptake-list-progress > .col__right,
  .content__uptake-venues .row-notice-buttons > .col__right {
    max-width: 280px;
  }

  .btn__show-preview img,
  .modal__show-preview .modal-dialog {
    max-width: 676px;
  }

  .logo__ecologi__custom-text {
    width: 114px;
    height: 52px;
  }

  .logo__ecologi__custom-text {
    width: 142px;
    height: 65px;
  }

  .logo__ecologi__custom-text .custom-text {
    width: 62px;
    height: 14px;
    font-size: 10px;
  }

  .main-footer .logo__ecologi__custom-text {
    top: -6px;
  }

  /*!
       * .section__reactive-account 768
       */
  .content__reactive-account .big-heading h1,
  .content__reactive-account .big-heading .h1 {
    font-size: 11vw;
  }

  .content__reactive-account .big-heading .ticket-yellow {
    top: -1%;
    left: 0;
    width: 6%;
    z-index: 1;
  }

  .content__reactive-account .big-heading .ticket-green {
    top: -7%;
    left: 31%;
    width: 9%;
  }

  .content__reactive-account .big-heading .ticket-blue {
    top: -2%;
    bottom: unset;
    left: 79.5%;
    width: 6%;
  }

  .content__reactive-account .big-heading .ticket-orange {
    bottom: 5%;
    left: 4%;
    width: 8%;
  }

  .content__reactive-account .big-heading .ticket-aquamarine {
    top: unset;
    bottom: -13%;
    left: 45%;
    width: 8%;
    z-index: 1;
  }

  .content__reactive-account .big-heading .ticket-red {
    bottom: 14%;
    right: 2%;
    width: 11%;
  }
}

/* END 768 */
@media (min-width: 992px) {
  .border-top-lg-0 {
    border-top-width: 0 !important;
  }

  .border-bottom-lg-0 {
    border-bottom-width: 0 !important;
  }

  .border-top-lg {
    border-top: 1px solid #000 !important;
  }

  .border-right-lg {
    border-right: 1px solid #000 !important;
  }

  .border-bottom-lg {
    border-bottom: 1px solid #000 !important;
  }

  .border-left-lg {
    border-left: 1px solid #000 !important;
  }

  .btn-circle-x {
    width: 22px;
    height: 22px;
  }

  .form-control {
    padding: 10px 16px 12px;
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 26px;
  }

  .form-group,
  .form-group__cta.d-flex .btn {
    margin-bottom: 13px;
  }

  .form-groups-wrapper.flex-lg-column {
    margin-bottom: 0;
  }

  .form-groups-wrapper.flex-lg-row {
    margin-bottom: 26px;
  }

  .letter-row .letter {
    font-size: 100px;
  }

  .form-control__datepicker {
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 26px;
    padding: 11px 46px 11px 16px;
  }

  .form-datepicker::after {
    top: 17px;
    right: 19px;
    width: 22px;
    height: 19px;
    background-size: 22px 19px;
  }

  .ts-wrapper:not(.form-select).single .ts-control {
    background-position: right 19px center;
    background-size: 22px 19px;
    padding-right: 46px;
  }

  .ts-dropdown .create,
  .ts-dropdown .no-results,
  .ts-dropdown .optgroup-header,
  .ts-dropdown .option {
    padding: 14px 0 9px;
  }

  .custom-control {
    padding-left: 37px;
  }

  .custom-control-label::before {
    top: 1px;
    left: 0;
    width: 22px;
    height: 22px;
  }

  .custom-checkbox__check .custom-control-label::after {
    background-size: 18px 19px;
    width: 18px;
    height: 19px;
    top: 0;
    left: 5px;
  }

  label {
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 24px;
    margin-bottom: 5px;
  }

  .small,
  small {
    line-height: 18px;
  }

  .h-lg-100 {
    height: 100% !important;
  }

  .ts-dropdown-content {
    max-height: 221px;
  }

  /*!
       * .owl-carousel__home-hero 992
       */
  .owl-carousel__home-hero .item1 .ticket-blue {
    width: 69px;
    top: 35.1%;
    left: 6.25%;
  }

  .owl-carousel__home-hero .item1 .ticket-aquamarine {
    width: 57px;
    top: -2.25%;
    left: 51%;
  }

  .owl-carousel__home-hero .item1 .ticket-green {
    width: 83px;
    left: 36.1%;
    bottom: -9.4%;
    z-index: 1;
  }

  .owl-carousel__home-hero .item1 .ticket-yellow {
    width: 68px;
    top: 24.8%;
    right: 15.2%;
  }

  .owl-carousel__home-hero .item2 .ticket-aquamarine {
    width: 51px;
    top: 36%;
    left: 8.9%;
  }

  .owl-carousel__home-hero .item2 .ticket-blue {
    width: 62px;
    bottom: 31.1%;
    left: 21.95%;
  }

  .owl-carousel__home-hero .item2 .ticket-green {
    width: 83px;
    right: 32.4%;
    bottom: 7.6%;
  }

  .owl-carousel__home-hero .item2 .ticket-yellow {
    width: 68px;
    right: 15.6%;
    top: 29.5%;
  }

  .owl-carousel__home-hero .item3 .ticket-yellow {
    width: 68px;
    top: 39.2%;
    left: 33px;
  }

  .owl-carousel__home-hero .item3 .ticket-aquamarine {
    width: 51px;
    bottom: 8.4%;
    left: 51.85%;
  }

  .owl-carousel__home-hero .item3 .ticket-blue {
    width: 62px;
    top: 14.9%;
    left: 50.5%;
  }

  .owl-carousel__home-hero .item3 .ticket-green {
    width: 83px;
    right: 19.5%;
    bottom: 32%;
  }

  .owl-carousel__home-hero .item4 .ticket-yellow {
    width: 68px;
    top: 41.7%;
    left: 9.4%;
  }

  .owl-carousel__home-hero .item4 .ticket-aquamarine {
    width: 51px;
    top: 11.1%;
    left: 52.1%;
  }

  .owl-carousel__home-hero .item4 .ticket-blue {
    width: 62px;
    top: 42.6%;
    right: 22.45%;
  }

  .owl-carousel__home-hero .item4 .ticket-green {
    width: 83px;
    left: 34.85%;
    bottom: 6.6%;
  }

  .owl-carousel__home-hero .item5 .ticket-yellow {
    width: 68px;
    left: 18.85%;
    bottom: 6.6%;
  }

  .owl-carousel__home-hero .item5 .ticket-aquamarine {
    width: 51px;
    top: 35.7%;
    left: -3.6%;
  }

  .owl-carousel__home-hero .item5 .ticket-blue {
    width: 62px;
    top: 11.1%;
    left: 32%;
  }

  .owl-carousel__home-hero .item5 .ticket-green {
    width: 83px;
    top: 42.6%;
    right: 38.45%;
  }

  [data-scroll].ticket,
  .container__for-venues [data-scroll],
  .container__for-members [data-scroll] {
    transform: translateY(300px);
  }

  [data-scroll].feature__impact {
    transform: translateY(200px);
  }

  .content__ticket-booking-steps .feature,
  .content__ticket-booking-steps .feature + .buttons-wrapper {
    transform: translateY(60px);
  }

  .content__list-values .list__values [data-scroll] {
    transform: translateY(100px);
  }

  /*!
       * .content__what-we-do .big-heading 992
       */
  .content__what-we-do .big-heading .ticket-red {
    top: 39px;
    left: 5.4%;
    width: 76px;
    z-index: -1;
  }

  .content__what-we-do .big-heading .ticket-yellow {
    bottom: -4px;
    left: 14.4%;
    width: 77px;
  }

  .content__what-we-do .big-heading .ticket-green {
    top: 68px;
    left: 33.95%;
    width: 77px;
  }

  .content__what-we-do .big-heading .ticket-aquamarine {
    bottom: 4px;
    right: 39.35%;
    width: 117px;
  }

  .content__what-we-do .big-heading .ticket-blue {
    top: 75px;
    right: 19.25%;
    width: 75px;
  }

  /*!
       * .content__for-venues 992
       */
  .container__for-venues .image1 {
    top: 82.45%;
    left: 12.9%;
    width: 326px;
  }

  /*!
       * .content__for-members 992
       */
  .container__for-members .image1 {
    top: 9%;
    right: 2%;
    width: 180px;
  }

  /*!
       * .content__membership-is-free .big-heading 992
       */
  .content__membership-is-free .big-heading .ticket-aquamarine {
    bottom: -37.5%;
    left: 13.35%;
    width: 75px;
  }

  .content__membership-is-free .big-heading .ticket-green {
    top: 32px;
    left: 33.35%;
    width: 82px;
    z-index: -1;
  }

  .content__membership-is-free .big-heading .ticket-blue {
    bottom: -9%;
    left: 46.6%;
    width: 92px;
    z-index: 1;
  }

  .content__membership-is-free .big-heading .ticket-orange {
    top: 6px;
    right: 37%;
    width: 78px;
    z-index: -1;
  }

  .content__membership-is-free .big-heading .ticket-yellow {
    bottom: -12.1%;
    right: 10.2%;
    width: 95px;
    z-index: 1;
  }

  /*!
       * .content__just-few-steps .big-heading 992
       */
  .content__just-few-steps .big-heading .ticket-yellow {
    bottom: -43px;
    left: 2px;
    width: 126px;
    z-index: -1;
  }

  .content__just-few-steps .big-heading .ticket-blue {
    top: -38px;
    left: 177.5px;
    width: 85px;
    z-index: -1;
  }

  .content__just-few-steps .big-heading .ticket-aquamarine {
    top: 67px;
    left: 56.5%;
    width: 77px;
    z-index: 1;
  }

  /*!
       * .content__for-venues 992
       */
  .content__for-venues .ticket-aquamarine {
    top: 38.2%;
    left: 56px;
    width: 92px;
  }

  .content__for-venues .ticket-green {
    bottom: 14.7%;
    right: 46.55%;
    width: 69px;
  }

  .content__for-venues .ticket-yellow {
    top: 15px;
    right: 21.9%;
    width: 89px;
  }

  .content__for-venues .content__header,
  .content__for-members .content__header,
  .content__just-few-steps .content__header,
  .content__just-few-steps .content__body,
  .main__about-us .content__header,
  .main__about-us .content__body {
    margin-bottom: 5vh;
  }

  .content__for-venues .content__body,
  .content__for-members .content__body {
    margin-bottom: 5.5vh;
  }

  /*!
       * .content__for-members 992
       */
  .content__for-members .ticket-aquamarine {
    top: 29.35%;
    left: 79px;
    width: 92px;
  }

  .content__for-members .ticket-green {
    top: 17.5%;
    right: 29.1%;
    width: 89px;
  }

  .content__for-members .ticket-yellow {
    top: 48.2%;
    left: 31.6%;
    width: 90px;
  }

  .content__for-members .ticket-blue {
    bottom: 8.2%;
    right: 12.1%;
    width: 72px;
  }

  .container__for-members .image1 {
    width: 220px;
    top: 5%;
    right: 5%;
  }

  /*!
       * .content__membership-is-free 992
       */
  .content__membership-is-free .sub-heading {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 30px;
  }

  .content__membership-is-free .content__header {
    margin-bottom: 8.5vh;
  }

  .content__membership-is-free .content__body {
    max-width: 70%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 6vh;
  }

  .content__new-look .lead,
  .content__membership-is-free .lead {
    font-size: 28px;
    line-height: 38px;
  }

  .feature__ticket-booking-step {
    margin-bottom: 30px;
  }

  .content__ticket-booking-steps {
    padding-top: 11px;
    padding-right: 100px;
    padding-left: 100px;
  }

  /*!
       * .content__NHSCT 992
       */
  .main__about-us .content {
    max-width: 66%;
  }

  .content__NHSCT .big-heading .ticket-yellow {
    top: 34.6%;
    left: 17.35%;
    width: 69px;
  }

  .content__NHSCT .big-heading .ticket-orange {
    bottom: -7.9%;
    left: 50.7%;
    width: 61px;
  }

  .content__NHSCT .big-heading .ticket-aquamarine {
    top: 0;
    left: 59.5%;
    width: 83px;
  }

  .logo-nhs-charities-blue,
  .content__NHSCT .logo-nhs-charities {
    width: 300px;
    height: auto;
  }

  .content__NHSCT .lead + .buttons-wrapper {
    margin-top: 50px;
  }

  .content__about-us .big-heading .ticket-blue {
    bottom: -6.4%;
    left: 13.6%;
    width: 54px;
  }

  .content__about-us .big-heading .ticket-orange {
    top: 14.95%;
    left: 21.75%;
    width: 53px;
  }

  .content__about-us .big-heading .ticket-green {
    top: 36.5%;
    left: 42.2%;
    width: 58px;
  }

  .content__about-us .big-heading .ticket-yellow {
    top: -2.45%;
    right: 23.65%;
    width: 61px;
  }

  .content__about-us .big-heading .ticket-aquamarine {
    bottom: -15.1%;
    right: 17.45%;
    width: 96px;
  }

  .content__about-us .lead {
    margin-bottom: 20px;
  }

  .features__impact {
    flex-wrap: nowrap;
    column-gap: 30px;
    row-gap: 0;
  }

  .feature__impact {
    width: 280px;
    flex-shrink: 1;
  }

  p + .features__impact {
    margin-top: 7vh;
  }

  .feature__impact .heading {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 15px;
  }

  .feature__impact .feature__icon {
    padding-top: 28px;
    padding-bottom: 28px;
  }

  .feature__impact .feature__body {
    padding: 30px 25px 25px;
  }

  .feature__impact,
  .feature__impact .feature__body {
    border-width: 3px;
  }

  .main__about-us .content__our-values .content__header {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .content__our-values .big-heading .ticket-blue {
    bottom: 10.85%;
    left: 8.65%;
    width: 57px;
  }

  .content__our-values .big-heading .ticket-orange {
    top: 7.5%;
    left: 16.45%;
    width: 56px;
  }

  .content__our-values .big-heading .ticket-green {
    bottom: 19.2%;
    left: 40.85%;
    width: 61px;
  }

  .content__our-values .big-heading .ticket-yellow {
    top: 36.65%;
    right: 28.7%;
    width: 65px;
  }

  .content__our-values .big-heading .ticket-aquamarine {
    bottom: 15.5%;
    right: 5.45%;
    width: 102px;
  }

  .list__values h3 {
    font-size: 30px;
    letter-spacing: 0.6px;
    line-height: 35px;
    margin-bottom: 20px;
  }

  .list__values .item__text {
    font-size: 20px;
    line-height: 26px;
  }

  /*!
       * .section__listing-show-steps 992
       */
  .heading + .list__stats {
    margin-top: 4vh;
  }

  .section__listing-show-steps .content__header {
    margin-bottom: 6vh;
  }

  .section__listing-show-steps .big-heading__bottom {
    margin-top: 10vh;
  }

  .content__listing-show-steps .icon-block__arrow {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  /*!
       * Guidelines page 992
       */
  .main__about-us .lead,
  .main__membership-guidelines .lead {
    font-size: 30px;
    letter-spacing: 0.7px;
    line-height: 35px;
  }

  .main__membership-guidelines .mg-screen .content {
    padding-top: 5vh;
  }

  .main__membership-guidelines .screen01 .content {
    padding-top: 0;
  }

  .content__NHSCT .lead {
    font-size: 24px;
    letter-spacing: 0.4px;
    line-height: 28px;
  }

  .main__membership-guidelines .mg-screen {
    padding-top: 0;
    padding-bottom: 0;
  }

  .mg-screen .content__cta .btn-icon img {
    width: 54px;
  }

  .main__membership-guidelines .screen04 .content__body {
    max-width: 860px;
  }

  .main__membership-guidelines .screen05 .content__body {
    max-width: 860px;
  }

  .main__membership-guidelines .screen06 .content__body {
    max-width: 800px;
  }

  .main__membership-guidelines .screen07 .content__body {
      max-width: 800px;
  }

  .main__membership-guidelines .screen07 .big-heading h2,
  .main__membership-guidelines .screen07 .big-heading .h2 {
    font-size: 4.5vw;
    line-height: 1em;
  }

  .main__membership-guidelines .vr {
    min-height: 8vh;
  }

  /* .content__screen01 992 */
  .content__screen01 .big-heading .ticket-orange {
    top: 17.55%;
    left: 18.4%;
    width: 72px;
  }

  .content__screen01 .big-heading .ticket-blue {
    bottom: -12%;
    left: 31.15%;
    width: 74px;
  }

  .content__screen01 .big-heading .ticket-green {
    top: 37%;
    left: 41.15%;
    width: 79px;
  }

  .content__screen01 .big-heading .ticket-aquamarine {
    bottom: -16.85%;
    right: 29.9%;
    width: 131px;
  }

  .content__screen01 .big-heading .ticket-yellow {
    top: -2.25%;
    right: 17.25%;
    width: 84px;
  }

  /* .content__screen07 992 */
  .main__membership-guidelines .screen07 {
    padding-bottom: 60px;
  }

  .content__screen07 .big-heading .ticket-orange1 {
    top: -40.36%;
    left: -3.05%;
    width: 72px;
  }

  .content__screen07 .big-heading .ticket-green1 {
    top: -64.15%;
    right: 35.65%;
    width: 74px;
  }

  .content__screen07 .big-heading .ticket-yellow1 {
    top: 5.75%;
    right: -12.05%;
    width: 99px;
  }

  .content__screen07 .big-heading .ticket-green3 {
    top: 23.85%;
    left: -38.1%;
    width: 141px;
  }

  .content__screen07 .big-heading .ticket-green2 {
    top: 28.7%;
    left: 25.45%;
    width: 79px;
    z-index: 1;
  }

  .content__screen07 .big-heading .ticket-blue1 {
    bottom: 31.65%;
    left: -11.25%;
    width: 74px;
  }

  .content__screen07 .big-heading .ticket-aquamarine1 {
    bottom: 34.1%;
    right: 20.8%;
    width: 131px;
  }

  .content__screen07 .big-heading .ticket-blue2 {
    bottom: 37%;
    right: -28.4%;
    width: 103px;
  }

  .content__screen07 .big-heading .ticket-yellow2 {
    bottom: -14.75%;
    left: -25.95%;
    width: 80px;
  }

  .content__screen07 .big-heading .ticket-orange2 {
    bottom: -65.95%;
    left: 41.15%;
    width: 120px;
  }

  .content__screen07 .big-heading .ticket-aquamarine2 {
    bottom: -49%;
    right: -1.4%;
    width: 78px;
  }

  /*!
       * .content__my-account 992
       */
  .content__my-account .nav-tabs.flex-column {
    padding-top: 15px;
    padding-bottom: 15px;
    width: 250px;
    flex-shrink: 0;
  }

  .content__my-account .nav-tabs.flex-column .nav-item {
    margin-bottom: 8px;
  }

  .content__my-account .nav-tabs.flex-column + .tab-content {
    padding: 15px 20px;
  }

  .content__my-account .nav-tabs.flex-column .nav-item {
    padding: 0 15px;
  }

  .content__my-account .tab-pane .btn-primary {
    min-width: 281px;
  }

  .content__my-account .form-group__cta {
    margin-top: 50px;
  }

  .content__my-account-home .list-nav-link-card > li {
    min-height: 220px;
  }

  /*!
       * .content__current-listings 992
       */
  .content__current-listings .big-heading .ticket-blue {
    top: 7.4%;
    left: 1.35%;
    width: 68px;
  }

  .content__current-listings .big-heading .ticket-red {
    bottom: 18.1%;
    left: 21.2%;
    width: 72px;
  }

  .content__current-listings .big-heading .ticket-green {
    bottom: 21.05%;
    left: 42.05%;
    width: 79px;
  }

  .content__current-listings .big-heading .ticket-aquamarine {
    bottom: 4.8%;
    right: 35.15%;
    width: 90px;
  }

  .content__current-listings .big-heading .ticket-yellow {
    top: 13%;
    right: 12.75%;
    width: 68px;
  }

  .search-results-wrapper {
    font-size: 20px;
    line-height: 24px;
  }

  .list__event-tags-wrapper .search-results-wrapper {
    margin-top: 26px;
  }

  /*!
       * .content__event-details 992
       */
  .btn__payment-method {
    height: 96px;
  }

  .section__event-details {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  .section__event-details .content__event-details {
    width: calc(100% - 380px);
    padding-right: 20px;
    padding-left: 15px;
  }

  .section__event-details .sticky__tabTicketInfoVenue {
    margin-top: -64px;
    padding-bottom: 100px;
  }

  .content__event-details .video-wrapper {
    max-width: 676px;
  }

  .content__event-details .video-wrapper__iframe {
    max-width: 676px;
    padding-top: 46.25%;
  }

  .content__event-details .event__header {
    margin-bottom: 20px;
  }

  .nav-tabs__info-venue-wrapper {
    width: 350px;
    position: absolute;
    right: 30px;
  }

  .nav-tabs__info-venue .tab-pane__body {
    margin-bottom: 20px;
  }

  .nav-tabs__info-venue + .list__event-tags {
    margin-top: 15px;
  }

  .nav-tabs__info-venue .nav-tabs .nav-link {
    font-size: 20px;
    padding-top: 8px;
  }

  .nav-tabs__info-venue .tab-content .btn {
    width: 100%;
  }

  .nav-tabs__info-venue + .list__event-tags .badge {
    font-size: 13px;
    padding: 5px 18px;
  }

  .promo-code__item {
    padding-bottom: 8px;
  }

  .promo-code__form-group {
    margin-bottom: 25px;
  }

  .promo-code__form-group .form-group + .btn__plus-to-x {
    margin-left: 25px;
  }

  /*!
       * .content__slidein 992
       */
  .slidein-wrapper .slidein {
    border-left: 1px solid #000;
    width: 805px;
  }

  .slidein .content__checkout > .content {
    min-height: calc(100vh - 128px);
  }

  /*!
       * .content__checkout 992
       */
  .content__checkout .input-group__as-checkbox .input-group-text {
    font-size: 25px;
    line-height: 30px;
  }

  .content__checkout .input-group__as-checkbox .custom-control {
    top: 2px;
  }

  .content__checkout .btn-close__absolute {
    top: 20px;
    right: 30px;
    width: 37px;
    height: 37px;
  }

  .content__checkout .heading,
  .content__checkout .content__title,
  .content__checkout .event__name,
  .content__checkout .subtotal-block .label,
  .content__checkout .subtotal-block .total-heading,
  .content__checkout .subtotal-block .total-amount,
  .content__checkout .content__footer .btn {
    font-size: 30px;
    line-height: 36px;
  }

  .content__checkout .content__title,
  .content__checkout .buttons-wrapper .btn {
    letter-spacing: 0.6px;
  }

  .content__checkout
    .content__checkout
    > .content
    > .content__body:not(.content__body--total) {
    min-height: 500px;
  }

  .content__slidein .content__checkout > .content__header,
  .content__slidein .content__checkout .content__body {
    padding: 20px 30px;
  }

  .content__slidein .content__checkout .content__body--total {
    padding: 15px 30px;
  }

  .content__checkout .content__checkout > .content__header {
    padding: 13px 80px 12px 30px;
  }

  .content__booking-success .big-heading h1,
  .content__booking-success .big-heading .h1,
  .content__booking-success .big-heading h2,
  .content__booking-success .big-heading .h2 {
    font-size: 160px;
    line-height: 1em;
  }

  .content__booking-success .content__header,
  .content__booking-timeout .content__header {
    padding-top: 80px;
  }

  .black-lg {
    color: #000 !important;
  }

  .datepicker__trigger {
    background-image: url(../images/icon-arrow-down-black-sm.svg);
    background-position: right 13px center;
    background-size: 16px 14px;
    padding-right: 36px;
    background-position: right 19px center;
    background-size: 22px 19px;
    padding-right: 46px;
    padding-left: 20px;
  }

  .content__choose-date .datepicker__trigger {
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 26px;
  }

  .content__select-time.as-dropdown {
    top: 50px;
  }

  .content__select-time > .col__right {
    margin-top: 0;
    padding-left: 26px;
  }

  /*!
       * .content__my-wallet 992
       */
  .content__my-wallet .big-heading .ticket-aquamarine {
    bottom: -1.75%;
    left: 31.65%;
    width: 82px;
  }

  .content__my-wallet .big-heading .ticket-blue {
    top: -15.15%;
    left: 51.35%;
    width: 59px;
  }

  .content__my-wallet .big-heading .ticket-yellow {
    bottom: -8.9%;
    right: 30.6%;
    width: 81px;
  }

  .content__my-wallet .col__top-up-info,
  .content__my-wallet .col__transaction-history {
    width: 50%;
  }

  /*!
       * .content__my-bookings 992
       */
  .content__my-bookings .big-heading .ticket-aquamarine {
    top: 8%;
    left: 21.1%;
    width: 75px;
  }

  .content__my-bookings .big-heading .ticket-yellow {
    bottom: 10%;
    left: 23.85%;
    width: 88px;
  }

  .content__my-bookings .big-heading .ticket-green {
    top: 19.45%;
    left: 45.4%;
    width: 88px;
  }

  .content__my-bookings .big-heading .ticket-blue {
    bottom: 9.2%;
    left: 52%;
    width: 71px;
  }

  .content__my-bookings .big-heading .ticket-orange {
    top: 19.3%;
    right: 26.45%;
    width: 84px;
  }

  .list__my-bookings .card__event .card__event-image {
    width: 278px;
  }

  .list__my-bookings .card__event .card-body {
    width: calc(100% - 482px);
  }

  .list__my-bookings .card__event .card-footer {
    width: 203px;
  }

  .list__my-bookings .card__event .card-footer .buttons-wrapper {
    gap: 14px;
  }

  .list__my-bookings .card__event .card-footer .buttons-wrapper .btn {
    min-width: 203px;
  }

  .content__my-bookings .list__my-bookings .card__event .card-body {
    padding-right: 60px;
    padding-left: 30px;
  }

  .content__my-bookings .list__my-bookings .buttons-wrapper {
    flex-direction: column;
  }

  .list__my-bookings .card__event .card-footer {
    margin-top: 0;
  }

  .list__my-bookings .card__event .list__event-details {
    font-size: 25px;
    line-height: 32px;
  }

  .list__my-bookings .card__event .list__event-details > li {
    margin-bottom: 5px;
  }

  .content__my-bookings .list__my-bookings .card__event .card-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /*!
       * .content__my-favourites 992
       */
  .content__my-favourites .big-heading .ticket-aquamarine {
    top: 22%;
    left: 18.4%;
    width: 75px;
  }

  .content__my-favourites .big-heading .ticket-red {
    bottom: 1.95%;
    left: 29.1%;
    width: 88px;
  }

  .content__my-favourites .big-heading .ticket-green {
    top: 19.25%;
    left: 47.3%;
    width: 88px;
  }

  .content__my-favourites .big-heading .ticket-blue {
    bottom: 9.9%;
    right: 29.4%;
    width: 71px;
  }

  .content__my-favourites .big-heading .ticket-orange {
    top: 30.75%;
    right: 16.8%;
    width: 84px;
  }

  /*!
       * .content__leave-feedback 992
       */
  .content__leave-feedback {
    max-width: 860px;
  }

  /*!
       * .content__refer-friend 992
       */
  .content__refer-friend .big-heading .ticket-aquamarine {
    top: 15.4%;
    left: 11.15%;
    width: 75px;
  }

  .content__refer-friend .big-heading .ticket-yellow {
    bottom: 5.75%;
    left: 19.15%;
    width: 88px;
  }

  .content__refer-friend .big-heading .ticket-red {
    top: 38.25%;
    left: 31.35%;
    width: 83px;
  }

  .content__refer-friend .big-heading .ticket-blue {
    bottom: 5.35%;
    left: 50.7%;
    width: 71px;
  }

  .content__refer-friend .big-heading .ticket-orange {
    top: 18.1%;
    right: 24.85%;
    width: 84px;
  }

  .content__refer-friend .big-heading .ticket-green {
    bottom: 29.6%;
    right: 7.45%;
    width: 88px;
  }

  .card__invite-link .card-body {
    padding: 40px;
  }

  .card__invite-link .lead + .form-group {
    margin-top: 30px;
  }

  .content__refer-friend .card__invite-link {
    width: calc(100% - 50px);
  }

  /*!
       * .content__faq 992
       */
  .content__faq .big-heading .ticket-orange {
    bottom: 28.15%;
    left: 10.9%;
    width: 67px;
  }

  .content__faq .big-heading .ticket-blue {
    top: 17.25%;
    left: 31.78%;
    width: 60px;
  }

  .content__faq .big-heading .ticket-aquamarine {
    bottom: -13.45%;
    left: 49.35%;
    width: 77px;
  }

  .content__faq .big-heading .ticket-yellow {
    top: 30.5%;
    right: 24.25%;
    width: 63px;
  }

  .content__faq .big-heading .ticket-red {
    bottom: -0.25%;
    right: 7.9%;
    width: 60px;
  }

  /*!
       * .content__register 992
       */
  .content__register .big-heading .ticket-orange {
    bottom: -12.9%;
    left: 39.4%;
    width: 66px;
  }

  .content__register .big-heading .ticket-aquamarine {
    top: 2.05%;
    left: 47.9%;
    width: 77px;
  }

  .content__register .big-heading .ticket-yellow {
    top: 54.55%;
    right: 36.3%;
    width: 63px;
  }

  .content__contact-us .col__left .content__body,
  .content__contact-us .col__right .content__body,
  .content__register .col__left .content__body,
  .content__register .col__right .content__body {
    padding: 30px;
  }

  .content__register .col__left .flex-md-row > .wrapper,
  .content__contact-us .col__left .flex-md-row > .wrapper {
    width: auto;
  }

  .content__register .col__left .feature,
  .content__contact-us .col__left .feature {
    margin-top: 50px;
    width: auto;
  }

  .content__register .col__left .feature {
    padding-left: 23px;
  }

  .content__contact-us .col__left .feature {
    padding-top: 0;
    padding-left: 0;
  }

  .content__register .row__checkboxes,
  .content__register .row__cta {
    margin-top: 12px;
  }

  .content__register .row__cta {
    margin-top: 30px;
  }

  .content__register .content__body .col__left,
  .content__contact-us .content__body .col__left {
    width: 300px;
    flex-shrink: 0;
  }

  .content__register .content__body .col__right {
    padding-right: 20px;
    padding-left: 20px;
  }

  .content__register-last-part .big-heading .ticket {
    display: block;
  }

  .content__register-last-part .big-heading .ticket-aquamarine1 {
    bottom: -2.6%;
    left: 7.7%;
    width: 93px;
    z-index: 1;
  }

  .content__register-last-part .big-heading .ticket-red {
    top: 9.35%;
    left: 27.35%;
    width: 70px;
  }

  .content__register-last-part .big-heading .ticket-orange1 {
    bottom: -17.25%;
    left: 36.85%;
    width: 80px;
  }

  .content__register-last-part .big-heading .ticket-blue {
    top: -22.2%;
    left: 44.1%;
    width: 76px;
  }

  .content__register-last-part .big-heading .ticket-aquamarine2 {
    bottom: -11.7%;
    right: 37.95%;
    width: 76px;
    z-index: 1;
  }

  .content__register-last-part .big-heading .ticket-yellow {
    top: -2.5%;
    right: 31.05%;
    width: 93px;
    z-index: 1;
  }

  .content__register-last-part .big-heading .ticket-orange2 {
    top: 0.15%;
    right: 10.35%;
    width: 109px;
    z-index: 1;
  }

  .content__register-last-part .big-heading h1,
  .content__register-last-part .big-heading .h1 {
    font-size: 12vw;
    line-height: 0.9em;
  }

  .content__register-last-part .p-account-created {
    font-size: 40px;
    line-height: 45px;
    margin-top: 55px;
  }

  .content__register-last-part .buttons-wrapper {
    margin-top: 62px;
  }

  .main__register-last-part {
    min-height: 100vh;
  }

  /*!
       * .content__contact-us 992
       */
  .content__contact-us .big-heading .ticket-aquamarine {
    top: 49%;
    left: 32.1%;
    width: 82px;
  }

  .content__contact-us .big-heading .ticket-blue {
    top: -15.15%;
    right: 45.6%;
    width: 59px;
  }

  .content__contact-us .big-heading .ticket-yellow {
    bottom: -3%;
    right: 32.25%;
    width: 81px;
  }

  .content__contact-us .col__right > .content > .content__body {
    padding-right: 40px;
    padding-left: 40px;
  }

  .content__contact-us .content__event-details .card__event .card__event-image {
    margin-bottom: 25px;
  }

  .content__contact-us .content__event-details .card__event .card__event-image {
    width: 100%;
  }

  .contact-clues .btn {
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 24px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .contact-clues .btn-check-wrapper {
    margin-bottom: 30px;
  }

  .contact-clues + .form-group__cta {
    margin-top: 35px;
  }

  .content__contact-us .content__body .col__right {
    flex-grow: 1;
  }

  .content__contact-us .card__event .card-title {
    margin-bottom: 0;
  }

  .content__contact-us.content-single-col {
    padding-top: 31px;
  }

  .content__help-with-show .content__header {
    padding-top: 12px;
    padding-bottom: 14px;
  }

  .content__contact-us .list__my-bookings .card__event .card-body .col-left {
    padding-right: 60px;
  }

  .content__contact-us-faq .heading + .accordion {
    margin-top: 39px;
  }

  .content__contact-us-faq .content__header {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  section {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  /*!
       * .content__wallet-topup 992
       */
  .p-forgot-password {
    font-size: 16px;
    letter-spacing: 0.32px;
    line-height: 19px;
  }

  /*!
         * .content__no-working-page 992
         */
  .content__no-working-page .big-heading .emoji-red {
    width: 92px;
  }

  .content__no-working-page .big-heading .emoji-aquamarine {
    width: 90px;
  }

  .content__no-working-page .big-heading .emoji-yellow {
    width: 67px;
  }

  .content__no-working-page .big-heading .emoji-blue {
    width: 84px;
  }

  .content__no-working-page .big-heading .emoji-green {
    width: 92px;
  }

  .content__no-working-page .big-heading .emoji-orange {
    width: 90px;
  }

  /*!
       * .content__uptake 992
       */
  .main__uptake .content__uptake {
    width: 100%;
  }

  .content__uptake .col__left .content__uptake-event .content__body {
    padding-right: 0;
  }

  .content__uptake > .content__body > .col__left {
    width: 300px;
  }

  .content__uptake > .content__body > .col__right {
    width: calc(100% - 300px);
  }

  .main__uptake .row--card__uptake {
    margin-right: -10px;
    margin-left: -10px;
  }

  .main__uptake .row--card__uptake > * {
    padding-left: 10px;
    padding-right: 10px;
  }

  .card__uptake--thank-you .card-body {
    padding: 21px 20px 21px;
  }

  .card__uptake--stat .stat__heading {
    font-size: 32px;
    letter-spacing: 0.4px;
    line-height: 36px;
  }

  .card__uptake--stat .card-body {
    padding: 13px 15px 15px;
  }

  .row--card__uptake > div {
    margin-bottom: 20px;
  }

  .card__uptake--progress .p-important-wrapper {
    border-top: 2px solid var(--bs-red);
    font-weight: bold;
    padding-top: 9px;
  }

  .card__uptake--progress .progress-bar-overlay + div {
    margin-top: 19px;
  }

  .content__uptake-details .row--card__uptake .card__uptake--stat,
  .content__uptake-event .p-important-wrapper {
    margin-bottom: 0;
  }

  .content__uptake-event .card__event .card__event-image {
    margin-bottom: 0;
    width: 320px;
  }

  .content__uptake-event .card-body__row .col.order-md-1 {
    max-width: 220px;
  }

  .content__uptake-event .card-body__row .col.order-md-2 {
    padding-left: 30px;
  }

  .content__uptake-event .p-registered {
    font-size: 8px;
    line-height: 10px;
  }

  .list-group__uptake-head .list-group-item .flex-lg-row > .heading,
  .list-group__uptake-list .list-group-item.flex-row div > .heading {
    margin-right: 8px;
  }

  .card__uptake--details .list-group__uptake-list .buttons-wrapper {
    margin-top: 0;
  }

  .list__uptake-details > li {
    width: 35%;
  }

  .list__uptake-details > .list-item__date,
  .list__uptake-details > .list-item__status {
    width: 60%;
  }

  .list__uptake-stat > li {
    font-size: 15px;
    letter-spacing: 0.3px;
    line-height: 20px;
  }

  .list-group__uptake-list .btn {
    min-width: auto;
  }

  .list__uptake-stat {
    column-gap: 29px;
  }

  .card__uptake--details .card-body {
    padding: 14px;
  }

  .card__uptake--details .list__uptake-details {
    width: calc(100% - 120px);
  }

  .card__uptake--details .list__uptake-details + .buttons-wrapper {
    width: 120px;
    padding-left: 0;
    padding-right: 0;
  }

  .content__uptake .btn {
    font-size: 17px;
    letter-spacing: 0.34px;
    line-height: 21px;
  }

  .content__uptake-event .card__event .card-title {
    margin-bottom: 0;
  }

  .content__uptake-event .buttons-wrapper .btn {
    font-size: 15px;
    letter-spacing: 0.3px;
    line-height: 20px;
    min-width: 193px;
  }

  .content__uptake-event .card__event .card__event-image + .card-body {
    padding-left: 20px;
  }

  .content__uptake-details-carousel > .content__body {
    column-gap: 15px;
  }

  .content__uptake-details-carousel > .content__body > .col__right {
    width: 350px;
  }

  .content__uptake-details-carousel > .content__body > .col__left {
    width: calc(100% - 350px);
    max-width: initial;
  }

  .card__uptake--carousel {
    margin: 0;
  }

  .row-uptake-list-progress .list__uptake-stat {
    margin-bottom: 0;
  }

  .content__uptake-event .row-uptake-list-progress {
    margin-bottom: 15px;
  }

  .owl-carousel__uptake .item__image {
    width: 301px;
    height: 201px;
  }

  .content__uptake-venues .big-heading .ticket-blue {
    bottom: 8.65%;
    left: 3.65%;
    width: 43px;
    z-index: 1;
  }

  .content__uptake-venues .big-heading .ticket-green1 {
    top: 20.95%;
    left: 17.35%;
    width: 49px;
  }

  .content__uptake-venues .big-heading .ticket-yellow1 {
    /*left: 31.6%;
        top: 36.5%;*/
    left: 30.5%;
    top: -14.5%;
    width: 44px;
    z-index: 1;
  }

  .content__uptake-venues .big-heading .ticket-aquamarine {
    left: 48.6%;
    top: 15.15%;
    width: 43px;
  }

  .content__uptake-venues .big-heading .ticket-yellow2 {
    bottom: 13.95%;
    right: 29%;
    width: 44px;
  }

  .content__uptake-venues .big-heading .ticket-green2 {
    top: 39.5%;
    right: 15.4%;
    width: 39px;
    z-index: 1;
  }

  .content__uptake-venues .big-heading .ticket-red {
    bottom: 48.75%;
    right: 2.75%;
    width: 45px;
  }

  .content__uptake-venues .row-notice-buttons .col__left .btn {
    min-width: 176px;
  }

  .content__uptake-venues .row-uptake-list-progress > .col__right,
  .content__uptake-venues .row-notice-buttons > .col__right {
    max-width: 250px;
  }

  /*!
       * .section__our-numbers 992
       */
  .list__stats .item__heading {
    font-size: 7vw;
  }

  /*!
       * .main__new-look 992
       */
  .main__new-look .content__what-we-do .big-heading .ticket-red {
    left: 3%;
    width: 9%;
  }

  .main__new-look .content__what-we-do .big-heading .ticket-yellow {
    width: 7%;
  }

  .main__new-look .content__what-we-do .big-heading .ticket-green {
    top: 0;
    left: 46%;
    width: 7%;
  }

  .main__new-look .content__what-we-do .big-heading .ticket-aquamarine {
    bottom: -13%;
    right: 30%;
    width: 9%;
  }

  .main__new-look .content__what-we-do .big-heading .ticket-blue {
    top: -3%;
    left: unset;
    right: 7%;
    width: 7%;
  }

  .main__new-look .content__questions .big-heading .ticket-blue {
    right: 5%;
  }

  .main__new-look .content__questions .big-heading .ticket-green {
    top: 9%;
    left: 40%;
  }

  .main__new-look .content__questions .big-heading .ticket-yellow {
    bottom: -12%;
    left: 24%;
  }

  .main__new-look .content__questions .big-heading .ticket-aquamarine {
    bottom: -14%;
    right: 38%;
  }

  .main__new-look .container__for-members .image1 {
    width: 180px;
    top: 0;
  }

  .main__new-look .container__for-venues .image1 {
    width: 200px;
  }

  .content__questions .big-heading .ticket-red {
    top: 39px;
    left: 5.4%;
    width: 76px;
    z-index: -1;
  }

  .content__questions .big-heading .ticket-yellow {
    bottom: -4px;
    left: 14.4%;
    width: 77px;
  }

  .content__questions .big-heading .ticket-green {
    top: 68px;
    left: 33.95%;
    width: 77px;
  }

  .content__questions .big-heading .ticket-aquamarine {
    bottom: 4px;
    right: 39.35%;
    width: 117px;
  }

  .content__questions .big-heading .ticket-blue {
    top: 75px;
    right: 19.25%;
    width: 75px;
  }

  .main__new-look .section__faq {
    padding-top: 100px;
  }

  .main__new-look .section__faq .content__header {
    margin-bottom: 7vh;
  }

  /*!
       * .section__reactive-account 992
       */
  .section__reactive-account {
    padding-top: 70px;
    padding-bottom: 90px;
  }

  .container__footer-menu .col-office-address {
    width: 40%;
  }

  .container__footer-menu .col-footer-menus {
    width: 60%;
  }

  .container__footer-menu .col-footer-menus .col-footer-menu {
    width: 50%;
  }

  .container__footer-menu .col__sponsors li {
    margin-left: 15px;
  }

  .container__footer-menu .col__sponsors li {
    margin-left: 0;
  }
}

/* END 992 */
@media (min-width: 1180px) {
  .content__uptake-event .card__event .card__event-image {
    width: 441px;
  }

  .content__uptake-venues .card__event .card__event-image {
    width: 396px;
  }
}

@media (min-width: 1200px) {
  .c-scrollbar {
    opacity: 1;
    z-index: 5;
  }

  @media (hover: hover) and (pointer: fine) {
    .c-scrollbar {
      opacity: 0;
    }
  }

  /* .slidein-login */
  .slidein-login {
    background-color: #fff;
    box-shadow: 0 10px 6px 6px rgb(0 0 0 / 10%);
    padding: 20px 30px;
    position: fixed;
    top: 91px;
    right: -460px;
    width: 460px;
    z-index: -1;
    visibility: hidden;
    transition: all 0.5s;
  }

  .show-login .slidein-login {
    animation: slideInRight 1s;
    right: 0;
    z-index: 1;
    visibility: visible;
  }

  .ts-wrapper:not(.form-select).single .ts-control,
  .form-control__datepicker {
    padding-left: 20px;
  }

  .border-top-xl-0 {
    border-top-width: 0 !important;
  }

  .border-bottom-xl-0 {
    border-bottom-width: 0 !important;
  }

  .border-top-xl {
    border-top: 1px solid #000 !important;
  }

  .border-right-xl {
    border-right: 1px solid #000 !important;
  }

  .border-bottom-xl {
    border-bottom: 1px solid #000 !important;
  }

  .border-left-xl {
    border-left: 1px solid #000 !important;
  }

  .form-groups-wrapper.flex-xl-row {
    margin-bottom: 26px;
  }

  .lead {
    font-size: 23px;
  }

  .letter-row .letter.active {
    top: -6px;
  }

  .modal__fw .modal-body {
    padding: 40px;
  }

  .modal__fw .btn-close__absolute {
    top: 40px;
    right: 30px;
  }

  .modal__fw .content.flex-xl-row {
    margin-left: -17px;
    width: calc(100% + 17px);
  }

  .modal__fw .content {
    padding-right: 50px;
  }

  .modal__fw .content .content__header h1,
  .modal__fw .content .content__header h2 {
    margin-top: -8px;
  }

  .modal__fw .content.flex-xl-row .content__header h2 {
    font-size: 50px;
    letter-spacing: 1px;
    line-height: 60px;
  }

  .modal__terms-and-conditions .flex-xl-row .content__header {
    width: 35%;
    padding-right: 50px;
  }

  .container__block,
  .container__block > .row > * {
    padding-right: 0;
    padding-left: 0;
  }

  .container__block > .row {
    margin-right: 0;
    margin-left: 0;
  }

  .btn {
    font-size: 23px;
    letter-spacing: 0;
    line-height: 30px;
  }

  .btn-primary {
    padding-top: 10px;
  }

  .btn-primary,
  .btn-outline-primary {
    min-width: 155px;
  }

  .btn-icon,
  .btn-circle-x,
  .btn__plus-to-x {
    min-width: auto;
  }

  .btn__plus-to-x {
    width: 51px;
    height: 51px;
  }

  .btn-xl-lg {
    font-size: 23px;
    line-height: 25px;
  }

  .main-header__navbar .nav-link {
    font-size: 20px;
    letter-spacing: 0;
    line-height: 26px;
  }

  .main-header__navbar .nav-link.active,
  .main-header__navbar .show > .nav-link,
  .main-header__navbar .nav-link.btn:hover,
  .main-header__navbar .nav-link.btn.active,
  .main-header__navbar .show > .nav-link.btn {
    color: var(--bs-red);
  }

  .main-header__main-menu .nav-item {
    margin-right: 10px;
    margin-left: 10px;
  }

  .main-header__main-menu .nav-item:first-child {
    margin-left: 0;
  }

  .main-header__main-menu .nav-item:last-child {
    margin-right: 0;
  }

  .main-header__navbar {
    height: 90px;
  }

  .main-header__main-menu .navbar-nav {
    align-items: center;
  }

  .btn-primary + .dropdown-menu > li:last-child .dropdown-item {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-color: var(--bs-red);
  }

  .btn-primary + .dropdown-menu .dropdown-item {
    color: #fff;
    background-color: var(--bs-red);
    border-top: 0;
    border-right: 1px solid var(--bs-red);
    border-bottom: 1px solid #fff;
    border-left: 1px solid var(--bs-red);
  }

  .main-header .dropdown-toggle.btn-primary.show {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .btn-primary + .dropdown-menu .dropdown-item {
    color: #fff;
    background-color: var(--bs-red);
    border-top: 0;
    border-right: 1px solid var(--bs-red);
    border-bottom: 1px solid #fff;
    border-left: 1px solid var(--bs-red);
  }

  .btn-primary + .dropdown-menu .dropdown-item:hover {
    color: var(--bs-red);
    background-color: #fff;
  }

  .btn-primary + .dropdown-menu > li:first-child .dropdown-item {
    border-top: 1px solid var(--bs-red);
  }

  .btn-primary + .dropdown-menu > li:last-child .dropdown-item {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-color: var(--bs-red);
  }

  .btn-huge {
    border-width: 2px;
    font-size: 110px;
    line-height: 1em;
  }

  .form-group,
  .form-group__cta.d-flex .btn {
    margin-bottom: 30px;
  }

  .ts-control {
    padding-top: 14px;
    padding-bottom: 14px;
  }

  .section__cta--huge .btn-huge {
    height: 220px;
  }

  .ticker {
    font-size: 46px;
    line-height: 55px;
    height: 70px;
  }

  .ticker .dot {
    width: 13px;
    height: 13px;
    margin: 0 28px;
  }

  .container__footer-menu .col__sponsors .nav-item__logo--fsb {
    margin-left: 4px;
  }

  .logo-nhs-charities-blue {
    width: 296px;
    height: auto;
  }

  .logo-nhs-charities-blue + .p-notice {
    margin-top: 17px;
  }

  .content__header--template {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .content__body--template {
    padding: 30px;
  }

  .list__tags .btn-check + .btn {
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 24px;
  }

  /*!
       * .owl-carousel__home-hero 1200
       */
  .owl-carousel__home-hero .hero__image::before {
    width: 98px;
    height: 98px;
    background-size: 98px 98px;
    right: 49px;
    bottom: 41px;
  }

  .owl-carousel__home-hero .hero-body {
    max-width: 600px;
    padding-left: 4px;
  }

  .owl-carousel__home-hero .hero-header {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .owl-carousel__home-hero .hero-header h1 {
    line-height: 1em;
  }

  .owl-carousel__home-hero .hero__content {
    padding-left: 30px;
    padding-right: 30px;
  }

  .content__carousel-home-hero .owl-nav {
    bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
    width: 50%;
  }

  .owl-carousel__home-hero .lead,
  .owl-carousel__home-hero p {
    font-size: 23px;
    line-height: 30px;
  }

  .owl-carousel__showsTicker .show__image {
    width: 652px;
    height: 415px;
  }

  .main__about-us section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .content__what-we-do .big-heading h1,
  .content__what-we-do .big-heading .h1 {
    font-size: 17vw;
    letter-spacing: -1.3px;
  }

  .content__our-values .big-heading h1,
  .content__our-values .big-heading .h1 {
    font-size: 13vw;
  }

  /*!
       * .content__for-venues 1200
       */
  .container__for-venues,
  .container__for-members {
    font-size: 25px;
    line-height: 30px;
  }

  .container__for-venues {
    padding-top: 60px;
    padding-bottom: 80px;
  }

  .container__for-venues .content__image {
    padding-left: 40px;
  }

  .content__for-venues {
    padding-right: 40px;
    padding-left: 3px;
  }

  .content__for-venues .ticket-aquamarine {
    left: 59px;
  }

  .content__for-venues .content__body,
  .content__for-venues .content__cta {
    padding-left: 60px;
  }

  .container__for-venues .image3 {
    height: auto;
    width: 90%;
  }

  .container__for-venues .image2 {
    top: 66.4%;
    right: 7.5%;
    width: 51.2%;
  }

  /*!
       * .content__for-members 1200
       */
  .container__for-members {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .container__for-members .content__for-members {
    padding-left: 40px;
  }

  .content__for-members .content__body,
  .content__for-members .content__cta {
    padding-left: 60px;
  }

  .container__for-members .content__image {
    padding-right: 40px;
  }

  .container__for-members .image1 {
    right: 10%;
  }

  .container__for-members .image3 {
    height: auto;
    width: 90%;
  }

  /*!
       * .content__membership-is-free 1200
       */
  .content__membership-is-free {
    padding-top: 8vh;
  }

  .content__membership-is-free .content__header .sub-heading {
    font-size: 4.1vw;
    line-height: 1em;
    margin-bottom: 5vh;
  }

  .section__what-we-do {
    padding-top: 67px;
    padding-bottom: 100px;
  }

  .main__new-look .section__what-we-do {
    padding-top: 100px;
    padding-bottom: 90px;
  }

  .section__new-look {
    padding-top: 67px;
    padding-bottom: 100px;
  }

  /*!
       * .section__ticket-booking-steps 1200
       */
  .feature__ticket-booking-step {
    margin-bottom: 30px;
  }

  .feature__ticket-booking-step .feature__icon {
    margin-right: 35px;
  }

  .feature__ticket-booking-step .feature-heading {
    margin-bottom: 13px;
  }

  .content__just-few-steps {
    padding-right: 40px;
    padding-left: 39px;
  }

  .content__just-few-steps .content__header {
    margin-bottom: 7.5vh;
  }

  .section__ticket-booking-steps {
    padding-top: 100px;
  }

  .section__ticket-booking-steps .content__left .content__body {
    padding-right: 80px;
  }

  .content__ticket-booking-steps {
    padding-right: 80px;
    padding-left: 0;
  }

  .spy-scrollbar {
    height: 65vh;
  }

  .spy-scrollbar__nav > li:not(:last-child) {
    margin-bottom: 8px;
  }

  /*!
       * .content__NHSCT 1200
       */
  .main__about-us .content {
    max-width: 72%;
  }

  .main__about-us .content__header,
  .main__about-us .content__body {
    margin-bottom: 7.5vh;
  }

  .content__NHSCT {
    padding-bottom: 125px;
  }

  .content__NHSCT .logo-nhs-charities {
    margin-top: 4px;
    width: 369px;
  }

  .content__NHSCT .lead + .buttons-wrapper {
    margin-top: 7.5vh;
  }

  .content__NHSCT .btn-primary {
    border-width: 2px;
    min-width: 288px;
  }

  .content__NHSCT .content__body .col__left {
    padding-right: 50px;
    width: calc(100% - 380px);
  }

  .content__NHSCT .content__body .col__right {
    padding-left: 50px;
  }

  .main__about-us .section__NHSCT {
    padding-top: 125px;
  }

  .main__about-us.bg-blue .content__NHSCT .btn-primary {
    padding-top: 18px;
    padding-bottom: 17px;
  }

  /*!
       * .content__about-us 1200
       */
  .main__about-us .section__about-us {
    padding-top: 87px;
    padding-bottom: 89px;
  }

  .main__about-us .section__ecologi {
    padding-top: 94px;
    padding-bottom: 107px;
  }

  .section__register,
  .section__about-us {
    letter-spacing: 0.4px;
  }

  .feature__impact {
    width: 280%;
    row-gap: 0;
  }

  .content__about-us .lead {
    margin-bottom: 30px;
  }

  .content__about-us .content__body .col__left {
    padding-right: 30px;
    width: 45%;
  }

  .content__about-us .content__body .col__right {
    padding-left: 30px;
    width: 55%;
  }

  /*!
       * .owl-carousel__about-us 1200
       */
  .owl-carousel__about-us .item__image {
    width: 576px;
    height: 384px;
  }

  .features__impact {
    flex-wrap: nowrap;
    column-gap: 80px;
    row-gap: 0;
  }

  .feature__impact {
    width: 280px;
    flex-shrink: 1;
  }

  /*!
       * .section__our-numbers 1200
       */
  .content__our-numbers .heading {
    font-size: 50px;
    line-height: 55px;
    margin-bottom: 30px;
  }

  .list__stats {
    gap: 60px 90px;
  }

  .list__stats .item__heading {
    font-size: 9vw;
  }

  .main__about-us .content__our-values .content__header {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  /*!
       * .section__our-values 1200
       */
  .list__values > li {
    margin-bottom: 25px;
  }

  .list__values .number {
    font-size: 30px;
    width: 70px;
    height: 70px;
  }

  .list__values h3 {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 15px;
  }

  .list__values .item__number {
    width: 100px;
  }

  .list__values .item__text {
    padding-top: 18px;
  }

  .list__values > li:not(:last-child) .item {
    padding-bottom: 29px;
  }

  .content__list-values {
    padding-top: 30px;
  }

  .heading + .list__stats {
    margin-top: 5vh;
  }

  .main__about-us .section__our-numbers {
    padding-bottom: 80px;
  }

  /*!
       * Guidelines page 1200
       */
  .main__membership-guidelines .mg-screen .content__body {
    max-width: 760px;
    margin-top: 50px;
    margin-bottom: 8vh;
  }

  .main__membership-guidelines .screen04 .content__body,
  .main__membership-guidelines .screen05 .content__body,
  .main__membership-guidelines .screen06 .content__body {
    max-width: 900px;
  }

  .main__membership-guidelines .mg-screen .vr {
    margin-top: 40px;
  }

  .main__membership-guidelines .screen07 {
    background-size: 1440px auto;
  }

  /* .content__screen01 1200 */
  .content__screen01 .big-heading .ticket-orange,
  .content__screen01 .big-heading .ticket-blue,
  .content__screen01 .big-heading .ticket-green,
  .content__screen01 .big-heading .ticket-yellow {
    z-index: 1;
  }

  .content__screen01 .big-heading .ticket-orange {
    left: 23.4%;
  }

  .content__screen01 .big-heading .ticket-blue {
    bottom: -15%;
  }

  .content__screen01 .big-heading .ticket-green {
    left: 41.15%;
  }

  .content__screen01 .big-heading .ticket-aquamarine {
    right: 29.9%;
  }

  .content__screen01 .big-heading .ticket-yellow {
    right: 19.25%;
  }

  /* .content__screen07 1200 */
  .content__screen07 .big-heading .ticket-orange1 {
    top: -29.36%;
    left: -8.05%;
  }

  .content__screen07 .big-heading .ticket-green1 {
    top: -40.15%;
  }

  .content__screen07 .big-heading .ticket-yellow1 {
    right: -24.05%;
  }

  .content__screen07 .big-heading .ticket-aquamarine1 {
    bottom: 22.1%;
    right: 13.8%;
  }

  .content__screen07 .big-heading .ticket-blue2 {
    bottom: 32%;
    right: -36.4%;
  }

  .content__screen07 .big-heading .ticket-green3 {
    left: -46.1%;
  }

  .content__screen07 .big-heading .ticket-aquamarine2 {
    right: -21.4%;
  }

  /*!
       * .section__listing-show-steps 1200
       */
  .main__about-us .section__listing-show-steps {
    padding-bottom: 140px;
  }

  .section__listing-show-steps .content__header {
    margin-bottom: 12vh;
  }

  .section__listing-show-steps .big-heading__bottom {
    margin-top: 15vh;
  }

  .icon-block__arrow img {
    width: 54px;
  }

  .section__listing-show-steps .lead + .lead {
    margin-top: 40px;
  }

  /*!
       * .content__faq 1200
       */
  .accordion-line-separated .accordion-button {
    padding-left: 8px;
  }

  .accordion-line-separated .accordion-body {
    padding-left: 9px;
  }

  .content__faq {
    max-width: 1138px;
  }

  .content__faq .content__header {
    padding-top: 40px;
    padding-bottom: 60px;
  }

  /*!
       * .content__register 1200
       */
  .content__register .content__body .col__left,
  .content__contact-us .content__body .col__left {
    width: 520px;
  }

  .content__register-part2 .row__form-groups {
    max-width: 570px;
  }

  .content__register-part2 .row__form-groups .form-group {
    margin-bottom: 40px;
  }

  .content__register-part2 .row__form-groups + .row__checkboxes {
    margin-top: 0;
  }

  .content__register-part2 label + .form-group__custom-controls {
    margin-top: 26px;
  }

  .content__register-part2 .form-group__cta .btn-primary {
    min-width: 354px;
  }

  .content__register-last-part .content__header {
    padding-top: 49px;
    padding-bottom: 47px;
  }

  .content__register .list__tags .btn-outline-primary {
    min-width: 172px;
  }

  /*!
       * .content__contact-us 1200
       */
  .user-cat .heading,
  .btn-prefix-arrow,
  .custom-checkbox__arrow label {
    font-size: 30px;
    letter-spacing: 0.6px;
    line-height: 35px;
  }

  .user-cat .btn-prefix-arrow {
    margin-bottom: 2px;
  }

  .user-cat > :not(.custom-checkbox__arrow),
  .custom-checkbox__arrow,
  .btn-prefix-arrow {
    padding-left: 49px;
  }

  .btn-prefix-arrow.active::before {
    background-image: url(../images/icon-arrow-right-34x31-red.svg);
    background-size: 34px auto;
    top: -4px;
    left: -1px;
    width: 34px;
    height: 31px;
  }

  .user-cat .heading + .user-cat__buttons {
    margin-top: 17px;
  }

  .contact-clues .btn {
    min-height: 69px;
  }

  .contact-clues .btn-check-wrapper {
    margin-bottom: 36px;
  }

  .content__contact-us .col__left p {
    letter-spacing: 0.4px;
  }

  .content__contact-us .col__right {
    min-height: 666px;
  }

  .content__contact-us .user-cat + div {
    margin-top: 40px;
  }

  .content__contact-us .col__right > .content > .content__body {
    padding-right: 50px;
    padding-left: 50px;
  }

  .content__contact-us .card__event .card-title,
  .content__contact-us .list-group__event-details {
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 26px;
  }

  .buttons-wrapper__nav .btn {
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 24px;
  }

  .buttons-wrapper__nav .icon-arrow__left {
    margin-top: -5px;
    margin-right: 5px;
  }

  .content__body.d-flex .content__contact-form .buttons-wrapper__nav {
    margin-top: -11px;
  }

  .content__contact-form .buttons-wrapper__nav,
  .content-single-col .content__contact-form .buttons-wrapper__nav {
    margin-bottom: 26px;
  }

  .content__contact-form .buttons-wrapper__nav + .heading {
    margin-top: -1px;
  }

  .content__contact-form .heading + form {
    margin-top: 32px;
  }

  .content__contact-form .form-group + .p-notice,
  .content__contact-form form + .p-notice {
    margin-top: -13px;
  }

  .content__contact-form textarea {
    min-height: 246px;
  }

  .content__contact-form--problem textarea {
    min-height: 167px;
  }

  .content__contact-form .form-groups-wrapper.flex-xl-row {
    column-gap: 40px;
  }

  .content__contact-form .form-groups-wrapper.flex-xl-row .form-group {
    width: 50%;
  }

  .content__contact-us .list__my-bookings .card__event {
    padding: 21px 47px 19px;
    max-width: 96%;
  }

  .content__contact-us .list__my-bookings .card__event .card-body {
    padding-left: 50px;
  }

  .content__contact-us .list__my-bookings .card__event .card-body .col-left {
    padding-right: 100px;
  }

  .content__contact-us .list__my-bookings .card__event .card-body .col-right {
    min-width: 203px;
  }

  .content__contact-us .list__my-bookings .card__event .col-right .btn {
    padding-top: 12px;
    padding-bottom: 10px;
  }

  .content__help-with-show {
    padding-bottom: 143px;
  }

  .content__contact-us-faq {
    padding-bottom: 143px;
  }

  .content__contact-us-faq .content__header {
    padding-left: 0;
    padding-right: 0;
  }

  .content__contact-us-faq .content__footer {
    padding-top: 58px;
    padding-left: 0;
    padding-right: 0;
  }

  .content__contact-us-faq .buttons-wrapper {
    margin-top: 58px;
  }

  /*!
       * .content__my-account 1200
       */
  .section__my-account {
    padding-bottom: 100px;
  }

  .content__my-account .content__header {
    padding-top: 28px;
    padding-bottom: 22px;
  }

  .content__my-account .big-heading .h4 {
    font-size: 40px;
    line-height: 48px;
  }

  .content__my-account .nav-tabs.flex-column {
    padding-top: 15px;
    padding-bottom: 15px;
    width: 300px;
  }

  .content__my-account .nav-tabs.flex-column .nav-link,
  .content__my-account .tab-content .heading {
    font-size: 22px;
    line-height: 28px;
  }

  .content__my-account .nav-tabs.flex-column + .tab-content {
    padding: 20px 30px;
  }

  .content__my-account .nav-tabs.flex-column .nav-item {
    padding-left: 20px;
    padding-right: 20px;
  }

  .content__my-account label.heading {
    margin-bottom: 10px;
  }

  .content__my-account .tab-pane__EmailPreferences .list__tags {
    gap: 18px 10px;
  }

  .content__my-account .form-groups__expiry-cvc > .form-group:first-child {
    margin-right: 40px;
  }

  .content__my-account .buttons-wrapper {
    margin-top: 30px;
  }

  .content__my-account-home .list-nav-link-card > li {
    min-height: 250px;
  }

  .list-nav-link-card.flex-md-row {
    gap: 40px;
  }

  .list-nav-link-card.flex-md-row > li {
    flex-shrink: 0;
    width: calc(50% - 20px);
  }

  /*!
       * .content__current-listings 1200
       */
  .listing-filters-wrapper {
    padding-top: 0;
  }

  .listing-filters {
    padding-top: 20px;
  }

  .form-control__search + .icon {
    top: 10px;
    width: 30px;
  }

  .listing-filters-wrapper .list__event-tags-wrapper {
    padding-top: 31px;
    padding-bottom: 32px;
  }

  #owlCarouselEventTagsNav {
  }

  #owlCarouselEventTagsNav button.owl-prev,
  #owlCarouselEventTagsNav button.owl-next {
    width: 54px;
  }

  .owl-carousel__event-tags .badge-outline {
    border-width: 2px;
    font-size: 25px;
    letter-spacing: 0;
    line-height: 30px;
    padding: 11px 30px 9px 30px;
    min-width: 116px;
  }

  .btn-heart {
    width: 41px;
    height: 37px;
    background-size: 41px 37px;
  }

  .btn-heart.active {
    width: 41px;
    height: 37px;
    background-size: 39px 35px;
  }

  .content__current-listings .owl-carousel__events {
    padding-top: 29px;
    padding-bottom: 26px;
  }

  .content__current-listings .owl-carousel__events .event-image {
    width: 360px;
    border-radius: 32px;
  }

  .content__current-listings .big-heading img.ticket-green,
  .content__current-listings .big-heading img.ticket-yellow {
    z-index: 1;
  }

  .content__current-listings .listing-filters .form-groups-wrapper-4 {
    width: 60%;
  }

  .content__current-listings .listing-filters .form-groups-wrapper__search {
    flex-shrink: 0;
    padding-left: 50px;
    width: 40%;
  }

  .card__event .btn-outline-primary {
    font-size: 25px;
    min-width: 273px;
    padding-top: 11px;
    padding-bottom: 11px;
  }

  .card__event .icon__location-pin,
  .nav-tabs__info-venue .icon__location-pin {
    width: 22px;
  }

  .card__event .icon__location-pin {
    top: -1px;
  }

  .card__event .icon__clock,
  .nav-tabs__info-venue .icon__clock {
    width: 26px;
  }

  .card__event .icon__clock {
    top: -3px;
  }

  .card__event .icon__payment-card,
  .nav-tabs__info-venue .icon__payment-card {
    width: 31px;
  }

  .card__event .icon__payment-card {
    top: -2px;
  }

  .card__event .card-title {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 15px;
  }

  .card__event-image .badge {
    font-size: 18px;
    line-height: 17px;
    height: 27px;
    padding-right: 13px;
    padding-left: 13px;
  }

  .card__event-image .list__event-tags {
    right: 16px;
  }

  .card__event-image .badge__ticket-sold-out {
    font-size: 22px;
    line-height: 44px;
    background-size: 131px auto;
    width: 131px;
    height: 55px;
    padding-right: 3px;
    padding-left: 3px;
    bottom: calc(50% - 27.5px);
    left: calc(50% - 65.5px);
  }

  .card__event .card-body {
    padding: 15px 0;
  }

  .list-group__location,
  .list-group__time,
  .list-group__price {
    margin-bottom: 12px;
  }

  .list-group__event-details {
    font-size: 20px;
    line-height: 30px;
  }

  .list-group__event-details .list-group-item {
    gap: 11px;
  }

  .listing-filters .ts-control {
    padding: 12px 16px 14px;
  }

  .listing-filters .form-control__datepicker {
    padding: 12px 46px 14px 16px;
  }

  .listing-filters .ts-wrapper:not(.form-select).single .ts-control,
  .listing-filters .form-control__datepicker {
    padding-left: 20px;
  }

  .listing-filters .ts-control,
  .listing-filters .ts-control input,
  .listing-filters .ts-dropdown,
  .listing-filters .form-control__datepicker {
    font-size: 20px;
    line-height: 26px;
  }

  .listing-filters .ts-control input,
  .listing-filters .form-control__datepicker {
    letter-spacing: 0.4px;
  }

  .listing-filters .ts-wrapper:not(.form-select).single .ts-control {
    background-position: right 19px center;
    background-size: 22px 19px;
    padding-right: 46px;
  }

  .listing-filters .form-datepicker::after {
    top: 19px;
    right: 14px;
    width: 22px;
    height: 19px;
    background-size: 22px 19px;
  }

  .current-listings.three-cols-xl .item {
    width: 33.33%;
    border-right: 1px solid #000;
  }

  .current-listings.three-cols-xl .item:nth-child(3n + 3) {
    width: 33.33%;
    border-right: 0;
  }

  /*!
       * .content__event-details 1200
       */
  .section__event-details .content__event-details {
    padding-left: 20px;
  }

  .section__event-details .sticky__tabTicketInfoVenue {
    margin-top: -25px;
  }

  .nav-tabs__info-venue .tab-content .heading {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 14px;
  }

  hr.event__name-hr {
    height: 3px;
  }

  .event__name + .event__name-hr {
    margin-top: 21px;
    margin-bottom: 18px;
  }

  .event__header-text .badge {
    font-size: 18px;
    letter-spacing: 0.36px;
    padding: 8px 24px 7px;
  }

  .event__location {
    margin-bottom: 18px;
  }

  .btn-citymapper {
    font-size: 16px;
  }

  .event .event__name {
    margin-top: -6px;
  }

  .event .event__header-image {
    width: 360px;
  }

  .event .event__header-text {
    padding-left: 20px;
  }

  .nav-tabs__info-venue .tab-content {
    padding: 12px;
  }

  .nav-tabs__info-venue .list-group__location,
  .nav-tabs__info-venue .list-group__time,
  .nav-tabs__info-venue .list-group__price {
    margin-bottom: 5px;
  }

  .nav-tabs__info-venue .list-group__event-details {
    padding-left: 2px;
  }

  .nav-tabs__info-venue .icon__clock {
    top: -5px;
  }

  .nav-tabs__info-venue .icon__payment-card {
    top: -4px;
  }

  .nav-tabs__info-venue .list-group + hr {
    margin: 5px 0 15px;
  }

  .content__event-details .event__header {
    margin-bottom: 15px;
  }

  .content__event-details .event__body {
    padding-top: 20px;
  }

  /*!
       * .content__slidein 1200
       */
  .slidein-wrapper {
    top: 91px;
    height: calc(100vh - 91px);
  }

  .slidein .content__slidein,
  .slidein .content__checkout {
    min-height: calc(100vh - 91px);
  }

  .slidein .content__checkout > .content {
    min-height: calc(100vh - 168px);
  }

  /*!
       * .content__checkout 1200
       */
  .content__select-time .p-label {
    margin-bottom: 23px;
  }

  .content__select-time > .p-label:first-child {
    margin-bottom: 25px;
  }

  .content__select-time .p-label + .p-value {
    margin-top: 1px;
    margin-bottom: 28px;
  }

  /*!
        * .content__my-wallet 1200
        */
  .section__my-wallet .content h3,
  .section__my-wallet .content .h3 {
    letter-spacing: 0;
  }

  .content__checkout .btn.btn-xxs,
  .content__my-wallet .btn.btn-xxs {
    min-width: 106px;
  }

  .content__my-wallet .p-top-up {
    padding-left: 1px;
  }

  .content__my-wallet .p-top-up .btn {
    margin-left: 30px;
    min-width: 176px;
    padding-bottom: 12px;
  }

  .content__my-wallet .heading__why-top-up {
    margin-top: 30px;
  }

  .content__my-wallet .big-heading h1,
  .content__my-wallet .big-heading .h1 {
    font-size: 120px;
    line-height: 112px;
  }

  .content__my-wallet .col__transaction-history .heading {
    font-size: 40px;
    line-height: 41px;
    margin-bottom: 22px;
  }

  .card__credit-balance .card-body {
    background-size: 172px auto;
    padding-left: 190px;
  }

  .card__credit-balance .card-body .h3 {
    font-size: 40px;
    letter-spacing: 0;
    line-height: 45px;
    margin-bottom: 10px;
  }

  .card__credit-balance .card-body .wallet-balance {
    font-size: 58px;
    line-height: 64px;
    margin-left: -1px;
  }

  .card__transaction-summary + .card__transaction-summary {
    margin-top: 23px;
  }

  .content__my-wallet .card__credit-balance {
    margin-bottom: 23px;
  }

  .content__my-wallet .col__top-up-info {
    padding-right: 40px;
  }

  .content__my-wallet .col__transaction-history {
    padding-left: 40px;
  }

  .content__checkout .content__wallet-topup-success .content__header {
    padding-top: 60px;
  }

  /*!
       * .content__my-bookings 1200
       */
  .list__my-bookings .card__event .card-title {
    font-size: 30px;
    letter-spacing: 0.6px;
    line-height: 40px;
  }

  .list__my-bookings .card__event .card-footer .buttons-wrapper {
    gap: 16px;
  }

  .list__my-bookings .buttons-wrapper {
    align-items: center;
  }

  .content__my-bookings .list__my-bookings .card__event .card-body {
    padding-right: 100px;
    padding-left: 50px;
  }

  .content__my-bookings .list__my-bookings .card__event {
    padding: 47px 47px 27px;
    width: 96%;
  }

  .content__my-bookings .list__my-bookings .card__event .card-footer {
    padding-top: 1px;
  }

  .content__manage-booking p + .buttons-wrapper {
    margin-top: 30px;
  }

  .content__manage-booking .heading__remaining-tickets {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .content__manage-booking-other-requests-received .big-heading h1 {
    font-size: 108px;
    line-height: 100px;
  }

  /*!
       * .content__my-favourites 1200
       */
  .content__my-favourites .content__header {
    padding-top: 21px;
    padding-bottom: 32px;
  }

  /*!
       * .content__leave-feedback 1200
       */
  .content__leave-feedback {
    background-color: #fff;
    max-width: 1200px;
    padding-left: 50px;
    padding-right: 50px;
    position: relative;
    z-index: 1;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
  }

  .content__leave-feedback > .content__body {
    max-width: 940px;
  }

  .container__leave-feedback {
    position: relative;
    overflow: hidden;
  }

  .container__leave-feedback::before,
  .container__leave-feedback::after {
    content: "";
    display: block;
    background-repeat: no-repeat;
    position: absolute;
    width: 298px;
    height: 3864px;
    background-image: url(../images/tickets/bg-tickets-leave-feedback.svg);
    background-size: 298px auto;
    top: 137px;
  }

  .container__leave-feedback::before {
    left: 0;
  }

  .container__leave-feedback::after {
    right: 0;
  }

  .container__leave-feedback .content__body {
    padding-top: 60px;
    padding-bottom: 25px;
  }

  .content__leave-feedback .card__event {
    margin-bottom: 60px;
  }

  .content__leave-feedback .card__event .card__event-image {
    margin-bottom: 61px;
  }

  .content__leave-feedback .card__event .card-title {
    font-size: 50px;
    line-height: 60px;
  }

  .content__leave-feedback .form-group__heading + div {
    margin-top: 44px;
  }

  .content__leave-feedback .form-group + .form-group {
    margin-top: 64px;
  }

  .content__leave-feedback .list__btn-checks {
    gap: 29px;
    margin-bottom: 36px;
  }

  .content__leave-feedback .list__btn-checks-circle {
    gap: 29px 44px;
  }

  .content__leave-feedback .btn-xl-lg {
    padding-top: 18px;
    padding-bottom: 16px;
  }

  .content__leave-feedback .input-group__amount {
    padding: 16px 21px 17px 25px;
  }

  .content__leave-feedback .input-group__amount .input-group-text,
  .content__leave-feedback .input-group__amount .form-control {
    font-size: 40px;
  }

  .content__leave-feedback .form-group + .form-group__cta {
    margin-top: 90px;
  }

  .content__leave-feedback .form-group__cta.d-flex .btn {
    margin-bottom: 18px;
  }

  /*!
       * .content__refer-friend 1200
       */
  .card__invite-link .heading {
    margin-bottom: 2px;
  }

  .card__invite-link .lead {
    font-size: 30px;
    line-height: 40px;
  }

  .card__invite-link .input-group__invite-link .btn {
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 24px;
    min-width: 126px;
  }

  .btn__modal-terms-conditions .btn__icon {
    width: 34px;
    position: relative;
    top: 2px;
  }

  .input-group__invite-link .form-control {
    font-size: 25px;
    line-height: 30px;
  }

  .content__refer-friend .card__invite-link {
    width: 1024px;
  }

  .content h3,
  .content .h3 {
    font-size: 30px;
    letter-spacing: 0.6px;
    line-height: 35px;
    margin-bottom: 20px;
  }

  .section__cta--huge {
    padding: 80px 60px;
  }

  main {
    padding-top: 91px;
    min-height: calc(100vh - 405px);
  }

  /*!
       * .content__no-working-page 1200
       */
  .content__no-working-page .content__header {
    padding-right: 50px;
    padding-left: 50px;
  }

  .content__no-working-page .big-heading h1,
  .content__no-working-page .big-heading .h1 {
    font-size: 100px;
    line-height: 108px;
  }

  /*!
       * .content__uptake 1200
       */
  .progress-xl-xl.progress-bar-overlay {
    height: 68px;
    font-size: 25px;
    letter-spacing: 0.5px;
  }

  .progress-xl-xl.progress-bar-overlay::before {
    height: 68px;
  }

  .card-title + .progress-bar-overlay {
    margin-top: 18px;
  }

  .content__uptake {
    max-width: 1500px;
    margin-bottom: 26px;
  }

  .content__uptake-event {
    margin-bottom: 30px;
  }

  .content__uptake-event .card__event {
    padding: 20px;
  }

  .content__uptake-event .status-wrapper {
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 24px;
    position: relative;
    top: 4px;
  }

  .content__uptake-event .card__event-status {
    padding: 14px 19px 16px;
  }

  .content__uptake-event .card__event .card-title {
    letter-spacing: 0.4px;
  }

  .content__uptake-event .card__event .card__event-image + .card-body {
    padding-left: 30px;
  }

  .content__uptake-event .card-body__row .col.order-md-1 {
    max-width: 250px;
  }

  .content__uptake-event .card-body > :only-child {
    margin-bottom: 0;
  }

  .content__uptake-event .row-uptake-list-progress {
    margin-bottom: 20px;
  }

  .content__uptake-event .status-wrapper.d-flex .heading {
    margin-right: 6px;
  }

  .content__uptake-event .logos-container {
    margin-top: 61px;
  }

  .content__uptake-event .logos-container .logo-nhs-charities {
    margin-right: 17px;
    width: 174px;
  }

  .content__uptake-event .logos-container .logo-ecologi {
    width: 136px;
  }

  .content__uptake-event .card__event + p {
    margin-top: -3px;
  }

  .content__uptake-event p + .logos-container {
    margin-top: 64px;
  }

  .card__uptake--stat .stat__heading {
    font-size: 50px;
    letter-spacing: 1px;
    line-height: 50px;
  }

  .card__uptake--stat .stat__text {
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 24px;
    margin-top: 5px;
    margin-bottom: 1px;
  }

  .card__uptake--progress .card-body {
    padding: 14px 29px 13px;
  }

  .content__uptake-event .status-wrapper,
  .card__uptake--progress .card-title,
  .card__uptake--details .list-group__uptake-head .list-group-item,
  .content__uptake-details .card__uptake .card-title {
    font-size: 25px;
    letter-spacing: 0.5px;
    line-height: 30px;
  }

  .card__uptake--members-feedback .card-body {
    padding: 14px 29px;
  }

  .card__uptake--details .card-body {
    padding: 19px 26px 17px;
  }

  .card__uptake--details .list__uptake-details {
    width: calc(100% - 150px);
  }

  .card__uptake--details .list__uptake-details + .buttons-wrapper {
    width: 150px;
    padding-right: 10px;
  }

  .card__uptake--details .uptake-item .item__body.border-top {
    padding-top: 18px;
  }

  .card__uptake--carousel .card-body {
    padding: 21px 29px 25px;
  }

  .card__uptake .p-donation {
    font-size: 21px;
    line-height: 27px;
  }

  .card__uptake .p-donation + .buttons-wrapper {
    margin-top: 26px;
  }

  .content__uptake > .content__body > .col__left {
    width: 400px;
  }

  .content__uptake > .content__body > .col__right {
    width: calc(100% - 400px);
    max-width: 1053px;
  }

  .list__uptake-details > li {
    width: 36%;
  }

  .list__uptake-details > .list-item__date,
  .list__uptake-details > .list-item__status {
    width: 58%;
  }

  .card__uptake--details .list__uptake-details {
    width: calc(100% - 200px);
  }

  .card__uptake--details .list__uptake-details + .buttons-wrapper {
    width: 200px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .content__uptake .col__left .content__uptake-event .content__body {
    padding-right: 0;
  }

  .card__uptake--carousel .buttons-wrapper {
    margin-bottom: 8px;
  }

  .list__uptake-details {
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 25px;
    row-gap: 12px;
    column-gap: 25px;
  }

  .list__uptake-stat {
    display: flex;
    flex-direction: row;
    gap: 20px 30px;
  }

  .list__uptake-stat > li {
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 24px;
  }

  .list__uptake-stat > li::before {
    height: 65px;
  }

  .list__uptake-stat .stat__number {
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 2px;
  }

  .content__uptake .list__uptake-details .heading {
    min-width: 72px;
  }

  .content__uptake .list__uptake-details .list-item__allocated .heading,
  .content__uptake .list__uptake-details .list-item__booked .heading {
    min-width: 110px;
  }

  .content__uptake .card__uptake--details + .card__uptake--details {
    margin-top: 26px;
  }

  .card__uptake--thank-you .card-title {
    font-size: 30px;
    line-height: 42px;
  }

  .content__uptake-event .status-wrapper {
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 24px;
  }

  .uptake-progress-wrapper .heading {
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 24px;
    margin-bottom: 10px;
  }

  .progress-bar-overlay {
    height: 36px;
    font-size: 18px;
  }

  .progress-bar-overlay::before {
    height: 36px;
  }

  .content__uptake-details-carousel > .content__body {
    column-gap: 26px;
  }

  .content__uptake-details-carousel > .content__body > .col__left {
    width: calc(100% - 400px);
  }

  .content__uptake-details-carousel > .content__body > .col__right {
    width: 400px;
  }

  .card__uptake--carousel .logos-container .p-registered {
    font-size: 8px;
  }

  .card__uptake--carousel .btn {
    min-width: 233px;
  }

  .list-group__uptake-list .btn,
  .card__uptake--carousel .btn,
  .content__uptake .btn {
    padding-bottom: 8px;
  }

  .content__uptake-event .btn {
    padding-bottom: 9px;
  }

  .p-important-wrapper {
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 18px;
  }

  .owl-carousel__uptake .item__image {
    width: 245px;
    height: 163px;
  }

  .card__uptake--carousel .logos-container .logo-nhs-charities {
    width: 174px;
  }

  .card__uptake--carousel .logos-container .logo-ecologi {
    width: 136px;
  }

  .card__uptake--carousel .logos-wrapper,
  .card__uptake--carousel .logos-container .p-registered {
    width: 339px;
  }

  .content__uptake-venues .row-uptake-list-progress > .col__left,
  .content__uptake-venues .row-notice-buttons > .col__left {
    padding-right: 20px;
  }

  .content__uptake-venues .row-uptake-list-progress > .col__right,
  .content__uptake-venues .row-notice-buttons > .col__right {
    max-width: 300px;
  }

  .main__uptake .section__uptake {
    padding-top: 44px;
    padding-bottom: 30px;
  }

  .row__big-heading .content__uptake-venues {
    margin-bottom: 13px;
  }

  .content__uptake-venues .big-heading .ticket-yellow1 {
    top: -2.5%;
  }

  .main__uptake .section__uptake-venues {
    padding-top: 12px;
  }

  /*!
       * .main__new-look 1200
       */
  .main__new-look .content__what-we-do .big-heading .ticket-red {
    left: 1%;
  }

  .main__new-look .content__what-we-do .big-heading .ticket-blue {
    top: -3%;
    right: 8%;
  }

  .section__new-look .content__header {
    margin-bottom: 60px;
  }

  .main__new-look .container__for-members .image1 {
    width: 220px;
  }

  .main__new-look .container__for-venues .image1 {
    left: 19%;
    width: 240px;
  }

  .main__new-look .container__for-venues .image2 {
    right: 1%;
  }

  .main__new-look .content__new-look .big-heading h1,
  .main__new-look .content__new-look .big-heading .h1 {
    font-size: 15vw;
    letter-spacing: -1.3px;
  }

  .main__new-look .container__for-venues {
    padding-top: 0;
    padding-bottom: 0;
  }

  .main__new-look .section__ticket-booking-steps {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .main__new-look .section__faq {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  /*!
       * .section__reactive-account 1200
       */
  .section__reactive-account {
    display: flex;
    min-height: 80vh;
    align-items: center;
    padding-bottom: 100px;
  }

  .content__reactive-account .big-heading h1,
  .content__reactive-account .big-heading .h1 {
    font-size: 110px;
  }

  .content__reactive-account .big-heading .ticket-yellow {
    top: -10%;
    left: 4.5%;
  }

  .content__reactive-account .big-heading .ticket-green {
    top: -12%;
    left: 36%;
    width: 9%;
  }

  .content__reactive-account .big-heading .ticket-blue {
    left: 86%;
  }

  .content__reactive-account .big-heading .ticket-orange {
    left: 6.5%;
  }

  .content__reactive-account .big-heading .ticket-aquamarine {
    bottom: -17%;
    width: 8%;
  }

  .content__reactive-account .big-heading .ticket-red {
    bottom: 10%;
    right: 3.5%;
    width: 12%;
  }

  .content__reactive-account {
    max-width: 1138px;
  }

  .content__reactive-account .content__header {
    padding-bottom: 60px;
  }

  .main-footer {
    font-size: 20px;
    line-height: 24px;
    padding-top: 45px;
  }

  .main-footer .nav-heading {
    font-size: 21px;
    letter-spacing: 0.42px;
    line-height: 25px;
    margin-bottom: 25px;
  }

  .main-footer .container__footer-menu {
    padding: 0 40px 20px;
  }

  .main-footer .container__copyright {
    padding: 17px 40px 14px;
  }

  .container__footer-menu .col__sponsors .nav-item__logo--fsb {
    margin-left: 20px;
  }

  .container__footer-menu .col__sponsors .nav-item__logo--NHSCT,
  .container__footer-menu .col__sponsors .nav-item__logo--ecologi {
    margin-left: 30px;
  }

  .col__footer-text .col-left__columns {
    padding-bottom: 28px;
  }
}

/* END 1200 */
@media (min-width: 1280px) {
  /*!
       * .content__uptake 1280
       */
  .p-important-wrapper {
    font-size: 15px;
    line-height: 20px;
  }

  /*!
       * .main__new-look 1280
       */
  .main__new-look .section__ticket-booking-steps + .section__faq {
    margin-top: 40px;
  }
}

/* END 1280 */
@media (min-width: 1366px) {
  /*!
       * .content__uptake 1366
       */
  .content__uptake-event .card-body__row .col.order-md-1 {
    max-width: 291px;
  }

  .content__uptake-event .card-body__row .col.order-md-2 {
    padding-left: 60px;
  }

  .content__uptake .list__uptake-details .heading {
    min-width: 78px;
  }

  .content__uptake .list__uptake-details .list-item__allocated .heading,
  .content__uptake .list__uptake-details .list-item__booked .heading {
    min-width: 110px;
  }

  .main__contact-us .container {
    max-width: 1233px;
  }

  .container__footer-menu .col__sponsors .nav-item__logo--fsb {
    margin-left: 26px;
  }

  .container__footer-menu .col__sponsors .nav-item__logo--NHSCT,
  .container__footer-menu .col__sponsors .nav-item__logo--ecologi {
    margin-left: 36px;
  }
}

/* END 1366*/
@media (min-width: 1400px) {
  .form-groups-wrapper.flex-xxl-row {
    margin-bottom: 30px;
  }

  .main-header__main-menu .nav-item {
    margin-right: 15px;
    margin-left: 15px;
  }

  .main-header__navbar .nav-link {
    font-size: 22px;
  }

  /*!
       * .owl-carousel__home-hero 1400
       */
  .owl-carousel__home-hero .hero-header {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .container__for-members {
    padding-top: 200px;
  }

  /*!
       * .content__membership-is-free 1400
       */
  .content__membership-is-free {
    padding-top: 10vh;
  }

  .content__membership-is-free .content__body {
    max-width: 1001px;
  }

  .feature__ticket-booking-step {
    margin-bottom: 50px;
  }

  .feature__ticket-booking-step + .buttons-wrapper {
    margin-top: -14px;
  }

  .content__ticket-booking-steps {
    padding-left: 80px;
  }

  /*!
       * .content__NHSCT 1400
       */
  .content__NHSCT .lead {
    font-size: 30px;
    letter-spacing: 0.7px;
    line-height: 35px;
  }

  .content__about-us .content__body .col__left {
    padding-right: 50px;
  }

  .content__about-us .content__body .col__right {
    padding-left: 50px;
  }

  /*!
       * .feature__impact 1400
       */
  .feature__impact {
    width: 330px;
  }

  .feature__impact .heading {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 10px;
  }

  .feature__impact .feature__icon {
    padding-top: 32px;
    padding-bottom: 38px;
  }

  .feature__impact .feature__icon .icon {
    width: 170px;
  }

  /*!
       * .section__our-numbers 1400
       */
  .list__stats {
    gap: 60px 100px;
  }

  .list__stats .item__heading {
    margin-bottom: 12px;
  }

  .heading + .list__stats {
    margin-top: 9vh;
  }

  .main__about-us .content__list-values {
    max-width: 1000px;
  }

  .spy-scrollbar {
    width: 140px;
    padding-left: 24px;
  }

  /*!
       * .content__event-details 1400
       */
  .section__event-details .container {
    max-width: 85%;
  }

  .section__event-details .content__event-details {
    width: calc(100% - 430px);
  }

  .nav-tabs__info-venue-wrapper {
    width: 400px;
    right: 8.25%;
  }

  .nav-tabs__info-venue .tab-content > .tab-pane {
    min-height: 425px;
  }

  /*!
       * .content__my-favourites 1400
       */
  /*!
       * .content__leave-feedback 1400
       */
  /*!
       * .content__refer-friend 1400
       */
  /*!
       * .content__register 1400
       */
  .content__contact-us .col__left .content__body,
  .content__contact-us .col__right .content__body,
  .content__register .col__left .content__body,
  .content__register .col__right .content__body {
    padding: 30px 40px;
  }

  .content__register .form-control {
    min-width: 370px;
  }

  .content__register .row__form-fields > div:first-child {
    padding-right: 20px;
  }

  .content__register .row__form-fields > div:nth-child(2) {
    padding-left: 20px;
  }

  .content__register-part2 .col__right .content {
    max-width: 900px;
  }

  /*!
       * .content__contact-us 1400
       */
  .flex-xxl-row-3cols > div {
    width: calc(33.33% - 15px);
  }

  .flex-xxl-row-2cols > div {
    width: calc(50% - 15px);
  }

  .content__contact-form .form-groups-wrapper.flex-xxl-row {
    column-gap: 30px;
  }

  .content__contact-form-organizer
    .form-groups-wrapper.flex-xxl-row
    .form-group {
    width: 33.33%;
  }

  .content__contact-us .content__event-details .content__body {
    padding-top: 24px;
  }

  .content__contact-us .list__my-bookings .card__event {
    max-width: 1413px;
  }

  .content__contact-us .list__my-bookings .card__event .card-body {
    padding-left: 98px;
  }

  /*!
       * .content__my-account 1400
       */
  .content__my-account .big-heading .h4 {
    font-size: 50px;
    line-height: 60px;
  }

  .content__my-account .nav-tabs.flex-column {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 330px;
  }

  .content__my-account .nav-tabs.flex-column .nav-item {
    margin-bottom: 15px;
    padding-left: 25px;
    padding-right: 25px;
  }

  .content__my-account .nav-tabs.flex-column .nav-link,
  .content__my-account .tab-content .heading {
    font-size: 25px;
    line-height: 35px;
  }

  .content__my-account .nav-tabs.flex-column + .tab-content {
    padding: 25px 40px;
  }

  .content__my-account .tab-pane__DeleteAccount .content {
    max-width: 790px;
  }

  /*!
       * .content__current-listings 1400
       */
  .listing-filters {
    padding-top: 37px;
    padding-bottom: 28px;
  }

  #owlCarouselEventTagsNav {
  }

  #owlCarouselEventTagsNav button.owl-prev,
  #owlCarouselEventTagsNav button.owl-next {
    width: 69px;
  }

  .content__current-listings .owl-carousel__events .event-image {
    width: 420px;
  }

  .content__current-listings .listing-filters .form-groups-wrapper__search {
    padding-left: 100px;
  }

  /*!
       * .content__checkout 1400
       */
  .content__checkout .content__checkout > .content__header,
  .content__checkout .content__footer .buttons-wrapper {
    display: flex;
    align-items: stretch;
  }

  .custom-control.custom-checkbox__check {
    padding-left: 42px;
  }

  .content__checkout
    .input-group__as-checkbox
    .custom-control.custom-checkbox__check {
    padding-left: 30px;
  }

  .content__checkout .btn-close__absolute {
    right: 30px;
  }

  .content__order-summary .logos-wrapper {
    column-gap: 30px;
  }

  /*!
       * .content__my-wallet 1400
       */
  .content__my-wallet .card__credit-balance {
    margin-bottom: 30px;
  }

  .content__my-wallet h3,
  .content__my-wallet .h3 {
    font-size: 40px;
    line-height: 41px;
  }

  .content__my-wallet .col__top-up-info {
    padding-right: 60px;
  }

  .content__my-wallet .col__transaction-history {
    padding-left: 60px;
  }

  /*!
       * .content__my-bookings 1400
       */
  .list__my-bookings .card__event .card-title {
    margin-bottom: 0;
  }

  .list__my-bookings .card__event .list__event-details {
    font-size: 30px;
    letter-spacing: 0.6px;
    line-height: 40px;
  }

  .list__my-bookings .card__event .list__event-details > li {
    margin-bottom: 0;
  }

  /*!
       * .content__uptake 1400
       */
  .content__uptake-event .card__event {
    padding: 30px;
  }

  .content__uptake-event .card__event .card__event-image + .card-body {
    padding-left: 50px;
  }

  .content__uptake-event .card-body__row .col.order-md-2 {
    padding-left: 71px;
  }

  .list__uptake-stat {
    column-gap: 50px;
  }

  .list-group__uptake-head .list-group-item > .flex-lg-row + .flex-lg-row {
    margin-top: 0;
    margin-left: 30px;
  }

  .card__uptake--details .uptake-item .item__header + .item__body {
    margin-top: 14px;
  }

  .content__uptake-venues .card__event .card__event-image + .card-body {
    padding-left: 65px;
  }

  .content__uptake-venues .row-uptake-list-progress > .col__right,
  .content__uptake-venues .row-notice-buttons > .col__right {
    max-width: 320px;
  }

  /*!
       * .main__new-look 1400
       */
  .main__new-look .section__faq .content__header {
    margin-bottom: 7vh;
  }

  .main__new-look .section__faq {
    padding-bottom: 120px;
  }

  .main__new-look .section__ticket-booking-steps + .section__faq {
    margin-top: 80px;
  }
}

/* END 1400 */
@media (min-width: 1500px) {
  .main__new-look .content__what-we-do .big-heading img.ticket-green,
  .main__new-look .content__what-we-do .big-heading img.ticket-aquamarine,
  .main__new-look .content__what-we-do .big-heading img.ticket-blue {
    z-index: 1;
  }

  /*!
       * .main__new-look 1500
       */
  .main__new-look .content__what-we-do .big-heading .ticket-red {
    left: 1%;
  }

  .main__new-look .section__ticket-booking-steps + .section__faq {
    margin-top: 140px;
  }

  .main__new-look .content__questions .big-heading .ticket-blue {
    right: 6%;
  }
}

/* END 1500 */
@media (min-width: 1680px) {
  .main-header__main-menu .nav-item {
    margin-right: 25px;
    margin-left: 25px;
  }

  .content__header--template {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .content__body--template {
    padding: 40px;
  }

  .section__what-we-do .btn {
    min-width: 180px;
  }

  .container__for-venues .image1 {
    width: 326px;
  }

  .container__for-members .image1 {
    width: 306px;
  }

  /*!
       * .content__membership-is-free 1680
       */
  .content__membership-is-free {
    padding-top: 20vh;
  }

  .content__membership-is-free .big-heading .h1 {
    font-size: 10.5vw;
    line-height: 1em;
  }

  /*!
       * .section__ticket-booking-steps 1680
       */
  .feature__ticket-booking-step .feature__icon {
    width: 60px;
    margin-right: 50px;
  }

  .feature__ticket-booking-step .step {
    min-width: 66px;
  }

  .feature__ticket-booking-step + .buttons-wrapper {
    padding-left: 110px;
  }

  .content__just-few-steps {
    padding-right: 50px;
    padding-left: 69px;
  }

  .content__just-few-steps .content__header {
    margin-bottom: 110px;
  }

  .section__ticket-booking-steps .content__left .content__body {
    font-size: 35px;
    line-height: 42px;
    padding-right: 60px;
    padding-left: 4px;
  }

  .content__ticket-booking-steps {
    padding-right: 140px;
  }

  /*!
       * .section__our-values 1680
       */
  .list__values .number {
    font-size: 50px;
    width: 90px;
    height: 90px;
  }

  .list__values h3 {
    font-size: 50px;
    line-height: 55px;
    margin-bottom: 25px;
  }

  .list__values .item__number {
    width: 130px;
  }

  .list__values .item__text {
    padding-top: 18px;
  }

  .list__values > li .item {
    padding-top: 12px;
    padding-bottom: 40px;
  }

  .list__values > li:not(:last-child) .item {
    padding-bottom: 40px;
  }

  .content__list-values {
    padding-top: 80px;
  }

  .spy-scrollbar {
    left: 21px;
  }

  /*!
       * Guidelines page 1680
       * .content__mg-section1 1680
       */
  .content__mg-section1 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .content__mg-section1 .big-heading .ticket-orange {
    top: 7.6%;
    left: 15.35%;
    width: 72px;
  }

  .content__mg-section1 .big-heading .ticket-blue {
    bottom: 10.8%;
    left: 7.3%;
    width: 74px;
  }

  .content__mg-section1 .big-heading .ticket-green {
    bottom: 18.95%;
    left: 40.6%;
    width: 79px;
  }

  .content__mg-section1 .big-heading .ticket-aquamarine {
    bottom: 15.25%;
    right: 4%;
    width: 131px;
  }

  .content__mg-section1 .big-heading .ticket-yellow {
    top: 36.65%;
    right: 27.95%;
    width: 84px;
  }

  .section__mg-section6 {
    background-position: center -28px;
    background-size: 1749px auto;
  }

  .main__membership-guidelines .screen07 {
    padding-bottom: 115px;
  }

  /* .content__screen01 1680 */
  .content__screen01 .big-heading .ticket-orange {
    top: 17.55%;
    left: 23.4%;
  }

  .content__screen01 .big-heading .ticket-blue {
    bottom: -12%;
    left: 31.15%;
  }

  .content__screen01 .big-heading .ticket-green {
    top: 37%;
    left: 41.15%;
  }

  .content__screen01 .big-heading .ticket-aquamarine {
    bottom: -16.85%;
    right: 35.9%;
  }

  .content__screen01 .big-heading .ticket-yellow {
    top: -2.25%;
    right: 23.25%;
  }

  .main__membership-guidelines .screen07 {
    padding-bottom: 60px;
  }

  /* .content__screen07 1680 */
  .content__screen07 .big-heading .ticket-orange1 {
    top: -11.36%;
    left: -13.05%;
  }

  .content__screen07 .big-heading .ticket-green1 {
    top: -16.15%;
    right: 35.65%;
  }

  .content__screen07 .big-heading .ticket-yellow1 {
    top: 5.75%;
    right: -12.05%;
  }

  .content__screen07 .big-heading .ticket-green3 {
    top: 23.85%;
    left: -38.1%;
  }

  .content__screen07 .big-heading .ticket-green2 {
    top: 28.7%;
    left: 25.45%;
  }

  .content__screen07 .big-heading .ticket-blue1 {
    bottom: 31.65%;
    left: -11.25%;
  }

  .content__screen07 .big-heading .ticket-aquamarine1 {
    bottom: 34.1%;
    right: 20.8%;
  }

  .content__screen07 .big-heading .ticket-blue2 {
    bottom: 37%;
    right: -28.4%;
  }

  .content__screen07 .big-heading .ticket-yellow2 {
    bottom: -14.75%;
    left: -25.95%;
  }

  .content__screen07 .big-heading .ticket-orange2 {
    bottom: -18.95%;
    left: 41.15%;
  }

  .content__screen07 .big-heading .ticket-aquamarine2 {
    bottom: -19%;
    right: -25.4%;
  }

  /*!
         * .content__faq 1680
         */
  .content__faq .content__header {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  /*!
        * .content__register 1680
        */
  .content__register .form-control {
    min-width: 440px;
  }

  .content__register .row__checkboxes {
    margin-top: 29px;
  }

  .content__register .row__cta {
    margin-top: 42px;
  }

  .content__register .col__left .feature {
    padding-left: 23px;
  }

  .content__register .col__left .feature {
    margin-top: 103px;
  }

  /*!
       * .content__contact-us 1680
       */
  .content__contact-us .col__left .feature {
    margin-top: 64px;
  }

  .section__contact-us .form-group__cta.flex-sm-row {
    column-gap: 40px;
  }

  .content__contact-us .col__right > .content > .content__body {
    padding-right: 69px;
    padding-left: 69px;
  }

  .content__contact-us .col__right .content__contact-form > .content__body {
    padding-left: 59px;
  }

  .content__contact-us .content__event-details {
    padding-top: 0;
  }

  .content__contact-us .content__event-details .card__event .card__event-image {
    margin-bottom: 38px;
  }

  .content__contact-clues .heading + .contact-clues {
    margin-top: 54px;
  }

  /*!
       * .content__my-account 1680
       */
  .section__my-account {
    padding-bottom: 106px;
  }

  .content__my-account .content__header {
    padding-top: 32px;
  }

  .content__my-account .big-heading h1 {
    font-size: 50px;
    line-height: 55px;
  }

  .content__my-account .nav-tabs.flex-column {
    padding-top: 25px;
    padding-bottom: 25px;
    width: 420px;
  }

  .content__my-account .nav-tabs.flex-column .nav-item {
    margin-bottom: 12px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .content__my-account .nav-tabs.flex-column .nav-link,
  .content__my-account .tab-content .heading {
    font-size: 30px;
    line-height: 40px;
  }

  .content__my-account .tab-content .form-group + .heading__your-area-2,
  .content__my-account .accordion-collapse .form-group + .heading__your-area-2 {
    margin-top: 41px;
  }

  .content__my-account .tab-content .heading__your-area-2,
  .content__my-account .accordion-collapse .heading__your-area-2 {
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 24px;
    margin-bottom: 32px;
  }

  .content__my-account .nav-tabs.flex-column + .tab-content {
    padding: 30px 60px;
  }

  .content__my-account-home {
    padding: 0 51px;
  }

  .content__my-account-home .big-heading .h4 {
    font-size: 60px;
    line-height: 70px;
  }

  .content__my-account-home .content__header {
    padding-top: 73px;
    padding-bottom: 70px;
  }

  .list-nav-link-card.flex-md-row {
    column-gap: 58px;
    row-gap: 55px;
  }

  .list-nav-link-card.flex-md-row > li {
    width: calc(50% - 58px);
  }

  .content__my-account-home .list-nav-link-card > li {
    min-height: 290px;
  }

  .content__my-account-home .nav-link__card {
    border-width: 3px;
  }

  /*!
       * .content__current-listings 1680
       */
  .content__current-listings
    .listing-filters
    .form-groups-wrapper-4
    .form-group {
    width: 282px;
  }

  .content__current-listings .listing-filters .form-groups-wrapper-4 {
    row-gap: 0;
    width: calc(100% - 378px);
  }

  .content__current-listings .listing-filters .form-groups-wrapper__search {
    flex-shrink: 0;
    padding-left: 0;
    width: 378px;
  }

  .list-group__event-details {
    font-size: 25px;
  }

  .list-group__location {
    margin-bottom: 15px;
  }

  .list-group__price {
    margin-bottom: 14px;
  }

  .list-group__time {
    margin-bottom: 16px;
  }

  .current-listings .card__event {
    padding: 25px;
  }

  .card__event .icon__location-pin {
    top: -5px;
  }

  .card__event .card-title {
    margin-bottom: 21px;
  }

  .card__event .card-body {
    padding: 28px 0;
  }

  .card__event .card-footer {
    padding-top: 5px;
  }

  /*!
       * .content__event-details 1680
       */
  .event .event__header-image {
    width: 420px;
  }

  .content__event-details {
    padding-top: 15px;
  }

  .section__event-details .content__event-details {
    padding-right: 40px;
    padding-left: 0;
  }

  .section__event-details .content__event-details {
    width: calc(100% - 524px);
  }

  .nav-tabs__info-venue-wrapper {
    width: 494px;
  }

  .nav-tabs__info-venue .nav-tabs .nav-link {
    padding-top: 11px;
    padding-bottom: 11px;
  }

  .nav-tabs__info-venue .list-group__location,
  .nav-tabs__info-venue .list-group__time,
  .nav-tabs__info-venue .list-group__price {
    margin-bottom: 12px;
  }

  .nav-tabs__info-venue .tab-content {
    padding: 30px 19px 20px;
  }

  /*!
       * .content__my-wallet 1680
       */
  .content__my-wallet .content__header {
    padding-top: 49px;
    padding-bottom: 48px;
  }

  .content__my-wallet .p-top-up .btn {
    margin-left: 46px;
  }

  .content__my-wallet .heading__why-top-up {
    margin-bottom: 31px;
  }

  .content__my-wallet p + .p-terms {
    margin-top: 9px;
  }

  .content__my-wallet .heading__why-top-up ~ p {
    margin-bottom: 26px;
  }

  .content__my-wallet .card__transaction-summary {
    font-size: 20px;
    line-height: 25px;
  }

  .content__my-wallet .card__transaction-summary .card-body {
    padding: 17px 21px 16px;
  }

  .content__my-wallet .card__transaction-summary .card-footer {
    padding: 0 21px 21px 21px;
  }

  .content__my-wallet .card__transaction-summary .wallet-balance {
    position: relative;
    top: 5px;
  }

  .content__my-wallet .card__transaction-summary .card-footer__hr {
    margin-bottom: 23px;
  }

  .card__transaction-summary .transaction-amount {
    font-size: 35px;
  }

  .content__my-wallet .col__top-up-info {
    padding-right: 90px;
  }

  .content__my-wallet .col__transaction-history {
    padding-left: 90px;
    padding-bottom: 61px;
  }

  /*!
       * .content__checkout 1680
       */
  .content__checkout .btn__payment-method + .heading {
    margin-top: 46px;
  }

  .content__checkout .content__checkout > .content__header {
    min-height: 64px;
  }

  .wallet-balance__text .wallet-balance {
    font-size: 40px;
    line-height: 35px;
  }

  .content__checkout .input-group__as-form-control {
    padding: 10px 16px 12px 20px;
  }

  .content__checkout .input-group__amount {
    padding-top: 9px;
    padding-bottom: 11px;
  }

  /*!
       * .content__my-bookings 1680
       */
  .content__my-bookings .list__my-bookings .card__event {
    padding-left: 44px;
    padding-right: 54px;
    width: 1417px;
  }

  .content__my-bookings .list__my-bookings .card__event .card-body {
    padding-right: 200px;
    padding-left: 99px;
  }

  .content__my-bookings .list__my-bookings .card__event {
    width: 80%;
  }

  /*!
       * .content__my-favourites 1680
       */
  /*!
       * .content__leave-feedback 1680
       */
  /*!
       * .content__refer-friend 1680
       */
  .card__invite-link .card-body {
    padding: 51px 50px 47px;
  }

  .card__invite-link .lead + .form-group {
    margin-top: 63px;
  }

  .card__invite-link .form-group + .form-group__cta {
    margin-top: 60px;
  }

  .content__refer-friend .card__invite-link {
    width: 1104px;
  }

  .modal__terms-and-conditions .content {
    padding-right: 100px;
  }

  .modal__terms-and-conditions .content {
    padding-right: 350px;
  }

  .modal__terms-and-conditions .flex-xl-row .content__header {
    width: 580px;
    padding-right: 60px;
  }

  /*!
       * .content__uptake 1680
       */
  .content__uptake-event .card-body > :only-child {
    margin-bottom: 0;
  }

  .list__uptake-stat {
    column-gap: 71px;
  }

  .list__uptake-stat > li {
    padding-left: 12px;
  }

  .list__uptake-stat > li::before {
    height: 77px;
  }

  .list__uptake-stat .stat__number {
    font-size: 55px;
    letter-spacing: 1.1px;
    line-height: 55px;
    margin-bottom: 7px;
  }

  .content__uptake-event .status-wrapper {
    position: relative;
    top: 3px;
  }

  .content__uptake-event .status-wrapper .status {
    margin-left: 3px;
    padding: 3px 14px 4px;
    min-width: 106px;
  }

  .content__uptake-event .row-event-title-status {
    position: relative;
    top: -2px;
  }

  .content__uptake-event .row-uptake-list-progress {
    margin-top: 24px;
    margin-bottom: 29px;
  }

  .content__uptake-event .uptake-progress-wrapper {
    padding-top: 9px;
  }

  .content__uptake-event .row-notice-buttons .buttons-wrapper {
    padding-bottom: 7px;
  }

  .content__uptake-event .p-important-wrapper {
    padding-top: 8px;
  }

  .content__uptake-event .card__event {
    padding: 45px 61px 42px 47px;
  }

  .list__uptake-details > li {
    width: 26%;
  }

  .list__uptake-details > .list-item__date,
  .list__uptake-details > .list-item__status {
    width: 64%;
  }

  .card__uptake--details .list__uptake-details + .buttons-wrapper {
    padding-right: 29px;
  }

  .content__uptake-details-carousel > .content__body > .col__right {
    width: 489px;
  }

  .content__uptake-details-carousel > .content__body > .col__left {
    width: calc(100% - 489px);
  }

  .card__uptake--carousel .p-donation .d-lg-none {
    display: block !important;
  }

  .content__uptake-venues .card__event {
    padding: 45px 60px 38px 45px;
  }

  .content__uptake-venues .list__uptake-stat {
    margin-top: -5px;
    margin-bottom: 5px;
    column-gap: 44px;
  }

  .content__uptake-venues .row-uptake-list-progress > .col__right,
  .content__uptake-venues .row-notice-buttons > .col__right {
    max-width: 374px;
  }

  .content__uptake-venues .row-uptake-list-progress {
    margin-top: 27px;
    margin-bottom: 41px;
  }

  .card__uptake--carousel .card-body {
    padding: 38px 38px 37px;
  }

  .card__uptake .p-donation + .buttons-wrapper {
    margin-top: 48px;
  }

  .card__uptake--carousel .logos-container {
    margin-top: 61px;
  }

  .card__uptake--carousel .logos-wrapper,
  .card__uptake--carousel .logos-container .p-registered {
    width: 347px;
  }

  .card__uptake--carousel .logos-wrapper {
    column-gap: 37px;
  }

  .container__footer-menu .col__sponsors .nav-item__logo--cyber-essentials {
    margin-left: 38px;
  }

  .container__footer-menu .col__sponsors .nav-item__logo--fsb {
    margin-left: 36px;
  }

  .container__footer-menu .col__sponsors .nav-item__logo--NHSCT,
  .container__footer-menu .col__sponsors .nav-item__logo--ecologi {
    margin-left: 46px;
  }

  .container__footer-menu .col-office-address {
    width: 37%;
  }

  /*!
       * .main__new-look 1680
       */
  .main__new-look .container__for-members .image1 {
    right: 26%;
    width: 260px;
  }

  .main__new-look .container__for-venues .image1 {
    top: 83.45%;
    left: 23%;
    width: 280px;
  }

  .main__new-look .container__for-venues .image2 {
    right: 5%;
  }

  .main__new-look .section__ticket-booking-steps {
    padding-bottom: 120px;
  }

  .main__new-look .section__what-we-do {
    padding-top: 120px;
    padding-bottom: 110px;
  }

  .main__new-look .section__ticket-booking-steps + .section__faq {
    margin-top: 180px;
  }

  .main-footer .container__footer-menu,
  .main-footer .container__copyright {
    padding-right: 65px;
    padding-left: 65px;
  }
}

/* END 1680 */
@media (min-width: 1800px) {
  .btn-xl-lg {
    padding-top: 19px;
    padding-bottom: 18px;
  }

  .form-control,
  .form-control__datepicker {
    padding: 14px 20px 14px;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
  }

  .ts-wrapper:not(.form-select).single .ts-control {
    background-position: right 22px center;
  }

  .ts-wrapper:not(.form-select).single .ts-control,
  .form-control__datepicker {
    padding-right: 50px;
  }

  .form-datepicker::after {
    right: 23px;
  }

  .btn-primary {
    padding-right: 40px;
    padding-left: 40px;
  }

  .btn-huge {
    font-size: 160px;
  }

  .promo-code__form-group .icon__plus {
    width: 33px;
    height: 33px;
  }

  .promo-code__form-group .icon__plus::before,
  .promo-code__form-group .icon__plus::after {
    height: 33px;
    left: 15.5px;
  }

  .section__cta--huge .btn-huge {
    height: 356px;
  }

  /*!
       * .owl-carousel__home-hero 1800
       */
  .owl-carousel__home-hero .hero__content {
    padding-left: 47px;
    padding-right: 50px;
  }

  .owl-carousel__home-hero .hero-header {
    padding-top: 41px;
    padding-bottom: 48px;
  }

  .owl-carousel__home-hero .item1 .hero-header {
    padding-top: 48px;
    padding-bottom: 71px;
  }

  .owl-carousel__home-hero .hero-header h1 {
    font-size: 150px;
    line-height: 145px;
  }

  .owl-carousel__home-hero .item1 .hero-header h1 {
    line-height: 130px;
  }

  .owl-carousel__home-hero .hero-body {
    margin-bottom: 73px;
  }

  .content__what-we-do {
    padding-left: 14px;
    padding-bottom: 90px;
  }

  .content__what-we-do .big-heading img.ticket-yellow,
  .content__what-we-do .big-heading img.ticket-green,
  .content__what-we-do .big-heading img.ticket-aquamarine,
  .content__what-we-do .big-heading img.ticket-blue,
  .content__questions .big-heading img.ticket-yellow,
  .content__questions .big-heading img.ticket-green,
  .content__questions .big-heading img.ticket-aquamarine,
  .content__questions .big-heading img.ticket-blue,
  .content__membership-is-free .big-heading img.ticket-blue,
  .content__membership-is-free .big-heading img.ticket-yellow,
  .content__NHSCT .big-heading img.ticket-orange,
  .content__about-us .big-heading img.ticket-blue,
  .content__about-us .big-heading img.ticket-green,
  .content__about-us .big-heading img.ticket-yellow,
  .content__about-us .big-heading img.ticket-aquamarine,
  .content__our-values .big-heading img.ticket,
  .content__screen01 .big-heading img.ticket,
  .content__current-listings .big-heading img.ticket,
  .content__my-account-home .big-heading img.ticket-yellow,
  .content__my-bookings .big-heading img.ticket,
  .content__my-wallet .big-heading img.ticket-aquamarine,
  .content__my-favourites .big-heading img.ticket,
  .content__refer-friend .big-heading img.ticket:not(.ticket-red),
  .content__faq .big-heading img.ticket,
  .content__register .big-heading img.ticket-orange,
  .content__register .big-heading img.ticket-yellow {
    z-index: 1;
  }

  /*!
       * .content__for-venues 1800
       */
  .container__for-venues .image2 {
    top: 67.5%;
    right: 9.9%;
  }

  .container__for-venues {
    padding-top: 94px;
    padding-bottom: 90px;
  }

  .content__for-venues .sub-heading,
  .container__for-venues,
  .content__for-members .sub-heading,
  .container__for-members {
    font-size: 30px;
    line-height: 40px;
  }

  .content__for-venues .sub-heading-upper,
  .content__for-members .sub-heading-upper {
    margin-bottom: 25px;
  }

  .content__for-venues .big-heading h2,
  .content__for-members .big-heading h2 {
    font-size: 80px;
    line-height: 80px;
  }

  .content__for-venues .content__header {
    margin-bottom: 68px;
  }

  .content__for-venues .content__body {
    margin-bottom: 106px;
  }

  .content__for-members .content__header {
    margin-bottom: 63px;
  }

  .content__for-members .content__body {
    margin-bottom: 78px;
  }

  .content__for-venues .content__body,
  .content__for-venues .content__cta {
    padding-right: 90px;
    padding-left: 148px;
  }

  .container__for-venues .content__image {
    padding-top: 20px;
    padding-left: 70px;
    padding-right: 52px;
  }

  /*!
       * .content__for-members 1800
       */
  .container__for-members {
    padding-top: 176px;
  }

  .container__for-members .content__for-members {
    padding-top: 24px;
    padding-left: 68px;
  }

  .content__for-members .content__body,
  .content__for-members .content__cta {
    padding-right: 70px;
    padding-left: 148px;
  }

  .container__for-members .content__image {
    padding-right: 62px;
  }

  .container__for-members .image1 {
    top: -1.1%;
    right: 9.2%;
  }

  .container__for-members .image2 {
    top: 5%;
    left: 0.3%;
  }

  /*!
       * .content__membership-is-free 1800
       */
  .content__carousel-home-hero .owl-nav {
    bottom: 40px;
    padding-left: 47px;
    padding-right: 44px;
  }

  .content__membership-is-free {
    padding-top: 134px;
    padding-left: 15px;
  }

  .content__membership-is-free .content__header .sub-heading {
    font-size: 80px;
    line-height: 1em;
    margin-bottom: 70px;
  }

  .content__membership-is-free .big-heading .h1 {
    font-size: 200px;
    line-height: 230px;
  }

  .content__new-look .lead,
  .content__membership-is-free .lead {
    font-size: 32px;
    line-height: 42px;
  }

  .content__membership-is-free .content__header {
    margin-bottom: 82px;
  }

  .content__membership-is-free .content__body {
    margin-bottom: 71px;
  }

  /*!
       * .content__NHSCT 1800
       */
  .main__about-us .content {
    max-width: 1455px;
  }

  .main__about-us .content__ecologi {
    max-width: 1452px;
  }

  .main__about-us .lead {
    margin-bottom: 40px;
  }

  .main__about-us .lead,
  .main__membership-guidelines .lead {
    font-size: 35px;
    letter-spacing: 0.7px;
    line-height: 40px;
  }

  .content__NHSCT {
    padding-bottom: 92px;
  }

  .content__NHSCT .content__header {
    margin-bottom: 129px;
  }

  .content__NHSCT .content__body {
    margin-bottom: 87px;
  }

  .content__NHSCT .logo-nhs-charities {
    margin-top: 12px;
  }

  .content__NHSCT .lead + .buttons-wrapper {
    margin-top: 87px;
  }

  .content__NHSCT .content__body .col__left {
    padding-right: 125px;
    width: calc(100% - 440px);
  }

  .content__NHSCT .content__body .col__right {
    padding-left: 21px;
  }

  section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .section__what-we-do {
    padding-top: 62px;
    padding-bottom: 150px;
  }

  /*!
       * .section__ticket-booking-steps 1800
       */
  .section__ticket-booking-steps {
    padding-top: 152px;
    padding-bottom: 104px;
  }

  .content__just-few-steps .content__body {
    font-size: 35px;
    line-height: 42px;
    padding-right: 40px;
  }

  .content__ticket-booking-steps .buttons-wrapper {
    font-size: 25px;
  }

  .feature__icon .icon-ticket {
    top: -12px;
  }

  .feature__ticket-booking-step {
    margin-bottom: 80px;
  }

  .feature__ticket-booking-step .step,
  .feature__ticket-booking-step .heading {
    font-size: 35px;
    line-height: 37px;
  }

  .feature__ticket-booking-step .feature-heading {
    margin-bottom: 13px;
  }

  .content__ticket-booking-steps {
    padding-left: 195px;
    padding-right: 220px;
  }

  /*!
       * .big-heading h1 1800
       */
  .content__just-few-steps .big-heading h1,
  .content__just-few-steps .big-heading .h1 {
    font-size: 120px;
    line-height: 96px;
  }

  .big-heading h2,
  .big-heading .h2 {
    font-size: 120px;
    line-height: 112px;
  }

  /*!
       * .content__about-us 1800
       */
  .section__about-us p {
    margin-bottom: 26px;
  }

  .section__about-us p:last-child {
    margin-bottom: 0;
  }

  .content__about-us .content__header {
    margin-bottom: 129px;
  }

  .content__about-us .lead + .feature {
    margin-top: 106px;
  }

  .content__about-us .content__body .col__left .feature img {
    width: 573px !important;
  }

  .content__about-us .content__body .col__left {
    padding-right: 60px;
    width: 670px;
  }

  .content__about-us .content__body .col__right {
    padding-top: 10px;
    padding-left: 60px;
    width: calc(100% - 670px);
  }

  /*!
       * .content__ecologi 1800
       */
  .section__ecologi {
    padding-top: 93px;
  }

  .main__about-us .content__ecologi .content__header {
    margin-bottom: 145px;
  }

  /*!
       * .feature__impact 1800
       */
  p + .features__impact {
    margin-top: 103px;
  }

  .features__impact {
    column-gap: 185px;
  }

  .feature__impact {
    font-size: 30px;
    line-height: 35px;
    width: 362px;
  }

  .feature__impact .heading {
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 17px;
  }

  .feature__impact .feature__icon {
    padding-top: 36px;
    padding-bottom: 41px;
  }

  .feature__impact .feature__icon .icon {
    width: 192px;
  }

  .feature__impact .feature__body {
    padding: 47px 30px 41px;
  }

  /*!
       * .section__our-numbers 1800
       */
  .heading + .list__stats {
    margin-top: 131px;
  }

  .list__stats {
    gap: 87px 145px;
  }

  .list__stats .item__heading {
    font-size: 197px;
    line-height: 184px;
    margin-bottom: 22px;
  }

  .content__our-numbers .heading {
    margin-bottom: 50px;
  }

  .main__about-us .section__our-numbers {
    padding-top: 99px;
    padding-bottom: 122px;
  }

  /*!
       * .section__our-values 1800
       */
  .main__about-us .section__our-values {
    padding-bottom: 36px;
  }

  .main__about-us .content__our-values {
    padding-left: 11px;
    max-width: 100%;
  }

  .main__about-us .content__our-values .content__header {
    padding-top: 26px;
  }

  .content__our-values .big-heading h1,
  .content__our-values .big-heading .h1 {
    font-size: 334px;
    line-height: 311px;
  }

  .list__values > li {
    margin-bottom: 42px;
  }

  .list__values .number {
    font-size: 85px;
    width: 132px;
    height: 132px;
  }

  .list__values h3 {
    font-size: 100px;
    line-height: 120px;
    margin-bottom: 40px;
  }

  .list__values .item__number {
    width: 204px;
  }

  .list__values .item__text {
    font-size: 30px;
    line-height: 40px;
    padding-top: 0;
  }

  .list__values > li .item {
    padding-top: 12px;
    padding-bottom: 40px;
  }

  .list__values > li:not(:last-child) .item {
    padding-bottom: 46px;
  }

  .content__list-values {
    padding-top: 73px;
  }

  /*!
       * .section__listing-show-steps 1800
       */
  .main__about-us .section__listing-show-steps {
    padding-bottom: 218px;
  }

  .section__listing-show-steps .content__header {
    margin-bottom: 159px;
  }

  .content__listing-show-steps .icon-block__arrow img {
    width: 92px;
  }

  .content__listing-show-steps .icon-block__arrow {
    margin-top: 95px;
    margin-bottom: 78px;
  }

  .section__listing-show-steps {
    padding-top: 136px;
  }

  .section__listing-show-steps .big-heading__bottom {
    margin-top: 227px;
  }

  .section__listing-show-steps .big-heading br,
  .section__listing-show-steps .lead br {
    display: block;
  }

  .big-heading__bottom h2 {
    letter-spacing: 2.4px;
  }

  /*!
       * .section__thanks-to-supporters 1800
       */
  .main__about-us .section__thanks-to-supporters {
    padding-top: 163px;
    padding-bottom: 202px;
  }

  .main__about-us .content__thanks-to-supporters .content__header {
    margin-bottom: 111px;
  }

  /*!
       * Guidelines 1800
       */
  .main__membership-guidelines .big-heading h1,
  .main__membership-guidelines .big-heading .h1 {
    font-size: 164px;
    line-height: 153px;
  }

  .main__membership-guidelines .big-heading h2,
  .main__membership-guidelines .big-heading .h2 {
    font-size: 120px;
    line-height: 112px;
  }

  .main__membership-guidelines .screen07 .big-heading h2,
  .main__membership-guidelines .screen07 .big-heading .h2 {
    font-size: 100px;
    line-height: 112px;
  }

  .main__membership-guidelines .mg-screen .content__body {
    max-width: 820px;
  }

  .main__membership-guidelines .screen04 .content__body,
  .main__membership-guidelines .screen05 .content__body,
  .main__membership-guidelines .screen06 .content__body {
    max-width: 1000px;
  }

  .main__membership-guidelines .screen07 .content__body {
    max-width: 1050px;
  }

  .main__membership-guidelines .screen07 .content__body h2 {
    margin-bottom: 113px;
  }

  .section__cta--huge {
    padding: 106px 61px 122px;
  }

  /*!
       * .content__faq 1800
       */
  .content__faq .content__header {
    padding-top: 56px;
    padding-bottom: 129px;
  }

  /*!
       * .content__my-account 1800
       */
  .content__my-account {
    padding-right: 80px;
    padding-left: 80px;
  }

  .content__my-account label.heading {
    margin-bottom: 14px;
  }

  .content__my-account h5 + .list__tags {
    margin-top: 32px;
  }

  .content__my-account .nav-tabs.flex-column {
    padding-top: 38px;
    width: 426px;
  }

  .content__my-account .nav-tabs.flex-column .nav-item {
    padding-right: 45px;
    padding-left: 45px;
  }

  .content__my-account .nav-tabs.flex-column + .tab-content {
    padding-top: 38px;
    padding-bottom: 38px;
  }

  .content__my-account .tab-content .heading + form {
    margin-top: 5px;
  }

  .content__my-account .form-control {
    padding: 11px 22px;
    font-size: 25px;
    line-height: 30px;
  }

  .content__my-account .form-control.tomselect {
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 24px;
  }

  .content__my-account .ts-control {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .content__my-account .form-group {
    margin-bottom: 25px;
  }

  .content__my-account .form-label {
    margin-bottom: 11px;
  }

  .content__my-account .col__delete-saved-card {
    padding-left: 33px;
  }

  .content__my-account .tab-pane .col__delete-saved-card .btn-primary {
    min-width: 275px;
  }

  .content__my-account #myAccntChangeCardInputCardName {
    width: 440px;
  }

  .content__my-account #myAccntChangeCardInputCardNum {
    width: 437px;
  }

  .content__my-account .heading__change-mobile-num {
    margin-top: 49px;
  }

  .content__my-account .heading__change-saved-card {
    margin-top: 44px;
  }

  .content__my-account .form-group__cta {
    margin-top: 69px;
  }

  .content__my-account .tab-pane__EmailPreferences .form-group__list-tags,
  .content__my-account .tab-pane__EmailPreferences .form-group__your-interests {
    margin-bottom: 55px;
  }

  .content__my-account
    .tab-pane__EmailPreferences
    .heading__select-email-preferences {
    margin-bottom: 25px;
  }

  .content__my-account
    .tab-pane__EmailPreferences
    .form-group__custom-controls {
    margin-bottom: 22px;
  }

  .content__my-account .tab-pane__SavedCards .form-group__cta {
    margin-top: 41px;
  }

  .content__my-account .tab-pane__SavedMobileNum .form-group__cta {
    margin-top: 55px;
  }

  .content__my-account .content .heading + p {
    margin-top: 6px;
  }

  .content__my-account .buttons-wrapper {
    margin-top: 46px;
  }

  .content__my-account-home .nav-link__card {
    padding: 40px 60px 39px;
    font-size: 30px;
    line-height: 35px;
  }

  .content__my-account-home .nav-link__heading {
    font-size: 90px;
    line-height: 108px;
  }

  .content__my-account-home .list-nav-link-card > li {
    min-height: 307px;
  }

  .modal__almost-done .modal-body {
    padding: 33px 25px 30px;
  }

  .modal__my-account-almost-done .modal-body {
    padding-top: 81px;
  }

  .modal__my-account-almost-done h2 {
    margin-bottom: 15px;
  }

  .modal__my-account-almost-done .p-send-again {
    margin-top: 49px;
  }

  .modal__my-account-delete-card h2 {
    font-size: 50px;
    letter-spacing: 0;
    line-height: 47px;
  }

  /*!
       * .content__current-listings 1800
       */
  .content__current-listings .owl-carousel__events .event-image {
    width: 536px;
  }

  /*!
       * .content__event-details 1800
       */
  .section__event-details {
    padding-top: 65px;
  }

  .section__event-details .container {
    max-width: 100%;
  }

  .section__event-details .content__event-details {
    width: 1157px;
    padding-right: 0;
    padding-left: 117px;
  }

  .section__event-details .sticky__tabTicketInfoVenue {
    margin-top: -15px;
  }

  .event .event__name {
    font-size: 50px;
    letter-spacing: 1px;
    line-height: 60px;
  }

  .event .event__header-image {
    width: 456px;
  }

  .event .event__header-text {
    padding-left: 52px;
  }

  .event__header-text .event__name {
    margin-top: -13px;
  }

  .event__header-text .list__event-tags {
    margin-bottom: 0;
  }

  .event__header-text .event__location + .list__event-tags {
    margin-top: 34px;
  }

  .nav-tabs__info-venue + .list__event-tags {
    margin-top: 24px;
  }

  .nav-tabs__info-venue .icon__location-pin {
    margin-left: -1px;
    top: -3px;
  }

  .nav-tabs__info-venue .icon__payment-card {
    width: 36px;
    margin-left: -2px;
  }

  .nav-tabs__info-venue .list-group__event-details .list-group-item {
    gap: 24px;
  }

  .nav-tabs__info-venue .list-group__location {
    margin-bottom: 19px;
  }

  .nav-tabs__info-venue .tab-pane__venue .list-group-item {
    gap: 18px;
  }

  .nav-tabs__info-venue .tab-content > .tab-pane {
    min-height: 558px;
  }

  .nav-tabs__info-venue .nav-tabs .nav-link {
    padding: 11px 24px 11px 21px;
  }

  .nav-tabs__info-venue .btn-primary {
    border-radius: 50rem;
    font-weight: bold;
    padding: 16px 29px 13px 29px;
  }

  .nav-tabs__info-venue-wrapper {
    right: 4.2%;
  }

  .nav-tabs__info-venue .nav-tabs {
    margin-bottom: 0;
  }

  .venue-map {
    margin-top: 29px;
  }

  .content__event-details .event__header {
    margin-bottom: 43px;
  }

  .content__event-details .event__body {
    font-size: 25px;
    line-height: 31px;
    padding-top: 26px;
  }

  .content__event-details p,
  .content__event-details ul {
    margin-bottom: 31px;
  }

  .content__event-details p + .video-wrapper {
    margin-top: 63px;
  }

  /*!
       * .content__slidein 1800
       */
  .slidein .content__checkout > .content {
    min-height: calc(100vh - 176px);
  }

  /*!
       * .content__checkout 1800
       */
  .ts-dropdown-content {
    max-height: 220px;
  }

  .btn__payment-method {
    padding-top: 15px;
    padding-bottom: 14px;
    height: 116px;
  }

  .content__checkout {
    font-size: 24px;
    line-height: 30px;
  }

  .content__checkout .btn-close__absolute {
    top: 24px;
    right: 39px;
  }

  .btn__payment-method .sub-heading__upper {
    margin-bottom: 5px;
  }

  .btn__payment-method .button__icon {
    height: 85px;
  }

  .content__checkout .btn__payment-method .icon__apple,
  .content__checkout .btn__payment-method:hover .icon__apple {
    background-size: 25px 31px;
    width: 25px;
    height: 31px;
  }

  .btn__payment-method .button__text .heading,
  .btn__payment-method .button__text .wallet-balance {
    font-size: 40px;
    line-height: 35px;
  }

  .content__slidein .content__checkout > .content__header,
  .content__slidein .content__checkout .content__body {
    padding: 24px 38px;
  }

  .content__slidein .content__checkout .content > .content__body:first-child {
    min-height: 600px;
  }

  .content__checkout .content__checkout > .content__header {
    min-height: 85px;
    padding-left: 38px;
    padding-right: 90px;
  }

  .content__slidein .content__checkout .content__body--total {
    padding-top: 21px;
    padding-bottom: 20px;
  }

  .content__checkout .content__footer .btn,
  .content__checkout
    .content__footer
    .btn-primary:not(.btn__plus-to-x):not(.btn-xxs) {
    min-height: 116px;
  }

  .content__checkout label {
    font-size: 24px;
    letter-spacing: 0;
    line-height: 28px;
  }

  .content__checkout .custom-control.custom-checkbox__check {
    padding-left: 36px;
  }

  .content__checkout .custom-checkbox__check .custom-control-label::before {
    top: 2px;
  }

  .content__checkout
    .custom-checkbox-sm.custom-checkbox__check
    .custom-control-label::before {
    top: 3px;
  }

  .content__checkout .custom-checkbox__check .custom-control-label::after {
    top: 1px;
  }

  .content__checkout .checkbox-wrapper {
    column-gap: 39px;
    margin-bottom: 25px;
  }

  .content__checkout .form-groups__expiry-cvc > .form-group:first-child {
    margin-right: 40px;
  }

  .content__checkout .form-group__card-expiry .form-control {
    width: 194px;
  }

  .content__checkout .form-group__cvc .form-control {
    width: 106px;
  }

  .content__payment--wallet .wallet-balance-wrapper .btn {
    position: relative;
    top: 10px;
  }

  .wallet-balance__text .wallet-balance,
  .content__checkout .input-group__as-form-control .input-group-text,
  .content__checkout .input-group__as-form-control .form-control {
    font-size: 40px;
    line-height: 35px;
  }

  .content__checkout .input-group__as-checkbox .input-group-text {
    font-size: 25px;
    line-height: 30px;
  }

  .content__checkout
    .input-group__as-checkbox
    .custom-control.custom-checkbox__check {
    padding-left: 30px;
  }

  .content__checkout .wallet-balance-wrapper {
    padding-bottom: 22px;
  }

  .content__checkout .heading + .wallet-balance-wrapper {
    margin-top: 24px;
  }

  .content__checkout .wallet-balance__text .sub-heading__upper {
    margin-bottom: 7px;
  }

  .content__checkout .wallet-balance-wrapper + .buttons-wrapper {
    margin-top: 36px;
  }

  .content__checkout .input-group__as-form-control {
    padding-right: 21px;
    padding-left: 24px;
  }

  .content__choose-date .form-group + .checkbox-wrapper__flexiticket {
    margin-top: 46px;
  }

  .content__choose-date .checkbox-wrapper__flexiticket + .small {
    margin-top: -11px;
  }

  .content__choose-date .checkbox-wrapper__flexiticket label,
  .content__choose-date .checkbox-wrapper__flexiticket .flexi-price {
    font-size: 30px;
    letter-spacing: 0.6px;
  }

  .btn-circle-x {
    width: 24px;
    height: 24px;
  }

  .promo-code__form-group {
    margin-bottom: 26px;
  }

  .content__choose-date .promo-code-block {
    margin-top: 48px;
  }

  .content__choose-date .promo-code__item {
    font-size: 30px;
    letter-spacing: 0.6px;
    line-height: 34px;
    padding-bottom: 11px;
  }

  .content__checkout .content__select-time .btn-check__select-time + label.btn {
    font-size: 15px;
    line-height: 1;
    font-weight: 400;
  }

  .content__order-summary .event__date + .table__items {
    margin-top: 24px;
  }

  .content__order-summary .table__items > :not(caption) > * > * {
    padding: 12px 0;
  }

  .content__order-summary .p-donate {
    line-height: 32px;
    margin-bottom: 24px;
  }

  .content__order-summary .logos-wrapper {
    column-gap: 45px;
    margin-bottom: 37px;
    padding-top: 7px;
  }

  .content__checkout .form-group-wrapper + hr,
  .content__checkout .input-group__as-form-control + hr {
    margin-top: 36px;
  }

  .content__checkout hr + .heading {
    margin-top: 26px;
  }

  .content__checkout
    .content__wallet-topup
    > .content
    > .content__body:not(.content__body--total),
  .content__checkout
    .content__checkout
    > .content
    > .content__body:not(.content__body--total) {
    min-height: 600px;
  }

  .content__payment-options .form-groups__use-new-card .form-control {
    font-size: 25px;
    line-height: 30px;
    padding-top: 10px;
    padding-bottom: 12px;
  }

  .content__payment-options .heading {
    margin-bottom: 34px;
  }

  .content__payment-options .heading__use-new-card {
    margin-bottom: 26px;
  }

  .content__payment-options .form-group,
  .content__payment-options .form-groups-wrapper {
    margin-bottom: 39px;
  }

  .content__payment-options .custom-control-sm-label {
    margin-top: -4px;
  }

  .content__payment-options .custom-control-sm-label label {
    font-size: 20px;
    line-height: 24px;
  }

  .content__checkout .custom-control-sm-label .custom-control-label::before {
    top: 4px;
  }

  .content__checkout .custom-control-sm-label .custom-control-label::after {
    top: 3px;
  }

  .content__topup-options > .content__body > .heading:first-child {
    margin-top: 17px;
  }

  .content__payment .buttons-wrapper + .heading {
    margin-top: 51px;
  }

  .content__payment .heading + .form-group-wrapper {
    margin-top: 36px;
  }

  .content__wallet-topup-success .big-heading h1 {
    font-size: 180px;
    line-height: 180px;
  }

  .content__booking-success .big-heading h1 {
    font-size: 180px;
    line-height: 170px;
  }

  .content__booking-success .content__header {
    padding-top: 108px;
    margin-bottom: 76px;
  }

  .content__booking-success .event__date {
    font-size: 30px;
    line-height: 45px;
  }

  .content__booking-success .event__date + .buttons-wrapper {
    margin-top: 34px;
  }

  .content__booking-timeout .content__header {
    padding-top: 132px;
    margin-bottom: 86px;
  }

  .content__booking-timeout .content__body .heading + .buttons-wrapper {
    margin-top: 85px;
  }

  /*!
       * .content__my-wallet 1800
       */
  .content__my-wallet .col__top-up-info {
    padding-top: 46px;
    padding-right: 162px;
  }

  .content__my-wallet .col__transaction-history {
    padding-top: 43px;
    padding-left: 155px;
  }

  /*!
       * .content__my-bookings 1800
       */
  .content__my-bookings .big-heading h1,
  .content__my-bookings .big-heading .h1 {
    font-size: 200px;
    line-height: 271px;
  }

  .content__manage-booking .btn-primary:not(.btn__plus-to-x):not(.btn-xxs),
  .content__manage-booking
    .btn-outline-primary:not(.btn__plus-to-x):not(.btn-xxs) {
    padding: 13px 38px 16px;
  }

  .content__manage-booking .heading__remaining-tickets {
    margin-top: 65px;
    margin-bottom: 56px;
  }

  .content__manage-booking .heading + form,
  .content__manage-booking .heading + .form-group {
    margin-top: 40px;
  }

  .content__manage-booking p + .buttons-wrapper {
    margin-top: 57px;
  }

  .content__manage-booking-other-requests .form-group + .buttons-wrapper {
    margin-top: 64px;
  }

  .content__manage-booking-other-requests-received .content__header {
    margin-bottom: 56px;
    padding-top: 158px;
  }

  .content__manage-booking-other-requests-received .big-heading h1 {
    font-size: 116px;
    line-height: 105px;
  }

  .content__manage-booking-other-requests-received p + .buttons-wrapper {
    margin-top: 129px;
  }

  /*!
       * .content__my-favourites 1800
       */
  .content__my-favourites .big-heading h1,
  .content__my-favourites .big-heading .h1 {
    font-size: 200px;
    line-height: 271px;
  }

  /*!
      * .content__leave-feedback 1800
      */
  .container__leave-feedback::before {
    left: 25px;
  }

  .container__leave-feedback::after {
    right: 4px;
  }

  /*!
       * .content__refer-friend 1800
       */
  .section__refer-friend {
    padding-bottom: 120px;
  }

  .content__refer-friend .card__invite-link .heading {
    line-height: 40px;
    margin-bottom: 0;
  }

  .content__refer-friend .card__invite-link {
    margin-top: 60px;
  }

  .modal__fw .modal-body {
    padding-top: 47px;
  }

  .modal__fw .content .content__header h1,
  .modal__fw .content .content__header h2 {
    margin-top: -20px;
  }

  .modal__terms-and-conditions {
    font-size: 30px;
    letter-spacing: 0.6px;
    line-height: 36px;
  }

  .modal__terms-and-conditions .content {
    padding-right: 350px;
  }

  .modal__terms-and-conditions .content p,
  .modal__terms-and-conditions .content ul {
    margin-top: 0;
    margin-bottom: 36px;
  }

  /*!
       * .content__faq 1800
       */
  .content__faq .big-heading h1,
  .content__faq .big-heading .h1,
  .content__faq .big-heading h2,
  .content__faq .big-heading .h2 {
    font-size: 120px;
    line-height: 112px;
  }

  /*!
       * .content__register 1800
       */
  .content__register .content__header,
  .content__contact-us .content__header {
    padding-top: 49px;
    padding-bottom: 47px;
  }

  .content__register .content__body h3,
  .content__register .content__body .h3 {
    margin-bottom: 52px;
  }

  .content__register .content__body .col__left,
  .content__contact-us .content__body .col__left {
    width: 590px;
    padding-top: 13px;
    padding-right: 20px;
    padding-left: 39px;
  }

  .content__register .content__body .col__right {
    padding-top: 12px;
    padding-right: 80px;
    padding-left: 90px;
  }

  .content__register-part2 label + .list__tags,
  .content__register-part2 .heading + .list__tags {
    margin-top: 18px;
  }

  .content__register-part2 .row__form-groups + .row__checkboxes {
    margin-top: 19px;
  }

  .content__register-part2 label + .form-group__custom-controls,
  .content__register-part2 .heading + .form-group__custom-controls {
    margin-top: 34px;
  }

  .content__register-part2 .row__cta {
    margin-top: 53px;
  }

  .content__register-last-part .big-heading h1,
  .content__register-last-part .big-heading .h1 {
    font-size: 233px;
    line-height: 223px;
  }

  .content__register-last-part .p-account-created {
    font-size: 48px;
    line-height: 45px;
  }

  /*!
       * .content__contact-us 1800
       */
  .content__contact-us .col__left .content__body {
    padding-left: 44px;
    padding-right: 12px;
  }

  .content__contact-us .content__event-details .content__body {
    padding-left: 44px;
    padding-right: 52px;
  }

  .content__contact-us .content__contact-form--problem .content__body {
    padding-left: 20px;
    padding-right: 28px;
  }

  .content__contact-us
    .col__right
    .content__contact-form--problem
    > .content__body {
    padding-left: 73px;
    padding-right: 48px;
  }

  .content__contact-us .user-cat + div {
    margin-top: 70px;
  }

  .content__contact-us .content__body .col__right {
    padding-top: 12px;
  }

  .content__contact-us .col__right > .content > .content__body {
    padding-right: 64px;
  }

  .flex-xxl-row-3cols > div {
    width: calc(33.33% - 31px);
  }

  .flex-xxl-row-2cols > div {
    width: calc(51% - 31px);
  }

  .content__contact-form .form-groups-wrapper.flex-xxl-row {
    column-gap: 62px;
  }

  .content__contact-form .form-group + .form-group__cta {
    margin-top: 46px;
  }

  .content__contact-form-organizer .form-groups-wrapper {
    column-gap: 60px;
  }

  .content__help-with-show .content__header {
    padding-top: 12px;
    padding-bottom: 14px;
  }

  .content__contact-us-faq .content__header {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  /*!
       * .content__no-working-page 1800
       */
  .content__no-working-page .big-heading h1,
  .content__no-working-page .big-heading .h1 {
    font-size: 100px;
    line-height: 108px;
  }

  .content__no-working-page .content__header {
    padding-top: 181px;
    padding-bottom: 250px;
  }

  .content__no-working-page .content__header .big-heading {
    margin-bottom: 71px;
  }

  .content__no-working-page .browser-alternatives-block p {
    font-size: 30px;
    line-height: 39px;
  }

  /*!
       * .content__uptake 1800
       */
  .content__uptake > .content__body > .col__left {
    width: 448px;
  }

  .content__uptake > .content__body > .col__right {
    width: 1053px;
  }

  .content__uptake-details-carousel > .content__body > .col__right {
    width: 489px;
  }

  .content__uptake-details-carousel > .content__body > .col__left {
    width: calc(100% - 489px);
  }

  .content__uptake-venues .big-heading {
    width: calc(100% + 10px);
    margin-left: -5px;
  }

  .content__uptake-venues .big-heading h1,
  .content__uptake-venues .big-heading .h1,
  .content__uptake-venues .big-heading h2,
  .content__uptake-venues .big-heading .h2 {
    font-size: 108px;
    letter-spacing: 2.16px;
    line-height: 108px;
  }

  .content__uptake-event .buttons-wrapper .btn {
    padding-right: 20px;
    padding-left: 20px;
  }

  .content__uptake-venues .big-heading .ticket-yellow1 {
    left: 30%;
    top: 4.5%;
  }

  /*!
       * .main__new-look 1800
       */
  .section__new-look {
    padding-top: 130px;
    padding-bottom: 140px;
  }

  .section__new-look .content__header {
    margin-bottom: 100px;
  }

  .main__new-look .content__what-we-do .big-heading .ticket-yellow {
    z-index: -1;
  }

  .main__new-look .container__for-members .image1 {
    top: -3%;
  }

  .main__new-look .container__for-venues .image1 {
    width: 326px;
  }

  .main__new-look .section__what-we-do {
    padding-top: 140px;
    padding-bottom: 140px;
  }

  .main__new-look .section__ticket-booking-steps {
    padding-top: 120px;
    padding-bottom: 150px;
  }

  .main__new-look
    .section__ticket-booking-steps
    ~ .section__ticket-booking-steps {
    padding-top: 140px;
  }

  .main__new-look .section__faq {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .main__new-look .section__ticket-booking-steps + .section__faq {
    margin-top: 0;
  }

  .main__new-look .section__faq .content__header {
    margin-bottom: 9vh;
  }

  /*!
       * .section__reactive-account 1800
       */
  .content__reactive-account .content__header {
    padding-bottom: 80px;
  }

  .container__footer-menu .col-footer-menus .col-footer-menu:nth-child(2) {
    padding-left: 5px;
  }
}

/* END 1800 */
@media (min-width: 1900px) {
  .content__what-we-do .big-heading h1,
  .content__what-we-do .big-heading .h1 {
    font-size: 334px;
  }

  .content__new-look .big-heading h1,
  .content__new-look .big-heading .h1 {
    font-size: 265px;
  }

  .container__for-venues .image2 {
    width: 451px;
  }

  .container__for-venues .image3 {
    width: 718px;
  }

  .container__for-members .image2 {
    width: 497px;
  }

  .container__for-members .image3 {
    width: 759px;
  }

  /*!
       * .section__our-values 1900
       */
  .content__our-values .big-heading h1,
  .content__our-values .big-heading .h1 {
    font-size: 334px;
    line-height: 311px;
  }

  /*!
       * Guidelines 1900
       */
  .main__membership-guidelines .content__screen01 .content__body {
    margin-top: 100px;
  }

  /*!
       * .content__content__register 1900
       */
  /*!
       * .content__my-account 1900
       */
  /*!
        * .content__current-listings 1900
        */
  .content__current-listings .content__header {
    padding-bottom: 26px;
  }

  .content__current-listings .big-heading h1,
  .content__current-listings .big-heading .h1 {
    font-size: 255px;
  }

  .content__current-listings .listing-filters .form-groups-wrapper-4 {
    column-gap: 52px;
  }

  /*!
        * .content__event-details 1900
        */
  .nav-tabs__info-venue .list-group + hr {
    margin: 25px 0 14px;
  }

  .nav-tabs__info-venue .list-group__time {
    margin-bottom: 30px;
  }

  .nav-tabs__info-venue .tab-content {
    padding-top: 41px;
  }

  /*!
       * .content__my-bookings 1900
       */
  /*!
       * .content__my-wallet 1900
       */
  .content__checkout .content__wallet-topup-success .content__header {
    margin-bottom: 40px;
    padding-top: 133px;
  }

  /*!
       * .content__my-favourites 1900
       */
  /*!
       * .content__refer-friend 1900
       */
  .content__refer-friend .big-heading h1,
  .content__refer-friend .big-heading .h1 {
    font-size: 226px;
    line-height: 271px;
  }

  /*!
       * .content__register 1900
       */
  .content__register .form-control {
    min-width: 513px;
  }

  /*!
       * .main__new-look 1900
       */
  .content__questions .big-heading h1,
  .content__questions .big-heading .h1 {
    font-size: 334px;
  }

  /*!
       * .section__reactive-account 1900
       */
  .content__reactive-account .big-heading h1,
  .content__reactive-account .big-heading .h1 {
    font-size: 120px;
    line-height: 112px;
  }

  .content__reactive-account .big-heading .ticket-yellow {
    left: 1%;
  }

  .content__reactive-account .big-heading .ticket-green {
    left: 33%;
    width: 10%;
  }

  .content__reactive-account .big-heading .ticket-blue {
    left: 89%;
  }

  .content__reactive-account .big-heading .ticket-orange {
    left: 2.75%;
  }

  .content__reactive-account .big-heading .ticket-aquamarine {
    bottom: -23%;
    left: 44%;
    width: 9%;
  }

  .content__reactive-account .big-heading .ticket-red {
    bottom: 8%;
    right: 0.5%;
  }
}

/* END 1900 */
@media (min-width: 1921px) {
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1920px;
  }

  .main-header {
    left: 0;
    right: 0;
  }

  [data-scroll-container],
  .main-header,
  .main-footer,
  .modal {
    max-width: 1920px;
    margin-right: auto;
    margin-left: auto;
  }

  .modal {
    right: 0;
  }

  .modal h1,
  .modal .h1 {
    font-size: 120px;
    line-height: 112px;
  }

  .modal h2,
  .modal .h2 {
    font-size: 67px;
  }
}

/* END 1921 */
@media (min-width: 2560px) {
}

/* END 2560 */
@media (min-width: 4001px) {
  .spy-scrollbar {
    top: 20vh;
  }

  .main__about-us .section__about-us {
    background-color: #fff;
  }

  .main__about-us .section__ecologi {
    background-color: var(--bs-mint);
  }

  .list__my-bookings .card__event .card-footer .btn,
  .list__my-bookings .card__event .col-right .btn {
    padding: 11px 10px 11px;
  }
}

/* END 4001 */
@media (max-width: 1799px) {
  .owl-carousel__home-hero .owl-item :not(.ticket) {
    position: relative;
    z-index: 2;
  }
}

@media (max-width: 1199px) {
  .main-header__navbar .navbar-brand {
    position: relative;
  }

  .main-header__navbar .navbar-brand .navbar-brand__logo {
    display: none;
  }

  .main-header__navbar .navbar-brand::before {
    content: "";
    display: inline-block;
    width: 192px;
    height: 23px;
    background-image: url(../images/centraltickets-logo-colour-mobile.svg);
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 192px auto;
  }

  .main-header__main-menu,
  .main-header__navbar .slideup-dropdown,
  .main-header__navbar .slideup-dropdown .nav {
    width: 321px;
  }

  .main-header > .container-fluid {
    padding-left: 22px;
    padding-right: 0;
  }

  .navbar-toggler__custom {
    padding: 0;
  }

  .main-header__main-menu {
    background-color: #fff;
    box-shadow: 0 8px 8px 6px rgb(0 0 0 / 10%);
    padding: 0 0 20px;
    position: absolute;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 51px;
    right: -340px;
    z-index: 1000;
    transition: all 0.5s;
  }

  .show-menu .main-header__main-menu {
    -webkit-animation: slideInRight 0.5s;
    animation: slideInRight 0.5s;
    right: 0;
  }

  .main-header__main-menu .nav-item {
    border-bottom: 1px solid #000;
  }

  .main-header__main-menu .form-group {
    margin-bottom: 9px;
  }

  .main-header__main-menu .form-group + .form-group__cta {
    margin-top: 32px;
  }

  .main-header__main-menu label {
    font-size: 12px;
    letter-spacing: 0.24px;
    line-height: 14px;
    margin-bottom: 5px;
  }

  .main-header__main-menu .form-control {
    height: 39px;
  }

  .main-header__main-menu .small {
    font-size: 12px;
    letter-spacing: 0.24px;
    line-height: 14px;
  }

  .main-header__navbar .nav-link {
    padding: 11px 19px;
  }

  .main-header__navbar .nav-link.btn {
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 25px;
  }

  .main-header__navbar .dropdown-item:focus,
  .main-header__navbar .dropdown-item:hover {
    color: var(--bs-red);
    background-color: #fff;
  }

  .main-header__navbar .slidein-login .nav-link.btn {
    padding: 6px 20px;
  }

  .row {
    margin-right: -19px;
    margin-left: -19px;
  }

  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl,
  .row > * {
    padding-left: 19px;
    padding-right: 19px;
  }

  .container__block,
  .container__block > .row > * {
    padding-right: 0;
    padding-left: 0;
  }

  .container__block > .row {
    margin-right: 0;
    margin-left: 0;
  }

  .nav-tabs.flex-column + .tab-content {
    padding: 10px 20px;
  }

  .slidein-login,
  .nav-item.dropdown {
    padding: 12px 19px 6px;
  }

  .list__tags label.btn-outline-primary {
    border-color: var(--bs-dark);
  }

  .section__home-hero .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  .content__just-few-steps {
    margin-bottom: 5vh;
  }

  /*!
       * .content__NHSCT 1199
       */
  .content__NHSCT .content__body .col__left {
    margin-bottom: 52px;
  }

  /*!
       * Guidelines page 1199
       */
  .main__membership-guidelines .screen01 .content__body {
    margin-top: 68px;
  }

  .content__about-us .feature {
    margin-bottom: 20px;
  }

  /*!
       * .content__refer-friend 1199
       */
  .dropdown-item {
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 30px;
  }

  .main-header .nav-link__price.show {
    border-radius: 50rem;
  }

  .main-header .nav-link__heading + .nav-link__price {
    margin-top: 12px;
  }

  .main-header__navbar .btn.nav-link__price {
    max-width: 185px;
    padding-bottom: 6px;
  }

  .main-header__navbar .btn.nav-link__price::after {
    background-image: none;
  }

  .main-header__navbar .dropdown-menu__wallet {
    position: relative;
    z-index: 1;
    display: block;
    margin-top: 10px;
  }

  .main-header .nav-link__price {
    pointer-events: none;
  }

  .dropdown-menu__wallet .dropdown-item {
    padding: 5px 15px 4px 32px;
    position: relative;
  }

  .dropdown-menu__wallet .dropdown-item::before {
    content: "";
    display: block;
    background: url(../images/icon-arrow-right-22x21-black.svg) center center
      no-repeat;
    width: 22px;
    height: 21px;
    position: absolute;
    top: 10px;
    left: 0;
  }

  .dropdown-menu__wallet .dropdown-item:hover::before {
    background-image: url(../images/icon-arrow-right-22x21-red.svg);
  }

  .content__refer-friend h3,
  .content__refer-friend .h3 {
    font-size: 20px;
    letter-spacing: 0;
    line-height: 25px;
    margin-bottom: 10px;
  }

  /*!
       * .content__contact-us 1199
       */
  .content__contact-us .col__right .heading {
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 25px;
  }

  .content__contact-us .form-group__cta {
    margin-top: 35px;
  }

  /*!
       * .content__current-listings 1199
       */
  .listing-filters .form-groups-wrapper__search {
    margin-top: 5px;
  }

  .listing-filters .form-control {
    height: 38px;
  }

  /*!
       * .content__event-details 1199
       */
  /*!
       * .content__my-account 1199
      */
  .content__my-account .form-group,
  .content__my-account .form-groups-wrapper {
    margin-bottom: 20px;
  }

  .container__footer-menu .col-office-address {
    width: 44%;
  }

  .container__footer-menu .col-footer-menus {
    width: 56%;
  }

  .container__footer-menu .col-footer-menu {
    width: 50%;
  }

  .container__footer-menu .col-footer-menu:nth-child(2) {
    padding-right: 0;
  }

  .container__footer-menu .col__footer-text {
    margin-bottom: 44px;
  }
}

/* END 1199 */
@media (max-width: 991px) {
  body {
    font-size: 15px;
    line-height: 20px;
  }

  .container__notification {
    padding-top: 2px;
    padding-bottom: 3px;
  }

  .container__notification--alert {
    font-size: 10px;
    line-height: 22px;
    letter-spacing: 0.3px;
  }

  .main__home {
    font-size: 20px;
    line-height: 24px;
  }

  .btn.has-icon .icon-apple-as-bg:first-child {
    margin-right: 2px;
  }

  .btn.has-icon .icon-googleplay-as-bg:first-child {
    margin-right: 6px;
  }

  .owl-carousel__home-hero .two-buttons .btn {
    padding-left: 14px;
    padding-right: 14px;
  }

  .owl-carousel__home-hero .two-buttons {
    gap: 11px;
  }

  .owl-carousel__home-hero .item2 .ticket-aquamarine {
    width: 25px;
    bottom: 19.5%;
    left: 8.2%;
    z-index: 2;
  }

  .owl-carousel__home-hero .item2 .ticket-blue {
    width: 30px;
    bottom: 16.1%;
    right: 34.1%;
    z-index: 2;
  }

  .owl-carousel__home-hero .item2 .ticket-green {
    width: 37px;
    left: 23.75%;
    top: 39.6%;
    z-index: 2;
  }

  .owl-carousel__home-hero .item3 .ticket-yellow,
  .owl-carousel__home-hero .item3 .ticket-blue,
  .content__what-we-do .big-heading .ticket-aquamarine,
  .content__what-we-do .big-heading .ticket-blue,
  .content__what-we-do .big-heading .ticket-yellow,
  .content__what-we-do .big-heading .ticket-green,
  .content__questions .big-heading .ticket-aquamarine,
  .content__questions .big-heading .ticket-blue,
  .content__questions .big-heading .ticket-yellow,
  .content__questions .big-heading .ticket-green,
  .content__for-venues .ticket-aquamarine,
  .content__for-members .ticket-green,
  .content__membership-is-free .big-heading .ticket-green,
  .content__membership-is-free .big-heading .ticket-yellow,
  .content__NHSCT .big-heading .ticket-aquamarine,
  .content__about-us .big-heading .ticket-orange,
  .content__about-us .big-heading .ticket-green,
  .content__about-us .big-heading .ticket-aquamarine,
  .content__our-values .big-heading .ticket-blue,
  .content__our-values .big-heading .ticket-orange,
  .content__our-values .big-heading .ticket-green,
  .content__screen01 .big-heading .ticket-yellow,
  .content__screen01 .big-heading .ticket-green,
  .content__screen01 .big-heading .ticket-blue,
  .content__screen01 .big-heading .ticket-orange,
  .content__current-listings .big-heading .ticket-red,
  .content__current-listings .big-heading .ticket-yellow {
    z-index: 2;
  }

  .owl-carousel__home-hero .item3 .ticket-green {
    width: 39px;
    left: 14.2%;
    bottom: 16.5%;
  }

  .owl-carousel__home-hero .item4 .ticket-aquamarine {
    width: 25px;
    top: 20.1%;
    right: 20.8%;
  }

  .owl-carousel__home-hero .item4 .ticket-blue {
    width: 30px;
    bottom: 18.2%;
    right: 25.8%;
  }

  .owl-carousel__home-hero .item4 .ticket-green {
    width: 37px;
    left: 9.5%;
    top: 37.2%;
  }

  .owl-carousel__home-hero .item4 .ticket-yellow {
    width: 29px;
    bottom: 19.7%;
    left: 12.5%;
  }

  .owl-carousel__home-hero .item5 .ticket-yellow {
    width: 29px;
    left: 18.85%;
    bottom: 20.6%;
  }

  .owl-carousel__home-hero .item5 .ticket-aquamarine {
    width: 25px;
    top: 35.7%;
    left: -3.6%;
  }

  .owl-carousel__home-hero .item5 .ticket-blue {
    width: 30px;
    top: 11.1%;
    left: 33%;
  }

  .owl-carousel__home-hero .item5 .ticket-green {
    width: 37px;
    top: 44.6%;
    right: 27.45%;
  }

  .content__what-we-do .big-heading .ticket-red {
    display: none;
  }

  .content__what-we-do .big-heading .ticket-green {
    bottom: 21%;
    right: 39.8%;
    width: 29px;
  }

  .content__what-we-do .big-heading .ticket-aquamarine {
    top: 39.2%;
    right: 7.15%;
    width: 38px;
  }

  .content__what-we-do .big-heading .ticket-blue {
    top: 1px;
    left: 28.6%;
    width: 31px;
  }

  /*!
       * .content__for-venues 991
       */
  .content__for-venues .ticket-blue {
    top: 53.7%;
    left: 14%;
    width: 41px;
  }

  .content__for-venues .ticket-aquamarine {
    top: 39.5%;
    right: 14%;
    width: 30px;
  }

  .content__for-venues .ticket-yellow {
    bottom: 25.4%;
    left: 30.2%;
    width: 39px;
  }

  .content__for-venues .ticket-green {
    bottom: 5.8%;
    right: 13.2%;
    width: 48px;
  }

  /*!
       * .content__for-members 991
       */
  .content__for-members .ticket-aquamarine {
    top: 3.7%;
    right: 13%;
    width: 35px;
  }

  .content__for-members .ticket-green {
    top: 31.5%;
    left: 22.8%;
    width: 48px;
  }

  /*!
       * .content__membership-is-free 991
       */
  .content__membership-is-free .big-heading .ticket-aquamarine {
    bottom: 13.2%;
    right: 3.1%;
    width: 32px;
  }

  .content__membership-is-free .big-heading .ticket-orange {
    top: -5%;
    left: 23.9%;
    width: 33px;
    z-index: -1;
  }

  .content__membership-is-free .big-heading .ticket-yellow {
    top: 38.5%;
    left: 0.5%;
    width: 37px;
  }

  /*!
       * .content__just-few-steps 991
       */
  .content__just-few-steps .big-heading .ticket-aquamarine {
    top: -9%;
    right: 13.2%;
    width: 27px;
    z-index: 1;
  }

  /*!
       * .content__NHSCT 991
       */
  .main__about-us .big-heading h1,
  .main__about-us .big-heading .h1,
  .main__about-us .big-heading h2,
  .main__about-us .big-heading .h2 {
    font-size: 55px;
    line-height: 55px;
  }

  .content__NHSCT {
    padding-bottom: 47px;
  }

  .content__NHSCT .big-heading .ticket-yellow {
    bottom: -7.6%;
    left: 30%;
    width: 33px;
    z-index: 2;
  }

  .content__NHSCT .big-heading .ticket-orange {
    top: 24%;
    right: 29%;
    width: 30px;
    z-index: 2;
  }

  .owl-carousel__about-us .item__image {
    width: 380px;
    max-height: 250px;
  }

  .content__about-us .content__header {
    margin-bottom: 58px;
  }

  .content__about-us .lead {
    margin-bottom: 64px;
  }

  .content__about-us .feature {
    margin-bottom: 49px;
  }

  .content__about-us .col__right p {
    letter-spacing: 0.3px;
  }

  .content__ecologi .content__header {
    margin-bottom: 56px;
  }

  .content__our-values .big-heading h1,
  .content__our-values .big-heading .h1,
  .content__our-values .big-heading h2,
  .content__our-values .big-heading .h2 {
    font-size: 72px;
    line-height: 67px;
  }

  .main__about-us .content__our-values .content__header {
    padding-top: 10px;
    padding-bottom: 13px;
  }

  /*!
       * Guidelines page 991
       */
  .main__membership-guidelines .content__screen05 .big-heading h1,
  .main__membership-guidelines .content__screen05 .big-heading .h1,
  .main__membership-guidelines .content__screen06 .big-heading h1,
  .main__membership-guidelines .content__screen06 .big-heading .h1 {
    font-size: 57px;
  }

  /* .content__screen07 991*/
  .content__screen07 .big-heading .ticket-yellow1 {
    left: 20.5%;
    z-index: 1;
  }

  .content__screen07 .big-heading .ticket-blue2 {
    left: -11.95%;
  }

  .content__screen07 .big-heading .ticket-green3 {
    right: 0.3%;
    z-index: 1;
  }

  .content__screen07 .big-heading .ticket-yellow2 {
    right: 14.5%;
    z-index: 1;
  }

  /*!
       * .content__register 991
       */
  .content__register .content__header--template {
    padding-top: 24px;
    padding-bottom: 25px;
  }

  .content__register .big-heading .ticket-yellow {
    z-index: 1;
  }

  .main__register-last-part {
    background-image: url(../images/tickets/bg-tickets-register-congratulations-mobile.svg);
    background-position: center 139px;
    background-repeat: no-repeat;
    background-size: 442px auto;
    min-height: 100vh;
  }

  .content__register .big-heading h1,
  .content__register .big-heading .h1,
  .content__register .big-heading h2,
  .content__register .big-heading .h2,
  .content__contact-us .big-heading h1,
  .content__contact-us .big-heading .h1,
  .content__contact-us .big-heading h2,
  .content__contact-us .big-heading .h2 {
    font-size: 58px;
    line-height: 54px;
  }

  /*!
       * .content__contact-us 991
       */
  .content__contact-us .user-cat {
    background-image: url(../images/message-bubble-red-sm.svg);
    background-position: 100% 12px;
    background-repeat: no-repeat;
    background-size: 81px auto;
  }

  .content__contact-us .list__my-bookings .card__event .col-right {
    padding-top: 11px;
  }

  .content__contact-us .list__my-bookings .card__event .col-right .btn {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  /*!
       * .content__my-favourites 991
       */
  .content__my-favourites .big-heading .ticket-red,
  .content__my-favourites .big-heading .ticket-green,
  .content__my-favourites .big-heading .ticket-orange {
    z-index: 1;
  }

  /*!
       * .content__refer-friend 991
       */
  .modal__terms-and-conditions .modal-dialog {
    margin-top: 61px;
  }

  /*!
       * .content__current-listings 991
       */
  .content__current-listings .big-heading .ticket-green {
    top: 0.05%;
    left: 37.5%;
    width: 27px;
  }

  /*!
       * .content__event-details 991
       */
  .nav-tabs__info-venue-wrapper {
    margin-top: 21px;
  }

  .nav-tabs__info-venue .tab-pane__cta {
    background-color: var(--bs-red);
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 1;
  }

  .nav-tabs__info-venue .btn-book-show {
    border-radius: 0;
    padding-top: 17px;
    padding-bottom: 17px;
    width: 100%;
  }

  .nav-tabs__info-venue .tab-content {
    border-right-width: 0;
    border-bottom-width: 0;
    border-left-width: 0;
  }

  .content__event-details .event__body {
    margin-top: 20px;
  }

  .nav-tabs__info-venue {
    width: calc(100% + 38px);
    margin-left: -19px;
  }

  .nav-tabs__info-venue .nav-tabs .nav-item {
    width: 50%;
  }

  .nav-tabs__info-venue .nav-tabs .nav-item .nav-link {
    color: #9a9a9a;
    width: 100%;
  }

  .nav-tabs__info-venue .nav-tabs .nav-link.active {
    color: #000;
    border-bottom-color: #000;
  }

  .nav-tabs__info-venue .nav-tabs .nav-item:first-child .nav-link {
    border-left-width: 0;
  }

  .nav-tabs__info-venue .nav-tabs .nav-item:nth-child(2) .nav-link {
    border-right-width: 0;
  }

  .nav-tabs__info-venue .btn-book-show:hover,
  .nav-tabs__info-venue .btn-book-show.active,
  .nav-tabs__info-venue .btn-book-show:active,
  .nav-tabs__info-venue .btn-book-show:focus {
    background-color: var(--bs-red);
    border-color: var(--bs-red);
    color: #fff;
  }

  /*!
       * .content__checkout 991
       */
  .subtotal-block + .custom-control {
    margin-top: 10px;
  }

  .btn__payment-method .button__arrow {
    background-size: 11px 9px;
    width: 24px;
    height: 24px;
  }

  .btn__payment-method {
    padding: 7px 14px 7px 7px;
  }

  .btn__payment-method .button__icon {
    width: 41px;
    height: 41px;
  }

  .btn__payment-method .button__icon--wallet {
    background-position: 8px center;
    background-size: 27px auto;
  }

  .btn__payment-method .button__icon--debitcredit {
    background-size: 31px auto;
  }

  .btn__payment-method .button__icon--poundsterling {
    background-size: 15px auto;
  }

  .btn__payment-method .button__icon + .button__text {
    padding-left: 10px;
  }

  .btn__payment-method .sub-heading__upper {
    font-size: 10px;
    letter-spacing: -0.1px;
    line-height: 19px;
    margin-top: -3px;
  }

  .btn__payment-method .button__text .heading {
    font-size: 20px;
    line-height: 22px;
  }

  .btn__payment-method .button__text .sub-heading__upper + .heading {
    line-height: 21px;
  }

  .wallet-balance__text .sub-heading__upper {
    font-size: 10px;
    letter-spacing: -0.1px;
    line-height: 12px;
    margin-top: -1px;
  }

  .wallet-balance__text .wallet-balance {
    font-size: 30px;
    line-height: 30px;
  }

  .content__checkout .input-group__as-checkbox + hr {
    margin-top: -7px;
  }

  .content__checkout .btn__payment-method {
    margin-bottom: 14px;
  }

  .content__checkout .btn__payment-method + .heading {
    margin-top: 47px;
  }

  .content__checkout .btn__payment-method .icon__apple,
  .content__checkout .btn__payment-method:hover .icon__apple {
    background-size: 14px 17px;
    width: 14px;
    height: 17px;
  }

  .content__checkout .table > :not(caption) > * > * {
    padding: 10px 0;
  }

  .content__checkout .checkbox-wrapper {
    gap: 11px;
    margin-bottom: 13px;
  }

  .content__checkout .content__footer .btn,
  .content__checkout
    .content__footer
    .btn-primary:not(.btn__plus-to-x):not(.btn-xxs) {
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 24px;
  }

  .content__checkout .content__body--total {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .content__checkout .content__choose-date .content__body--total {
    padding-bottom: 18px;
  }

  .content__checkout .subtotal-block .label,
  .content__checkout .subtotal-block .total-heading,
  .content__checkout .subtotal-block .total-amount,
  .content__checkout .heading {
    font-size: 20px;
    line-height: 24px;
  }

  .content__body--total .custom-checkbox-sm {
    padding-left: 34px !important;
  }

  .content__body--total .custom-checkbox-sm .custom-control-label::before {
    width: 17px;
    height: 17px;
  }

  .content__body--total .custom-checkbox__check .custom-control-label::after {
    background-size: 14px 15px;
    width: 14px;
    height: 15px;
    top: 1px;
    left: 4px;
  }

  .content__body--total .custom-checkbox-sm .custom-control-label {
    font-size: 11px;
    line-height: 14px;
    padding-top: 2px;
  }

  .wallet-balance__text + .btn {
    min-width: 106px;
  }

  .content__checkout
    .content__body
    .btn-primary:not(.btn__plus-to-x):not(.btn-xxs) {
    font-size: 19px;
    line-height: 24px;
    padding: 9px 20px 10px;
  }

  .content__payment .buttons-wrapper + .heading {
    margin-top: 61px;
  }

  .content__payment .wallet-balance-wrapper {
    margin-bottom: 30px;
    padding-top: 11px;
    padding-bottom: 10px;
  }

  .content__choose-date,
  .content__checkout > .content {
    border-bottom: none !important;
  }

  .content__choose-date .content__footer {
    border-top: none !important;
  }

  .content__choose-date .form-group + .checkbox-wrapper {
    margin-top: 30px;
  }

  .content__choose-date .checkbox-wrapper + .small {
    margin-top: 16px;
  }

  .content__choose-date .promo-code-block {
    margin-top: 26px;
  }

  .promo-code__item .promo-code-value-wrapper {
    padding-right: 7px;
  }

  .content__choose-date .promo-code-block .form-control {
    min-width: 260px;
    padding-top: 10px;
    padding-bottom: 9px;
  }

  .content__choose-date .promo-code__item .btn-circle-x {
    position: relative;
    top: -1px;
  }

  .content__order-summary .event__name {
    margin-top: -5px;
  }

  .content__order-summary .event__name + .event__date {
    margin-top: -6px;
    margin-bottom: 17px;
  }

  .content__order-summary .p-donate {
    margin-top: -3px;
    margin-bottom: 14px;
  }

  .content__order-summary .small,
  .content__order-summary small {
    font-size: 10px;
    line-height: 15px;
  }

  .content__order-summary .logos-wrapper {
    gap: 20px 38px;
    align-items: flex-end;
  }

  .content__order-summary .small + .logos-wrapper {
    margin-top: 56px;
  }

  .content__order-summary .logo-nhs-charities {
    width: 128px;
    height: auto;
  }

  .content__order-summary .logo-pennies {
    width: 102px;
    height: auto;
  }

  .content__order-summary .content__body:first-child {
    padding-bottom: 0;
  }

  .content__payment--wallet .input-group__amount .input-group-text {
    margin-right: 10px;
  }

  .content__payment--wallet .input-group__amount {
    width: 170px;
  }

  .content__payment--wallet .fc__amount {
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 24px;
  }

  .content__payment .input-group__amount .input-group-text {
    font-size: 20px;
    line-height: 24px;
  }

  .content__payment-options .form-groups__use-new-card .form-control {
    font-size: 20px;
    line-height: 24px;
    padding-top: 7px;
    padding-bottom: 8px;
  }

  .content__booking-timeout .big-heading .emoji-aquamarine,
  .content__booking-timeout .big-heading .emoji-green,
  .content__booking-timeout .big-heading .emoji-red {
    z-index: 1;
  }

  .content__booking-timeout .heading {
    font-size: 25px;
    line-height: 30px;
  }

  /*!
       * .content__my-wallet 991
       */
  .content__my-wallet .col__top-up-info .content,
  .content__my-wallet .col__transaction-history .content {
    margin-left: auto;
    margin-right: auto;
  }

  .content__my-wallet .col__transaction-history {
    padding-top: 0;
  }

  /*!
       * .content__my-bookings 991
       */
  .content__my-bookings .big-heading .ticket-yellow,
  .content__my-bookings .big-heading .ticket-green {
    z-index: 1;
  }

  .list__my-bookings .card__event .card-body {
    width: calc(100% - 240px);
  }

  .list__my-bookings
    .card__event
    .card-footer
    .buttons-wrapper
    .btn-view-ticket,
  .list__my-bookings
    .card__event
    .card-footer
    .buttons-wrapper
    .btn-manage-booking {
    width: calc(50% - 5px);
    min-width: auto;
  }

  /*!
       * .content__uptake 991
       */
  .card__uptake--stat .stat__heading {
    margin-left: 5px;
  }

  .list-group__uptake-list .buttons-wrapper,
  .list-group__uptake-list .buttons-wrapper .btn {
    width: 100%;
  }

  /*!
       * .main__new-look 991
       */
  .main__new-look .content__what-we-do .big-heading .ticket-red {
    display: block;
  }

  .main__new-look .content__what-we-do .big-heading .ticket-blue {
    top: -8%;
  }

  .main__new-look .content__what-we-do .big-heading .ticket-green {
    bottom: -14%;
  }

  .main__new-look .content__what-we-do .big-heading .ticket-aquamarine {
    right: 4%;
  }

  .content__questions .big-heading .ticket-red {
    display: none;
  }

  .content__questions .big-heading .ticket-green {
    bottom: 21%;
    right: 39.8%;
    width: 29px;
  }

  .content__questions .big-heading .ticket-aquamarine {
    top: 39.2%;
    right: 7.15%;
    width: 38px;
  }

  .content__questions .big-heading .ticket-blue {
    top: 1px;
    left: 28.6%;
    width: 31px;
  }
}

/* END 991 */
@media (max-width: 819px) {
  /*!
       * .content__uptake 819
       */
}

/* END 819 */
@media (max-width: 767px) {
  .btn-citymapper {
    max-width: 231px;
  }

  .btn__show-preview img {
    max-width: 100%;
  }

  .logo__c {
    width: 44px;
  }

  .logo__fsb {
    width: 73px;
  }

  .logo__NHSCT {
    width: 115px;
  }

  .logo__ecologi {
    width: 83px;
  }

  .logo__cyber-essentials {
    width: 56px;
  }

  .container__footer-menu .col-footer-menus {
    position: relative;
    left: 1px;
  }

  .col__sponsors .nav > .nav-item img {
    position: relative;
    left: -2px;
  }

  .col__sponsors .nav > .nav-item .logo__ecologi {
    left: -1px;
  }

  .col__sponsors .nav > .nav-item:first-child img {
    left: 0;
  }

  .main-footer .logo__ecologi__custom-text .custom-text {
    right: 1px;
  }

  .main-footer .logo__ecologi {
    width: 100px;
  }

  .main-footer .logo__c {
    top: -4px;
  }

  .main-footer .logo__fsb {
    top: -6px;
  }

  .main-footer .logo__NHSCT {
    top: -2px;
  }

  .form-groups-wrapper.flex-md-row {
    margin-bottom: 0;
  }

  .owl-carousel__home-hero .hero__content {
    margin-bottom: 30px;
  }

  .owl-carousel__home-hero .item4 .hero-header h1 {
    line-height: 63px;
  }

  .content__header--template {
    padding-top: 12px;
    padding-bottom: 16px;
  }

  /*!
       * .content__for-venues 767
       */
  .container__for-venues .image3 {
    left: 2px;
  }

  .content__for-venues {
    padding-top: 142px;
  }

  /*!
       * .container__for-members 767
       */
  .container__for-members {
    position: relative;
  }

  .container__for-members::after {
    content: "";
    display: block;
    background-color: #000;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
  }

  .container__for-members .content__image {
    margin-bottom: 55px;
  }

  .section__ticket-booking-steps {
    padding-bottom: 53px;
  }

  .section__cta--huge > .container-fluid {
    padding-left: 12px;
    padding-right: 12px;
  }

  .owl-carousel__about-us .item {
    width: 220px;
    height: 153px;
  }

  .owl-carousel__about-us .item__image {
    width: 100%;
    height: 100%;
  }

  .list__stats .item__heading + .item__text {
    margin-top: -9px;
  }

  .content__thanks-to-supporters .big-heading h1,
  .content__thanks-to-supporters .big-heading .h1,
  .content__thanks-to-supporters .big-heading h2,
  .content__thanks-to-supporters .big-heading .h2 {
    font-size: 40px;
    line-height: 45px;
  }

  .section__thanks-to-supporters .content__header {
    margin-bottom: 28px;
  }

  /*!
       * .content__faq 767
       */
  .content__faq .big-heading .ticket-orange,
  .content__faq .big-heading .ticket-red,
  .content__faq .big-heading .ticket-aquamarine {
    z-index: 1;
  }

  /*!
       * Guidelines page 767
       */
  .main__membership-guidelines .mg-screen .content {
    padding-top: 100px;
  }

  /*!
       * .content__register 767
       */
  .content__register {
    padding-bottom: 24px;
  }

  .content__register .col__right .content__body {
    padding-top: 29px;
  }

  .content__register .form-group,
  .content__register .form-group__cta.d-flex .btn {
    margin-bottom: 14px;
  }

  .content__register .form-group__cta .btn-primary {
    min-width: auto;
    width: 100%;
  }

  .main__register-last-part {
    background-position: 0 106px;
  }

  .row__form-fields + .row__checkboxes {
    margin-top: 26px;
  }

  .row__form-fields + .row__cta,
  .row__checkboxes + .row__cta {
    margin-top: 22px;
  }

  .content__register-last-part .big-heading h1,
  .content__register-last-part .big-heading .h1 {
    font-size: 46px;
    line-height: 48px;
  }

  .content__register-last-part .buttons-wrapper .btn {
    width: 100%;
  }

  /*!
       * .content__contact-us 767
       */
  .content__contact-us .content__header--template {
    padding-top: 24px;
    padding-bottom: 25px;
  }

  .content__contact-form .form-group,
  .content__contact-form .form-group__cta.d-flex .btn {
    margin-bottom: 15px;
  }

  .content__contact-us .content__contact-form .form-group__cta {
    margin-top: 30px;
  }

  .content__contact-us .col__left .content__body {
    line-height: 19px;
    padding-top: 26px;
    padding-bottom: 25px;
    padding-right: 19px;
  }

  .content__contact-us .col__right .content__body {
    padding-top: 26px;
    padding-bottom: 41px;
  }

  .content__contact-us .col__right .content__contact-form .content__body {
    padding-top: 23px;
    padding-bottom: 41px;
  }

  .form__contact-us-member-login .form-control {
    border-radius: 50px;
  }

  .content__contact-us .p-notice {
    font-size: 12px;
    letter-spacing: 0.24px;
    line-height: 14px;
  }

  .content-single-col .content__contact-form .buttons-wrapper__nav + form {
    margin-top: -4px;
  }

  .content__contact-us .list__my-bookings .card__event .col-right .btn {
    padding-top: 3px;
    padding-bottom: 4px;
  }

  /*!
       * .content__my-favourites 767
       */
  /*!
       * .content__leave-feedback 767
       */
  .form-group__venue-spend .input-group__as-form-control {
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;
    width: min-content;
    min-width: 164px;
  }

  .form-group__venue-spend .input-group__amount .form-control {
    flex: none;
    min-width: fit-content;
    max-width: initial;
    text-align: left;
  }

  /*!
       * .content__refer-friend 767px
       */
  .modal-backdrop.show {
    opacity: 0.45;
  }

  .modal__fw .modal-content {
    border-width: 0;
  }

  .btn-close__custom {
    width: 19px;
    height: 20px;
  }

  .modal .btn-close__absolute {
    top: 19px;
    right: 19px;
  }

  .modal__terms-and-conditions {
    font-size: 15px;
    letter-spacing: 0.3px;
    line-height: 18px;
  }

  .modal__terms-and-conditions .content__header {
    margin-bottom: 12px;
  }

  .modal__terms-and-conditions p {
    margin-bottom: 18px;
  }

  .modal__terms-and-conditions h2,
  .modal__terms-and-conditions .h2 {
    font-size: 30px;
    letter-spacing: 0.6px;
  }

  .content__refer-friend .big-heading .ticket-aquamarine {
    top: 22.1%;
    right: 7.5%;
    width: 48px;
  }

  /*!
       * .content__current-listings 767
       */
  .card__event .card-body {
    padding-bottom: 24px;
  }

  .listing-filters .form-groups-wrapper__search {
    margin-top: -3px;
  }

  /*!
       * .content__event-details 767
       */
  .nav-tabs__info-venue .list-group__time {
    margin-bottom: 13px;
  }

  .nav-tabs__info-venue .list-group__event-details .icon-wrapper {
    width: 22px;
  }

  .nav-tabs__info-venue .list-group__location {
    margin-bottom: 16px;
  }

  .nav-tabs__info-venue .icon__clock {
    top: -3px;
  }

  .nav-tabs__info-venue .icon__payment-card {
    top: -1px;
  }

  .nav-tabs__info-venue .tab-pane__body p {
    line-height: 20px;
  }

  /*!
       * .content__slidein 767
       */
  /*!
       * .content__checkout 767
       */
  .content__checkout .form-groups__expiry-cvc > .form-group__card-expiry {
    width: 170px;
  }

  .content__checkout .form-groups__expiry-cvc > .form-group__cvc {
    width: 90px;
  }

  .content__checkout .fc__amount {
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 24px;
  }

  .content__booking-timeout .heading,
  .content__booking-success .event__name {
    font-size: 15px;
    line-height: 20px;
  }

  .content__booking-success .content__header {
    padding-top: 16.9vh;
  }

  .content__booking-success .buttons-wrapper .btn {
    min-width: 100%;
  }

  .content__select-time .datepicker-inline {
    width: calc(100% - 30px);
  }

  .content__select-time .btn-close {
    top: 17px;
  }

  .content__select-time.as-dropdown {
    border: none;
    min-height: 100vh;
    top: 0;
  }

  /*!
       * .content__my-wallet 767
       */
  .content__my-wallet .big-heading .ticket-aquamarine {
    z-index: 1;
  }

  .content__my-wallet .col__top-up-info,
  .content__my-wallet .col__transaction-history {
    padding: 20px;
  }

  .content__my-wallet .col__top-up-info {
    padding-top: 22px;
  }

  .content__my-wallet .col__top-up-info p {
    margin-bottom: 20px;
  }

  .content__my-wallet .col__transaction-history {
    border-top: 1px solid #000;
    padding-bottom: 57px;
  }

  .card.bg-aqua,
  .card-footer.bg-aqua,
  .card.bg-aqua .card-footer {
    background-color: #89ffbc !important;
  }

  .card.bg-aqua {
    border-color: #89ffbc !important;
  }

  .content__my-wallet .card__credit-balance {
    margin-bottom: 30px;
  }

  .content__my-wallet .p-top-up {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 40px;
  }

  .content__my-wallet .p-top-up .btn {
    margin-top: 15px;
  }

  .content__my-wallet .heading__why-top-up {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .card__transaction-summary {
    font-size: 11px;
    line-height: 14px;
  }

  .card__transaction-summary .card-body {
    padding: 10px 12px 12px;
  }

  .card__transaction-summary .card-footer {
    padding-left: 13px;
    padding-right: 13px;
  }

  /*!
       * .content__my-bookings 767
       */
  .list__my-bookings .card__event .event-image {
    width: 100%;
  }

  .list__my-bookings .card__event .card-body {
    width: calc(100% - 170px);
  }

  .list__my-bookings .card__event .card-body {
    letter-spacing: 0.2px;
  }

  .list__my-bookings .card__event .card-title,
  .list__my-bookings .list__event-details {
    font-size: 10px;
    letter-spacing: 0.2px;
    line-height: 15px;
  }

  .list__my-bookings .card__event .card-title {
    margin-top: -1px;
    margin-bottom: 0;
  }

  .list__my-bookings .card__event .card-footer {
    margin-top: 19px;
  }

  .list__my-bookings .card__event .card-footer .btn {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .content__manage-booking-other-requests-received .big-heading h1 {
    font-size: 58px;
    line-height: 53px;
  }

  .content__manage-booking-other-requests-received .content__header {
    margin-top: 25.2vh;
    margin-bottom: 19px;
  }

  .content__manage-booking-other-requests-received .buttons-wrapper {
    margin-top: 40px;
  }

  /*!
       * .content__my-account 767
       */
  .list__tags .btn-check + .btn {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .section__my-account {
    padding-bottom: 0;
  }

  .section__my-account .list__tags {
    justify-content: center;
  }

  .section__my-account .list__tags > li {
    width: calc(50% - 5px);
  }

  .section__my-account .list__tags .btn {
    width: 100%;
  }

  .content__my-account .content__header {
    padding-left: 20px;
    padding-right: 20px;
  }

  .content__my-account-home .content__header {
    padding-top: 28px;
    margin-bottom: 91px;
  }

  .content__my-account-home .content__body {
    padding-left: 20px;
    padding-right: 20px;
  }

  .content__my-account .form-group,
  .content__my-account .form-groups-wrapper {
    margin-bottom: 8px;
  }

  .content__my-account .form-group__cta {
    margin-top: 30px;
  }

  .content__my-account .form-group__cta .btn {
    width: 100%;
  }

  .content__my-account .content > :last-child,
  .content__my-account .content > form > :last-child {
    margin-bottom: 0;
  }

  .content__my-account-home .ticket-yellow {
    top: 136.75%;
    right: 19.6%;
    width: 85px;
  }

  .content__my-account-home .ticket-aquamarine {
    top: -17px;
    left: 75.55%;
    width: 61px;
  }

  .content__my-account-home .ticket-orange {
    transform: translateY(100px);
  }

  .content__my-account-home .ticket-yellow2 {
    transform: translateY(70px);
    top: 33px;
    left: 43.5%;
    width: 46px;
  }

  .content__my-account-home .nav-link__card {
    padding: 13px 20px 15px;
    font-size: 14px;
    line-height: 19px;
  }

  .content__my-account-home .nav-link__heading {
    margin-bottom: 2px;
  }

  .form-groups__expiry-cvc > .form-group:first-child {
    margin-right: 10px;
  }

  .form-groups__expiry-cvc > .form-group {
    width: calc(50% - 5px);
  }

  .accordion__my-account-settings .btn-primary,
  .accordion__my-account-settings .btn-outline-primary {
    min-width: 100%;
  }

  .accordion__my-account-settings .form-control[readonly] {
    font-size: 20px;
    color: #000;
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .accordion__my-account-settings .accordion-body,
  .accordion-item__EmailPreferences .heading,
  .accordion-item__DeleteAccount .heading {
    font-size: 15px;
    letter-spacing: 0.3px;
    line-height: 20px;
  }

  .accordion__my-account-settings .form-group__cta + p {
    margin-top: 20px;
  }

  .accordion-item__SavedMobileNum .heading {
    font-size: 25px;
    line-height: 30px;
    margin-top: 3px;
    margin-bottom: 8px;
  }

  .accordion-item__SavedMobileNum .form-group + .heading {
    margin-top: 23px;
  }

  .accordion-item__EmailPreferences .accordion-body {
    padding-top: 18px;
  }

  .accordion-item__EmailPreferences .form-group + .heading {
    margin-top: 19px;
    margin-bottom: 12px;
  }

  .accordion-item__SavedCards .col__delete-saved-card {
    margin-top: 15px;
  }

  .accordion-item__SavedCards .heading__change-saved-card {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .accordion-item__SavedCards .row + .form-group {
    margin-top: 16px;
  }

  .accordion-item__SavedCards .form-group {
    margin-bottom: 15px;
  }

  .accordion-item__DeleteAccount .content > .heading:first-child {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .accordion-item__DeleteAccount .buttons-wrapper {
    margin-top: 15px;
  }

  .modal__registration-verification {
    font-size: 15px;
    letter-spacing: 0.3px;
    line-height: 20px;
  }

  .modal__message-received {
    font-size: 15px;
    line-height: 18px;
    top: 51px;
  }

  .modal__message-received .modal-dialog {
    margin: 0;
    max-width: 100%;
  }

  .modal__message-received .modal-content {
    border-radius: 0;
    box-shadow: none;
  }

  .modal__message-received .modal-body {
    min-height: calc(100vh - 51px);
  }

  .modal__message-received .big-heading h1,
  .modal__message-received .big-heading .h1,
  .modal__message-received .big-heading h2,
  .modal__message-received .big-heading .h2 {
    font-size: 80px;
    line-height: 73px;
  }

  .modal__message-received .content__header {
    padding-bottom: 50px;
  }

  .modal__message-received .content__body {
    padding-left: 20px;
    padding-right: 20px;
  }

  .modal__message-received .form-group__cta {
    margin-top: 28px;
  }

  .modal__message-received .big-heading h1 {
    position: relative;
    z-index: 2;
  }

  .modal__message-received .big-heading .ticket {
    transform: none;
    z-index: 3;
    display: block;
    visibility: visible;
    opacity: 1;
    transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1),
      transform 2s cubic-bezier(0.215, 0.61, 0.355, 1);
    animation: displayNoneToBLockTranslateY 1.2s;
  }

  .modal__message-received .big-heading .ticket-red {
    z-index: 1;
  }

  /*!
       * .content__uptake 767
       */
  .content__uptake .content__uptake-event {
    padding-top: 1px;
  }

  .content__uptake .col__right .content__uptake-details .content__body {
    padding-top: 9px;
    padding-bottom: 41px;
  }

  .content__uptake-event .logos-container {
    padding-left: 24px;
    padding-right: 24px;
  }

  .card__uptake--details .card-body {
    padding: 13px 14px 16px;
  }

  .card__uptake--details .card-body > .uptake-item {
    margin-top: -7px;
  }

  .content__uptake-event .card__event .btn,
  .card__uptake--carousel .btn {
    width: 100%;
  }

  .content__uptake-event {
    margin-bottom: 14px;
  }

  .content__uptake .list__uptake-details .heading {
    min-width: 98px;
  }

  .content__uptake-venues .big-heading .ticket-yellow1,
  .content__uptake-venues .big-heading .ticket-green2 {
    z-index: 1;
  }

  .content__uptake-event .row-uptake-list-progress {
    margin-bottom: 34px;
  }

  .content__uptake-venues .row-notice-buttons > .col + .col {
    margin-top: 10px;
  }

  .content__uptake-venues .big-heading .ticket-blue,
  .content__uptake-venues .big-heading .ticket-green1 {
    z-index: 1;
  }

  .content__uptake-venues .big-heading h1,
  .content__uptake-venues .big-heading .h1,
  .content__uptake-venues .big-heading h2,
  .content__uptake-venues .big-heading .h2 {
    font-size: 26px;
    line-height: 30px;
  }

  .content__uptake-venues {
    margin-bottom: 13px;
  }

  .main__uptake .section__uptake-venues {
    padding-top: 14px;
    padding-bottom: 10px;
  }

  .container__footer-menu .col-office-address {
    width: 51%;
  }

  .container__footer-menu .col-footer-menus {
    width: 49%;
  }

  .container__footer-menu .col-footer-menu {
    width: 100%;
  }

  .container__footer-menu .col-footer-menu + .col-footer-menu {
    margin-top: 24px;
  }

  body.modal-slide-down .modal-backdrop.show.fadein {
    opacity: 1;
  }

  /*!
       * .main__new-look 767
       */
  .main__new-look .content__what-we-do .big-heading .ticket-green {
    bottom: -6%;
  }

  .main__new-look .content__what-we-do .big-heading .ticket-aquamarine {
    top: 36%;
    right: 12%;
  }

  .main__new-look .section__ticket-booking-steps {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .main__new-look
    .section__ticket-booking-steps
    ~ .section__ticket-booking-steps {
    padding-top: 50px;
  }

  .main__new-look .container__for-members .content__image {
    margin-top: 80px;
  }

  .main__new-look .container__for-members::after {
    display: none;
  }

  .main__new-look .content__for-venues {
    padding-top: 0;
  }

  .main__new-look .container__for-venues .content__image {
    margin-top: 50px;
    margin-bottom: 0;
  }
}

/* END 767 */
@media (max-width: 575px) {
  .modal-dialog {
    margin-left: 20px;
    margin-right: 20px;
  }

  /*!
       * .content__NHSCT 575
       */
  .content__NHSCT .btn-primary {
    width: 100%;
  }

  /*!
       * Guidelines page 575
       */
  .main__membership-guidelines .screen05 .big-heading h2,
  .main__membership-guidelines .screen05 .big-heading .h2, 
  .main__membership-guidelines .screen06 .big-heading h2,
  .main__membership-guidelines .screen06 .big-heading .h2 {
    font-size: 57px;
    line-height: 57px;
  }

  .main__membership-guidelines .mg-screen .content__screen01 {
    padding-top: 17px;
  }

  .main__membership-guidelines .mg-screen .content__screen07 {
    padding-top: 163px;
  }

  .main__membership-guidelines .screen07 .big-heading h2 {
    margin-bottom: 45px;
  }

  /*!
       * .content__refer-friend 575
       */
  .section__refer-friend {
    padding-bottom: 89px;
  }

  .content__refer-friend .big-heading .ticket-aquamarine,
  .content__refer-friend .big-heading .ticket-yellow,
  .content__reactive-account .big-heading .ticket-yellow,
  .content__refer-friend .big-heading .ticket-blue {
    z-index: 2;
  }

  .content__refer-friend .content__header {
    padding-top: 84px;
    padding-bottom: 109px;
  }

  .content__refer-friend h3,
  .content__refer-friend .h3 {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 1px;
  }

  .card__invite-link .form-control {
  }

  .card__invite-link .form-group + .form-group__cta {
    margin-top: 25px;
  }

  .btn__modal-terms-conditions .btn__text {
    font-size: 10px;
    line-height: 25px;
  }

  .content__contact-us-faq .accordion-item:first-child {
    border-top: none;
  }

  .content__contact-us-faq .content__header {
    padding-bottom: 12px;
  }

  .card__uptake--details .btn {
    min-width: 100%;
  }
}

/* END 575 */
@media (max-width: 414px) {
  /*!
       * .content__membership-is-free 414
       */
  .content__new-look .lead,
  .content__membership-is-free .lead {
    font-size: 15px;
    line-height: 20px;
  }

  .content__membership-is-free .content__header {
    margin-bottom: 50px;
  }

  .content__membership-is-free .content__body {
    margin-bottom: 26px;
  }

  .content__screen01 .big-heading .ticket-yellow {
    top: 31.75%;
    right: -15px;
  }

  /*!
       * .content__register 414
       */
  .content__register .list__tags > li {
    width: calc(50% - 5px);
  }

  .content__register .list__tags .btn-check + .btn {
    width: 100%;
  }

  .card__uptake--carousel .logos-container .logo-ecologi {
    position: relative;
    left: -5px;
  }
}

/* END 414 */
@media (max-width: 389px) {
  .main__about-us .big-heading h1,
  .main__about-us .big-heading .h1,
  .main__about-us .big-heading h2,
  .main__about-us .big-heading .h2 {
    font-size: 45px;
    line-height: 45px;
  }

  .main__membership-guidelines .lead {
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 24px;
  }

  .main__membership-guidelines .screen01 .lead {
    font-size: 25px;
    line-height: 30px;
  }

  .main__membership-guidelines .vr {
    min-height: 30px;
  }

  .main__membership-guidelines .mg-screen .vr {
    margin-top: 20px;
  }

  .main__membership-guidelines .mg-screen .content__body {
    margin: 20px auto 40px;
  }

  .main__membership-guidelines .big-heading h1,
  .main__membership-guidelines .big-heading .h1,
  .main__membership-guidelines .big-heading h2,
  .main__membership-guidelines .big-heading .h2,
  .main__membership-guidelines .screen05 .big-heading h2,
  .main__membership-guidelines .screen05 .big-heading .h2,
  .main__membership-guidelines .screen06 .big-heading h2,
  .main__membership-guidelines .screen06 .big-heading .h2, 
  .main__membership-guidelines .screen07 .big-heading h2,
  .main__membership-guidelines .screen07 .big-heading .h2 {
    font-size: 36px;
    line-height: 36px;
  }

  .main__membership-guidelines .screen01 .big-heading h1,
  .main__membership-guidelines .screen01 .big-heading .h1,
  .main__membership-guidelines .screen01 .big-heading h2,
  .main__membership-guidelines .screen01 .big-heading .h2 {
    font-size: 48px;
    line-height: 48px;
  }

  .content__refer-friend .big-heading h1,
  .content__refer-friend .big-heading .h1 {
    font-size: 24vw;
    line-height: 1em;
  }

  /*!
      * .content__checkout 389
      */
  .content__booking-timeout .big-heading h1,
  .content__booking-timeout .big-heading .h1,
  .content__booking-timeout .big-heading h2,
  .content__booking-timeout .big-heading .h2 {
    font-size: 39vw;
  }

  .content__booking-success .big-heading h1,
  .content__booking-success .big-heading .h1,
  .content__booking-success .big-heading h2,
  .content__booking-success .big-heading .h2 {
    font-size: 26vw;
    line-height: 1em;
  }

  .content__booking-timeout .content__header,
  .content__booking-success .content__header {
    padding-top: 10vh;
  }

  /*!
       * .content__my-bookings 389
       */
  .content__manage-booking-other-requests-received .big-heading h1 {
    font-size: 14.5vw;
    line-height: 0.95em;
  }

  .content__manage-booking-other-requests-received .content__header {
    margin-top: 20vh;
  }

  /*!
       * .content__my-account 389
       */
  .content__my-account .big-heading h1,
  .content__register-last-part .big-heading h1,
  .content__register-last-part .big-heading .h1 {
    font-size: 11vw;
    line-height: 1.1em;
  }

  .content__my-account-home .nav-link__heading {
    font-size: 32px;
    line-height: 36px;
  }

  .content__contact-us .list__my-bookings .card__event .col-right .btn {
    min-width: auto;
  }

  .content__uptake-event .logos-container {
    padding-left: 0;
    padding-right: 0;
  }

  .list__uptake-stat {
    gap: 12px 15px;
  }

  .list__uptake-stat .stat__number {
    font-size: 36px;
    line-height: 32px;
  }

  .list__uptake-stat > li::before {
    top: 1px;
    height: 50px;
  }

  .main-footer .logo__c {
    width: 33px;
  }

  .main-footer .logo__fsb {
    width: 50px;
  }

  .main-footer .logo__NHSCT {
    width: 86px;
  }

  .main-footer .logo__ecologi__custom-text {
    top: -8px;
  }
}

/* END 389 */
