
/* ---------------------------------------------------------------------------- */
/* --------------------------Scroll Bar Locomotive------------------------------ */
/* ---------------------------------------------------------------------------- */
/* -------------------------------Testing Filters  it should removed------------------------------- */
/* .owl-carousel__event-tags .badge-outline {
      font-size: 30px;
}

#collapseListingFilters .list__event-tags-wrapper .list__event-tags .owl-stage, #collapseListingFilters .list__event-tags-wrapper .list__event-tags .owl-stage-outer {
      height: 40px !important;
} */
/* -------------------------------End Testing Filters  it should removed------------------------------- */




[data-scroll-direction="vertical"][data-scrolling="false"]::-webkit-scrollbar {
      display: none !important;
}

/*--------------------------------------------------------------------------------------------------- */
/*-------------------------------------- Default ScrollBar Style ------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */
/* width */
::-webkit-scrollbar {
      width: 12px;
      
}
    
/* Track */
::-webkit-scrollbar-track {
      background-color: transparent;
      box-shadow: inset 0 0 2px grey; 
      border-radius: 0px;
}
     
/* Handle */
::-webkit-scrollbar-thumb {
      background: var(--bs-red); 
      border-radius: 10px;
      opacity: 0.7;
}
    
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
      background: var(--bs-red); 
      opacity: 0.7;
}


/*--------------------------------------------------------------------------------------------------- */
/*---------------------------------------------  Header --------------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */


@media (max-width : 1199px) {
      .main-header__main-menu .loginPopup-mobile .form-group + .form-group__cta {
            margin-top: 20px;
      }
}


/* Code for sticky header */
.sticky-header-link {
      text-decoration: underline;
}

.sticky-header-link:hover {
      color: #fff !important;
      cursor: pointer;
}

/* End Code for sticky header */

/* trustpilot-header*/
.trustpilot-header {
     width: 100%;
     padding: 0;
     margin: 0;
     border-bottom: 1px solid #000;
}

.trustpilot-header .col {
      width: 100%;
     padding: 0;
     margin: 0;
     display: flex;
     justify-content: center;
     align-items: center;
}

.trustpilot-header .col .Trustpilot-reviews {
      width: 365px;
      vertical-align: middle;
}


@media (min-width : 414px) {
      .trustpilot-header .col .Trustpilot-reviews {
            width: 404px;
      }
}

@media (min-width : 767px) {
      .trustpilot-header .col .Trustpilot-reviews {
            width: 480px;
      }

}

@media (min-width : 1200px) {
      .trustpilot-header .col .Trustpilot-reviews {
            width: 520px;
      }
     
}
/* end trustpilot-header*/

/*--------------------------------------------------------------------------------------------------- */
/*---------------------------------------------  Footer --------------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */ 

/* trustpilot-footer */

.container__copyright .trustpilot-footer {
     margin : 0;
      padding-top: 0;
      padding-bottom:0 ;
      margin-top: 5px;
}

.container__copyright .trustpilot-footer .d-flex {
      justify-content: flex-start;
      align-items: center;
}

.container__copyright .trustpilot-footer .d-flex .Trustpilot-reviews {
      width: 365px;
      margin-left: -14px;
}

.container__footer-menu .logo__c {
      width: 44px;
      top: 0;
}
.container__footer-menu .logo__NHSCT {
      height: 44px;
      width: auto;
      top: 0;
      left: 0 !important; 
}

.container__footer-menu .logo__ecologi__custom-text {
      width: 100px;
      height: 44px;
      top: 0px;
      margin-top: 15px;
}

.container__footer-menu .logo__ecologi__custom-text .logo__ecologi {
      width: 100%;
      height: 44px;
      left: 0px !important;
}
.container__footer-menu .col__sponsors ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
}

.container__footer-menu .col__sponsors ul li {
      flex: 1 1 50%;
}

.container__footer-menu .logo-star__custom-text {
      display: flex;
      align-items: center;
      height: 44px;
      width: auto;
      top: 0px;
      margin-top: 15px;
}


.container__footer-menu .logo__ecologi__custom-text .logo__star-secure {
      width: auto;
      height: 44px;
      left: 0px !important;
}


 @media (min-width : 414px) {
      .container__copyright .trustpilot-footer .d-flex .Trustpilot-reviews {
            width: 408px;
      }
}

@media (min-width : 767px) {
      .container__copyright .trustpilot-footer .d-flex .Trustpilot-reviews {
            width: 480px;
            margin-left: -18px;
      }

      .container__footer-menu .col__sponsors ul li {
            flex: auto;
      }

      .container__footer-menu .logo__c {
            width: 57px;
      }

      .container__footer-menu .logo__NHSCT {
            height: auto;
            width: 150px;
      }

      .container__footer-menu .logo__ecologi__custom-text {
            width: 142px;
            height: 65px;
            margin-top: 0px;
            /* top: -2px; */
      }


      .container__footer-menu .logo__ecologi__custom-text .logo__ecologi {
            width: inherit;
            height: inherit;
      }

      .container__footer-menu .logo-star__custom-text {
            height: 65px;
            width: auto;
            /* top: -2px; */
      }

      .container__footer-menu .logo__ecologi__custom-text .logo__star-secure {
            height: 65px;
            width: auto;
      }
}

@media (min-width : 1200px) {

      .container__copyright .trustpilot-footer {
            margin : 0;
            padding: 0;
            margin-top: -14px;
       }

       
      .container__copyright .trustpilot-footer .d-flex {
            justify-content: flex-end;
      }


      .trustpilot-header .col .Trustpilot-reviews {
            width: 520px;
      }

      
} 

/* End of trustpilot-footer */

@media (min-width:1260px) and (max-width:1400px) {
      .container__footer-menu .logo__ecologi__custom-text {
            width: 116px;
            height: 52px;
      }

      .container__footer-menu .logo__ecologi__custom-text .logo__ecologi {
            height: 52px;
            width: 116px;
      }

      .container__footer-menu .logo-star__custom-text {
            height: 52px;
            width: auto;
      }

      .container__footer-menu .logo__ecologi__custom-text .logo__star-secure {
            height: 52px;
            width: auto;
      }
}



.section__ticket-booking-steps .content__footer-logo-wrapper {
      display: flex;
}

.section__ticket-booking-steps .content__footer .logo__ecologi__custom-text {
      width: 153px;
}

.section__ticket-booking-steps .content__footer .logo__ecologi__custom-text .custom-text {
      font-size: 10px;
      color: #fff;
}

.content__footer-logo-wrapper .logo__ecologi,
.content__footer-logo-wrapper .logo-nhs-charities-blue {
      width: 153px;
      height: auto;
}



@media (min-width : 400px) {
      .content__footer-logo-wrapper .logo__ecologi,
      .content__footer-logo-wrapper .logo-nhs-charities-blue {
            width: 173px;
            height: auto;
      }

}



/*--------------------------------------------------------------------------------------------------- */
/*-----------------------------------------  Homepage --------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

/* trustpilot-home-header */
.main__home.trustpilot-home-header {
      /* padding-top: 87px; */
      padding-top: 47px;
}

@media (min-width : 414px) {

      .main__home.trustpilot-home-header {
            padding-top: 50px;
            /* padding-top: 90px; */
      }
}

@media (min-width : 767px) {

      .main__home.trustpilot-home-header {
            padding-top: 50px;
            /* padding-top: 98px; */
      }
}

@media (min-width : 1024px) {

      .main__home.trustpilot-home-header {
            padding-top: 51px;
            /* padding-top: 99px; */
      }
}

@media (min-width : 1200px) {

      .main__home.trustpilot-home-header {
            padding-top: calc(91px + 0px);
            /* padding-top: calc(91px + 52px); */
      }
}
/* End trustpilot-home-header */



.main__home .section__home-hero {
      margin-top: 27px;
}


.content__carousel-home-hero .custom-nav {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-left: 10px;
      padding-right: 10px;
      position: absolute;
      bottom: 20px;
      z-index: 1;
      width: 100%;
}


.custom-nav [class*="owl-"] span {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 32px 21px;
      display: block;
      width: 32px;
      height: 21px;
      margin-right: auto;
      margin-left: auto;
      text-indent: -9999px;
}

.custom-nav [class*="owl-"] {
      -webkit-user-select: none;
      user-select: none;
      background-color: transparent;
      border: none;
}

.custom-nav button.owl-prev span {
      background-image: url('./assets/images/icon-arrow-left-32x21-black.svg');
}

.custom-nav button.owl-next span {
      width: 33px;
      background-image: url('./assets/images/icon-arrow-right-33x21-black.svg');
      background-size: 33px 21px;
}




@media (min-width: 768px) {
      .content__carousel-home-hero .custom-nav {
            bottom: 10px;
      }
}

@media (min-width : 1200px) {
      .content__carousel-home-hero .custom-nav {
            bottom: 20px;
            padding-left: 30px;
            padding-right: 30px;
            width: 50%;
      }
}

@media (min-width:1800px) {
      .content__carousel-home-hero .custom-nav {
            bottom: 40px;
            padding-left: 47px;
            padding-right: 44px;
      }
}

@media (min-width: 991px) {
      .main__home .section__home-hero {
            margin-top: 43px;
      }
}

/* Added By PP - For WHat We Do Section */

@media (min-width: 1000px) {
  .content__what-we-do .big-heading .ticket-red {
    top: 2px;
    left: 18.4%;
    width: 56px;
    z-index: -1;
  }

  .content__what-we-do .big-heading .ticket-yellow {
    bottom: -19px;
    left: 25.4%;
    width: 57px;
    z-index: 1;
  }

  .content__what-we-do .big-heading .ticket-green {
    top: 8px;
    left: 37.95%;
    width: 57px;
    z-index: 1;
  }

  .content__what-we-do .big-heading .ticket-aquamarine {
    bottom: -27px;
    right: 41.35%;
    width: 97px;
    z-index: 1;
  }

  .content__what-we-do .big-heading .ticket-blue {
    top: 9px;
    right: 28.25%;
    width: 55px;
    z-index: 1;
  }
}

@media (min-width: 1350px) {
  .content__what-we-do .big-heading .ticket-red {
    top: 39px;
    left: 5.4%;
    width: 76px;
    z-index: -1;
  }

  .content__what-we-do .big-heading .ticket-yellow {
    bottom: -4px;
    left: 14.4%;
    width: 77px;
    z-index: 1;
  }

  .content__what-we-do .big-heading .ticket-green {
    top: 68px;
    left: 33.95%;
    width: 77px;
    z-index: 1;
  }

  .content__what-we-do .big-heading .ticket-aquamarine {
    bottom: 4px;
    right: 39.35%;
    width: 117px;
    z-index: 1;
  }

  .content__what-we-do .big-heading .ticket-blue {
    top: 75px;
    right: 19.25%;
    width: 75px;
    z-index: 1;
  }
}

/* Added By PP - For WHat We Do Section */

/* Added By PP - For Our event organisers */
@media (min-width: 1000px) {
  .content__for-venues .ticket-yellow {
    top: 15px;
    right: 2%;
    width: 89px;
  }

  .content__for-venues .ticket-aquamarine {
    top: 45.2%;
    left: -29px !important;
    width: 92px;
  }

  .content__for-venues .ticket-green {
    bottom: 6.7%;
    right: 46.55%;
    width: 69px;
  }
}

@media (min-width: 1350px) {
  .content__for-venues .ticket-yellow {
    top: 15px;
    right: 2%;
    width: 89px;
  }

  .content__for-venues .ticket-aquamarine {
    top: 56.2%;
    left: -29px !important;
    width: 92px;
  }

  .content__for-venues .ticket-green {
    bottom: 1.7%;
    right: 46.55%;
    width: 69px;
  }
}
/* Added By PP - For Our event organisers */

/* Added By PP - Membership is free */
@media (min-width: 1250px) {
  .content__membership-is-free .big-heading .ticket-aquamarine {
    bottom: -125.5%;
    left: 13.35%;
    width: 65px;
    z-index: 1 !important;
  }
  .content__membership-is-free .big-heading .ticket-green {
    top: -47px;
    left: 33.35%;
    width: 72px;
    z-index: 1 !important;
  }

  .content__membership-is-free .big-heading .ticket-blue {
    bottom: -127%;
    left: 44.6%;
    width: 92px;
    z-index: 1;
  }

  .content__membership-is-free .big-heading .ticket-orange {
    top: -45px;
    right: 40%;
    width: 68px;
    z-index: 1 !important;
  }

  .content__membership-is-free .big-heading .ticket-yellow {
    bottom: -128.1%;
    right: 20.2%;
    width: 85px;
    z-index: 1 !important;
  }
}

@media (min-width: 1650px) {
  .content__membership-is-free .big-heading .ticket-aquamarine {
    bottom: -37.5%;
    left: 13.35%;
    width: 75px;
    z-index: 1 !important;
  }
  .content__membership-is-free .big-heading .ticket-green {
    top: 32px;
    left: 33.35%;
    width: 82px;
    z-index: 1 !important;
  }

  .content__membership-is-free .big-heading .ticket-blue {
    bottom: -13%;
    left: 44.6%;
    width: 92px;
    z-index: 1;
  }

  .content__membership-is-free .big-heading .ticket-orange {
    top: 6px;
    right: 37%;
    width: 78px;
    z-index: 1 !important;
  }

  .content__membership-is-free .big-heading .ticket-yellow {
    bottom: -12.1%;
    right: 10.2%;
    width: 95px;
    z-index: 1 !important;
  }
}
/* Added By PP - Membership is free */
@media (max-width: 1199px){

      .show-menu .main-header__main-menu {
            top: 50px;
      }
}

@media (min-width : 576px) {
      .section__ticket-booking-steps .content__footer-logo-wrapper {
            margin-left: auto;
            margin-right: auto;
      }
}

@media (max-width: 767px){

      .main__home .section__home-hero .owl-carousel__home-hero .item4 .hero-header h1 {
          line-height: 57px;
      }
      
      .main__home .section__home-hero .owl-stage-outer{
            padding-bottom: 60px !important;
      }

      .main__home .content__membership-is-free .lead{
            font-size: 20px;
            line-height: 24px;
      }
      
}

@media (min-width:768px) {

      .section__ticket-booking-steps .feature__icon .icon-search   {
            top: 4px;
      }

      .section__ticket-booking-steps .feature__icon .icon-ticket {
            top: 4px;
      }

      .section__ticket-booking-steps .feature__icon .icon-location-pin {
            top: 4px;
      }

}

@media (min-width : 992px) {
      .content__footer-logo-wrapper .logo__ecologi,
      .content__footer-logo-wrapper .logo-nhs-charities-blue {
            width: 300px;
            height: auto;
      }
}

@media (min-width : 1200px) {
      .content__footer-logo-wrapper .logo__ecologi,
      .content__footer-logo-wrapper .logo-nhs-charities-blue {
            width: 250px;
            height: auto;
      }

      .show-login .slidein-login {
            box-shadow: none;
            border-left: 1px solid;
            border-bottom: 1px solid;
            border-top: 1px solid;
            top: 90px;
      }
}


@media (max-width :1200px) {
      .section__ticket-booking-steps .content__footer .logo__ecologi__custom-text {
            width: 300px;
      }

      .section__ticket-booking-steps .content__footer .logo__ecologi__custom-text .custom-text {
            width: 133px;
            bottom: -67px;
            font-size: 20px;
            height: auto;
      }
}

@media (min-width :1200px) {
      .section__ticket-booking-steps .content__footer .logo__ecologi__custom-text {
            width: 250px;
      }

      .section__ticket-booking-steps .content__footer .logo__ecologi__custom-text .custom-text {
            width: 111px;
            bottom: -46px;
            font-size: 17px;
            height: auto;
      }
}


@media (max-width :991px) {
      .section__ticket-booking-steps .content__footer .logo__ecologi__custom-text {
            width: 173px;
      }

      .section__ticket-booking-steps .content__footer .logo__ecologi__custom-text .custom-text {
            width: 76px;
            bottom: -12px;
            font-size: 12px;
            height: auto;
      }
}


@media (max-width :767px) {
      .section__ticket-booking-steps .content__footer .logo__ecologi__custom-text {
            width: 173px;
      }

      .section__ticket-booking-steps .content__footer .logo__ecologi__custom-text .custom-text {
            width: 76px;
            bottom: -31px;
            font-size: 12px;
      }
}


@media (max-width :398px) {
      .section__ticket-booking-steps .content__footer .logo__ecologi__custom-text {
            width: 153px;
      }

      .section__ticket-booking-steps .content__footer .logo__ecologi__custom-text .custom-text {
            width: 68px;
            bottom: -21px;
            font-size: 10px;
            height: auto;
      }
}


@media (min-width : 1400px) {
      .main__home .container-fluid.container__for-members {
            padding-top: 120px;
      }
}

@media (min-width : 1800px) {
      .main__home .container-fluid.container__for-members {
            padding-top: 120px;
      }
}

/* Added By PP - Membership is free */
/* / New Arrow CSS for Homepage / */
@media (max-width: 480px) {
      .content__carousel-home-hero .custom-nav {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding-left: 10px;
        padding-right: 10px;
        position: absolute;
        bottom: 45% !important;
        z-index: 1;
        width: 100%;
      }
    
      .content__carousel-home-hero .owl-nav {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding-left: 10px;
        padding-right: 10px;
        position: absolute;
        bottom: 45% !important;
        z-index: 1;
        width: 100%;
      }
    
      .main__home .section__home-hero .owl-stage-outer {
        padding-bottom: 0px !important;
      }
    
      .owl-carousel__home-hero .hero__content {
        margin-bottom: 50px;
      }
    }
    
    @media (max-width: 380px) {
      .owl-carousel__home-hero .two-buttons .btn {
        padding-left: 12px;
        padding-right: 14px;
      }
    }
 /* / New Arrow CSS for Homepage / */

 @media (max-width: 991px) {
      .content__for-members .ticket-green {
            top: 31.5%;
            left: 22.8%;
            width: 48px;
            z-index: -1;
      }
}

/*--------------------------------------------------------------------------------------------------- */
/*------------------------------------------  New Look Page  ---------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */
/* Smaller Size >= 1000 & <=1350x*/
@media (min-width: 1000px) {
      /* Content for venues */
      .main__new-look .content__for-venues .ticket-aquamarine {
        left: -24px;
        top: 45%;
        width: 92px;
      }

      .main__new-look .content__for-venues .ticket-green {
        bottom: 6.7%;
        right: 46.55%;
        width: 69px;
      }

      .main__new-look .content__for-venues .ticket-yellow {
        top: -50px;
        right: 36.9%;
        width: 89px;
      }
      /* Content for venues */

      .main__new-look .content__what-we-do .big-heading .ticket-green {
        top: 110px;
        left: 49%;
        width: 7%;
        z-index: 1;
      }

      .main__new-look .content__what-we-do .big-heading .ticket-red {
        left: 15.5%;
        top: -23px;
      }

      .main__new-look .content__what-we-do .big-heading .ticket-aquamarine {
        bottom: -6%;
        right: 22%;
        width: 9%;
      }

      .main__new-look .content__what-we-do .big-heading .ticket-blue {
        top: 1%;
        right: 8%;
      }
    }

    /* Smaller Size >= 1350 & <=1899x*/
    @media (min-width: 1350px) {
      /* Content for venues */
      .main__new-look .content__for-venues .ticket-aquamarine {
        left: -24px;
        top: 45%;
        width: 92px;
      }

      .main__new-look .content__for-venues .ticket-green {
        bottom: 6.7%;
        right: 46.55%;
        width: 69px;
      }

      .main__new-look .content__for-venues .ticket-yellow {
        top: -50px;
        right: 36.9%;
        width: 89px;
      }
      /* Content for venues */

      .main__new-look .content__what-we-do .big-heading .ticket-green {
        top: 181px;
        left: 49%;
        width: 7%;
        z-index: 1;
      }

      .main__new-look .content__what-we-do .big-heading .ticket-red {
        left: 13.5%;
        top: -34px;
      }

      .main__new-look .content__what-we-do .big-heading .ticket-aquamarine {
        bottom: -6%;
        right: 22%;
        width: 9%;
      }

      .main__new-look .content__what-we-do .big-heading .ticket-blue {
        top: 1%;
        right: 8%;
      }
    }
    /* Big Size 1900px*/
    @media (min-width: 1900px) {
      /* Content for venues */
      .main__new-look .content__for-venues .ticket-aquamarine {
        top: 38.2%;
        left: 56px;
        width: 92px;
      }

      .main__new-look .content__for-venues .ticket-green {
        bottom: 14.7%;
        right: 46.55%;
        width: 69px;
      }

      .main__new-look .content__for-venues .ticket-yellow {
        top: 15px;
        right: 21.9%;
        width: 89px;
      }
    }
    /* Big Size*/
    @media (min-width: 2350px) {
      .main__new-look .content__what-we-do .big-heading .ticket-green {
        top: 233px;
        left: 57%;
        width: 7%;
        z-index: 1;
      }

      .main__new-look .content__what-we-do .big-heading .ticket-blue {
        top: -3%;
        right: 4%;
      }
    }



/* ---------------------------------------------------------------------------- */
/* -----------------------------Current Listing--------------------------------- */
/* ---------------------------------------------------------------------------- */
.section__current-listings .current-listings .results-not-found {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 150px;
}



.section__current-listings .current-listings .results-not-found h5 {
      text-align: center;
      margin: 0;
}

.section__current-listings .current_listings__soldout__event-image{
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
}

.current-listing-spinner {
      top: 46% !important;
      left: 43% !important;
      position: absolute !important;
      font-size: 4rem !important;
      opacity: 1 !important;
      z-index: 9999 !important;
      color: var(--bs-red) !important;
      position: fixed !important;
      transform-origin: center;
} 

.current-listings .item .event-image-listing {
      position: absolute;
      left: 0;
}

.padding-for-sticky-header {
      padding-top: 27px !important;
}

@media (min-width:767px) {

      .current-listing-spinner {
            top: 48% !important;
            left: 46% !important;
      } 
}

@media (min-width:992px) {

      .padding-for-sticky-header {
            padding-top: 44px !important;
      }

      #selectTicketType + .ts-wrapper .ts-control {
            padding-top: 12px !important;
            padding-bottom: 12px !important;
      }

      .selectedTickets  #selectTicketType + .ts-wrapper .ts-control {
            padding-top: 14px !important;
            padding-bottom: 14px !important;
      }

      #selectTicketsTotal + .ts-wrapper .ts-control {
            padding-top: 14px !important;
            padding-bottom: 14px !important;
      }
}

@media (min-width:1024px) {

      .current-listing-spinner {
            top: 48% !important;
            left: 47% !important;
      } 
}


@media (min-width:1200px) {

      .current-listing-spinner {
            top: 52% !important;
            left: 48% !important;
      } 


      .section__current-listings .current-listings.three-cols-xl .item {
            width: 25%;
            border-right: 1px solid #000;
      }
        
      .section__current-listings .current-listings.three-cols-xl .item:nth-child(4n + 4) {
            width: 25%;
            border-right: 0;
      }

      .section__current-listings .item  .card__event .btn-outline-primary {
            font-size: 25px;
            min-width: 198px;
            padding-top: 11px;
            padding-bottom: 11px;
      }


      /* .selectedTickets #selectTicketType + .ts-wrapper .ts-control { */
      #selectTicketType + .ts-wrapper .ts-control {
            padding-top: 12px !important;
            padding-bottom: 12px !important;
      }

      .selectedTickets  #selectTicketType + .ts-wrapper .ts-control {
            padding-top: 14px !important;
            padding-bottom: 14px !important;
      }

      #bookTicketsStep1 #btnSelectEventDate {
            padding-top: 11px !important;
            padding-bottom: 11px !important;
      }
}

@media (min-width :1550px) {
      .section__current-listings .item .card__event .btn-outline-primary {
            font-size: 25px;
            min-width: 273px;
            padding-top: 11px;
            padding-bottom: 11px;
      }
}


@media (min-width:1800px) {

      .current-listing-spinner {
            top: 52% !important;
            left: 49% !important;
      } 
}

    /* Smaller Size >= 575 & <=768px*/
    @media (min-width: 575px) {
      .content__current-listings .big-heading .ticket-blue {
        top: 7.4 !important;
        left: 18.35% !important;
        width: 18px !important;
        z-index: 1 !important;
      }
      .content__current-listings .big-heading .ticket-red {
        bottom: 19.1%;
        left: 31.2%;
        width: 19px;
        z-index: 1 !important;
      }

      .content__current-listings .big-heading .ticket-green {
        top: 51% !important;
        /* bottom: 25.05%; */
        left: 45.05%;
        width: 19px;
        z-index: 1 !important;
      }

      .content__current-listings .big-heading .ticket-aquamarine {
        bottom: 9.8%;
        right: 40.15%;
        width: 20px;
        z-index: 1 !important;
      }

      .content__current-listings .big-heading .ticket-yellow {
        top: 11%;
        right: 25.75%;
        width: 18px;
        z-index: 1 !important;
      }
    }

    /* Smaller Size >= 768 & <=1000px*/
    @media (min-width: 768px) {
      .content__current-listings .big-heading .ticket-blue {
        top: 7.4% !important;
        left: 6.35% !important;
        width: 33px !important;
        z-index: 1 !important;
      }
      .content__current-listings .big-heading .ticket-red {
        bottom: 18.1%;
        left: 24.2% !important;
        width: 37px !important;
        z-index: 1 !important;
      }

      .content__current-listings .big-heading .ticket-green {
        top: 35% !important;
        bottom: 21.05% !important;
        left: 42.05% !important;
        width: 45px !important;
        z-index: 1 !important;
      }

      .content__current-listings .big-heading .ticket-aquamarine {
        bottom: 4.8% !important;
        right: 35.15% !important;
        width: 55px !important;
        z-index: 1 !important;
      }

      .content__current-listings .big-heading .ticket-yellow {
        top: 13% !important;
        right: 12.75% !important;
        width: 33px !important;
        z-index: 1 !important;
      }
    }

    /* Smaller Size >= 1000 & <=1350px*/
    @media (min-width: 1000px) {
      .content__current-listings .big-heading .ticket-blue {
        top: 7.4% !important;
        left: 6.35% !important;
        width: 48px !important;
        z-index: 1 !important;
      }
      .content__current-listings .big-heading .ticket-red {
        bottom: 18.1% !important;
        left: 24.2% !important;
        width: 52px !important;
        z-index: 1 !important;
      }

      .content__current-listings .big-heading .ticket-green {
        top: 35% !important;
        bottom: 21.05% !important;
        left: 42.05% !important;
        width: 60px !important;
        z-index: 1 !important;
      }

      .content__current-listings .big-heading .ticket-aquamarine {
        bottom: 4.8% !important;
        right: 35.15% !important;
        width: 70px !important;
        z-index: 1 !important;
      }

      .content__current-listings .big-heading .ticket-yellow {
        top: 13% !important;
        right: 12.75% !important;
        width: 48px !important;
        z-index: 1 !important;
      }
    }

    /* Smaller Size >= 1350 & <=1899x */
    @media (min-width: 1350px) {
      .content__current-listings .big-heading .ticket-blue {
        top: 7.4% !important;
        left: 6.35% !important;
        width: 68px !important;
        z-index: 1 !important;
      }
      .content__current-listings .big-heading .ticket-red {
        bottom: 18.1% !important;
        left: 24.2% !important;
        width: 72px !important;
        z-index: 1 !important;
      }

      .content__current-listings .big-heading .ticket-green {
        bottom: 21.05% !important;
        left: 42.05% !important;
        width: 79px !important;
        z-index: 1 !important;
      }

      .content__current-listings .big-heading .ticket-aquamarine {
        bottom: 4.8% !important;
        right: 35.15% !important;
        width: 90px !important;
        z-index: 1 !important;
      }

      .content__current-listings .big-heading .ticket-yellow {
        top: 13% !important;
        right: 12.75% !important;
        width: 68px !important;
        z-index: 1 !important;
      }
    }



/* ---------------------------------------------------------------------------- */
/* -----------------------------Listing Filters--------------------------------- */
/* ---------------------------------------------------------------------------- */

/* .section__current-listings .listing-filters-wrapper .collapse {
      transition: all linear 0.3s;
}
 */

/* .accordion__my-account-settings .accordion-item__ChangePassword .accordion-collapse,
.accordion__my-account-settings .accordion-item__ChangeEmail .accordion-collapse,
.accordion__my-account-settings .accordion-item__SavedMobileNum .accordion-collapse,
.accordion__my-account-settings .accordion-item__EmailPreferences .accordion-collapse,
.accordion__my-account-settings .accordion-item__SavedCards .accordion-collapse,
.accordion__my-account-settings .accordion-item__DeleteAccount .accordion-collapse
{
      height: 0px;
      overflow: hidden;
      transition: height ease 0.3s;
} */



#collapseListingFilters .list__event-tags-wrapper .list__event-tags.owl-carousel.owl-hidden {
      opacity: 1;
}

#collapseListingFilters .list__event-tags-wrapper .list__event-tags .owl-stage,
#collapseListingFilters .list__event-tags-wrapper .list__event-tags .owl-stage-outer {
      height: 30px;
}

#collapseListingFilters .list__event-tags-wrapper .list__event-tags div:hover .badge-outline {  /* replace a tag with div by rushit*/
      border-color: var(--bs-red);
      color: var(--bs-red);
      background-color: rgba(0, 0, 0, 0);
}

#collapseListingFilters .list__event-tags-wrapper .list__event-tags div .badge.active {
      border-color: var(--bs-red);
      color: #fff;
      background-color: var(--bs-red);
}

#collapseListingFilters .list__event-tags-wrapper .btn-close__clearfilters {
      position: relative;
      bottom: -2px;
      height: 15px;
      width: 15px;
      font-size: 15px;
      margin-left: 10px;
}


#collapseListingFilters #owlCarouselEventTagsNav button.owl-next {
      display: none;
}

#collapseListingFilters #owlCarouselEventTagsNav button.owl-next:last-child {
      display: block;
}

.listing-filters-wrapper .list__event-tags-wrapper {
      overflow: hidden;
}

#collapseListingFilters .plugin-checkbox_options .option input {
      display: none;
}

#collapseListingFilters #selectLocation + .ts-wrapper .ts-control>div.item {
      background-color: var(--bs-red);
      color: white;
      margin: 0;
}

#collapseListingFilters #selectLocation + .ts-wrapper .ts-dropdown.multi .selected {
      color: var(--bs-red);
}

#collapseListingFilters #selectLocation + .ts-wrapper:not(.form-select).multi .ts-control {
      background-image: url("./assets/images/icon-arrow-down-black-sm.svg");
      background-position: right 13px center;
      background-size: 16px 14px;
      padding-right: 36px;
}

#collapseListingFilters #selectLocation + .ts-wrapper:not(.form-select).multi .ts-control {
      background-position: right 13px center;
      background-size: 16px 14px;
      padding-right: 46px;
      background-repeat: no-repeat;
}

#collapseListingFilters #selectLocation + .ts-wrapper.has-items .ts-control {
      padding-top: 5px;
      padding-bottom: 4px;
}


#collapseListingFilters #selectLocation + .ts-wrapper #selectLocation-opt-2,
#collapseListingFilters #selectLocation + .ts-wrapper #selectLocation-opt-3,
#collapseListingFilters #selectLocation + .ts-wrapper #selectLocation-opt-4,
#collapseListingFilters #selectLocation + .ts-wrapper #selectLocation-opt-5,
#collapseListingFilters #selectLocation + .ts-wrapper #selectLocation-opt-6
 {
      padding-left: 20px;
}

@media (min-width :992px) {
      #collapseListingFilters .list__event-tags-wrapper .btn-close__clearfilters {
            bottom: -3px;
            height: 20px;
            width: 20px;
            font-size: 20px;
            margin-left: 12px;
      }
      

      #collapseListingFilters #selectSortBy + .ts-wrapper .ts-dropdown-content,
      #collapseListingFilters #selectLocation + .ts-wrapper .ts-dropdown-content{
            max-height: 305px;
      }
}

@media (min-width:1024px) {

      /* #collapseListingFilters .list__event-tags-wrapper .btn-close__clearfilters {
            height: 24px;
            width: 24px;
            font-size: 20px;
      } */
}

@media (min-width:1200px) {
      #collapseListingFilters .list__event-tags-wrapper .list__event-tags .owl-stage,
      #collapseListingFilters .list__event-tags-wrapper .list__event-tags .owl-stage-outer {
            height: 54px;
      }

      #collapseListingFilters #selectLocation + .ts-wrapper:not(.form-select).multi .ts-control {
            padding-left: 20px;
      }


      #collapseListingFilters #selectLocation + .ts-wrapper:not(.form-select).multi .ts-control {
            background-position: right 14px center;
            background-size: 22px 19px;
            padding-right: 46px;
      }

      #collapseListingFilters #selectLocation + .ts-wrapper.has-items .ts-control {
            padding-top: 11px;
            padding-bottom: 13px;
      }
      
}

@media(min-width: 1800px) {
      #collapseListingFilters #selectSortBy + .ts-wrapper .ts-dropdown-content,
      #collapseListingFilters #selectLocation + .ts-wrapper .ts-dropdown-content{
            max-height: 305px;
      }
      
}
/* ---------------------------------------------------------------------------- */
/* -----------------------------My Booking Page--------------------------------- */
/* ---------------------------------------------------------------------------- */

.list__my-bookings .feedback-left {
      opacity: 0.5;
}

.list__my-bookings .feedback-left:active,
.list__my-bookings .feedback-left:hover,
.list__my-bookings .feedback-left:focus {
      opacity: 0.5;
      background-color: var(--bs-red);
      border-color: var(--bs-red);
      color: #fff;
}

@media (min-width:1700px) {
      .list__my-bookings .card__event .event-image {
            width: 120% !important;
            height: auto;
      }
}

.section__my-bookings .section__no-my-bookings {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-size: 65px;
      min-height: 20vh;
      line-height: 57px;
      color: var(--bs-red);
      border-bottom: 1px solid var(--bs-dark) !important;
}

/* My Booking image style changes while vendor is TTG */
.card__event-image .ttg-image-wrapper {
      /* width: 333px; */
      padding-top: 0;
      padding-bottom: 0px;
      border-radius: 10px;
      height: 97px;
      text-align: center;
      background-size: cover;
}

.card__event-image .ttg-image-wrapper img {
      height: 97px !important;
      width:auto;
      display: block;
      border-radius: 0px;
}


@media (min-width: 768px) {

      .section__my-bookings .section__no-my-bookings  {
            font-size: 7.8vw;
            line-height: 0.9em;
      }

      /* My Booking image style changes while vendor is TTG */
      .card__event-image .ttg-image-wrapper,
      .card__event-image .ttg-image-wrapper img {
            height: 137px !important;
      }
}


@media (min-width: 991px) {

      /* My Booking image style changes while vendor is TTG */
      .card__event-image .ttg-image-wrapper,
      .card__event-image .ttg-image-wrapper img {
            height: 158px !important;
      }



      /* Contact Us Event Detail image style changes while vendor is TTG */
      .content__event-details  .card__event-image .ttg-image-wrapper,
      .content__event-details .card__event-image .ttg-image-wrapper img {
            height: 136px !important;
      }

      .content__event-details .card__event-image .ttg-image-wrapper img {
            margin: auto;
      }
}

@media (min-width: 991px) {
      .card__event-image .ttg-image-wrapper, .card__event-image .ttg-image-wrapper img {
            height: 158px !important;
            border-radius: 10px;
      }
}


@media (min-width: 1200px) {
      .section__my-bookings .section__no-my-bookings {
            line-height: 1em;
      }

      /* Contact Us Event Detail image style changes while vendor is TTG */
      .content__event-details  .card__event-image .ttg-image-wrapper,
      .content__event-details .card__event-image .ttg-image-wrapper img {
            height: 261px !important;
      }

}

@media (min-width: 1700px) {

      /* My Booking image style changes while vendor is TTG */
      .card__event-image .ttg-image-wrapper {
            width: 120% !important;
      }
      .card__event-image .ttg-image-wrapper,
      .card__event-image .ttg-image-wrapper img {
            height: 198px !important;
      }
      .card__event-image .ttg-image-wrapper img {
            margin-left: -10%;
            border-radius: 10px;
      }

      /* Contact Us Event Detail image style changes while vendor is TTG */
      .content__event-details  .card__event-image .ttg-image-wrapper {
            width: 100% !important;
      }
}

@media (min-width: 1800px) {
      .section__my-bookings .section__no-my-bookings {
            line-height: 130px;
      }
}

/* ---------------------------------------------------------------------------- */
/* ------------------------Change Password Component---------------------------- */
/* ---------------------------------------------------------------------------- */
.content__my-account .is_change_password {
      color: var(--bs-red);
}
.accordion-item__ChangePassword .is_password_change .accordion-button {
      color: var(--bs-red);
      margin: 0;
      padding-left: 0;
}
.accordion-item__ChangePassword .is_password_change .accordion-button::after {
      display: none;
      
}

/* ---------------------------------------------------------------------------- */
/* ---------------------------Delete Account Page------------------------------- */
/* ---------------------------------------------------------------------------- */


.delete-account .form-group {
      max-width: 680px;
      margin-bottom: 15px;
}

.delete-account .form-group textarea {
      min-height: 180px;
      padding-top: 12px;
      padding-bottom: 12px;
}

/* ---------------------------------------------------------------------------- */
/* -----------------------------My Wallet Page--------------------------------- */
/* ---------------------------------------------------------------------------- */

.col__transaction-history .col__no-transaction-history {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      background-color: var(--bs-red);
      padding: 20px;
      border-radius: 10px;
      color: #fff;
}

.col__transaction-history .col__no-transaction-history h2 {
      font-size: 90px;
      color: #fff;
      line-height: 86px;
      margin-bottom: 40px;
      text-align: center;
      font-weight: bold;
}

.col__transaction-history .col__no-transaction-history p {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 40px;
      text-align: center;
      margin-bottom: 50px;
}

.col__transaction-history .col__no-transaction-history button {
      color: #fff;
      border-color: #fff;
      width: 100%;
      max-width: 405px;
}

.col__transaction-history .col__no-transaction-history button:hover,
.col__transaction-history .col__no-transaction-history button:active {
      color: #fff;
      border-color: #fff;
}

.col__transaction-history #inside-js-scroll {
      /* max-height: 50vh; */
      overflow: auto;
} 

.col__transaction-history ::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: #fff !important;
  border-radius: 10px !important;
}

.col__transaction-history ::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  background-color: #fff !important;
}

.col__transaction-history ::-webkit-scrollbar-thumb {
  background-color: #000 !important;
  border-radius: 10px !important;
  transition: 0.3s !important;
}

.col__transaction-history ::-webkit-scrollbar-thumb {
  background-color: #999 !important;
}

.col__transaction-history :hover::-webkit-scrollbar-thumb {
  background-color: #999 !important;
}


.content__wallet-topup .custom-checkbox {
      margin-top: 10px;
}

.content__wallet-topup .custom-checkbox-hide-checkbox {
      margin-top: 5px;
      padding-left: 0px !important;
}

.content__wallet-topup .custom-checkbox-sm.custom-checkbox__check .custom-control-label::before {
      top: 2px;
      left: 0;
      width: 17px;
      height: 17px;
}

.content__wallet-topup .custom-checkbox-sm.custom-checkbox__check .custom-control-label-hide::before {
      display: none;
}

.content__wallet-topup .custom-checkbox-sm.custom-checkbox__check .custom-control-label::after {
      top: 3px;
      left: 2px;
      width: 14px;
      height: 15px;
}

.content__wallet-topup .custom-checkbox-sm.custom-checkbox__check .custom-control-label-hide::after {
      display: none;
}

.content__wallet-topup .bottom-text,
.content__wallet-topup .form-label-error {
      font-size: 15px;
      letter-spacing: 0;
      line-height: 18px;
}

.space-for-apple-pay-button {
      margin: 19px;
}
#walletTopupStep1 .for_flexi_error_design .flexi_error_mobile {
      display: none;
}

@media (max-width:767px) {
      #walletTopupStep1 .for_flexi_error_design {
            gap: 0.5rem !important;
      }

      #walletTopupStep1 .for_flexi_error_design .flexi_error_mobile {
            display: block;
            margin: 0;
      }

      #walletTopupStep1 .flexi_error_device {
            display: none;
      }
}

@media (max-width:820px) {

      .col__transaction-history .inside-js-scroll-height {
            height: auto !important;
      }
}

@media (max-width :991px) {
      .space-for-apple-pay-button {
            margin: 14px;
      }
}

@media (max-width:1200px) {
      .col__transaction-history .col__no-transaction-history h2 {
            font-size: 65px;
            line-height: 65px;
            margin-bottom: 35px;
      }
}


@media (max-width:576px) {
      .col__transaction-history .col__no-transaction-history h2 {
            font-size: 45px;
            line-height: 45px;
            margin-bottom: 35px;
      }
}

@media (max-width:991px){

      .content__wallet-topup .custom-checkbox-sm .custom-control-label {
            font-size: 11px;
            line-height: 14px;
            /* padding-top: 2px; */
      }

      .content__wallet-topup .bottom-text,
      .content__wallet-topup .form-label-error {
            font-size: 11px;
            line-height: 14px;
      }
}

/* ---------------------------------------------------------------------------- */
/* -------------------------- My Favourites Page ------------------------------ */
/* ---------------------------------------------------------------------------- */
.section__my-favourites .section__no-my-favourites {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-size: 65px;
      min-height: 40vh;
      line-height: 57px;
      color: var(--bs-red);
      border-top: 1px solid var(--bs-dark) !important;
}

.section__my-favourites #tickerLikedShows {
      border-bottom: 1px solid;
}

@media (min-width: 768px) {

      .section__my-favourites .section__no-my-favourites  {
            font-size: 7.8vw;
            line-height: 0.9em;
        }
}

@media (min-width: 1200px) {
      .section__my-favourites .section__no-my-favourites {
            line-height: 1em;
      }

      .section__my-favourites .current-listings.three-cols-xl .item {
            width: 25%;
            border-right: 1px solid #000;
      }
        
      .section__my-favourites .current-listings.three-cols-xl .item:nth-child(4n + 4) {
            width: 25%;
            border-right: 0;
      }

}

@media (min-width: 1800px) {
      .section__my-favourites .section__no-my-favourites {
            line-height: 130px;
      }

      
}



/* --------------------------------------------------------------------------------------------- */
/* ----------------------Register1-2 Page & Change Password & Wallet TopUp -------------------- */
/* --------------------------------------------------------------------------------------------- */

.content__register .form-label-error,
.content__change-password .form-label-error,
.settings_change_password .form-label-error,
.settings_change_email .form-label-error,
.saved-card-contetn  .form-label-error,
.change-mobileNumber-content .form-label-error,
.change-mobileNumber-content .form-label-error,
.content__payment-options .form-label-error,
#manageBookingOtherRequests .form-label-error{
      color: #ff0a41;
      margin-top: 5px;
      visibility: visible;
}


/*----------------------------------------------------------------------------------------------- */
/*------------------------------------  OTP & Error Handler Modal -------------------------------- */
/*----------------------------------------------------------------------------------------------- */


#modalVerificationCode .otp-input-wrapper {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: space-around;
}

/*
#modalVerificationCode .otp-hidden-input {
      position: absolute;
      opacity: 0; 
      z-index: 1;
      width: 100%;
      height: 54px;
      text-align: center;
      font-size: 1.5em;
      letter-spacing: 10px;
} */

#modalVerificationCode .otp-hidden-input:focus + .otp-display {
      /* border-color: var(--bs-green); */
}

#modalVerificationCode .otp-display {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 54px;
      background: none !important;
      border: 1px solid var(--bs-body-color);
      border-radius: 10px;
      align-items: center;
}
    
#modalVerificationCode .otp-char {
      font-size: 30px;
      margin: 0 16px;
      color: var(--bs-body-color);
      letter-spacing: 10px;
}

#modalVerificationCode .otp-char.otp-char-asteric {
      padding-top: 9px;
}

#modalVerificationCode.modalOpen,
#modalAlmostDone.modalOpen,
#modalMyAccountDeleteCard.modalOpen,
.modal_error_handler.modalOpen,
.modal__show-preview.modalOpen,
#modalMessageReceived.modalOpen {
      animation: openModal 0.5s linear forwards;
}

#modalVerificationCode.modalClosed,
#modalAlmostDone.modalClosed,
#modalMyAccountDeleteCard.modalClosed,
.modal_error_handler.modalClosed,
.modal__show-preview.modalClosed,
#modalMessageReceived.modalClosed {
      animation: closeModal 0.5s linear forwards;
}

#modalVerificationCode .invalid-otp{
      color: #ff0a41;
      text-align: center;
      margin-bottom: 20px;
}

#modalVerificationCode .form-group__invalid-verification-code  {
      margin-bottom: 20px;
}

@media (max-width : 374px) {
      .modal_error_handler .modal-content{
            background-size : 265px
      }

      .modal_error_handler .modal-content h2 {
            font-size: 50px;
            line-height: 48px;
            margin-bottom: 20px;
      }

      .modal_error_handler .modal-content h2 + p {
            font-size: 16px;
            line-height: 18px;
      }

      .modal_error_handler .modal-content .buttons_wraper .btn{
            font-size: 16px;
            letter-spacing: 0.3px;
            padding: 8px 23px 9px;
            line-height: 18px;
      }
}



@media (max-width : 320px) {
      .modal_error_handler .modal-content{
            background-size : 215px
      }

      .modal_error_handler .modal-content h2 {
            font-size: 42px;
            line-height: 40px;
            margin-bottom: 16px;
      }

      .modal_error_handler .modal-content h2 + p {
            font-size: 18px;
            line-height: 20px;
      }

      .modal_error_handler .modal-content .buttons_wraper .btn{
            font-size: 18px;
            letter-spacing: 0.4px;
            padding: 9px 25px 10px;
            line-height: 20px;
      }
}



@media (min-width : 768px) {
      #modalVerificationCode .invalid-otp{
            margin-bottom: 25px;
      }
      
      #modalVerificationCode .form-group__invalid-verification-code  {
            margin-bottom: 25px;
      }
}

@keyframes openModal {
      0% {
            opacity: 0;
            transform: translateY(-10%);
      }
      50% {
            opacity: 0.5;
            transform: translateY(-5%);
      }
      100% {
            opacity: 1;
            transform: translateY(0);
      }
}

@keyframes closeModal {
      0% {
            opacity: 1;
            transform: translateY(0%);
      }
      50% {
            opacity: 0.5;
            transform: translateY(2.5%);
      }
      100% {
            opacity: 0;
            transform: translateY(5%);
      }
} 

/*----------------------------------------------------------------------------------------------- */
/*--------------------------------------- Error Handler Modal ----------------------------------- */
/*----------------------------------------------------------------------------------------------- */
.modal_error_handler {
      z-index: 1060;
}

.modal_error_handler .buttons_wraper {
      display: flex;
      justify-content: space-around;
}

@media (max-width:767px){

      .modal_error_handler .buttons_wraper .btn {
            font-size: 20px;
            letter-spacing: 0.4px;
            padding: 9px 29px 10px;
            line-height: 24px;
      }

      .modal_error_handler.ticAlert .buttons_wraper { 
            flex-direction: column;
      }

      .modal_error_handler.ticAlert p {
            font-size: 18px;
      }

      .modal_error_handler.ticAlert .buttons_wraper .btn {
            font-size: 18px;
      }

      .modal_error_handler.ticAlert .buttons_wraper .btn:first-child { 
            margin-bottom: 5px;
      }
      
}


@media (min-width:767px) {

      .modal_error_handler .buttons_wraper .btn {
            font-size: 20px;
            letter-spacing: 0.4px;
            line-height: 24px;
            padding: 9px 29px 10px;
      }

      .modal_error_handler.ticAlert .buttons_wraper .btn {
            padding: 9px 18px 10px;
      }

      .modal_error_handler.ticAlert .modal-dialog {
            max-width: 460px;
      }


      .modal_error_handler.ticAlert .modal-content {
            background-position: 91px 11px;
            background-size: 350px;
      }
}

@media (min-width:1200px) {
      .modal_error_handler .buttons_wraper .btn {
            font-size: 23px;
            letter-spacing: 0px;
            line-height: 30px;
            padding: 9px 29px 10px;
      }

      .modal_error_handler.ticAlert .modal-dialog {
            max-width: 500px;
      }

      .modal_error_handler.ticAlert .buttons_wraper .btn {
            font-size: 22px;
            padding: 9px 22px 10px;
      }
}


/*----------------------------------------------------------------------------------------------- */
/*----------------------------------------- Change Password ------------------------------------- */
/*----------------------------------------------------------------------------------------------- */

.main__change-password .form-group{
      max-width: 550px;
      margin-left: auto;
      margin-right: auto;

}

.main__change-password button {
      margin-left: auto;
      margin-right: auto;
}

.main__change-password .form-group-button {
      text-align: center;
      margin-bottom: 6rem;
}


/*--------------------------------------------------------------------------------------------------- */
/*---------------------------------------Settings Email Prefrence------------------------------------ */
/*--------------------------------------------------------------------------------------------------- */
.emailPrefrenceForm .form-group__list-tags .list__tags .unCheckLabel,
.emailPrefrenceForm .form-group__your-interests .list__tags .unCheckLabel {
      background-color: #fff;
      border-color:  var(--bs-dark);
      color: #000;
}



/* .emailPrefrenceForm .form-group__list-tags .list__tags .unCheckLabel:hover, */
/* .emailPrefrenceForm .form-group__your-interests .list__tags .unCheckLabel:hover, */
.emailPrefrenceForm .form-group__list-tags .list__tags .checkedLabel,
.emailPrefrenceForm .form-group__your-interests .list__tags .checkedLabel {
      background-color: var(--bs-red);
      border-color: var(--bs-red);
      color: #fff;
}

.emailPrefrenceForm .custom-checkbox .custom-control-input:checked~.custom-control-label::after,
.emailPrefrenceForm .custom-checkbox .custom-control-input~.custom-control-label::after {
      opacity: 0;
}


.emailPrefrenceForm .custom-checkbox .custom-control-input-checked:checked~.custom-control-label::after,
.emailPrefrenceForm .custom-checkbox .custom-control-input-checked~.custom-control-label::after {
      opacity: 1;
}

.emailPrefrenceForm .error-exists {
      color: #ff0a41;
      margin-top: 5px;
      visibility: visible;
}

/* React tooltip Design */

.emailPrefrenceForm .react-tooltip,
.content__register-part2  .react-tooltip{
      font-style: normal;
      font-weight: 400;
      line-height: 1.5;
      text-shadow: none;
      text-transform: none;
      letter-spacing: normal;
      word-break: normal;
      word-spacing: normal;
      white-space: normal;
      line-break: auto;
      font-size: .875rem;
      word-wrap: break-word;
}

.emailPrefrenceForm .react-tooltip,
.content__register-part2 .react-tooltip{
      z-index: 100;
      opacity: 1;
      height: auto;
      width: 180px;
      /* background-color: var(--bs-red); */
      max-width: 200px;
      padding: .25rem .5rem;
      color: #fff;
      text-align: center;
      background-color: #000;
      border-radius: .25rem;
      padding-top: 8px;
      padding-bottom: 12px;
}



/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------- Leave Feedback Page --------------------------------- */
/* --------------------------------------------------------------------------------------------- */
.section__leave-feedback .error-exists {
      color: #ff0a41;
      margin-top: 5px;
      visibility: visible;
}

.content__leave-feedback .event-image {
      max-width: 287px;
}

@media (max-width : 767px) {

      .section__leave-feedback  #inputVenueSpendAmount {
            min-width: 55px;
      }
}

/*--------------------------------------------------------------------------------------------------- */
/*-------------------------------------------- Register Page2 --------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */
.content__register-part2 .form-group .list__tags .unCheckLabel {
      background-color: #fff;
      border-color:  var(--bs-dark);
      color: #000;
}

.content__register-part2 .form-group .list__tags .checkedLabel,
.emailPrefrenceForm .form-group__your-interests .list__tags .checkedLabel {
      background-color: var(--bs-red);
      border-color: var(--bs-red);
      color: #fff;
}

.content__register-part2 .row__checkboxes .custom-control-input:checked~.custom-control-label::after,
.content__register-part2 .row__checkboxes .custom-control-input~.custom-control-label::after {
      opacity: 0;
}

.content__register-part2 .row__checkboxes .custom-control-input-checked:checked~.custom-control-label::after,
.content__register-part2 .row__checkboxes .custom-control-input-checked~.custom-control-label::after {
      opacity: 1;
}

.content__register-part2 .error-exists {
      color: #ff0a41;
      margin-top: 5px;
      visibility: visible;
}

.content__register-part2 .custom-control-label.tooltip-label::before {
      content: "";
      visibility: hidden;
}
/*--------------------------------------------------------------------------------------------------- */
/*--------------------------------------- Update Name Modal ----------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

#modalVerificationCode.modal__update-name h2 {
      text-align: center;
}

#modalVerificationCode.modal__update-name .form-group {
      display: flex;
      flex-direction: column;
}

#modalVerificationCode.modal__update-name .form-group label {
      text-align: left;
}

#modalVerificationCode.modal__update-name .form-label-error {
      text-align: left;
      color: var(--bs-red);
      margin-top: 5px;
}
/*--------------------------------------------------------------------------------------------------- */
/*------------------------------------------ Uptake Page -------------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */
/* .main__uptake .card__uptake {
      padding: 0 !important;
      border: none !important;
} */
.main__uptake .card__uptake .ecologi-card {
      color: #fff;
      border-radius: 9px !important;
}

.main__uptake .ct_available {
      color: #5cb85c;
      background-color:  var(--bs-ct_available);
}

.main__uptake  .ct_very_limited {
      background-color: var(--bs-ct_very_limited);
}

.main__uptake  .ct_limited {
      background-color: var(--bs-ct_limited);
}

.main__uptake .ct_alert {
      background-color: var(--bs-ct_alert);
}

.main__uptake .uptake-progress-wrapper .progress-bar-overlay {
      border: 1px solid #000;
}

.main__uptake .uptake-progress-wrapper .progress-bar-overlay::before {
      border: none;
}

.main__uptake .logo__ecologi__custom-text-uptake {
      position: relative;
}

.main__uptake .logo__ecologi__custom-text-uptake .custom-text {
      position: absolute;
}


.main__uptake .logo__ecologi__custom-text-uptake {
      width: 112px;
}

.main__uptake .logo__ecologi__custom-text-uptake .custom-text {
      font-size: 7px;
      right: 5px;
      text-align: center;
      bottom: -5px;
      color: #fff;
      width: 50px;
      z-index: 1;
}


.main__uptake .section__uptake {
      padding-top: 15px;
}

.main__uptake .icon-tooltip-info {
      top: 1px;
}

.main__uptake .section__uptake .stat__text {
      display: flex;
      justify-content: center;
      align-items: center;
}

@media (min-width : 415px) {
      
      .main__uptake .logo__ecologi__custom-text-uptake .custom-text {
            font-size: 7px;
            right: 0px;
            text-align: center;
            bottom: -5px;
            color: #fff;
            width: 50px;
            z-index: 1;
      }

}

@media (min-width : 1000px) {
      
      .main__uptake .logo__ecologi__custom-text-uptake .custom-text {
            font-size:8px;
            bottom: -8px;
      }

}


@media (min-width : 1200px) {

      .main__uptake .logo__ecologi__custom-text-uptake {
            width: 136px;
      }
      
      .main__uptake .logo__ecologi__custom-text-uptake .custom-text {
            font-size: 8px;
            right: -1px;
            text-align: center;
            bottom: -6px;
            color: #fff;
            width: 62px;
            z-index: 1;
      }

      .main__uptake .section__uptake {
            padding-top: 26px;
      }
}


/* uptake colured ticket dynamic as per title text */

.content__uptake__large .big-heading .ticket-blue {
      bottom: 8.65%;
      left: 3.65%;
      width: 43px;
      z-index: 1;
    }

    .content__uptake__large .big-heading .ticket-green1 {
      top: 20.95%;
      left: 17.35%;
      width: 49px;
    }

    .content__uptake__large .big-heading .ticket-yellow1 {
      left: 30.5%;
      top: -14.5%;
      width: 44px;
      z-index: 1;
    }

    .content__uptake__large .big-heading .ticket-aquamarine {
      left: 48.6%;
      top: 15.15%;
      width: 43px;
    }

    .content__uptake__large .big-heading .ticket-yellow2 {
      bottom: 13.95%;
      right: 29%;
      width: 44px;
    }

    .content__uptake__large .big-heading .ticket-green2 {
      top: 39.5%;
      right: 15.4%;
      width: 39px;
      z-index: 1;
    }
    .content__uptake__large .big-heading .ticket-red {
      bottom: 48.75%;
      right: 2.75%;
      width: 45px;
    }


    .content__uptake__medium .big-heading .ticket-green1 {
      top: 20.95%;
      left: 17.35%;
      width: 49px;
    }

    .content__uptake__medium .big-heading .ticket-yellow1 {
      left: 30.5%;
      top: -14.5%;
      width: 44px;
      z-index: 1;
    }

    .content__uptake__medium .big-heading .ticket-aquamarine {
      left: 48.6%;
      top: 15.15%;
      width: 43px;
    }

    .content__uptake__medium .big-heading .ticket-red {
      bottom: 48.75%;
      right: 18.75%;
      width: 45px;
    }

    .content__uptake__medium .big-heading .ticket-yellow2 {
      bottom: 5.95%;
      right: 34%;
      width: 44px;
      z-index: 1;
    }

    .content__uptake__medium .big-heading .ticket-red {
      bottom: 48.75%;
      right: 18.75%;
      width: 45px;
      z-index: 1;
    }

    .content__uptake__small .big-heading .ticket-green1 {
      top: 53.95%;
      left: 43.35%;
      width: 49px;
    }

    .content__uptake__small .big-heading .ticket-aquamarine {
      left: 57.6%;
      top: 0.15%;
      width: 43px;
      z-index: 1;
    }

    .content__uptake__small .big-heading .ticket-yellow2 {
      bottom: -13.05%;
      right: 27%;
      width: 44px;
      z-index: 1;
    }


/*--------------------------------------------------------------------------------------------------- */
/*-------------------------------- Locomotive Scroll Flipping Issue --------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.has-scroll-smooth {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
}

/*--------------------------------------------------------------------------------------------------- */
/*---------------------------------- Wallet TopUp & Booking Module ---------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

/* walletTopupStep2 */

#bookTicketsStep1  #btnSelectEventDate {
      color: #000 !important;
}

#bookTicketsStep1  #btnSelectEventDate {
      color: #000 !important;
}


.slidein__book-tickets  #bookTicketsStep1 .ts-dropdown-content {
      opacity: 1;
      background-color: #fff;
}

@media (max-width:991px) {
      #bookTicketsStep1 .content__body--total .custom-checkbox-sm .custom-control-label {
            font-size: 16px;
            line-height: 20px;
      }
}

@media (max-width:767px) {
      #walletTopupStep2 .content__body.reusecard__wrapper,
      #bookTicketsStep4PayViaCard .content__body.reusecard__wrapper {
            margin: 0;
            padding: 0;
      }
      
      #walletTopupStep2 .content__body.reusecard__wrapper .input-group__as-checkbox,
      #bookTicketsStep4PayViaCard .content__body.reusecard__wrapper .input-group__as-checkbox {
            margin-bottom: 19px;
      }
      
      #walletTopupStep2 .content__body.reusecard__wrapper .input-group__as-CVC,
      #bookTicketsStep4PayViaCard .content__body.reusecard__wrapper .input-group__as-CVC {
            height: 42px;
      }
      
      #walletTopupStep2 .content__body.reusecard__wrapper .input-group__as-CVC input,
      #bookTicketsStep4PayViaCard .content__body.reusecard__wrapper .input-group__as-CVC input {
            height: 38px;
            font-size: 20px;
            line-height: 24px;
      }
      
}

@media (min-width:768px) {

      #walletTopupStep2 .content__body.reusecard__wrapper,
      #bookTicketsStep4PayViaCard .content__body.reusecard__wrapper
       {
           display: flex;
           margin: 0;
           padding: 0;
      }

      #walletTopupStep2 .content__body.reusecard__wrapper .input-group__as-CVC, 
      #bookTicketsStep4PayViaCard .content__body.reusecard__wrapper .input-group__as-CVC 
      {
            height: 54px;
            margin-left: 5px;
      }

      #walletTopupStep2 .content__body.reusecard__wrapper .input-group__as-CVC input,
      #bookTicketsStep4PayViaCard .content__body.reusecard__wrapper .input-group__as-CVC input
      {
            font-size: 20px;
            height: 54px;
      }

      .bookTicketsStep4PayViaWallet__wrapper  .content__footer__wrapper .content__footer .btn.btn-primary{
            opacity: 1;
      }


}

@media (min-width:1024px) {

      #walletTopupStep2 .content__body.reusecard__wrapper .input-group__as-CVC, 
      #bookTicketsStep4PayViaCard .content__body.reusecard__wrapper .input-group__as-CVC 
      {
            height: 48px;
            margin-left: 10px;
      }

      #walletTopupStep2 .content__body.reusecard__wrapper .input-group__as-CVC input,
      #bookTicketsStep4PayViaCard .content__body.reusecard__wrapper .input-group__as-CVC input
      {
            font-size: 20px;
            height: 48px;
      }

}

@media (min-width:1800px) {

      #walletTopupStep2 .content__body.reusecard__wrapper .input-group__as-CVC input,
      #bookTicketsStep4PayViaCard .content__body.reusecard__wrapper .input-group__as-CVC input
      {
            font-size: 25px;
            height: 57px;
      }

}

/*--------------------------------------------------------------------------------------------------- */
/*-------------------------------------------  My Booking ------------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

@media (min-width: 992px) {

      .list__my-bookings .card__event .card-footer .buttons-wrapper .btn {
            min-width: 205px;
      }
}

/*--------------------------------------------------------------------------------------------------- */
/*-----------------------------------------  Cancel Booking ----------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */
#manageBookingReduceTickets .select-ticket-type-wrapper {
      margin-bottom: 26px;
      padding: 0;
}

#manageBookingReduceTickets .select-ticket-type-wrapper h4,
#manageBookingReduceTickets .select-ticket-type-wrapper .form-group {
      margin: 0;
      padding: 0;
}

#manageBookingReduceTickets .form-label-error {
      color: #ff0a41;
      margin-top: 5px;
      visibility: visible;
}

@media (min-width:768px) {

      #manageBookingReduceTickets .select-ticket-type-wrapper {
            display: flex;
            justify-content: space-between;
            width: 80%;
      }

      #manageBookingReduceTickets .select-ticket-type-wrapper .form-group {
            width: 50%;
      }

}

/*--------------------------------------------------------------------------------------------------- */
/*----------------------------------------- Event Details  ----------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */
.modal__show-preview.show {
      display: block;
}

.section__event-details .event-details__soldout__event-image{
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
}

/* -------------------------------Booking Module Book Now Button------------------------------------- */

.content-scrollable.content-scrollable-in-steps {
      flex: 1;
      min-height: auto;
      height: 100%;
}

.content-scrollable.content-scrollable-in-steps form {
      width: 100%;
      height: 100%;
}

.content-scrollable.content-scrollable-in-steps .content__checkout {
      min-height: auto;
      height: 100%;
      display: flex;
}

.content-scrollable.content-scrollable-in-steps .bookTicketsStep1__wrapper,
.content-scrollable.content-scrollable-in-steps .bookTicketsStep2__wrapper,
.content-scrollable.content-scrollable-in-steps .bookTicketsStep3__wrapper,
.content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaCard__wrapper,
.content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaWallet__wrapper,
.content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaWalletPlusCard__wrapper,
.content-scrollable.content-scrollable-in-steps .bookTicketsStep5OrderSummaryPayViaWalletPlusCard__wrapper
{
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: auto;
      padding-bottom: 178px;
}

.content-scrollable.content-scrollable-in-steps .bookTicketsStep1__wrapper {
      padding-bottom: 240px;
}

.content-scrollable.content-scrollable-in-steps .bookTicketsStep1__wrapper  #bookTicketsStep1 .content__body {
      flex: 1;
      overflow: auto;
}




/* .content-scrollable.content-scrollable-in-steps.iphone-extra-padding .bookTicketsStep1__wrapper {
      padding-bottom: 240px;
}


.content-scrollable.content-scrollable-in-steps.iphone-extra-padding .bookTicketsStep1__wrapper  #bookTicketsStep1 .content__body {
      flex: 1;
      overflow: auto;
} */

.content-scrollable.content-scrollable-in-steps.iphone-extra-padding .bookTicketsStep2__wrapper {
      padding: 0;
}

.content-scrollable.content-scrollable-in-steps.iphone-extra-padding .bookTicketsStep2__wrapper #bookTicketsStep2 {
      flex: 1;
      overflow: auto;
      padding-bottom: 160px;
}

.content-scrollable.content-scrollable-in-steps.iphone-extra-padding .bookTicketsStep4PayViaWallet__wrapper #bookTicketsStep4PayViaWallet .content__body{
      flex: 1;
      overflow: auto;
      padding-bottom: 80px;
}

.content-scrollable.content-scrollable-in-steps.iphone-extra-padding .bookTicketsStep4PayViaCard__wrapper #bookTicketsStep4PayViaCard > .content__body{
      flex: 1;
      overflow: auto;
      padding-bottom: 80px;
}

.content-scrollable.content-scrollable-in-steps .bookTicketsStep1__wrapper #bookTicketsStep1,
.content-scrollable.content-scrollable-in-steps .bookTicketsStep2__wrapper #bookTicketsStep2, 
.content-scrollable.content-scrollable-in-steps .bookTicketsStep3__wrapper #bookTicketsStep3, 
.content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaCard__wrapper #bookTicketsStep4PayViaCard, 
.content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaWallet__wrapper #bookTicketsStep4PayViaWallet, 
.content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaWalletPlusCard__wrapper #bookTicketsStep4PayViaWalletPlusCard, 
.content-scrollable.content-scrollable-in-steps .bookTicketsStep5OrderSummaryPayViaWalletPlusCard__wrapper #bookTicketsStep5OrderSummaryPayViaWalletPlusCard 
{
      flex: 1;
      display: flex;
}

.content-scrollable.content-scrollable-in-steps .bookTicketsStep1__wrapper #bookTicketsStep1 .content__body:not(.content__body--total),
/* .content-scrollable.content-scrollable-in-steps .bookTicketsStep2__wrapper #bookTicketsStep2 .content__body:not(.content__body--total) */
.content-scrollable.content-scrollable-in-steps .bookTicketsStep3__wrapper #bookTicketsStep3 .content__body:not(.content__body--total),
.content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaCard__wrapper #bookTicketsStep4PayViaCard .content__body:not(.content__body--total),
.content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaWallet__wrapper #bookTicketsStep4PayViaWallet .content__body:not(.content__body--total),
.content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaWalletPlusCard__wrapper #bookTicketsStep4PayViaWalletPlusCard .content__body:not(.content__body--total),
.content-scrollable.content-scrollable-in-steps .bookTicketsStep5OrderSummaryPayViaWalletPlusCard__wrapper #bookTicketsStep5OrderSummaryPayViaWalletPlusCard .content__body:not(.content__body--total)
{
      flex: 1;
} 

.content-scrollable.content-scrollable-in-steps .bookTicketsStep1__wrapper .content__body--total,
.content-scrollable.content-scrollable-in-steps  .bookTicketsStep2__wrapper .content__body--total,
.content-scrollable.content-scrollable-in-steps  .bookTicketsStep2__wrapper #btnBackToStep1,
.content-scrollable.content-scrollable-in-steps  .bookTicketsStep3__wrapper .content__body--total,
.content-scrollable.content-scrollable-in-steps  .bookTicketsStep3__wrapper #btnBackToStep2,
.content-scrollable.content-scrollable-in-steps  .bookTicketsStep4PayViaCard__wrapper .content__body--total,
.content-scrollable.content-scrollable-in-steps  .bookTicketsStep4PayViaCard__wrapper #btnBackToStep3FromPayViaCard, 
.content-scrollable.content-scrollable-in-steps  .bookTicketsStep4PayViaWallet__wrapper .content__body--total,
.content-scrollable.content-scrollable-in-steps  .bookTicketsStep4PayViaWallet__wrapper #btnBackToStep3FromPayViaWallet, 
.content-scrollable.content-scrollable-in-steps  .bookTicketsStep4PayViaWalletPlusCard__wrapper .content__body--total,
.content-scrollable.content-scrollable-in-steps  .bookTicketsStep4PayViaWalletPlusCard__wrapper #btnBackToStep3FromPayViaWalletPlusCard, 
.content-scrollable.content-scrollable-in-steps  .bookTicketsStep5OrderSummaryPayViaWalletPlusCard__wrapper .content__body--total,
.content-scrollable.content-scrollable-in-steps  .bookTicketsStep5OrderSummaryPayViaWalletPlusCard__wrapper .content__footer .btn__back.btn
{
      background-color: #fff;
}

.content-scrollable.content-scrollable-in-steps .bookTicketsStep1__wrapper .content__footer__wrapper, 
.content-scrollable.content-scrollable-in-steps .bookTicketsStep2__wrapper .content__footer__wrapper,
.content-scrollable.content-scrollable-in-steps .bookTicketsStep3__wrapper .content__footer__wrapper,
.content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaCard__wrapper .content__footer__wrapper,
.content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaWallet__wrapper .content__footer__wrapper,
.content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaWalletPlusCard__wrapper .content__footer__wrapper,
.content-scrollable.content-scrollable-in-steps .bookTicketsStep5OrderSummaryPayViaWalletPlusCard__wrapper .content__footer__wrapper
{
      position: fixed;
      bottom: 0;
      width: 805px;
      z-index: 98;
}


.content-scrollable.content-scrollable-in-steps ::-webkit-scrollbar-track {
      -webkit-box-shadow: none !important;
      background-color: #fff !important;
      border-radius: 10px !important;
}
    
.content-scrollable.content-scrollable-in-steps ::-webkit-scrollbar {
      width: 6px !important;
      height: 6px !important;
      background-color: #fff !important;
}
    
.content-scrollable.content-scrollable-in-steps ::-webkit-scrollbar-thumb {
      background-color: #000 !important;
      border-radius: 10px !important;
      transition: 0.3s !important;
}
    
.content-scrollable.content-scrollable-in-steps ::-webkit-scrollbar-thumb {
      background-color: #999 !important;
}
    
.content-scrollable.content-scrollable-in-steps :hover::-webkit-scrollbar-thumb {
      background-color: #999 !important;
}


@media (max-width: 767px) { 

      .content-scrollable.content-scrollable-in-steps .bookTicketsStep1__wrapper .content__footer__wrapper,
      .content-scrollable.content-scrollable-in-steps .bookTicketsStep2__wrapper .content__footer__wrapper,
      .content-scrollable.content-scrollable-in-steps .bookTicketsStep3__wrapper .content__footer__wrapper,
      .content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaCard__wrapper .content__footer__wrapper,
      .content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaWallet__wrapper .content__footer__wrapper,
      .content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaWalletPlusCard__wrapper .content__footer__wrapper,
      .content-scrollable.content-scrollable-in-steps .bookTicketsStep5OrderSummaryPayViaWalletPlusCard__wrapper .content__footer__wrapper {
            width: 100%;
      }

      .content__select-time.as-dropdown {
            overflow: hidden !important;
      }
}

@media (max-width: 992px) {
      
      .content-scrollable.content-scrollable-in-steps .bookTicketsStep1__wrapper .content__footer__wrapper,
      .content-scrollable.content-scrollable-in-steps .bookTicketsStep2__wrapper .content__footer__wrapper,
      .content-scrollable.content-scrollable-in-steps .bookTicketsStep3__wrapper .content__footer__wrapper,
      .content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaCard__wrapper .content__footer__wrapper,
      .content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaWallet__wrapper .content__footer__wrapper,
      .content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaWalletPlusCard__wrapper .content__footer__wrapper,
      .content-scrollable.content-scrollable-in-steps .bookTicketsStep5OrderSummaryPayViaWalletPlusCard__wrapper .content__footer__wrapper{
            width: 100%;
      }
}

@media(min-width : 1200px){
      .content-scrollable.content-scrollable-in-steps .bookTicketsStep1__wrapper, 
      .content-scrollable.content-scrollable-in-steps .bookTicketsStep2__wrapper,
      .content-scrollable.content-scrollable-in-steps .bookTicketsStep3__wrapper,
      .content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaCard__wrapper,
      .content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaWallet__wrapper,
      .content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaWalletPlusCard__wrapper,
      .content-scrollable.content-scrollable-in-steps .bookTicketsStep5OrderSummaryPayViaWalletPlusCard__wrapper{
            padding-bottom: 178px;
      }
}


@media(min-width : 1800px){
      .content-scrollable.content-scrollable-in-steps .bookTicketsStep1__wrapper, 
      .content-scrollable.content-scrollable-in-steps .bookTicketsStep2__wrapper,
      .content-scrollable.content-scrollable-in-steps .bookTicketsStep3__wrapper,
      .content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaCard__wrapper,
      .content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaWallet__wrapper,
      .content-scrollable.content-scrollable-in-steps .bookTicketsStep4PayViaWalletPlusCard__wrapper,
      .content-scrollable.content-scrollable-in-steps .bookTicketsStep5OrderSummaryPayViaWalletPlusCard__wrapper {
            padding-bottom: 278px;
      }
}

/* -----------------------------End Booking Module Book Now Button----------------------------------- */

/* .section__event-details .buttons-wrapper__nav.back-button {
      position: absolute;
      top: 49px;
      left: 20px;
} */

.section__event-details .fixed-scroll-target {
      position: relative;
}
@media (max-width: 767px) {

      .main__event-details.stop-scroll + .main-footer{
            display: none !important;
      }

      .main__event-details.stop-scroll .nav-tabs__info-venue .tab-content .tab-pane__info .tab-pane__body h4.heading, 
      .main__event-details.stop-scroll .nav-tabs__info-venue .tab-content .tab-pane__info .tab-pane__body h4.heading  + p,
      .main__event-details.stop-scroll .nav-tabs__info-venue .tab-content .tab-pane__venue .venue-map,
      .main__event-details.stop-scroll .nav-tabs__info-venue-wrapper .list__event-tags,
      .main__event-details.stop-scroll .content__event-details .event__body
      {
            display: none !important;
      }
      .content__slidein.mobile-bottom-padding{
            padding-bottom: 56px; 
      }

      .content__slidein.mobile-bottom-padding-ios{
            padding-bottom: 60px; 
      }
}

@media (max-width: 1200px) { 
      .content__slidein.mobile-bottom-padding-ios-mac{
            padding-bottom: 40px !important; 
      }

      .content__slidein.mobile-bottom-padding{
            padding-bottom: 90px !important; 
      }

}


/* code for fix scroller issue on booking module*/
@media (max-width: 767px) {
      .slidein .content__checkout {
        min-height: auto;
      }
    
      .content__select-time.as-dropdown {
        min-height: auto;
        height: 100%;
        overflow: auto;
        
      }
    
      /* target iOS devices only */
      @supports (-webkit-touch-callout: none) {
        div.slidein__book-tickets__is-open {
          left: 0;
          position: fixed;
          right: 0;
          overflow-y: hidden !important;
        }
    
        .slidein-wrapper.show {
          background: #fff;
        }
    
        .slidein-wrapper .slidein {
          height: 87%;
        }
    
        .slidein .content__slidein {
          height: 100%;
        }

        .slidein-wrapper .slidein.ios-app-mobile-bottom-padding {
            height: 100%;
          }
      }

      .bookTicketsStep1__wrapper .content__select-time--wrapper .content__select-time.as-dropdown {
            overflow: scroll !important;
            padding-bottom: 70px;
      }
}

@media (max-width : 1120px) {
      
      /* target iOS devices only */
      @supports (-webkit-touch-callout: none) {
            div.slidein__book-tickets__is-open {
                  left: 0;
                  position: fixed;
                  right: 0;
                  overflow-y: hidden !important;
                }
           
        
            .slidein-wrapper .slidein {
              height: 97%;
            }
        
            .slidein .content__slidein {
              height: 100%;
            }
    
            .slidein-wrapper .slidein.ios-app-mobile-bottom-padding {
                height: 100%;
              }
          }

}


/* code for badge beneth  nav-tabs__info-venue to make same size as header badge*/
@media (min-width: 768px) {
      .section__event-details .nav-tabs__info-venue + .list__event-tags li .badge {
            padding: 3px 16px 4px;
            font-size: 15px;
            letter-spacing: 0.36px;
            padding-top: 6px;
            padding-bottom: 5px;
      }

      /* .section__event-details .buttons-wrapper__nav.back-button {
            position: absolute;
            top: 54px;
            left: 20px;
      } */
}

@media (max-width : 991px) {
      .section__event-details .nav-tabs__info-venue .tab-pane__cta {
            box-shadow: 0 -3px 15px rgb(0 0 0/50%);
      }
}


@media (min-width: 992px) {
      .main__event-details #tabTicketInfoVenue {
        min-height: 700px;
      }

      .section__event-details .nav-tabs__info-venue-wrapper.fixed {
            position: fixed;
            right: 30px;
            top: 91px;
      }

      .section__event-details .nav-tabs__info-venue-wrapper.fixed-bottom-tab {
            position: absolute;
            right: 30px;
            left: auto;
            top: auto;
            bottom: 0;
            z-index: 0;
      }

      .section__event-details .sticky__tabTicketInfoVenue {
            margin-top: 0px;
            padding-bottom: 25px;
      }
}

@media (min-width: 991px) {
      .section__event-details .buttons-wrapper__nav.back-button {
            position: absolute;
            top: -30px;
            left: 33px;
      }
}
@media (min-width : 1200px) {
      .section__event-details .nav-tabs__info-venue + .list__event-tags li .badge {
            font-size: 18px;
            letter-spacing: 0.36px;
            padding: 8px 24px 7px;
      }

      .section__event-details .buttons-wrapper__nav.back-button {
            position: absolute;
            top: -34px;
            left: 33px;
      }

      .section__event-details .nav-tabs__info-venue-wrapper.fixed {
            position: fixed;
            right: 30px;
            top: 130px;
      }

      .section__event-details .nav-tabs__info-venue-wrapper.fixed-bottom-tab {
            position: absolute;
            left: auto;
            top: auto;
            bottom: 0;
            z-index: 0;
      }
}

@media (min-width: 1400px) {
      .section__event-details .buttons-wrapper__nav.back-button {
            position: absolute;
            top: -36px;
            left: 2.5%;
      }

      .section__event-details .nav-tabs__info-venue-wrapper {
            position: fixed;
      }

      .section__event-details .nav-tabs__info-venue-wrapper.fixed {
            position: fixed;
            right: 8.25%;
            top: 120px;
      }

      .section__event-details .nav-tabs__info-venue-wrapper.fixed-bottom-tab {
            position: absolute;
            right:  10px;
            left: auto;
            top: auto;
            bottom: 0;
            z-index: 0;
      }
}

@media (min-width: 1680px) {
      .section__event-details .buttons-wrapper__nav.back-button {
            position: absolute;
            top: -28px;
            left: 0.5%;
      }

      .section__event-details .nav-tabs__info-venue-wrapper.fixed {
            top: 145px;
      }


      .section__event-details .nav-tabs__info-venue-wrapper.fixed-bottom-tab {
            position: absolute;
            right:  15px;
            left: auto;
            top: auto;
            bottom: 0;
            z-index: 0;
      }

      /* .section__event-details .sticky__tabTicketInfoVenue {
            margin-top: 15px;
      } */
}

@media (min-width: 1800px) {
      .section__event-details .buttons-wrapper__nav.back-button {
            position: absolute;
            top: -41px;
            left: 126px;
      }

      .section__event-details .nav-tabs__info-venue-wrapper.fixed {
            position: fixed;
            right: 4.2%;
            top: 170px;
      }

      .section__event-details .nav-tabs__info-venue-wrapper.fixed-bottom-tab {
            position: absolute;
            right:  calc(4.2% - 0px);
            left: auto;
            top: auto;
            bottom: 0;
            z-index: 0;
      }
}

@media (min-width : 1921px) {
      .event-details-main-container {
            max-width: 1920px !important;
            margin-left: auto !important;
            margin-right: auto !important;
      }

      .section__event-details .nav-tabs__info-venue-wrapper {
            position: absolute;
      }

      .booking-module-slidein-wrapper {
            position: absolute;
            max-width: 1920px !important;
            margin-left: auto !important;
            margin-right: auto !important;
            right: 0;
      }
}
/*--------------------------------------------------------------------------------------------------- */
/*----------------------------------------- Booking Module ----------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.slidein__book-tickets .common_timer {
      position: absolute;
      right: 14%;
}


#bookTicketsStep1 .form-label-error,
#bookTicketsStep4PayViaWallet .form-label-error {
      color: #ff0a41;
      margin: 10px 0;
      visibility: visible;
}

/* ----------------spinner--------------------- */

.slidein__book-tickets .ct_widget_spinner__alone,
.slidein__wallet-topup .ct_widget_spinner__alone {
      top: 44%;
      left: 44%;
      position: absolute;
      font-size: 4rem;
      opacity: 1;
      z-index: 9999;
      color: var(--bs-red);
      transform-origin: center;
} 

.slidein-wrapper.close .slidein {
      animation-duration: 1s;
      animation-timing-function : ease;
      animation-delay : 0s;
      animation-direction: normal;
      animation-fill-mode: none;
      animation-play-state: running;
      animation-name: slideOurRight;
}

#bookTicketsStep1 .custom-checkbox-sm.custom-checkbox__check .custom-control-label::before {
      top: 2px;
      left: 0;
      width: 17px;
      height: 17px;
}

#bookTicketsStep1 .custom-checkbox-sm.custom-checkbox__check .custom-control-label::after {
      top: 3px;
      left: 2px;
      width: 14px;
      height: 15px;
      background-image: url(./assets/images/Tick.svg);
}

#bookTicketsStep1 #checkFlexiTicket + .custom-control-label {
      font-size: 12px;
}

@media (min-width:412px) {
      #bookTicketsStep1 #checkFlexiTicket + .custom-control-label {
            font-size: 14px;
      } 
}


@media (min-width:992px) {
      #bookTicketsStep1 #checkFlexiTicket + .custom-control-label {
            font-size: 18px;
      } 
}

@media (min-width:1800px) {
      #bookTicketsStep1 #checkFlexiTicket + .custom-control-label {
            font-size: 24px;
      } 
}

/* Hide tom select arrow and dropdown if multiple tickets types are not avialable */
.hideDropdownArrow .ts-dropdnow {
      display: none !important;
} 

.hideDropdownArrow .ts-wrapper:not(.form-select).single .ts-control {
      background-image: none;
}

.hideDropdownArrow  .disabled .ts-control {
      background-color: #fff;
      opacity: 1;
}

@-webkit-keyframes slideOurRight {
      from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
      }
    
      to {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
      }
}
    
@keyframes slideOurRight {
      from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
      }
    
      to {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
      }
}


/*--------------------------------------------------------------------------------------------------- */
/*-------------------------------------------- Flexi Modal ------------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

#modalFlexiTermsAndConditions {
      margin-bottom: 0;
      display: block;
}

#modalFlexiTermsAndConditions .modal-dialog {
      margin-bottom: 0px;
      margin-top: 0px;
}
/*--------------------------------------------------------------------------------------------------- */
/*-------------------------------------------- Contact Us ------------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.main__contact-us .form-label-error {
      color: #ff0a41;
      margin: 10px 0;
      visibility: visible;
}


.custom_contact_button {
      background-color: #ffffff !important;
      color: #ff0a41 !important;
}

.custom_contact_button:hover {
      background-color: #ff0a41 !important;
      color: #fff !important;
}

.content__header-logo-wrapper {
      position: relative;
}

.content__header-logo-wrapper .buttons-wrapper {
      text-align: center;
      justify-content: center;
      margin-bottom: 8px;
}


.main__contact-us .btn-prefix-arrow.active::before {
      top: 0px;
}


.main__contact-us .section__no-my-bookings {
      display: flex;
      justify-content: center;
      color: var(--bs-red);
      margin-top: 20px;
      min-height: 30vh;
}

.main__contact-us .section__no-my-bookings h1 {
      margin: 0;
}

.main__contact-us .content__footer .p-accordion-btn a {
      color: var(--bs-red) !important;
}

@media (max-width:767px) {
      .main__contact-us .btn-outline-primary.hover-primary:hover {
            color: #000;
            background-color: #fff;
            border-color: #000;
      }

      .main__contact-us .btn-outline-primary.hover-primary:focus,
      .main__contact-us .btn-outline-primary.hover-primary:active {
            background-color: var(--bs-red);
            border-color: var(--bs-red);
            color: #fff;
      }
}

@media (min-width:768px) {
      .content__header-logo-wrapper {
            text-align: center;
      }

      .content__header-logo-wrapper .buttons-wrapper {
            position: absolute;
            top: 50%;
            left: 0%;
            transform: translateY(-50%);
      }

      

}

@media (min-width:1024px) {
      .content__header-logo-wrapper .buttons-wrapper {
            left: 10%;
      }
}

@media (min-width: 1200px) {
      .main__contact-us .btn-prefix-arrow.active::before {
            top: 0px;
      }

      .content__header-logo-wrapper .buttons-wrapper {
            left: 15%;
      }
}

@media (min-width: 1400px) {

      .content__header-logo-wrapper .buttons-wrapper {
            left: 20%;
      }
}

/*--------------------------------------------------------------------------------------------------- */
/*----------------------------------------- Reactive Account  --------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */




/*--------------------------------------------------------------------------------------------------- */
/*-----------------------------------------  Privacy Account  --------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

@media (max-width: 991px) {
      .section__privacy .content__body,
      .section__terms .content__body {
            padding: 24px;
      } 

}

/* added by PP */

@media (min-width: 1000px) {
      .privacy_page .content__faq .big-heading .ticket-orange {
        bottom: 70.15%;
        left: 24.9%;
        width: 57px;
        z-index: 1;
      }
    
      .privacy_page .content__faq .big-heading .ticket-blue {
        top: 76.25%;
        left: 31.78%;
        width: 50px;
        z-index: 1;
      }
    
      .privacy_page .content__faq .big-heading .ticket-yellow {
        top: -10.5%;
        right: 23.25%;
        width: 53px;
        z-index: 1;
      }
    
      .privacy_page .content__faq .big-heading .ticket-red {
        bottom: 62.75%;
        right: 44.9%;
        width: 50px;
        z-index: 1;
      }
    
      .privacy_page .content__faq .big-heading .ticket-aquamarine {
        bottom: -30.45%;
        left: 71.35%;
        width: 67px;
        z-index: 1;
      }
    }
    
    @media (min-width: 1650px) {
      .privacy_page .content__faq .big-heading .ticket-orange {
        bottom: 70.15%;
        left: 20.9%;
        width: 67px;
        z-index: 1;
      }
    
      .privacy_page .content__faq .big-heading .ticket-blue {
        top: 76.25%;
        left: 31.78%;
        width: 60px;
        z-index: 1;
      }
    
      .privacy_page .content__faq .big-heading .ticket-yellow {
        top: -10.5%;
        right: 23.25%;
        width: 63px;
        z-index: 1;
      }
    
      .privacy_page .content__faq .big-heading .ticket-red {
        bottom: 62.75%;
        right: 44.9%;
        width: 60px;
        z-index: 1;
      }
    
      .privacy_page .content__faq .big-heading .ticket-aquamarine {
        bottom: -30.45%;
        left: 76.35%;
        width: 77px;
        z-index: 1;
      }
    }
    
    /* added by PP */
    

/*--------------------------------------------------------------------------------------------------- */
/*-----------------------------------------  Terms Page & faq --------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.section__faq .blink {
      animation: blink 2s infinite;
}

.section__faq  .accordion-button .fa-arrow-right {
      margin-left: -38px;
      margin-right: 10px;
}
.section__terms .blink {
      animation: blink 2s infinite;
}

@keyframes blink {
      0% { opacity: 1; }
      25% { opacity: 0; }
      50% { opacity: 1; }
      75% { opacity: 0; }
      100% { opacity: 1; }
}

@media (max-width : 767px) {
      .section__faq  .accordion-button .fa-arrow-right {
            margin-left: -12px;
      }
}

@media (max-width : 1024px) {
      .section__faq  .accordion-button .fa-arrow-right {
            margin-left: -12px;
      }
}

@media(min-width: 1200px) {
      .modal__fw .content.flex-xl-row .content__header h2 {
            letter-spacing: 0px;
      }
}


/* added by PP */
@media (min-width: 1150px) {
      .terms_page .content__faq .big-heading .ticket-orange {
        bottom: 57.15%;
        left: 19.9%;
        width: 57px;
        z-index: 1;
      }
    
      .terms_page .content__faq .big-heading .ticket-yellow {
        top: -24.5%;
        right: 30.25%;
        width: 53px;
        z-index: 1;
      }
    
      .terms_page .content__faq .big-heading .ticket-aquamarine {
        bottom: -18.45%;
        left: 50.35%;
        width: 67px;
        z-index: 1;
      }
      .terms_page .content__faq .big-heading .ticket-blue {
        top: 17.25%;
        left: 36.78%;
        width: 47px;
        z-index: 1;
      }
      .terms_page .content__faq .big-heading .ticket-red {
        bottom: -2.25%;
        right: 17.9%;
        width: 50px;
        z-index: 1;
      }
    }
    
    @media (min-width: 1650px) {
      .terms_page .content__faq .big-heading .ticket-orange {
        bottom: 57.15%;
        left: 9.9%;
        width: 67px;
        z-index: 1;
      }
    
      .terms_page .content__faq .big-heading .ticket-yellow {
        top: -24.5%;
        right: 24.25%;
        width: 63px;
        z-index: 1;
      }
    
      .terms_page .content__faq .big-heading .ticket-aquamarine {
        bottom: -18.45%;
        left: 50.35%;
        width: 77px;
        z-index: 1;
      }
      .terms_page .content__faq .big-heading .ticket-blue {
        top: 17.25%;
        left: 31.78%;
        width: 60px;
        z-index: 1;
      }
      .terms_page .content__faq .big-heading .ticket-red {
        bottom: -0.25%;
        right: 7.9%;
        width: 60px;
        z-index: 1;
      }
    }
    /* added by PP */
    


/*--------------------------------------------------------------------------------------------------- */
/*-------------------------------------------  About Us Page ---------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

/* Smaller Size >= 990 & <=1350x*/
@media (min-width: 990px) {
  /* for our value section */
  .content__our-values .big-heading .ticket-blue {
    bottom: 10.85%;
    left: 8.65%;
    width: 43.5px;
    z-index: 1 !important;
  }

  .content__our-values .big-heading .ticket-orange {
    top: 7.5%;
    left: 16.45%;
    width: 43px;
    z-index: 1 !important;
  }

  .content__our-values .big-heading .ticket-green {
    bottom: 19.2%;
    left: 40.85%;
    width: 45.5px;
    z-index: 1 !important;
  }

  .content__our-values .big-heading .ticket-yellow {
    top: 25.65%;
    right: 25.7%;
    width: 47.5px;
    z-index: 1 !important;
  }

  .content__our-values .big-heading .ticket-aquamarine {
    bottom: 15.5%;
    right: 5.45%;
    width: 66px;
    z-index: 1 !important;
  }

  /* for we belive section */
  .content__about-us .big-heading .ticket-blue {
    bottom: -8.4%;
    left: 10.6%;
    width: 44px;
    z-index: 1 !important;
  }

  .content__about-us .big-heading .ticket-orange {
    top: 14.95%;
    left: 21.75%;
    width: 43px;
    z-index: 1 !important;
  }

  .content__about-us .big-heading .ticket-green {
    top: 36.5%;
    left: 42.2%;
    width: 48px;
    z-index: 1 !important;
  }

  .content__about-us .big-heading .ticket-yellow {
    top: -2.45%;
    right: 20.65%;
    width: 51px;
    z-index: 1 !important;
  }

  .content__about-us .big-heading .ticket-aquamarine {
    bottom: -20.1%;
    right: 13.45%;
    width: 86px;
    z-index: 1 !important;
  }

  /* for we work togather section */
  .content__NHSCT .big-heading .ticket-yellow {
    top: 34.6%;
    left: 17.35%;
    width: 59px;
  }

  .content__NHSCT .big-heading .ticket-orange {
    bottom: -13.9%;
    left: 50.7%;
    width: 51px;
    z-index: 1 !important;
  }

  .content__NHSCT .big-heading .ticket-aquamarine {
    top: -12px;
    left: 59.5%;
    width: 73px;
  }
}

/* Smaller Size >= 1350 & <=1899x*/
@media (min-width: 1350px) {
  /* for our value section */
  .content__our-values .big-heading .ticket-blue {
    bottom: 10.85%;
    left: 8.65%;
    width: 57px;
    z-index: 1 !important;
  }

  .content__our-values .big-heading .ticket-orange {
    top: 7.5%;
    left: 16.45%;
    width: 56px;
    z-index: 1 !important;
  }

  .content__our-values .big-heading .ticket-green {
    bottom: 19.2%;
    left: 40.85%;
    width: 61px;
    z-index: 1 !important;
  }

  .content__our-values .big-heading .ticket-yellow {
    top: 36.65%;
    right: 28.7%;
    width: 65px;
    z-index: 1 !important;
  }

  .content__our-values .big-heading .ticket-aquamarine {
    bottom: 15.5%;
    right: 5.45%;
    width: 102px;
    z-index: 1 !important;
  }

  /* for we belive section */
  .content__about-us .big-heading .ticket-blue {
    bottom: -8.4%;
    left: 10.6%;
    width: 44px;
    z-index: 1 !important;
  }

  .content__about-us .big-heading .ticket-orange {
    top: 14.95%;
    left: 21.75%;
    width: 43px;
    z-index: 1 !important;
  }

  .content__about-us .big-heading .ticket-green {
    top: 36.5%;
    left: 42.2%;
    width: 48px;
    z-index: 1 !important;
  }

  .content__about-us .big-heading .ticket-yellow {
    top: -2.45%;
    right: 20.65%;
    width: 51px;
    z-index: 1 !important;
  }

  .content__about-us .big-heading .ticket-aquamarine {
    bottom: -20.1%;
    right: 13.45%;
    width: 86px;
    z-index: 1 !important;
  }

  /* for we work togather section */
  .content__NHSCT .big-heading .ticket-yellow {
    top: 34.6%;
    left: 17.35%;
    width: 69px;
  }

  .content__NHSCT .big-heading .ticket-orange {
    bottom: -7.9%;
    left: 50.7%;
    width: 61px;
    z-index: 1 !important;
  }

  .content__NHSCT .big-heading .ticket-aquamarine {
    top: 0;
    left: 59.5%;
    width: 83px;
    z-index: 1 !important;
  }
}

#sectionEcologi .no-text-color,
#sectionAboutUs .no-text-color {
      color: #000;
}

#sectionOurValues .content__body .bg-lime {
      background-color: #5FFF00 !important;
}

#sectionOurValues .content__body .bg-mustard {
      background-color: #FF8500  !important;
}

#sectionOurValues .content__body .bg-turquoise {
      background-color: #06E3B3 !important;
}

#sectionOurValues .content__body .bg-cobalt {
      background-color: #0084ff !important;
}

#sectionOurValues .content__body .bg-avocado {
      background-color: #EBFF00  !important;
}

.main__about-us #sectionNHSCT .btn-primary:hover {
      color : #fff;
}

.main__about-us .section__listing-show-steps .btn-primary {
      background-color: var(--bs-yellow) !important;
      border-color: #fff;
      color: var(--bs-body-color);
      border-color: var(--bs-body-color);
}

@media (max-width : 448px) {
      .main__about-us .big-heading h2 {
            font-size: 46px;
            line-height: 46px;
      }
}


@media (max-width :575px) {
      .main__about-us .section__listing-show-steps .btn-primary {
            width: 100%;
      }
}

@media (min-width: 576px) {
      .main__about-us .section__listing-show-steps .btn-primary {
            border-width: 1px;
            min-width: 350px;
      }
}



@media (min-width: 1200px) {
      .main__about-us .section__listing-show-steps .btn-primary {
            border-width: 2px;
      }
}

/*--------------------------------------------------------------------------------------------------- */
/*-------------------------------------  Membership Guidelines Page --------------------------------- */
/*--------------------------------------------------------------------------------------------------- */


.main__membership-guidelines .screen05 .content__screen05 .btn__scrollTo-prev-screen {
      height: 50%;
}

.main__membership-guidelines .screen05 .content__screen05 .btn__scrollTo-next-screen {
      height: 30%;
      top :70%;
}

@media (min-width : 540px) {
      .main__membership-guidelines .screen05 .content__screen05 .btn__scrollTo-prev-screen {
            height: 45%;
      }
      
      .main__membership-guidelines .screen05 .content__screen05 .btn__scrollTo-next-screen {
            height: 30%;
            top :70%;
      }
}

@media (min-width : 767px) {
      .main__membership-guidelines .screen05 .content__screen05 .btn__scrollTo-prev-screen {
            height: 40%;
      }
      
      .main__membership-guidelines .screen05 .content__screen05 .btn__scrollTo-next-screen {
            height: 40%;
            top :60%;
      }
}

@media (min-width : 1024px) {
      .main__membership-guidelines .screen05 .content__screen05 .btn__scrollTo-prev-screen {
            height: 45%;
      }
      
      .main__membership-guidelines .screen05 .content__screen05 .btn__scrollTo-next-screen {
            height: 35%;
            top :65%;
      }
}

@media (min-width : 1800px) {
      .main__membership-guidelines .screen05 .content__screen05 .btn__scrollTo-prev-screen {
            height: 45%;
      }
      
      .main__membership-guidelines .screen05 .content__screen05 .btn__scrollTo-next-screen {
            height: 20%;
            top :80%;
      }
}

/* .main__membership-guidelines .screen07 .content__body {
      max-width: 80% !important;
} */

/* / Disabled by pp / */

.main__membership-guidelines .content__screen07 .big-heading .ticket-green2 {
  top: 28.7%;
  left: 25.45%;
  width: 79px;
  z-index: -1;
  display: none;
}

.content__screen07 .big-heading .ticket-green3 {
  right: 0.3%;
  z-index: 1;
  display: none;
}

@media (min-width: 390px) {
  .main__membership-guidelines .content__screen08 .big-heading .ticket-green2 {
    top: 28.7%;
    left: 25.45%;
    width: 79px;
    z-index: -1;
  }

 .main__membership-guidelines .content__screen08 .big-heading .ticket-orange1 {
    top: 102.35%;
    left: 41.05%;
    width: 48px;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-orange2 {
    bottom: 3.95%;
    left: 77.15%;
    width: 51px;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-green1 {
    top: 25.45%;
    right: 92.65%;
    width: 44px;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-green2 {
    top: -4.8%;
    left: 39.1%;
    width: 51px;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-green3 {
    right: 2.3%;
    z-index: 1;
    width: 39px;
    top: 50%;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-yellow1 {
    right: 39.5%;
    z-index: 1;
    top: 46%;
    width: 37px;
    z-index: -1;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-yellow2 {
    right: 14.5%;
    z-index: 1;
    display: none;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-blue1 {
    bottom: 95.15%;
    left: 87.5%;
    width: 53px;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-blue2 {
    bottom: 103.65%;
    width: 72px;
    left: -8.95%;
  }

  .main__membership-guidelines
    .content__screen08
    .big-heading
    .ticket-aquamarine1 {
    bottom: -18.25%;
    right: -7.5%;
    width: 61px;
  }

  .main__membership-guidelines
    .content__screen08
    .big-heading
    .ticket-aquamarine2 {
    bottom: 12.85%;
    right: 73.6%;
    width: 59px;
  }
}

@media (min-width: 992px) {
  /* / .content__screen08 992 / */
  .main__membership-guidelines .content__screen08 .big-heading .ticket-orange1 {
    top: 52.64%;
    left: 42.95%;
    width: 52px;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-orange2 {
    bottom: 95.05%;
    left: -20.85%;
    width: 90px;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-yellow1 {
    right: 30.5%;
    z-index: -1;
    top: 21%;
    width: 34px;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-yellow2 {
    right: 14.5%;
    z-index: 1;
    display: none;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-blue1 {
    bottom: 55.65%;
    left: -0.25%;
    width: 44px;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-blue2 {
    bottom: 59%;
    width: 52px;
    left: 95%;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-green1 {
    top: 104.85%;
    right: 43.65%;
    width: 54px;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-green2 {
    top: -25.3%;
    left: 62.45%;
    width: 59px;
    z-index: -1;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-green3 {
    left: 107.9%;
    top: 105%;
    width: 99px;
  }

  .main__membership-guidelines
    .content__screen08
    .big-heading
    .ticket-aquamarine1 {
    bottom: -8.9%;
    right: 82.8%;
    width: 91px;
  }

  .main__membership-guidelines
    .content__screen08
    .big-heading
    .ticket-aquamarine2 {
    right: -17.4%;
    bottom: 40%;
    width: 48px;
  }
}

@media (min-width: 1200px) {
  /* / .content__screen08 1200 / */
  .main__membership-guidelines .content__screen08 .big-heading .ticket-orange1 {
    top: 52.64%;
    left: 68.95%;
    width: 62px;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-orange2 {
    bottom: 85.05%;
    left: -27.85%;
    width: 109px;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-yellow1 {
    left: 73.5%;
    top: 78.75%;
    right: -12.05%;
    z-index: -1;
    width: 89px;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-yellow2 {
    bottom: -14.75%;
    left: -25.95%;
    width: 80px;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-blue1 {
    bottom: 55.65%;
    left: 7.75%;
    width: 64px;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-blue2 {
    bottom: 74%;
    left: 81%;
    width: 71px;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-green1 {
    top: 104.85%;
    right: 43.65%;
    width: 64px;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-green2 {
    top: -12.3%;
    left: 43.45%;
    width: 79px;
    z-index: -1;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-green3 {
    left: 120.9%;
    top: 87%;
    width: 130px;
  }

  .main__membership-guidelines
    .content__screen08
    .big-heading
    .ticket-aquamarine1 {
    bottom: -2.9%;
    right: 74.8%;
    width: 121px;
  }
  .main__membership-guidelines
    .content__screen08
    .big-heading
    .ticket-aquamarine2 {
    right: -22.4%;
    bottom: 53%;
    width: 71px;
  }
}

@media (min-width: 1680px) {
  /* / .content__screen08 1680 / */
  .main__membership-guidelines .content__screen08 .big-heading .ticket-orange1 {
    top: 34.64%;
    left: 49.95%;
    width: 71px;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-green1 {
    top: 107.85%;
    right: 43.65%;
    width: 71px;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-green2 {
    top: -5.3%;
    left: 41.45%;
    width: 89px;
    z-index: -1;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-yellow1 {
    left: 72.5%;
    z-index: -1;
    top: 81.75%;
    width: 89px;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-green3 {
    top: 84.85%;
    left: 105.9%;
    width: 151px;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-green2 {
    top: -4.3%;
    left: 41.45%;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-blue1 {
    bottom: 53.65%;
    left: 11.75%;
    width: 69px;
  }

  .main__membership-guidelines
    .content__screen08
    .big-heading
    .ticket-aquamarine1 {
    bottom: 5.1%;
    right: 73.8%;
    width: 131px;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-blue2 {
    bottom: 76%;
    right: 6.6%;
    width: 77px;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-yellow2 {
    bottom: 4.25%;
    left: -25.95%;
    width: 89px;
  }

  .main__membership-guidelines .content__screen08 .big-heading .ticket-orange2 {
    bottom: 91.05%;
    left: -4.85%;
    width: 125px;
  }

  /* / updated / */
  .main__membership-guidelines
    .content__screen08
    .big-heading
    .ticket-aquamarine2 {
    bottom: 32%;
    right: 1.6%;
    width: 89px;
  }
}

/*--------------------------------------------------------------------------------------------------- */
/*---------------------------------------  Access Needs Page ---------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

.access-needs .access-needs-message {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 2rem;
}


.access-needs .form-group {
      max-width: 550px;
      margin-left: auto;
      margin-right: auto;
}

/*--------------------------------------------------------------------------------------------------- */
/*------------------------------------ Membership Guidlines Page ------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */  

@media (min-width:1800px) {

      .main__membership-guidelines .screen07 .content__body {
            max-width: 1100px;
      }

      .main__membership-guidelines .screen07 {
            padding-bottom: 250px;
      }
}

/*--------------------------------------------------------------------------------------------------- */
/*---------------------------------------- Feedback Surevey ----------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */  

.section__leave-feedback .step3 .form-group__heading .brand-color {
      color: var(--bs-red);
}

/* Speciallized CSS for Servey pages */
@media (min-width: 768px) {
   .section__leave-feedback.section__survey  .list__btn-checks-circle .btn-sm {
    padding: 6px 6px;
  }
  
}

@media (min-width: 1200px) {
   .section__survey .content__leave-feedback .list__btn-checks-circle {
    gap: 25px 25px;
  }

  .section__survey  .gap_beetween_likely_and_non_likly {
    gap: 45% !important;
  }
}

.section__leave-feedback.section__survey .success-exists {
  color: black;
  margin-top: 5px;
  visibility: visible;
}

.section__leave-feedback.section__survey .content__body_servy {
  padding-top: 90px !important;
  padding-bottom: 0px !important;
}

.section__leave-feedback.section__survey .form-label-error {
      text-align: left;
      margin-top: 5px;
      width: 100%;
      color: var(--bs-red);
}


/* Speciallized CSS for Servey pages - 30-08-2023*/
@media (min-width: 390px) 
{

      .section__leave-feedback.section__survey .content__leave-feedback .list__btn-checks-circle .btn-sm {
            font-size: 17px;
            letter-spacing: 0.4px;
            line-height: 18px;
            min-width: 38px !important;
            padding: 9px;
      }

      .section__leave-feedback.section__survey .success-exists {
            color: black;
            margin-top: 5px;
            visibility: visible;
            display: none;
      }
 

}


@media (min-width: 768px) {
      .section__leave-feedback.section__survey .content__leave-feedback .list__btn-checks-circle .btn-sm {
            padding: 6px 6px;
      }

      .section__leave-feedback.section__survey .content__leave-feedback .list__btn-checks-circle .btn-sm {
            font-size: 20px;
            letter-spacing: 0.4px;
            line-height: 18px;
            min-width: 41px !important;
            padding: 9px;
      }

      .section__leave-feedback.section__survey .success-exists {
            color: black;
            margin-top: 5px;
            visibility: visible;
            display: block;
      }

      .section__leave-feedback.section__survey .content__leave-feedback .list__btn-checks-circle {
            gap: 20px;
      }

      .section__leave-feedback.section__survey .gap_beetween_likely_and_non_likly {
            gap: 45% !important;
      }

}

@media (min-width: 1200px) {
      .section__leave-feedback.section__survey .content__leave-feedback .list__btn-checks-circle {
            gap: 25px 25px;
      }

      .section__leave-feedback.section__survey .gap_beetween_likely_and_non_likly {
            gap: 45% !important;
      }

      .section__leave-feedback.section__survey .content__leave-feedback .list__btn-checks-circle .btn-sm {
            font-size: 20px;
            letter-spacing: 0.4px;
            line-height: 18px;
            min-width: 41px !important;
            padding: 9px;
      }

}

.section__leave-feedback.section__survey .success-exists {
      color: black;
      margin-top: 5px;
      visibility: visible;
}
    
.section__leave-feedback.section__survey .content__body_servy {
      padding-top: 90px !important;
      padding-bottom: 0px !important;
}

/*--------------------------------------------------------------------------------------------------- */
/*------------------------------------------- Login Page -------------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */  

.section__login .content__register .big-heading .ticket-yellow {
      top: 57%;
      right: 23.3%;
      width: 32px;
      transition: all 1s;
}

.content__register .big-heading .ticket-yellow {
      transition: all 1s;
}

.section__login.section__change-password .content__register .big-heading .ticket-yellow {
      top: 86%;
      right: 6.3%;
      width: 32px;
      transition: all 1s;
}

.content__register .big-heading .ticket-orange  {
      transition: all 1s;
}

.section__login.section__change-password .content__register .big-heading .ticket-orange {
      bottom: -12.9%;
      left: 6.7%;
      width: 33px;
      transition: all 1s;
}

.section__login.section__change-password .content__register .big-heading .ticket-aquamarine {
      top: -8.5%;
      left: 43.9%;
      width: 39px;
      transition: all 1s;
}

.login-page .modal-backdrop.show {
      z-index: 9999;
}

@media (min-width: 699px) {
      .section__login .content__register .big-heading .ticket-yellow {
            top: 61.55%;
            right: 35.3%;
            width: 45px;
      }

      .section__login.section__change-password .content__register .big-heading .ticket-yellow {
            top: 86%;
            right: 14.3%;
            width: 45px;
      }

      .section__login .content__register .big-heading .ticket-orange {
            bottom: -12.9%;
            left: 34.7%;
            width: 33px;
      }

      .section__login.section__change-password .content__register .big-heading .ticket-orange {
            bottom: -12.9%;
            left: 13.7%;
            width: 33px;
      }

      .section__login .content__register .big-heading .ticket-aquamarine {
            top: -21.5%;
            left: 43.9%;
            width: 39px;
      }

      .section__login.section__change-password .content__register .big-heading .ticket-aquamarine {
            top: -21.5%;
            left: 41.9%;
            width: 39px;
      }
}

@media (min-width: 992px) {
      .section__login .content__register .big-heading .ticket-yellow {
            top: 61.55%;
            right: 38.3%;
            width: 63px;
      }

      .section__login.section__change-password .content__register .big-heading .ticket-yellow {
            top: 32%;
            right: 17.3%;
            width: 63px;
      }

      .section__login .content__register .big-heading .ticket-aquamarine {
            top: -21.5%;
            left: 45.9%;
            width: 63px;
      }

      .section__login.section__change-password .content__register .big-heading .ticket-aquamarine {
            top: -21.5%;
            left: 41.9%;
            width: 63px;
      }

      .section__login .content__register .big-heading .ticket-orange {
            bottom: -12.9%;
            left: 37.7%;
            width: 49px;
      }

      .section__login.section__change-password .content__register .big-heading .ticket-orange {
            bottom: -12.9%;
            left: 17.7%;
            width: 49px;
      }
}

.section__login .content__body .col__right {
      width: 100%;
}

.section__login .content__body .col__right .custom-control-label::before {
      width: 0;
      height: 0;
}


.section__login .content__body .col__right .form-label-error {
      color:var(--bs-red);
      margin: 0;
      padding: 0;
      border: none;
      margin-top:5px;
      margin-left:5px;
      max-width: 513px;
}

.section__login .content__body .col__right .row__cta .forgot-password-link {
      color: var(--bs-red);
      text-decoration: underline;
}

@media (min-width :1600px) {
      .section__login .content__body .col__right .form-label-error {
            max-width: 370px;
      }
}

/*--------------------------------------------------------------------------------------------------- */
/*----------------------------------------- More Infor Page ----------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */  

@media (min-width: 992px) {
      .section__more-info  .content__register  .big-heading .ticket-yellow {
            top: 59.55%;
            right: 34.3%;
            width: 63px;
      }

      .section__more-info  .content__register .big-heading .ticket-orange {
            bottom: -24.9%;
            left: 39.4%;
            width: 66px;
      }

      .section__more-info  .content__register .big-heading .ticket-aquamarine {
            top: -6.95%;
            left: 47.9%;
            width: 77px;
      }
}


.section__more-info .col__right .content__body .custom-control-input.checked~.custom-control-label::after {
      opacity: 1;
}

.section__more-info .col__right .content__body .form-check-input + .btn-outline-primary.hover-primary {
      color: #000;
      border-color: var(--bs-dark);
      background-color: transparent;
}

.section__more-info .col__right .content__body .form-check-input.btn-checked + .btn-outline-primary {
      background-color: var(--bs-red);
      border-color: var(--bs-red);
      color: #fff;
}
.section__more-info .col__right .content__body .custom-control-label.form-label-error::before {
      width: 0px;
      height: 0px;
      border: none;
}


.section__more-info .col__right .content__body .form-label-error {
      color: var(--bs-red);
}

/*--------------------------------------------------------------------------------------------------- */
/*----------------------------------- UI Change Modbile Number -------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */ 
.modal__registration-verification .content__body .form-group__button {
      margin-top: 35px;
      margin-bottom: 35px;
      flex-direction: column;
      align-items: center;
}

.modal__registration-verification .content__body .form-group__button input {
      width: 100%;
      border-radius: 5px;
}

.modal__registration-verification .content__body .form-group__button .btn {
      margin-top: 35px;
}

.modal__registration-verification .content__body span.blue{
      text-decoration: underline;
      cursor: pointer;
}

.modal__registration-verification .content__body .form-group__button .invalid-mobile-number + .btn {
      margin-top: 0px;
}

.modal__registration-verification .content__body .form-group__button .invalid-mobile-number {
      margin-top: 25px;
      margin-bottom: 25px;
      color: var(--bs-red);
}


/*--------------------------------------------------------------------------------------------------- */
/*---------------------------------- Underrepresented Page ------------------------------------ */
/*--------------------------------------------------------------------------------------------------- */  
.main__underrepresented_communities {

}

.main__underrepresented_communities .card-body .card-title {
      font-size: 30px;
      line-height: 1.2;
}

.main__underrepresented_communities .card-body .header-date .card-title {
      padding-right: 0;
}

.main__underrepresented_communities .card-body .header-date .card-title.date-range {
      font-weight: 400;
      font-size: 15px;
}

.underrepresented-list-items {
      /* margin: 0; */
}

.underrepresented-list-items .card-title {
      font-size: 15px;
      line-height: 1.2;
      padding: 0;
      margin-bottom: 12px;
      text-align: center;
}

.underrepresented-list-items .report_download_button {
      margin-top: 10px;
      margin-bottom: 10px;
}

.card-underrepresented-list-item {
      border-radius: 10px;
      background-color: transparent;
      margin-right: auto;
      margin-bottom: 10px;
}



.list-underrepresented-detail {
      border: 1px solid #000;
      border-radius: 10px;
      margin: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      list-style: none;
      padding: 10px 10px;
      align-items: center;
      
}

.list-underrepresented-detail {
      font-size: 15px;
      letter-spacing: 0.3px;
      line-height: 20px;
      gap: 0 30px;
}


.list-underrepresented-detail .heading {
      color: var(--bs-red);
      font-weight: bold;
      display: inline-block;
}

.main__underrepresented_communities .events_slider_wrapper {
      position: relative;
      border: 1px solid #000;
      padding-top: 20px;
      padding-bottom: 20px;
      border-radius: 10px;
      overflow: hidden;
     
}


.main__underrepresented_communities .events_slider_wrapper #owlCarouselEventTagsNav button.owl-prev {
      border: none;
      border-right: 1px solid #000;
}


.main__underrepresented_communities .events_slider_wrapper #owlCarouselEventTagsNav button.owl-next {
      border: none;
      border-left: 1px solid #000;
}

.main__underrepresented_communities .list__event-tags-wrapper .owl-stage-outer{
      height:30px !important;
}


@media (min-width: 767px) {
      .main__underrepresented_communities .card-body .card-title {
            font-size: 40px;
      }

      .underrepresented-list-items .card-title {
            font-size: 25px;
            margin-bottom: 15px;
      }

      
}

@media (min-width: 992px) {
      .main__underrepresented_communities .card-body .header-date .card-title.date-range {
            font-size: 15px;
            line-height: 20px;
      }

      .underrepresented-list-items .card-title {
            text-align: center;
            margin-bottom: 20px;
      }

      .main__underrepresented_communities .report_download_button {
            margin-top: 10px;
      }
}




@media (min-width: 1200px) {
      .main__underrepresented_communities .card-body .card-title {
            font-size: 40px;
      }

      .main__underrepresented_communities .card-body .header-date .card-title.date-range {
            font-size: 20px;
            line-height: 24px;
      }

      .main__underrepresented_communities .list__event-tags-wrapper  .owl-stage-outer{
            height:54px !important;
      }

      .underrepresented-list-items .card-title {
            text-align: center;
      }

      .main__underrepresented_communities .report_download_button {
            margin-top: 20px;
      }

      .main__underrepresented_communities .row-uptake-list-progress {
            margin-bottom: 0px;
      }
}




/*


.list-underrepresented-detail {
      background-color: red;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      list-style: none;
      padding: 1rem 0.5rem;
} */

/*--------------------------------------------------------------------------------------------------- */
/*-----------------------------------------  Web View Save Card -------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */


/* ----------------spinner--------------------- */

.section__web-view-save-card .ct_widget_spinner__alone,
.section__web-view-save-card .ct_widget_spinner__alone {
      top: 44%;
      left: 44%;
      position: absolute;
      font-size: 4rem;
      opacity: 1;
      z-index: 9999;
      color: var(--bs-red);
      transform-origin: center;
} 

/*--------------------------------------------------------------------------------------------------- */
/*-------------------------------------- Web View Booking Module ------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */

/* / css for checkout date / */
#WebViewBookingModule .new_content__select-time {
      padding: 0;
}

#WebViewBookingModule  .new_content__select-time .col__left,
#WebViewBookingModule  .new_content__select-time .col__right {
      padding-left: 20px;
      padding-right: 20px;
}

#WebViewBookingModule #contentSelectEventTime.web__view_style,
#contentSelectEventTime.mobile_view_style {
      display: flex !important;
      justify-content: space-between;
      margin-right: 30px;
}
#WebViewBookingModule #contentSelectEventTime.web__view_style .selected-date,
#WebViewBookingModule #contentSelectEventTime.web__view_style .selected-time,
#contentSelectEventTime.mobile_view_style .selected-date,
#contentSelectEventTime.mobile_view_style .selected-time {
      display: flex;
      flex-direction: column;
}

#WebViewBookingModule  .new_content__select-time #contentTitle {
      padding-left: 20px;
      padding-right: 20px;
}     

@media (max-height : 600px) {
      #WebViewBookingModule  .webview__content__hide-title {
            display: none;
      }
}


#WebViewBookingModule  .new_content__select-time .new_checkour_date_title {
      padding-left: 20px;
      padding-right: 20px;
}
 
#WebViewBookingModule .new_checkour_date_title {
  border-bottom: 1px solid;
  padding-bottom: 15px;
  border-top: 1px solid;
  padding-top: 15px;
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
}

#WebViewBookingModule .new_checkout_event_title {
  color: var(--bs-red);
  font-weight: bold;
}

/* / end css for checkout date / */

#WebViewBookingModule .content__header {
      border-top: 1px solid #dee2e6!important;
      border-color: var(--bs-dark) !important;
}

#WebViewBookingModule .webview__content__title {
      color: var(--bs-red);
      font-weight: bold;
}

#WebViewBookingModule .ts-control,
#WebViewBookingModule #btnSelectEventDate {
      border-radius: 0.5rem;
}

/* #WebViewBookingModule #bookTicketsStep1 .content__body--total {
      border-bottom: 1px solid #dee2e6!important;
      border-color: var(--bs-dark) !important;
} */

#WebViewBookingModule #bookTicketsStep1 + .content__footer__wrapper .buttons-wrapper,
#WebViewBookingModule #bookTicketsStep2 + .content__footer__wrapper .buttons-wrapper,
#WebViewBookingModule #bookTicketsStep3 + .content__footer__wrapper .buttons-wrapper,
#WebViewBookingModule #bookTicketsStep4PayViaWallet + .content__footer__wrapper .buttons-wrapper,
#WebViewBookingModule #bookTicketsStep5OrderSummaryPayViaWalletPlusCard + .content__footer__wrapper .buttons-wrapper,
#WebViewBookingModule #bookTicketsStep4PayViaWalletPlusCard + .content__footer__wrapper .buttons-wrapper,
#WebViewBookingModule #bookTicketsStep4PayViaCard + .content__footer__wrapper .buttons-wrapper {
      min-height: 77px !important;
      justify-content: center;
      align-items: center;
      background-color: #fff;
}


#WebViewBookingModule #bookTicketsStep1 + .content__footer__wrapper .buttons-wrapper button {
      width: 80% !important;
      min-height: 24px !important;
      border-radius: 24px;
}

#WebViewBookingModule #bookTicketsStep2 .content__body.border-top:not(.content__body--total) {
      border-top: none !important;
}

#WebViewBookingModule #bookTicketsStep2 .event__date, 
#WebViewBookingModule #bookTicketsStep5OrderSummaryPayViaWalletPlusCard .event__date {
      padding-top: 10px;
      padding-bottom: 10px;
      margin: 0;
      border-top: 1px solid var(--bs-dark)!important;
}

#WebViewBookingModule #bookTicketsStep2 tbody:last-child {
      border-bottom: 1px solid var(--bs-dark)!important;
}

#WebViewBookingModule #bookTicketsStep2 .logos-wrapper {
      margin-top: 0 !important;
}


#WebViewBookingModule #bookTicketsStep2 + .content__footer__wrapper .buttons-wrapper,
#WebViewBookingModule #bookTicketsStep3 + .content__footer__wrapper .buttons-wrapper,
#WebViewBookingModule #bookTicketsStep4PayViaWallet + .content__footer__wrapper .buttons-wrapper.web-view-changes,
#WebViewBookingModule #bookTicketsStep5OrderSummaryPayViaWalletPlusCard + .content__footer__wrapper .buttons-wrapper,
#WebViewBookingModule #bookTicketsStep4PayViaWalletPlusCard + .content__footer__wrapper .buttons-wrapper,
#WebViewBookingModule #bookTicketsStep4PayViaCard + .content__footer__wrapper .buttons-wrapper {
      justify-content: space-around;
      background-color: #fff;
}

#WebViewBookingModule #bookTicketsStep2 + .content__footer__wrapper .buttons-wrapper button, 
#WebViewBookingModule #bookTicketsStep3 + .content__footer__wrapper .buttons-wrapper button,
#WebViewBookingModule #bookTicketsStep4PayViaWallet + .content__footer__wrapper .buttons-wrapper.web-view-changes button,
#WebViewBookingModule #bookTicketsStep5OrderSummaryPayViaWalletPlusCard + .content__footer__wrapper .buttons-wrapper button,
#WebViewBookingModule #bookTicketsStep4PayViaWalletPlusCard + .content__footer__wrapper .buttons-wrapper button,
#WebViewBookingModule #bookTicketsStep4PayViaCard + .content__footer__wrapper .buttons-wrapper button {
      min-height: 24px !important;
      border-radius: 24px;
      width: 40% !important;
      border: none;
}

#WebViewBookingModule #bookTicketsStep2 + .content__footer__wrapper .buttons-wrapper .btn__back,
#WebViewBookingModule #bookTicketsStep3 + .content__footer__wrapper .buttons-wrapper .btn__back,
#WebViewBookingModule #bookTicketsStep4PayViaWallet + .content__footer__wrapper .buttons-wrapper.web-view-changes .btn__back,
#WebViewBookingModule #bookTicketsStep5OrderSummaryPayViaWalletPlusCard + .content__footer__wrapper .buttons-wrapper .btn__back,
#WebViewBookingModule #bookTicketsStep4PayViaWalletPlusCard + .content__footer__wrapper .buttons-wrapper .btn__back,
#WebViewBookingModule #bookTicketsStep4PayViaCard + .content__footer__wrapper .buttons-wrapper .btn__back {
      border: 1px solid var(--bs-dark);
}

#WebViewBookingModule #bookTicketsStep4PayViaWallet .input-group__amount {
      width: 100%;
}

#WebViewBookingModule #bookTicketsStep5OrderSummaryPayViaWalletPlusCard + .content__footer__wrapper .subtotal-block:not(:last-of-type) {
      border-bottom: 1px solid var(--bs-dark);
      padding-bottom: 5px;
      margin-bottom: 0.5rem;
}

#bookTicketsStep4PayViaCard hr {
      display: none;
}

#WebViewBookingModule .slidein-wrapper {
      height: 100vh;
      top: 0;
}

#WebViewBookingModule .slidein .content__slidein {
      height: 100vh;
}

#WebViewBookingModule .slidein .content__checkout {
      height: 100vh;
}

#WebViewBookingModule .slidein .content__checkout>.content {
      height: calc(100vh - 60px);
}

/* Wallet TopUp */
#WebViewWalletModule main {
      padding-top: 0px;
}

#WebViewWalletModule  .terms_web li {
      padding: 11px 0px 0px 0px;
}

#WebViewWalletModule  .terms_web ul,
#WebViewWalletModule  .terms_web ol {
      list-style-type: none;
}

#WebViewWalletModule  .terms_web ol {
      padding-inline-start: 5px !important;
      margin-block-start: 1px !important;
      margin-block-end: 1px !important;
}
#WebViewWalletModule  .spacing_manage {
      margin-block-start: 3px !important;
      margin-block-end: 3px !important;
}

#WebViewWalletModule  .profileHear {
      margin-top: 140px !important;
}

#WebViewWalletModule  .terms_web span,
#WebViewWalletModule  .terms_web p {
      font-size: 20px !important;
      letter-spacing: 0.4px;
      line-height: 26px;
      }



#WebViewWalletModule .content__wallet-topup .content__header {
      border-top: 1px solid var(--bs-dark)!important;
}

#WebViewWalletModule #walletTopupStep1 .form-group-wrapper {
      display: flex;
      flex-direction: row !important;
      justify-content: space-between;
      align-items: center;
}

#WebViewWalletModule #walletTopupStep1 .form-group-wrapper .input-group {
      width: auto;
}

#WebViewWalletModule #walletTopupStep2 hr {
      height: 0;
}

#WebViewWalletModule #walletTopupStep2 .buttons-wrapper {
      min-height: 77px !important;
      justify-content: center;
      align-items: center;
}

#WebViewWalletModule #walletTopupStep2 .buttons-wrapper {
      justify-content: space-around;
}

#WebViewWalletModule #walletTopupStep2 .buttons-wrapper button {
      min-height: 24px !important;
      border-radius: 24px;
      width: 40% !important;
      border: none;
}

#WebViewWalletModule #walletTopupStep2 .buttons-wrapper .btn__back {
      border: 1px solid var(--bs-dark);
}

#WebViewWalletModule #walletTopupSuccess .content__header {
      border-top: none !important;
}

#WebViewWalletModule .slidein-wrapper {
      top: 0;
      height: 100vh;
}

#WebViewWalletModule .slidein .content__slidein {
      height: 100vh;
}

#WebViewWalletModule .slidein .content__checkout {
      height: 100vh;
}

#WebViewWalletModule .slidein .content__checkout>.content {
      height: 100vh;
}

/* Save Cards */
#webViewSaveCard .accordion-item__SavedCards .accordion-body {
      padding-left: 0;
      padding-right: 0;
}

#webViewSaveCard .accordion-item__SavedCards .accordion-body .row {
      padding-left: 20px;
      padding-right: 20px;
}

#webViewSaveCard .accordion-item__SavedCards .accordion-body form .form-group__cta,
#webViewSaveCard .accordion-item__SavedCards .accordion-body form .form-group__cta + p {
      padding-left: 20px;
      padding-right: 20px;
}


#webViewSaveCard .accordion-item__SavedCards .heading {
      border-top: 1px solid var(--bs-dark);
      border-bottom: 1px solid var(--bs-dark);
      padding: 10px;
}

#webViewSaveCard .content__header {
      padding-left: 10px;
      padding-top: 0px;
      padding-bottom: 0px;
}

#webViewSaveCard .big-heading h1 {
      font-size: calc(1.275rem + .3vw);
}

/*  */


/*--------------------------------------------------------------------------------------------------- */
/*----------------------------------- Web View Flexi Modal Page ------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */  

.content-flexi-modal .ticket {
      position: absolute;
      height: auto;
      opacity: 1;
      transform: none;
      transition-delay: 0.2s;
}

.content-flexi-modal .ticket-green1 {
      left: 19%;
      top: 1.1%;
      width: 22px;
      z-index: 1;
}
    
.content-flexi-modal .ticket-AquamarineSm {
      left: 71.6%;
      top: 1.2%;
      width: 24px;
      z-index: 0;
}

.content-flexi-modal .ticket-blue {
      left: 49.6%;
      top: 0.4%;
      width: 18px;
      z-index: 1;
}

.content-flexi-modal .ticket-red {
      left: 67.6%;
      top: 2.1%;
      width: 23px;
      z-index: 1;
}

.content-flexi-modal .ticket-orange1 {
      left: 32.6%;
      top: 2.1%;
      width: 24px;
      z-index: 1;
}



/*--------------------------------------------------------------------------------------------------- */
/*---------------------------------- Web View Wallet Terms Modal ------------------------------------ */
/*--------------------------------------------------------------------------------------------------- */  

.content-wallet-terms-modal .ticket-orange1 {
  left: 31.6%;
  top: 0.1%;
  width: 24px;
  z-index: 1;
}

.content-wallet-terms-modal .ticket-AquamarineSm {
  left: 49.6%;
  top: 3.6%;
  width: 24px;
  z-index: 0;
}

.content-wallet-terms-modal .ticket-red {
  left: 67.6%;
  top: 1.1%;
  width: 23px;
  z-index: 1;
}

.content-wallet-terms-modal .ticket-blue {
  left: 24.6%;
  top: 4.8%;
  width: 18px;
  z-index: 1;
}

.content-wallet-terms-modal .ticket-green1 {
  left: 70%;
  top: 7.1%;
  width: 22px;
  z-index: 1;
}

.content-wallet-terms-modal .ticket {
  position: absolute;
  height: auto;
  opacity: 1;
  transform: none;
  transition-delay: 0.2s;
}

/*--------------------------------------------------------------------------------------------------- */
/*----------------------------- Ticket Placement for ReferFriend Page ------------------------------- */
/*--------------------------------------------------------------------------------------------------- */  
/* CSS for new referal page */

@media (max-width: 575px) {
      .content__refer-friend .big-heading .ticket-blue {
        bottom: -11.6%;
        left: 39.5%;
        width: 46px;
      }
    
      .content__refer-friend .big-heading .ticket-aquamarine {
        top: 22.1%;
        right: 12.5%;
        width: 48px;
      }
    
      .content__refer-friend .big-heading .ticket-orange {
        top: 65.1%;
        right: 13.6%;
        width: 54px;
      }
    }
    @media (min-width: 900px) {
      .content__refer-friend .big-heading .ticket-aquamarine {
        top: 15.4%;
        left: 18.15%;
        width: 55px;
        z-index: 1;
      }
    
      .content__refer-friend .big-heading .ticket-yellow {
        bottom: -6.25%;
        left: 27.15%;
        width: 68px;
        z-index: 1;
      }
    
      .content__refer-friend .big-heading .ticket-red {
        top: -11.75%;
        left: 42.35%;
        width: 63px;
        z-index: 1;
      }
    
      .content__refer-friend .big-heading .ticket-blue {
        bottom: -13.65%;
        left: 51.7%;
        width: 51px;
      }
    
      .content__refer-friend .big-heading .ticket-orange {
        top: 5.1%;
        right: 27.85%;
        width: 64px;
      }
    
      .content__refer-friend .big-heading .ticket-green {
        bottom: -15.4%;
        right: 18.45%;
        width: 68px;
        opacity: 1;
        z-index: -1 !important;
      }
    }
    
    @media (min-width: 1600px) {
      .content__refer-friend .big-heading .ticket-aquamarine {
        top: 15.4%;
        left: 18.15%;
        width: 75px;
      }
    
      .content__refer-friend .big-heading .ticket-yellow {
        bottom: 5.75%;
        left: 27.15%;
        width: 88px;
      }
    
      .content__refer-friend .big-heading .ticket-red {
        top: 4.25%;
        left: 42.35%;
        width: 83px;
      }
    
      .content__refer-friend .big-heading .ticket-orange {
        top: 18.1%;
        right: 26.85%;
        width: 84px;
      }
    
      .content__refer-friend .big-heading .ticket-green {
        bottom: 5.6%;
        right: 18.45%;
        width: 88px;
        opacity: 1;
        z-index: -1 !important;
      }
    }
    

/*--------------------------------------------------------------------------------------------------- */
/*----------------------------- Ticket Placement for Update Name Modal ------------------------------- */
/*--------------------------------------------------------------------------------------------------- */  

/* Tickets placement for firstname lastname validation model */

.content-fname-validation-modal .ticket-orange1 {
      left: 23.6%;
      top: 25.1%;
      width: 34px;
      z-index: 1;
    }
    
    .content-fname-validation-modal .ticket-green1 {
      left: 72%;
      top: 29.1%;
      width: 32px;
      z-index: 1;
    }
    
    .content-fname-validation-modal .ticket-blue {
      left: 51.6%;
      top: 23.4%;
      width: 28px;
      z-index: 1;
    }
    
    .content-fname-validation-modal .ticket-red {
      left: 21.6%;
      top: 12.1%;
      width: 33px;
      z-index: 1;
    }
    
    .content-fname-validation-modal .ticket-AquamarineSm {
      left: 66.6%;
      top: 12.2%;
      width: 33px;
      z-index: 0;
    }
    
    @media (max-width: 1100px) {
      .content-fname-validation-modal .ticket-red {
        left: 20.6%;
        top: 14.1%;
        width: 23px;
        z-index: 1;
      }
    
      .content-fname-validation-modal .ticket-blue {
        left: 45.6%;
        top: 14.4%;
        width: 19px;
        z-index: 1;
      }
    
      .content-fname-validation-modal .ticket-orange1 {
        left: 75.6%;
        top: 16.1%;
        width: 22px;
        z-index: 1;
      }
    
      .content-fname-validation-modal .ticket-green1 {
        left: 62%;
        top: 24.1%;
        width: 25px;
        z-index: 1;
      }
    
      .content-fname-validation-modal .ticket-AquamarineSm {
        left: 29.6%;
        top: 24.2%;
        width: 23px;
        z-index: 0;
      }
    }
    
    @media (max-width: 767px) {
      .content-fname-validation-modal .ticket-red {
        left: 15.6%;
        top: 14.1%;
        width: 18px;
        z-index: 1;
      }
    
      .content-fname-validation-modal .ticket-blue {
        left: 45.6%;
        top: 14.4%;
        width: 14px;
        z-index: 1;
      }
    
      .content-fname-validation-modal .ticket-orange1 {
        left: 80.6%;
        top: 16.1%;
        width: 17px;
        z-index: 1;
      }
    
      .content-fname-validation-modal .ticket-green1 {
        left: 62%;
        top: 23.1%;
        width: 20px;
        z-index: 1;
      }
    
      .content-fname-validation-modal .ticket-AquamarineSm {
        left: 29.6%;
        top: 22.2%;
        width: 18px;
        z-index: 0;
      }
    }
    /* Tickets placement for firstname lastname validation model */

    /* Current Listing Carousel Image Size */

    @media (min-width: 768px)
    {
      .content__current-listings .owl-carousel__events .event-image
      {
            width: 180px !important;
      }   
   }

   @media (min-width: 1200px)
    {
      .content__current-listings .owl-carousel__events .event-image
      {
            width: 216px !important;
      }   
   }

   @media (min-width: 1400px)
    {
      .content__current-listings .owl-carousel__events .event-image
      {
            width: 252px !important;
      }   
   }

    @media (min-width: 1800px)
    {
      .content__current-listings .owl-carousel__events .event-image
      {
            width: 320px !important;
      }   
   }

   
   
  
/* / Css to hide tickets behind the text / */
@media (max-width: 991px) {
  .content__for-members .ticket-green {
    left: 22.8%;
    top: 31.5%;
    width: 48px;
    z-index: -1;
  }

  .content__NHSCT .big-heading .ticket-yellow {
    bottom: -20.6%;
    left: 30%;
    width: 33px;
    z-index: 2;
  }
}

.content__about-us .big-heading .ticket-aquamarine {
  bottom: -15.5%;
  right: 3.9%;
  width: 54px;
  z-index: -1 !important;
}
/* / Css to hide tickets behind the text / */

.payment-request-button {
      margin: 20px 0;
      border: 1px solid #000;
      border-radius: 10px;
      box-shadow: 0 0 0 1px transparent;
      overflow: hidden;
    }
    
/*--------------------------------------------------------------------------------------------------- */
/*----------------------------------- Modal Cookies design ----------------------------------------- */
/*--------------------------------------------------------------------------------------------------- */ 
    
.modal-cookies {
      bottom: 0 !important;
      top: auto;
      height: auto;
}

.modal-cookies.modal__terms-and-conditions {
      line-height: 30px;
}

.modal-cookies .modal-dialog{
      margin: 0;
      box-shadow: 0 2px #999;
      bottom: 0;
}

.modal-cookies .modal-body {
      background-color:#fff;
      padding: 16px;
}

.modal-cookies p {
      margin-bottom: 8px;
      font-size: 14px;
}

.modal-cookies .cookie-home-page p {
      margin-bottom: 0px;
}

.modal-cookies label{
      font-size: 14px;
}

.modal-cookies .custom-checkbox__radio .custom-control-label::before {
      width: 18px;
      height: 18px;
}

.modal-cookies h5 {
      font-size: 18px;
      margin-bottom: 8px;
}


.modal-cookies.modal__fw .modal-content {
      background: var(--bs-body-color) !important;  
      border-top: 1px solid #000;
}

.modal-cookies .cookie-banner {
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: 0px solid transparent;
      background-color: #fff;
      /* background-color: #f3f2f1; */
}


.modal-cookies .width-container {
      max-width: 960px;
      margin-right: 0;
      margin-left: 0;
}

.modal-cookies .cookie-banner__message {
      margin-bottom: 0;
}



.modal-cookies .grid-column-two-thirds {
      box-sizing: border-box;
      width: 100%;
      padding: 0 15px;
}

.modal-cookies h2 {
      font-size: 23px;
      margin-bottom: 16px;
}

.modal-cookies .cookie-home-page h2 {
      margin-bottom: 10px;
}

.modal-cookies .btn-primary {
      font-size: 18px;
      padding: 4px 8px 4px;
      min-width: 95px;
      /* min-width: 125px; */
}

.modal-cookies .form-group__cta  {
      margin-top: 16px;
}
.modal-cookies .form-group__cta .reject {
      color: var(--bs-body-color);
      background-color: #fff;
      border-color: var(--bs-body-color);
}

.modal-cookies .form-group__cta .btn:last-child {
      margin-left: 20px;
}

.modal-cookies .form-group__cta .more-options {
      color: var(--bs-body-color);
      border: none;
      cursor: pointer;
      margin: 0;
      padding: 0;
      background: none;
      font-weight: 700;
      text-decoration: underline;
      font-size: 14px;
}


.modal-cookies .cookie-settings {
      padding: 0;
      margin: 0;
}

.modal-cookies .cookie-settings h2 {
      margin: 0;
      padding: 0;
      border-bottom: 1px solid #e4e4e4;
      padding-bottom: 16px;
      font-size: 23px;
      position: relative;
}

.modal-cookies .cookie-settings .btn-close__absolute {
      top: 0px;
      width: 23px;
      right: 25px;
}

.modal-cookies .cookie-settings .content {
      margin: 0;
      margin-top: 16px;
      margin-bottom: 0;
      padding: 0;
}

.modal-cookies .cookie-settings.width-container {
      width: 100%;
      max-width: 100% !important;
}


.modal-cookies .cookie-settings .row-container {
      margin: 0;
      padding: 0;
      width: 100%;
}

.modal-cookies .cookie-settings .content .custom-control:first-child {
      margin: 0;
}

.modal-cookies .cookie-settings .content .custom-control {
      margin: 0;
      margin-top: 16px;
}

.modal-cookies .custom-checkbox__radio .custom-control-label::after {
      top: 3px;
      left: 2px;
      width: 14px;
      height: 14px;
}

@media (max-width: 767px) {
      .modal-cookies h2 {
            letter-spacing: .6px;
      }

      .modal-cookies .cookie-home-page .row-2 .text-button-wrapper {
            margin-left: 16px;
            align-items: center;
            vertical-align: middle;
            justify-content: center;
            display: flex;
      }

      
}

@media (min-width: 767px) {
      .modal-cookies .cookie-home-page {
            display: flex;
            width: 100%;
            max-width: 100%;
            flex-direction: column;
      }

      .modal-cookies .cookie-home-page .row-1 {
            margin: 0;
            width: 100%;
      }

      .modal-cookies .cookie-home-page .row-2 {
            margin: 0;
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: row;
            margin-top: 16px;
      }

      .modal-cookies .cookie-home-page .row-2 .form-group__cta {
            display: flex !important;
            justify-content: flex-start;
            align-items: center;
            margin: 0;
      }

      .modal-cookies .cookie-home-page .row-2 .text-button-wrapper {
            margin-right: 16px;
      }

      .modal-cookies .form-group__cta .btn:last-child {
            margin-left: 16px;
      }

      .modal-cookies .cookie-home-page .row-2 .text-button-wrapper {
            margin-left: 16px;
            align-items: center;
            vertical-align: middle;
            justify-content: center;
            display: flex;
      }

}